const langs = {
    gb: {
        _name: 'English',
        timePickerModal: {
            title: 'Close In Time'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Take Profit & Stop Loss',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            updateOrder: 'Update Order',
        },
        balanceModal: {
            balance: 'Balance',
            credit: 'Credit',
            equity: 'Equity',
            practice: 'PRACTICE',
            real: 'REAL',
            account: 'ACCOUNT',
            invested: 'Invested',
            profit: 'Profit',
            loss: 'Loss',
            margin: 'Margin',
            marginLevel: 'Margin Level',
            marginFree: 'Free Margin'
        },
        header: {
            cfd: 'CFD',
            platform: 'Platform',
            deposit: 'Deposit'
        },
        sideMenu: {
            marketWatch: 'Market Watch',
            activeOrders: 'Active Orders',
            tradingHistory: 'Trading History',
            economicCalendar: 'Economic Calendar',
            marketNews: 'Market News'
        },
        closePositionModal: {
            closeOrder: 'Close Position',
            areYouSure: 'Are you sure to close the position',
            buy: 'Buy',
            sell: 'Sell',
            yes: 'Yes',
            no: 'No',
        },
        pciDssModal: {
            desc: `Payment Card Industry Data Security Standard (PCI DSS) compliance is required of all entities that store, process, or transmit Visa/Master Card/Stripe
            cardholder data, including financial institutions, merchants and service providers.
            IT is the global security standard for all entities that store, process, or transmit cardholder data and/or sensitive authentication data. PCI DSS sets a baseline level of protection for consumers and helps reduce fraud and data breaches across the entire payment ecosystem.`
        },
        chart: {
            addNewChart: 'Add New Chart',
        },
        symbolsModal: {
            watchlist: 'Watchlist',
            asset: 'Asset',
            price: 'Price',
            changePct: 'Change 24h'
        },
        pendingModal: {
            pending: 'Pending',
            assetPrice: 'Asset Price',
            current: 'Current',
            revert: 'Revert to Market Price',
            automatically: 'Position will be opened automatically when the price reaches this level'
        },
        orderMenu: {
            default: 'default',
            volume: 'Volume',
            lots: 'lots',
            units: 'units',
            currency: 'currency',
            contractSize: 'Contract size',
            position: 'Position',
            margin: 'Margin',
            freeMargin: 'Free Margin',
            takeProfitStopLoss: 'Take Profit & Stop Loss',
            pending: 'Pending',
            market: 'Market',
            leverage: 'Leverage',
            spread: 'Spread',
            notSet: 'Not set',
            at: 'at',
            buy: 'buy',
            sell: 'sell',
        },
        footer: {
            supportBanner: 'EVERY DAY, AROUND THE CLOCK',
            currentTime: 'CURRENT TIME',
            liveChat: 'Live Chat'
        },
        statusBar: {
            balance: 'Balance',
            credit: 'Credit',
            equity: 'Equity',
            margin: 'Margin',
            marginLevel: 'Margin level',
            freeMargin: 'Free Margin',
            pnl: 'PnL',
            profitTotal: 'Lifetime PnL'
        },
        accountModal: {
            dateRegistered: 'Date Registered',
            userId: 'User ID',
            dashboard: 'Dashboard',
            personalData: 'Personal Data',
            deposit: 'Deposit',
            withdrawFunds: 'Withdraw Funds',
            savings: 'Savings',
            settings: 'Settings',
            logout: 'Logout'
        },
        depositModal: {
            BT_INVOICE: 'Enter amount and generate invoice',
            BT_TITLE: 'Our banking details',
            account: 'Account',
            bank_name: 'Bank Name',
            beneficiary: 'Beneficiary',
            beneficiary_address: 'Beneficiary Address',
            swift: 'Swift',
            reference: 'Reference',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Amount',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Description',
            TOKEN_PAYMENT_DETAILS: 'Payment Details',
            PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
            makeDeposit: 'Make a Deposit',
            practiceAccount: 'Practice Account',
            realAccount: 'Real Account',
            noWithdrawals: 'No Withdrawals',
            easyWithdrawals: 'Easy Withdrawals',
            allAssets: 'All assets available',
            fullFledged: 'Full-ledged platform',
            fillUpTo: 'Fill Up To',
            freeReplenishment: 'Free replenishment',
            topUp: 'Top Up Your Account',
            minimumAmount: 'Minimum amount',
            canSwitch: 'You can switch between your accounts at any moment'
        },
        gridsModal: {
            chartGrids: 'CHART GRIDS',
            chart1: '1 chart',
            chart2: '2 charts',
            chart3: '3 charts',
            chart4: '4 charts',
        },
        noConnectionModal: {
            connectionLost: 'Connection with the server is lost',
            retryNow: 'Retry Now',
        },
        loginModal: {
            loginToTradeRoom: 'Login To Traderoom',
            email: 'Email address',
            enterEmail: 'Enter your email',
            twoFactor: '2FA Code (if enabled)',
            twoFactorAuth: 'Two-factor authentication',
            password: 'Password',
            yourPassword: 'Your password',
            signIn: 'Sign In',
            dontHaveAccount: 'Don’t have an account?',
            restore: 'Restore',
            fullName: 'Full Name',
            havePromo: 'I have a promo-code',
            promo: 'Promo code',
            login: 'Login',
            haveAccount: 'Already have an account?',
            hide: 'Hide',
            goTo: 'Go to back'
        },
        newPasswordModal: {
            setNewPassword: 'Set New Password',
            newPassword: 'New password',
            confirmNewPassword: 'Confirm new password',
            setButton: 'Set'
        },
        registerModal: {
            repeatPassword: 'Repeat Password',
            createNewAccount: 'Create A New Account',
            email: 'Email address',
            firstName: 'First Name',
            lastName: 'Last Name',
            country: 'Country',
            phone: 'Phone',
            password: 'Password',
            createAccount: 'Create Account',
            currency: 'Currency',
            privacyAndPolicy:  'Privacy & Policy',
            customerAgreement: 'Customer Agreement',
        },
        forgotPasswordModal: {
            forgotPassword: 'Forgot Password?',
            submitEmail: 'Please submit an email used for registration, check your inbox and follow the instructions provided',
            submit: 'Submit'
        },
        notifications: {
            error: 'Error',
            success: 'Success',
            deposit: 'Deposit',
            withdrawal: 'Withdrawal',
            depositApproved: 'Deposit has been approved',
            depositDeclined: 'Deposit has been declined',
            withdrawalApproved: 'Withdrawal has been approved',
            withdrawalDeclined: 'Withdrawal has been declined'
        },
        markets: {
            forex: 'Forex',
            stocks: 'Stocks',
            commodities: 'Commodities',
            indices: 'Indices',
            crypto: 'Crypto',
            metals: 'Metals',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'BUY',
            profitCalculator: 'Profit Calculator',
            sell: 'SELL',
            volume: 'Volume',
            entryPrice: 'Entry Price',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            maxPosition: 'Max Position',
            calculate: 'Calculate',
            calculationsFor: 'Calculations for',
            leverage: 'Leverage',
            requiredMargin: 'Required Margin',
            profitFromTP: 'Profit from TP',
            lossFromSL: 'Loss from SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Deposit successful',
            text: 'Your deposit has been processed successfully!',
        },
        depositFailModal: {
            title: 'Deposit failed',
            text: 'Your deposit has not been processed.',
        },
        widgetMarketWatch: {
            marketWatch: 'Market Watch',
            search: 'Search...',
        },
        widgetOrders: {
            commission: 'Commission',
            volume: 'Volume',
            margin: 'Margin',
            active: 'Active',
            pending: 'Pending',
            activeOrders: 'Active Orders',
            portfolio: 'Portfolio',
            allPositions: 'All Positions',
            noPositionsLine1: 'You have no open',
            noPositionsLine2: 'positions yet',
            show: 'Show',
            more: 'more',
            less: 'less',
            purchaseTime: 'Purchase Time',
            closePosition: 'Close Position',
            priceOpen: 'Purchase Price',
            priceSl: 'Stop Loss Price',
            priceTp: 'Take Profit Price',
            type: 'Position Direction',
            pnl: 'Profit/Loss',
            cancelOrder: 'Cancel Order',
            orderType: 'Order Type',
            cancelled: 'Cancelled',
            tradingHistory: 'Trading History',
            noHistoryLine1: 'You don\'t have any',
            noHistoryLine2: 'closed deals yet',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Economic Calendar'
        },
        widgetMarketNews: {
            marketNews: 'Market News'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'Active Orders',
            ordersHistory: 'Orders History',
            id: 'ID',
            symbol: 'Symbol',
            type: 'Type',
            volume: 'Volume',
            openPrice: 'Open Price',
            openTime: 'Open Time',
            closePrice: 'Close Price',
            closeTime: 'Close Time',
            sl: 'SL',
            tp: 'TP',
            price: 'Price',
            pnl: 'PnL',
            actions: 'Actions',
            edit: 'Edit',
            close: 'Close',
            commission: 'Commission',
            swap: 'Swap',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short'
        },
        assetInfoModal: {
            assetInfo: 'Asset Info',
            tradingConditions: 'Trading Conditions',
            information: 'Session Change',
            bid: 'Bid',
            ask: 'Ask',
            sessionChange: 'Session Change',
            tradeNow: 'Trade Now',
            opens: 'Opens',
            closes: 'Closes',
            at: 'at',
            open247: 'Open 24/7',
            today: 'today',
            tomorrow: 'tomorrow',
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            contractSpecification: 'Contract Specification',
            symbol: 'Symbol',
            name: 'Name',
            market: 'Market',
            baseCurrency: 'Base Currency',
            digits: 'Digits',
            lotSize: 'Lot Size',
            lotStep: 'Lot Step',
            minVolume: 'Min Volume',
            maxVolume: 'Max Volume',
            leverage: 'Leverage',
            commission: 'Commission',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short',
            schedule: 'Schedule',
            weekday: 'Weekday',
            tradingTime: 'Trading Time',
            closed: 'closed',
            sell: 'Sell',
            buy: 'Buy',
            low: 'Low',
            high: 'High',
            oneHourChange: '1 hour change',
            oneDayChange: '1 day change',
            oneWeekChange: '1 week change',
            oneMonthChange: '1 month change',
            threeMonthsChange: '3 months change',
            oneYearChange: '1 year change',
            loading: 'Loading...',
        },
        autoTrader: {
            days: 'days',
            title: 'Auto Trader',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Risk Level: Low',
            riskLevelMedium: 'Risk Level: Medium',
            riskLevelHigh: 'Risk Level: High',
            perDay: 'Trades per day:',
            desc: `Trading CFDs and other leveraged products can lead to losses. Before trading, clients should
                read the
                relevant risk statements on our Risk Disclosure page. Automated trading does not guarantee results. The
                company accepts no responsibility for the loss of funds in automatic trading. Please make sure that you
                fully understand the risks and take measures to manage risks.`,
            accept: 'Accept',
            save: 'Save',
            cancel: 'Cancel',
        },
        cardBox: {
            name: 'Name',
            cardNumber: 'Card Number',
            expiration: 'Expiration (mm/yy)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "Trade",
            startTrading: 'Start Trading',
            deposit: 'Deposit',
            dashboard: 'Dashboard',
            personalInfo: 'Personal Information',
            contactInfo: 'Contact Information',
            withdrawal: 'Withdrawal',
            verification: 'Verification',
            accounts: 'Accounts',
            liveChat: 'Live Chat',
            savings: 'Savings',
            settings: 'Settings',
            logOut: 'Log Out',
        },
        fullScreenDeposit: {
            methods: 'METHODS',
            confirm: 'Confirm in the application of your bank',
            sendOtp: 'Send otp',
            otpCode: 'Otp code',
            addWalletAddress: 'Wallet for this deposit method is not set. Please contact your agent or support team.',
            addressReceipt: 'Please wait for the address to load',
            makeDeposit: 'MAKE A DEPOSIT',
            instant: 'Instant',
            minutes: 'minutes',
            hours: 'hours',
            days: 'days',
            amount: 'Amount',
            continue: 'Continue',
            qrCode: 'QR CODE',
            depositAddress: 'DEPOSIT ADDRESS',
            copy: 'Copy',
            last: 'LAST',
            deposits: 'DEPOSITS',
            time: 'Time',
            currency: 'Currency',
            status: 'Status',
            info: 'Info',
        },
        fullScreenCcExt: {
            pleaseWait: 'Please wait. Connecting to payment service provider...',
            cardPayment: 'Card payment',
            inOrder: 'In order to send the amount',
            pleaseProvide: 'please provide card details:'
        },
        fullScreenDashboard: {
            completeVerification: 'Please Complete Verification',
            noOrderYet: 'No order yet',
            totalBalance: 'Total Balance',
            totalPnl: 'Total PNL',
            profitableOrders: 'Profitable Orders',
            roi: 'ROI',
            activityLog: 'Activity Log',
            loginFromIp: 'Login from IP',
            tradingResults: 'Trading Results',
            week: 'Week',
            month: 'Month',
            year: 'Year',
            successRate: 'Success rate',
            closedWithProfit: 'Closed With Profit',
            closedWithLoss: 'Closed With Loss',
            account: 'Account',
            balance: 'Balance',
            leverage: 'Leverage',
            credit: 'Credit',
            tradeNow: 'Trade Now',
            usingCurrentRate: 'using current exchange rate',
            totalDeposits: 'Total Deposits'
        },
        fullScreenPersonal: {
            personalDetails: 'Personal Details',
            profilePhoto: 'Profile Photo',
            firstName: 'First Name',
            country: 'Country',
            lastName: 'Last Name',
            address: 'Address',
            email: 'E-mail',
            phone: 'Phone number',
            saveChanges: 'Save Changes',
            state: 'City',
            postcode: 'ZIP',
            created: 'Account created on',
        },
        fullScreenAvatar: {
            dropFile: 'Drop a file on the circle above to upload',
            notAllowed: 'It is not allowed to publish',
            notAllowedLine1: 'Photos of an explicitly sexual or pornographic nature',
            notAllowedLine2: 'Images aimed at inciting ethnic or racial hatred or aggression',
            notAllowedLine3: 'Photos involving persons under 18 years of age',
            notAllowedLine4: 'Third-party copyright protected photos',
            notAllowedLine5: 'Images larger than 5 MB and in a format other than JPG, GIF or PNG',
            requirements: 'Your face must be clearly visible on the photo. All photos and videos uploaded by you must comply with these requirements, otherwise they can be removed.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Please contact your account manager for withdrawal details',
            cryptocurrency: 'Cryptocurrency',
            withdrawal: 'Withdrawal',
            requestNewWithdrawal: 'REQUEST A NEW WITHDRAWAL',
            amount: 'Amount',
            currentBalance: 'Your current account balance is',
            withdrawAll: 'Withdraw all',
            requestWithdrawal: 'Request Withdrawal',
            last: 'LAST',
            withdrawalRequests: 'WITHDRAWAL REQUESTS',
            time: 'Time',
            currency: 'Currency',
            info: 'Info',
            status: 'Status',
            bank: 'Bank',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            card: 'Card',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Withdrawal Details',
            address: 'Wallet Address',
            bankAccount: 'Account Number',
            bankHolder: 'Account Holder Name',
            bankIban: 'IBAN',
            bankSwift: 'Bank SWIFT code',
            recipientAddress: 'Recipient Address',
            routingNumber: 'Routing Number',
            bankBeneficiary: 'Bank of Beneficiary Name',
            bankAddress: 'Bank Address',
            bankCode: 'Bank code (BSB)',
            desc: 'Description',
            cardNumber: 'Card Number',
            cardHolder: 'Card Holder Name',
        },
        fullScreenVerification: {
            dragAndDrop: 'drag and drop the document to this area',
            documentsList: 'LIST OF UPLOADED DOCUMENTS',
            document: 'Document',
            timeUploaded: 'Time Uploaded',
            timeProcessed: 'Time Processed',
            status: 'Status',
            types: ['Proof of Id', 'Proof of Residence', 'Credit Card Front', 'Credit Card Back', 'Proof of Id Back', 'Selfie'],
            types2: ['Proof of identification (POI)', 'Proof of residential address', 'Credit Card Front', 'Credit Card Back', 'Proof of identification Back', 'Selfie with ID'],
            upload: 'Upload',
            isConfirmed: 'is confirmed',
            uploadAnotherDocument: 'Upload another document',
            isUnderReview: 'is under review',
            questionnaire: 'Questionnaire',
            sendAnswers: 'Send Answers',
            progressBarOf: 'of',
            progressBarText: 'of your documents have been uploaded and confirmed'
        },
        fullScreenAccounts: {
            manageAccounts: 'Transfer funds between your accounts',
            transferFrom: 'From account',
            transferTo: 'To account',
            amount: 'Amount',
            makeTransfer: 'Make Transfer',
            yourAccounts: 'Your Accounts',
            account: 'Account',
            currency: 'Currency',
            balance: 'Balance',
            credit: 'Credit',
            makeActive: 'Make Active',
            active: 'Active'
        },
        fullScreenSupport: {
            sendMessage: 'Send Message'
        },
        fullScreenSavings: {
            pct_0: 'Flexible',
            pct_30: 'Locked 1 Month',
            pct_90: 'Locked 1 Quarter',
            pct_180: 'Locked 6 Months',
            pct_365: 'Locked 1 Year',
            savings: 'Savings',
            detailedInformation: 'Detailed Information',
            pleaseSelect: 'Please select a currency and a period',
            openSavings: 'Open Savings',
            currentTime: 'Current Time',
            releaseTime: 'Release Time',
            currencyChosen: 'Currency Chosen',
            periodChosen: 'Period Chosen',
            yourRate: 'Your Rate',
            yourEarnings: 'Your earnings',
            accountFrom: 'Account to transfer funds from',
            enterAmount: 'Enter Amount',
            in: 'In',
            openAndInvest: 'Open Savings Account and invest',
            investment: 'Investment',
            period: 'Period',
            daysTotal: 'Days total',
            finalEarnings: 'Final earnings',
            created: 'Created',
            daysElapsed: 'Days Elapsed',
            days: 'days',
            earnings: 'Earnings',
            fundsReleasedIn: 'Funds Released In',
            claim: 'Claim',
        },
        fullScreenSettings: {
            updatePassword: 'UPDATE PASSWORD',
            currentPassword: 'Current Password',
            newPassword: 'New Password',
            changePassword: 'Change Password',
            activationForGoogle: 'Activation code for Google Authenticator',
            activateAndEnter: 'Activate Authenticator and enter a generated code in field below',
            qrCode: 'QR Code',
            activateProtection: 'Activate 2FA Protection',
            protectionActive: '2FA protection is active',
            twoFactorCode: '2FA Code',
            disableProtection: 'Disable 2FA Protection',
            language: 'Language',
            dashboardCurrency: 'Dashboard Currency',
            confirmNewPassword: 'Confirm new password',
        },
        fullScreenReferrals: {
            yourLink: 'YOUR REFERRAL LINK',
            referredFriends: 'REFERRED FRIENDS',
            id: 'ID',
            name: 'Name',
            registered: 'Register',
            level: 'Level',
            payout: 'Payout',
            lastPayouts: 'LAST REFERRAL PAYOUTS',
            time: 'Time',
            amount: 'Amount',
            currency: 'Currency',
            info: 'Info'
        },
        months: {
            Jan: 'Jan',
            Feb: 'Feb',
            Mar: 'Mar',
            Apr: 'Apr',
            May: 'May',
            Jun: 'Jun',
            Jul: 'Jul',
            Aug: 'Aug',
            Sep: 'Sep',
            Oct: 'Oct',
            Nov: 'Nov',
            Dec: 'Dec'
        },
        orderTypes: ['Buy', 'Sell', 'Buy Limit', 'Sell Limit', 'Buy Stop', 'Sell Stop'],
        statuses: ['Pending', 'Approved', 'Declined', 'Processing'],
        errors: {
            NOT_FOUND: 'Request error',
            FORBIDDEN: 'Access denied. Please re-login',
            SERVER_ERROR: 'Action failed. Please try again later',
            INVALID_ACCOUNT: 'Session expired. Please re-login',
            INVALID_EMAIL: 'Invalid e-mail address',
            INVALID_PHONE: 'Invalid phone number',
            INVALID_COUNTRY: 'Invalid country',
            INVALID_LOGIN: 'Invalid login credentials',
            USER_BLOCKED: 'Account is blocked. Please contact support',
            USER_REGISTERED: 'User with entered e-mail or phone number is already registered',
            INVALID_USER: 'User is not found',
            REJECTED: 'Operation is not allowed due to platform restrictions',
            INVALID_OLD_PASSWORD: 'Old password is invalid',
            INVALID_SYMBOL: 'Invalid trading symbol',
            INVALID_SYMBOL_PRECISION: 'Invalid symbol precision',
            INVALID_FRAME: 'Invalid chart frame',
            INVALID_CURRENCY: 'Invalid currency',
            INVALID_PRODUCT: 'Invalid account trading type',
            INSUFFICIENT_FUNDS: 'Insufficient funds',
            INVALID_WITHDRAWAL: 'Withdrawal is not found',
            INVALID_STATUS: 'Invalid status',
            INVALID_FILE: 'Invalid file extension or file is too large',
            INVALID_DOCUMENT: 'Document is not found',
            INVALID_NAME: 'Invalid name',
            INVALID_LOT_SIZE: 'Invalid lot size',
            INVALID_LOT_STEP: 'Invalid lot step',
            INVALID_MIN_VOLUME: 'Invalid minimal volume',
            INVALID_MAX_VOLUME: 'Invalid maximal volume',
            INVALID_GROUP: 'Invalid group',
            INVALID_SPREAD: 'Invalid spread',
            REQUEST_TIMEOUT: 'Request timed out. Please try again later',
            EXISTING_OPEN_ORDERS: 'You have an open order, first close the order for this user and try again.',
            INVALID_TYPE: 'Invalid order type',
            INVALID_VOLUME: 'Invalid order volume',
            INVALID_SL: 'Invalid stop loss value',
            INVALID_TP: 'Invalid take profit value',
            INVALID_PRICE: 'Invalid order price',
            INVALID_EXPIRATION: 'Invalid order expiration',
            NOT_ENOUGH_MARGIN: 'Not enough margin',
            INVALID_ORDER: 'Order is not found',
            MARKET_CLOSED: 'Trading is not available. Please try again',
            INVALID_BALANCE_TYPE: 'Invalid type of balance operation',
            INVALID_HASH: 'Invalid hash',
            HASH_EXPIRED: 'Your password reset link is expired. Please request a new one',
            INVALID_CODE: 'Invalid 2FA code',
            CHAT_DISABLED: 'Access to the support chat has been closed',
            WITHDRAWAL_NOT_ALLOWED: 'Access to the withdrawals has been closed',
            TRADING_DISABLED: 'Trading is disabled for current account',
            PENDING_DEPOSITS_LIMIT: 'Deposits limit exceeded',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Only one pending withdrawal allowed',
            LOCAL_DEPOSIT_ONLY_REAL: 'Deposit is available only for real account',
            LOCAL_DEPOSIT_POSITIVE: 'Amount of deposit must be more than 0',
            LOCAL_INVALID_CARD_NUMBER: 'Invalid card number',
            LOCAL_INVALID_CARD_EXPIRATION: 'Invalid card expiration',
            LOCAL_INVALID_CVC: 'Invalid CVV/CVC code',
            LOCAL_PASSWORDS_NOT_MATCH: 'Passwords do not match, please re-enter',
            LOCAL_NO_TRADING_ACCOUNTS: 'You do not have trading accounts available. Please contact the support',
            LOCAL_MIN_DEPOSIT: 'Minimum deposit is $',
            LOCAL_MIN_WITHDRAWAL: 'Minimum withdrawal amount is $',
            LOCAL_INVALID_FILE_TYPE: 'File format not supported. Please attach only JPG, PNG or PDF',
            LOCAL_MIN_STAKING: 'Minimum deposit for this currency is ',
            FLOOD_REJECTED: 'You are sending messages too often. Please try again later'
        },
        countries: {
            AF: 'Afghanistan',
            AX: 'Aland Islands',
            AL: 'Albania',
            DZ: 'Algeria',
            AS: 'American Samoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarctica',
            AG: 'Antigua And Barbuda',
            AR: 'Argentina',
            AM: 'Armenia',
            AW: 'Aruba',
            AU: 'Australia',
            AT: 'Austria',
            AZ: 'Azerbaijan',
            BS: 'Bahamas',
            BH: 'Bahrain',
            BD: 'Bangladesh',
            BB: 'Barbados',
            BY: 'Belarus',
            BE: 'Belgium',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Bhutan',
            BO: 'Bolivia',
            BA: 'Bosnia And Herzegovina',
            BW: 'Botswana',
            BV: 'Bouvet Island',
            BR: 'Brazil',
            IO: 'British Indian Ocean Territory',
            BN: 'Brunei Darussalam',
            BG: 'Bulgaria',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Cambodia',
            CM: 'Cameroon',
            CA: 'Canada',
            CV: 'Cape Verde',
            KY: 'Cayman Islands',
            CF: 'Central African Republic',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'China',
            CX: 'Christmas Island',
            CC: 'Cocos (Keeling) Islands',
            CO: 'Colombia',
            KM: 'Comoros',
            CG: 'Congo',
            CD: 'Congo, Democratic Republic',
            CK: 'Cook Islands',
            CR: 'Costa Rica',
            CI: 'Cote D\'Ivoire',
            HR: 'Croatia',
            CU: 'Cuba',
            CY: 'Cyprus',
            CZ: 'Czech Republic',
            DK: 'Denmark',
            DJ: 'Djibouti',
            DM: 'Dominica',
            DO: 'Dominican Republic',
            EC: 'Ecuador',
            EG: 'Egypt',
            SV: 'El Salvador',
            GQ: 'Equatorial Guinea',
            ER: 'Eritrea',
            EE: 'Estonia',
            ET: 'Ethiopia',
            FK: 'Falkland Islands (Malvinas)',
            FO: 'Faroe Islands',
            FJ: 'Fiji',
            FI: 'Finland',
            FR: 'France',
            GF: 'French Guiana',
            PF: 'French Polynesia',
            TF: 'French Southern Territories',
            GA: 'Gabon',
            GM: 'Gambia',
            GE: 'Georgia',
            DE: 'Germany',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Greece',
            GL: 'Greenland',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Heard Island & Mcdonald Islands',
            VA: 'Holy See (Vatican City State)',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Hungary',
            IS: 'Iceland',
            IN: 'India',
            ID: 'Indonesia',
            IR: 'Iran',
            IQ: 'Iraq',
            IE: 'Ireland',
            IM: 'Isle Of Man',
            IL: 'Israel',
            IT: 'Italy',
            JM: 'Jamaica',
            JP: 'Japan',
            JE: 'Jersey',
            JO: 'Jordan',
            KZ: 'Kazakhstan',
            KE: 'Kenya',
            KI: 'Kiribati',
            KR: 'Korea',
            KW: 'Kuwait',
            KG: 'Kyrgyzstan',
            LA: 'Lao People\'s Democratic Republic',
            LV: 'Latvia',
            LB: 'Lebanon',
            LS: 'Lesotho',
            LR: 'Liberia',
            LY: 'Libyan Arab Jamahiriya',
            LI: 'Liechtenstein',
            LT: 'Lithuania',
            LU: 'Luxembourg',
            MO: 'Macao',
            MK: 'Macedonia',
            MG: 'Madagascar',
            MW: 'Malawi',
            MY: 'Malaysia',
            MV: 'Maldives',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marshall Islands',
            MQ: 'Martinique',
            MR: 'Mauritania',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Mexico',
            FM: 'Micronesia',
            MD: 'Moldova',
            MC: 'Monaco',
            MN: 'Mongolia',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Morocco',
            MZ: 'Mozambique',
            MM: 'Myanmar',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Netherlands',
            AN: 'Netherlands Antilles',
            NC: 'New Caledonia',
            NZ: 'New Zealand',
            NI: 'Nicaragua',
            NE: 'Niger',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Norfolk Island',
            MP: 'Northern Mariana Islands',
            NO: 'Norway',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Palestinian Territory, Occupied',
            PA: 'Panama',
            PG: 'Papua New Guinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Philippines',
            PN: 'Pitcairn',
            PL: 'Poland',
            PT: 'Portugal',
            PR: 'Puerto Rico',
            QA: 'Qatar',
            RE: 'Reunion',
            RO: 'Romania',
            RU: 'Russia',
            RW: 'Rwanda',
            BL: 'Saint Barthelemy',
            SH: 'Saint Helena',
            KN: 'Saint Kitts And Nevis',
            LC: 'Saint Lucia',
            MF: 'Saint Martin',
            PM: 'Saint Pierre And Miquelon',
            VC: 'Saint Vincent And Grenadines',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tome And Principe',
            SA: 'Saudi Arabia',
            SN: 'Senegal',
            RS: 'Serbia',
            SC: 'Seychelles',
            SL: 'Sierra Leone',
            SG: 'Singapore',
            SK: 'Slovakia',
            SI: 'Slovenia',
            SB: 'Solomon Islands',
            SO: 'Somalia',
            ZA: 'South Africa',
            GS: 'South Georgia And Sandwich Isl.',
            ES: 'Spain',
            LK: 'Sri Lanka',
            SD: 'Sudan',
            SR: 'Suriname',
            SJ: 'Svalbard And Jan Mayen',
            SZ: 'Swaziland',
            SE: 'Sweden',
            CH: 'Switzerland',
            SY: 'Syrian Arab Republic',
            TW: 'Taiwan',
            TJ: 'Tajikistan',
            TZ: 'Tanzania',
            TH: 'Thailand',
            TL: 'Timor-Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad And Tobago',
            TN: 'Tunisia',
            TR: 'Turkey',
            TM: 'Turkmenistan',
            TC: 'Turks And Caicos Islands',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukraine',
            AE: 'United Arab Emirates',
            GB: 'United Kingdom',
            US: 'United States',
            UM: 'United States Outlying Islands',
            UY: 'Uruguay',
            UZ: 'Uzbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Viet Nam',
            VG: 'Virgin Islands, British',
            VI: 'Virgin Islands, U.S.',
            WF: 'Wallis And Futuna',
            EH: 'Western Sahara',
            YE: 'Yemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe'
        },
    },
    ru: {
        _name: 'Русский',
        timePickerModal: {
            title: 'Закрыть по времени'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Тейк Профит и Стоп Лосс',
            takeProfit: 'Тейк Профит',
            stopLoss: 'Стоп Лосс',
            updateOrder: 'Изменить ордер',
        },
        balanceModal: {
            balance: 'Баланс',
            credit: 'Кредит',
            equity: 'Средства',
            practice: 'Пробный',
            real: 'Реальный',
            account: 'Счет',
            invested: 'Вложено',
            profit: 'Прибыль',
            loss: 'Убыток',
            margin: 'Маржа',
            marginLevel: 'Уровень маржи',
            marginFree: 'Свободная маржа'
        },
        header: {
            cfd: 'CFD',
            platform: 'Platform',
            deposit: 'Депозит'
        },
        sideMenu: {
            marketWatch: 'обзор Рынков',
            activeOrders: 'открытые ордера',
            tradingHistory: 'история Торговли',
            economicCalendar: 'экономический Календарь',
            marketNews: 'Новости рынков'
        },
        closePositionModal: {
            closeOrder: 'Закрыть позицию',
            areYouSure: 'Вы уверены, что хотите закрыть позицию',
            buy: 'Купить',
            sell: 'Продать',
            yes: 'Да',
            no: 'Нет',
        },
        pciDssModal: {
            desc: `Соблюдение стандарта безопасности данных платежной индустрии (PCI DSS) обязательно для всех субъектов, которые хранят, обрабатывают или передают данные держателей карт Visa/Master Card/Stripe, включая финансовые учреждения, торговцев и поставщиков услуг. 
            Это глобальный стандарт безопасности для всех субъектов, которые хранят, обрабатывают или передают данные держателей карт и/или чувствительные аутентификационные данные. PCI DSS устанавливает базовый уровень защиты для потребителей и помогает снизить мошенничество и утечки данных во всей платежной экосистеме.`
        },
        chart: {
            addNewChart: 'Добавить график',
        },
        symbolsModal: {
            watchlist: 'Избранное',
            asset: 'Инструмент',
            price: 'Цена',
            changePct: 'Изменения 24ч'
        },
        pendingModal: {
            pending: 'Цена', // <- NB
            assetPrice: 'Цена инструмента',
            current: 'Текущая',
            revert: 'Вернуть к рыночной',
            automatically: 'Позиция будет открыта автоматически при достижении указанного уровня'
        },
        orderMenu: {
            default: 'По умолчанию',
            volume: 'Объем',
            lots: 'лоты',
            units: 'юниты',
            currency: 'валюта',
            contractSize: 'Размер контракта',
            position: 'Позиция',
            margin: 'Маржа',
            freeMargin: 'Свободная маржа',
            takeProfitStopLoss: 'Тейк Профит и Стоп Лосс',
            pending: 'Цена',
            market: 'Рынок',
            leverage: 'Плечо',
            spread: 'Спрэд',
            notSet: 'Не задано',
            at: 'по',
            buy: 'купить',
            sell: 'продать',
        },
        footer: {
            supportBanner: 'КАЖДЫЙ ДЕНЬ, 24/7',
            currentTime: 'ТЕКУЩЕЕ ВРЕМЯ',
            liveChat: 'Поддержка'
        },
        statusBar: {
            balance: 'Баланс',
            credit: 'Кредит',
            equity: 'Средства',
            margin: 'Маржа',
            marginLevel: 'Уровень маржи',
            freeMargin: 'Свободная маржа',
            pnl: 'Прибыль/Убыток',
            profitTotal: 'Прибыль всего'
        },
        accountModal: {
            dateRegistered: 'Дата Регистрации',
            userId: 'ID Пользователя',
            dashboard: 'Панель пользователя',
            personalData: 'Личные Данные',
            deposit: 'Депозит',
            withdrawFunds: 'Вывод Средств',
            savings: 'Накопительные Счета',
            settings: 'Настройки',
            logout: 'Выйти'
        },
        depositModal: {
            BT_INVOICE: 'Введите сумму и сформируйте счет',
            BT_TITLE: 'Ваши платежные реквизиты',
            PENDING_DEPOSITS_LIMIT: 'Лимит депозитов',
            account: 'Счет получателя',
            bank_name: 'Наименование банка получателя',
            beneficiary: 'Получатель',
            beneficiary_address: 'Счет получателя',
            swift: 'БИК Банка получателя',
            reference: 'Reference',
            iban: 'IBAN',
            ru_inn: 'ИНН',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent accountКорреспондентский счёт',
            TOKEN_AMOUNT: 'Сумма',
            TOKEN_SUBTOTAL: 'Промежуточный итог',
            TOKEN_TOTAL: 'Всего',
            TOKEN_DESCRIPTION: 'Описание',
            TOKEN_PAYMENT_DETAILS: 'Платежные реквизиты',
            makeDeposit: 'Внести Депозит',
            practiceAccount: 'Учебнй Счет',
            realAccount: 'Реальный Счет',
            noWithdrawals: 'Нет вывода средств',
            easyWithdrawals: 'Легкий вывод средств',
            allAssets: 'Доступны все инструменты',
            fullFledged: 'Полнофункциональная платформа',
            fillUpTo: 'Пополнить до',
            freeReplenishment: 'Бесплатное Пополнение',
            topUp: 'Пополните Ваш Счет',
            minimumAmount: 'Минимальная Сумма',
            canSwitch: 'Вы можете переключиться между счетами в любое время'
        },
        gridsModal: {
            chartGrids: 'СЕТКА ГРАФИКОВ',
            chart1: '1 график',
            chart2: '2 графика',
            chart3: '3 графика',
            chart4: '4 графика',
        },
        noConnectionModal: {
            connectionLost: 'Соединение с сервером потеряно',
            retryNow: 'Попробовать еще',
        },
        loginModal: {
            loginToTradeRoom: 'Авторизация',
            email: 'Электронная Почта',
            enterEmail: 'Введите почту',
            twoFactor: 'Код 2FA (если активна)',
            twoFactorAuth: 'Двухфакторная аутентификация',
            password: 'Пароль',
            yourPassword: 'Ваш пароль',
            signIn: 'Войти',
            dontHaveAccount: 'У вас нет аккаунта?',
            restore: 'Восстановить',
            fullName: 'Полное имя',
            havePromo: 'У меня есть промо код',
            promo: 'Промо код',
            login: 'Вход в систему',
            haveAccount: 'Уже есть аккаунт?',
            hide: 'Скрыть',
            goTo: 'Вернуться назад'
        },
        newPasswordModal: {
            setNewPassword: 'Установить новый пароль',
            newPassword: 'Новый пароль',
            confirmNewPassword: 'Подтвердите новый пароль',
            setButton: 'Установить'
        },
        registerModal: {
            repeatPassword: 'Подтвердить Пароль',
            createNewAccount: 'Создать Новый Счет',
            email: 'Электронная Почта',
            firstName: 'Имя',
            lastName: 'Фамилия',
            country: 'Страна',
            phone: 'Телефон',
            password: 'Пароль',
            createAccount: 'Создать Счет',
            currency: 'Валюта',
            privacyAndPolicy: "Конфиденциальность и Политика",
            customerAgreement: 'Пользовательское Соглашение',
        },
        forgotPasswordModal: {
            forgotPassword: 'Забыли Пароль?',
            submitEmail: 'Пожалуйста, введите имейл, указанный при регистрации, проверьте свой ящик и следуйте инструкциям, полученным в письме',
            submit: 'Отправить'
        },
        notifications: {
            error: 'Ошибка',
            success: 'Успешно',
            deposit: 'Депозит',
            withdrawal: 'Вывод',
            depositApproved: 'Депозит одобрен',
            depositDeclined: 'Депозит отклонен',
            withdrawalApproved: 'Вывод средств одобрен',
            withdrawalDeclined: 'Вывод средств отклонен'
        },
        markets: {
            forex: 'Форекс',
            stocks: 'Акции',
            commodities: 'Сырье',
            indices: 'Индексы',
            crypto: 'Криптовалюта',
            metals: 'Металлы',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'ПОКУПКА',
            sell: 'ПРОДАЖА',
            volume: 'Объем',
            entryPrice: 'Цена Входа',
            takeProfit: 'Тейк Профит',
            stopLoss: 'Стоп Лосс',
            maxPosition: 'Макс. Позиция',
            calculate: 'Рассчитать',
            calculationsFor: 'Рассчет для',
            leverage: 'Плечо',
            requiredMargin: 'Требуемая Маржа',
            profitFromTP: 'Прибыль от ТП',
            lossFromSL: 'Убыток от СЛ',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Deposit successful',
            text: 'Your deposit has been processed successfully!',
        },
        depositFailModal: {
            title: 'Deposit failed',
            text: 'Your deposit has not been processed.',
        },
        widgetMarketWatch: {
            marketWatch: 'Обзор Рынков',
            search: 'Поиск...',
        },
        widgetOrders: {
            commission: 'Комиссия',
            volume: 'Объем',
            margin: 'Маржа',
            active: 'Открытые',
            pending: 'В Ожидании',
            activeOrders: 'Открытые ордера',
            portfolio: 'Портфолио',
            allPositions: 'Все Позиции',
            noPositionsLine1: 'У вас ещё нет',
            noPositionsLine2: 'открытых позиций',
            show: 'Показать',
            more: 'больше',
            less: 'меньше',
            purchaseTime: 'Открыт',
            closePosition: 'Закрыть',
            priceOpen: 'Цена открытия',
            priceSl: 'Цена Стоп Лосс',
            priceTp: 'Цена Тейк Профит',
            type: 'Направление позиции',
            pnl: 'Прибыль/Убыток',
            cancelOrder: 'Отменить Ордер',
            orderType: 'Тип Ордера',
            cancelled: 'Отменено',
            tradingHistory: 'Торговая История',
            noHistoryLine1: 'У вас ещё нет',
            noHistoryLine2: 'закрытых позиций',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Экономический Календарь'
        },
        widgetMarketNews: {
            marketNews: 'Новости Рынков'
        },
        ordersPane: {
            closeInTime: 'Закрыть по времени',
            timedOrder: 'Timed Order',
            ordersActive: 'Активные ордеры',
            ordersHistory: 'История Ордеров',
            id: 'ID',
            symbol: 'Символ',
            type: 'Тип',
            volume: 'Объем',
            openPrice: 'Цена открытия',
            openTime: 'Время открытия',
            closePrice: 'Цена закрытия',
            closeTime: 'Время закрытия',
            sl: 'СЛ',
            tp: 'ТП',
            price: 'Цена',
            pnl: 'ПиУ',
            actions: 'Действия',
            edit: 'Править',
            close: 'Закрыть',
            commission: 'Комиссия',
            swap: 'Своп',
            swapLong: 'Своп Лонг',
            swapShort: 'Своп Шорт'
        },
        assetInfoModal: {
            assetInfo: 'Информация об Инструменте',
            tradingConditions: 'Торговые Условия',
            information: 'Изменение за сессию',
            bid: 'Покупка',
            ask: 'Продажа',
            sessionChange: 'Изменение за сессию',
            tradeNow: 'Торговать',
            opens: 'Открывается',
            closes: 'Закрывается',
            at: 'в',
            open247: 'Открыто круглосуточно',
            today: 'сегодня',
            tomorrow: 'завтра',
            sunday: 'Воскресенье',
            monday: 'Понедельник',
            tuesday: 'Вторник',
            wednesday: 'Среда',
            thursday: 'Четверг',
            friday: 'Пятница',
            saturday: 'Суббота',
            contractSpecification: 'Спецификация контракта',
            symbol: 'Символ',
            name: 'Наименование',
            market: 'Рынок',
            baseCurrency: 'Базовая Валюта',
            digits: 'Точность',
            lotSize: 'Размер лота',
            lotStep: 'Шаг лота',
            minVolume: 'Мин Объем',
            maxVolume: 'Макс Объем',
            leverage: 'Плечо',
            commission: 'Комиссия',
            swapLong: 'Своп Лонг',
            swapShort: 'Своп Шорт',
            schedule: 'Расписание',
            weekday: 'День Недели',
            tradingTime: 'Время Торгов',
            closed: 'закрыто',
            sell: 'Продавать',
            buy: 'Покупать',
            low: 'Мин',
            high: 'Макс',
            oneHourChange: 'Изменение 1 час',
            oneDayChange: 'Изменение 1 день',
            oneWeekChange: 'Изменение 1 неделя',
            oneMonthChange: 'Изменение 1 месяц',
            threeMonthsChange: 'Изменение 3 месяца',
            oneYearChange: 'Изменение 1 год',
            loading: 'Загрузка...',
        },
        autoTrader: {
            days: 'дня',
            title: 'Автоматический Трейдер',
            customTitle: 'Срок действия Автоматического Трейдера',
            riskLevelLow: 'Уровень риска: Низкий',
            riskLevelMedium: 'Уровень риска: Средний',
            riskLevelHigh: 'Уровень риска: Высокий',
            perDay: 'Количество сделок в день:',
            desc: `Торговля CFD и другими продуктами с кредитным плечом может привести к убыткам. Перед началом торговли клиентам следует
                ознакомиться с
                соответствующими заявлениями о рисках на нашей странице "Информация о рисках". Автоматизированная торговля не гарантирует результатов. Компания не несет ответственности за потерю средств при автоматической торговле. Пожалуйста, убедитесь, что вы
                полностью понимаете риски и принимаете меры по управлению ими.`,
            accept: 'Принять',
            save: 'Сохранить',
            cancel: 'Отмена',
        },
        cardBox: {
            name: 'Имя',
            cardNumber: 'Номер карты',
            expiration: 'Срок действия (мм/гг)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "Торговля",
            startTrading: 'К Торговле',
            deposit: 'Депозит',
            dashboard: 'Панель Управления',
            personalInfo: 'Личные Данные',
            contactInfo: 'Контактная информация',
            withdrawal: 'Вывод Средств',
            verification: 'Верификация',
            accounts: 'Счета',
            liveChat: 'Поддержка',
            savings: 'Накопительные Счета',
            settings: 'Настройки',
            logOut: 'Выход',
        },
        fullScreenDeposit: {
            methods: 'МЕТОДЫ',
            confirm: 'Подтвердите в приложении вашего банка',
            sendOtp: 'Отправить код',
            otpCode: 'OTP Код',
            addWalletAddress: 'Кошелек для данного метода не установлен. Пожалуйста, обратитсь к Вашему агенту или в службу поддержки.',
            addressReceipt: 'Пожалуйста, подождите, получаем текущий адрес',
            makeDeposit: 'ВНЕСТИ ДЕПОЗИТ',
            instant: 'Моментально',
            minutes: 'минут',
            hours: 'часов',
            days: 'дней',
            amount: 'Сумма',
            continue: 'Продолжить',
            qrCode: 'QR код',
            depositAddress: 'АДРЕС КОШЕЛЬКА',
            copy: 'Копировать',
            last: 'ПОСЛЕДНИЕ',
            deposits: 'ДЕПОЗИТЫ',
            time: 'Время',
            currency: 'Валюта',
            status: 'Статус',
            info: 'Инфо',
        },
        fullScreenCcExt: {
            pleaseWait: 'Пожалуйста, подождите. Идет соединение с сервисом приема платежей...',
            cardPayment: 'Оплата картоу',
            inOrder: 'Чтобы отправить сумму',
            pleaseProvide: 'пожалуйста предоставьте данные карты:'
        },
        fullScreenDashboard: {
            completeVerification: 'Пожалуйста, заполните верификацию',
            noOrderYet: 'Закрыто в убыток',
            totalBalance: 'Итоговый Баланс',
            totalPnl: 'Прибыль / Убыток',
            profitableOrders: 'Прибыльные Ордеры',
            roi: 'ROI',
            activityLog: 'Лог Активности',
            loginFromIp: 'Вход с IP',
            tradingResults: 'Результаты Торговли',
            week: 'неделя',
            month: 'Месяц',
            year: 'Год',
            successRate: 'Успешные сделки',
            closedWithProfit: 'Закрыто с прибылью',
            closedWithLoss: 'Закрыто в убыток',
            account: 'Счет',
            balance: 'Баланс',
            leverage: 'Плечо',
            credit: 'Кредит',
            tradeNow: 'Торговать',
            usingCurrentRate: 'по текущей выбранной валюте',
            totalDeposits: 'Всего депозитов'
        },
        fullScreenPersonal: {
            personalDetails: 'Персональные Данные',
            profilePhoto: 'Фото Пользователя',
            firstName: 'Имя',
            country: 'Страна',
            lastName: 'Фамилия',
            address: 'Адрес',
            email: 'Электронная почта',
            phone: 'Номер телефона',
            saveChanges: 'Сохранить Изменения',
            state: 'Город',
            postcode: 'Почтовый индекс',
            created: 'Учетная запись создана',
        },
        fullScreenAvatar: {
            dropFile: 'Для загрузки перетащите файл на круг выше',
            notAllowed: 'Не разрешается размещать',
            notAllowedLine1: 'Эротические и порнографические фотографии',
            notAllowedLine2: 'Изображения, разжигающие этническую или расовую ненависть или агрессию',
            notAllowedLine3: 'Фотографии с детьми до 18 лет',
            notAllowedLine4: 'Фотографии, защищенные авторским правом',
            notAllowedLine5: 'Фотографии размером более 5 Мб и в формате, отличном от JPG, GIF или PNG',
            requirements: 'На фото должно быть четко видно Ваше лицо. Все загруженные фотографии должны соответсвовать критериям, описанным выше. В противном случае они будут принудительно удалены.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Пожалуйста, свяжитесь с вашим менеджером по работе с клиентами для получения информации о снятии средств',
            cryptocurrency: 'Криптовалюта',
            withdrawal: 'Вывод Средств',
            requestNewWithdrawal: 'ЗАПРОСИТЬ ВЫВОД СРЕДСТВ',
            amount: 'Сумма',
            currentBalance: 'Ваш текущий баланс',
            withdrawAll: 'Вывести все',
            requestWithdrawal: 'Вывод',
            last: 'ПОСЛЕДНИЕ',
            withdrawalRequests: 'ВЫВОД СРЕДСТВ',
            time: 'Время',
            currency: 'Валюта',
            info: 'Инфо',
            status: 'Статус',
            bank: 'Банк',
            bitcoin: 'Биткоин',
            zelle: 'Zelle',
            card: 'Карта',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Детали Вывода Средств',
            address: 'Адрес Кошелька',
            bankAccount: 'Номер Счета',
            bankHolder: 'Имя Владельца Счета',
            bankIban: 'IBAN',
            bankSwift: 'SWIFT код',
            recipientAddress: 'Адрес получателя',
            routingNumber: 'Номер маршрутизации',
            bankBeneficiary: 'Название банка бенефициара',
            bankAddress: 'Адрес банка',
            bankCode: 'Банковский код',
            desc: 'Описание',
            cardNumber: 'Номер Карты',
            cardHolder: 'Имя Владельца Карты',
        },
        fullScreenVerification: {
            dragAndDrop: 'для загрузки перетащите документ в эту область',
            documentsList: 'СПИСОК ЗАГРУЖЕННЫХ ДОКУМЕНТОВ',
            document: 'Документ',
            timeUploaded: 'Загружен',
            timeProcessed: 'Обработан',
            status: 'Статус',
            types: ['Подтверждeние Личности', 'Подтверждeние Адреса', 'Карта - Лицевая Сторона', 'Карта - Обратная Сторона', 'Подтверждeние Личности - Обратная Сторона', 'Селфи'],
            types2: ['Подтверждeние Личности', 'Подтверждeние Адреса', 'Карта - Лицевая Сторона', 'Карта - Обратная Сторона', 'Подтверждeние Личности - Обратная Сторона', 'Селфи'],
            upload: 'Загрузить',
            isConfirmed: 'одобрено',
            uploadAnotherDocument: 'Загрузите другой документ',
            isUnderReview: 'находится в обработке',
            questionnaire: 'Опросник',
            sendAnswers: 'Отправить Ответы',
            progressBarOf: 'из',
            progressBarText: 'ваших документов были загружены и подтверждены'
        },
        fullScreenAccounts: {
            manageAccounts: 'Перевод средств между счетами',
            transferFrom: 'Со счета',
            transferTo: 'На счет',
            amount: 'Сумма',
            makeTransfer: 'Перевести Средства',
            yourAccounts: 'Ваши Счета',
            account: 'Счет',
            currency: 'Валюта',
            balance: 'Баланс',
            credit: 'Кредит',
            makeActive: 'Сделать Активным',
            active: 'Активен'
        },
        fullScreenSupport: {
            sendMessage: 'Отправить сообщение'
        },
        fullScreenSavings: {
            pct_0: 'Гибкий',
            pct_30: 'Фиксированный 1 Месяц',
            pct_90: 'Фиксированный 1 Квартал',
            pct_180: 'Фиксированный 6 Месяцев',
            pct_365: 'Фиксированный 1 Год',
            savings: 'Накопительные счета',
            detailedInformation: 'Подробная Информация',
            pleaseSelect: 'Пожалуйста, выберите валюту и период',
            openSavings: 'Открытые накопительные счета',
            currentTime: 'Текущее время',
            releaseTime: 'Время закрытия счета',
            currencyChosen: 'Выбранная валюта',
            periodChosen: 'Выбранный период',
            yourRate: 'Ваша ставка',
            yourEarnings: 'Ваш доход',
            accountFrom: 'Счет, с которого произвести трансфер',
            enterAmount: 'Введите сумму',
            in: 'в',
            openAndInvest: 'Открыть накопительный счет и вложить',
            investment: 'Вложение',
            period: 'Период',
            daysTotal: 'Дней всего',
            finalEarnings: 'Итоговый доход',
            created: 'Создано',
            daysElapsed: 'Дней прошло',
            days: 'дней',
            earnings: 'Доход',
            fundsReleasedIn: 'До закрытия',
            claim: 'Забрать',
        },
        fullScreenSettings: {
            updatePassword: 'ОБНОВИТЬ ПАРОЛЬ',
            currentPassword: 'Текущий Пароль',
            newPassword: 'Новый Пароль',
            changePassword: 'Изменить Пароль',
            activationForGoogle: 'Код активации для Google Authenticator',
            activateAndEnter: 'Активируйте Authenticator и введите сгенерированный код в поле ниже',
            qrCode: 'QR код',
            activateProtection: 'Активировать Двухфакторную Защиту',
            protectionActive: 'ДВУХФАКТОРНАЯ ЗАЩИТА АКТИВНА',
            twoFactorCode: 'Код Верификации',
            disableProtection: 'Отключить Двухфакторную Защиту',
            language: 'Язык',
            dashboardCurrency: 'Валюта для панели управления',
            confirmNewPassword: 'Подтвердите новый пароль',
        },
        fullScreenReferrals: {
            yourLink: 'ВАША ССЫЛКА ДЛЯ ПРИВЛЕЧЕНИЯ',
            referredFriends: 'ПРИВЛЕЧЕННЫЕ ДРУЗЬЯ',
            id: 'ID',
            name: 'Имя',
            registered: 'Зарегистрироваться',
            level: 'Уровень',
            payout: 'Выплата',
            lastPayouts: 'ПОСЛЕДНИЕ ВЫПЛАТЫ',
            time: 'Время',
            amount: 'Сумма',
            currency: 'Валюта',
            info: 'Комментарий'
        },
        months: {
            Jan: 'Янв',
            Feb: 'Фев',
            Mar: 'Мар',
            Apr: 'Апр',
            May: 'Май',
            Jun: 'Июн',
            Jul: 'Июл',
            Aug: 'Авг',
            Sep: 'Сен',
            Oct: 'Окт',
            Nov: 'Ноя',
            Dec: 'Дек'
        },
        orderTypes: ['Покупка', 'Продажа', 'Лимитный Покупка', 'Лимитный Продажа', 'Стоп Покупка', 'Стоп Продажа'],
        statuses: ['Новый', 'Одобрен', 'Отклонен', 'В Обработке'],
        errors: {
            NOT_FOUND: 'Ошибка запроса',
            FORBIDDEN: 'Доступ запрещен. Пожалуйста, авторизуйтесь заново',
            SERVER_ERROR: 'Действие не удалось. Пожалуйста, попробуйте позже',
            INVALID_ACCOUNT: 'Сессия истекла. Пожалуйста, авторизуйтесь заново',
            INVALID_EMAIL: 'Неверная электронная почта',
            INVALID_PHONE: 'Неверный номер телефона',
            INVALID_COUNTRY: 'Неверная страна',
            INVALID_LOGIN: 'Неверные данные авторизации',
            USER_BLOCKED: 'Аккаунт заблокирован. Пожалуйста, свяжитесь со службой поддержки',
            USER_REGISTERED: 'Пользователь с такими электронной почтой или номером телефона уже зарегистрирован',
            INVALID_USER: 'Пользователь не найден',
            REJECTED: 'Операция запрещена согласно настройкам платформы',
            INVALID_OLD_PASSWORD: 'Текущий пароль неверен',
            INVALID_SYMBOL: 'Неверный торговый инструмент',
            INVALID_SYMBOL_PRECISION: 'Неверная точность после запятой',
            INVALID_FRAME: 'Неверный период',
            INVALID_CURRENCY: 'Неверная валюта',
            INVALID_PRODUCT: 'Неверный тип счета',
            INSUFFICIENT_FUNDS: 'Недостаточно денег. Пожалуйста, внесите депозит',
            INVALID_WITHDRAWAL: 'Запрос на вывод средств не найден',
            INVALID_STATUS: 'Неверный статус',
            INVALID_FILE: 'Неверный тип файла или файл слишком большой',
            INVALID_DOCUMENT: 'Документ не найден',
            INVALID_NAME: 'Неверное имя',
            INVALID_LOT_SIZE: 'Неверный размер лота',
            INVALID_LOT_STEP: 'Неверный шаг лота',
            INVALID_MIN_VOLUME: 'Неверный минимальный объем',
            INVALID_MAX_VOLUME: 'Неверный максимальный объем',
            INVALID_GROUP: 'Неверная группа',
            INVALID_SPREAD: 'Неверный спред',
            REQUEST_TIMEOUT: 'Время ожидания запроса истекло. Пожалуйста, попробуйте еще раз',
            EXISTING_OPEN_ORDERS: 'У вас есть открытый ордер, сначала закройте ордер для этого пользователя и повторите попытку.',
            INVALID_TYPE: 'Неверный тип ордера',
            INVALID_VOLUME: 'Неверный объем ордера',
            INVALID_SL: 'Неверный стоп лосс',
            INVALID_TP: 'Неверный тейк профит',
            INVALID_PRICE: 'Неверная цена ордера',
            INVALID_EXPIRATION: 'Неверное время экспирации',
            NOT_ENOUGH_MARGIN: 'Недостаточно маржи',
            INVALID_ORDER: 'Ордер не найден',
            MARKET_CLOSED: 'Торговля недоступна, рынок закрыт',
            INVALID_BALANCE_TYPE: 'Неверный тип балансовой операции',
            INVALID_HASH: 'Неверный хеш',
            HASH_EXPIRED: 'Ваша ссылка на сброс пароля истекла. Пожалуйста, запросите новую',
            INVALID_CODE: 'Неверный код 2FA',
            CHAT_DISABLED: 'Доступ к чату поддержки был закрыт',
            WITHDRAWAL_NOT_ALLOWED: 'Доступ к выводу средств был закрыт',
            TRADING_DISABLED: 'Торговля отключена для этого счета',
            PENDING_DEPOSITS_LIMIT: 'Превышен лимит депозитов',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Возможно только одно неподтвержденное снятие средств',
            LOCAL_DEPOSIT_ONLY_REAL: 'Пополнение возможно только для реального аккаунта',
            LOCAL_DEPOSIT_POSITIVE: 'Сумма депозита должна быть больше 0',
            LOCAL_INVALID_CARD_NUMBER: 'Неверный номер карты',
            LOCAL_INVALID_CARD_EXPIRATION: 'Неверная дата истечения срока действия',
            LOCAL_INVALID_CVC: 'Неверный код CVV/CVC',
            LOCAL_PASSWORDS_NOT_MATCH: 'Пароли не сопадают, пожалуйста, введите снова',
            LOCAL_NO_TRADING_ACCOUNTS: 'У вас нет торговых счетов. Пожалуйста, свяжитесь со службой поддержки',
            LOCAL_MIN_DEPOSIT: 'Минмальная сумма пополнения - $',
            LOCAL_MIN_WITHDRAWAL: 'Минимальная сумма снятия - $',
            LOCAL_INVALID_FILE_TYPE: 'Неподдерживаемый формат файла. Пожалуйста, приложите файл JPG, PNG или PDF',
            LOCAL_MIN_STAKING: 'Минимальная сумма для выбранной валюты ',
            FLOOD_REJECTED: 'Вы отправляете сообщения слишком часто. Пожалуйста, повторите попытку позже'
        },
        countries: {
            AF: 'Афганистан',
            AX: 'Аландские острова',
            AL: 'Албания',
            DZ: 'Алжир',
            AS: 'Американское Самоа',
            AD: 'Андорра',
            AO: 'Ангола',
            AI: 'Ангилья',
            AQ: 'Антарктида',
            AG: 'Антигуа и Барбуда',
            AR: 'Аргентина',
            AM: 'Армения',
            AW: 'Аруба',
            AU: 'Австралия',
            AT: 'Австрия',
            AZ: 'Азербайджан',
            BS: 'Багамские Острова',
            BH: 'Бахрейн',
            BD: 'Бангладеш',
            BB: 'Барбадос',
            BY: 'Белоруссия',
            BE: 'Бельгия',
            BZ: 'Белиз',
            BJ: 'Бенин',
            BM: 'Бермудские Острова',
            BT: 'Бутан',
            BO: 'Боливия',
            BA: 'Босния и Герцеговина',
            BW: 'Ботсвана',
            BV: 'Остров Буве',
            BR: 'Бразилия',
            IO: 'Британская территория в Индийском океане',
            BN: 'Бруней',
            BG: 'Болгария',
            BF: 'Буркина-Фасо',
            BI: 'Бурунди',
            KH: 'Камбоджа',
            CM: 'Камерун',
            CA: 'Канада',
            CV: 'Кабо-Верде',
            KY: 'Острова Кайман',
            CF: 'ЦАР',
            TD: 'Чад',
            CL: 'Чили',
            CN: 'Китай',
            CX: 'Остров Рождества',
            CC: 'Кокосовые острова',
            CO: 'Колумбия',
            KM: 'Коморы',
            CG: 'Конго',
            CD: 'Демократическая Республика Конго',
            CK: 'Острова Кука',
            CR: 'Коста-Рика',
            CI: 'Кот-д’Ивуар',
            HR: 'Хорватия',
            CU: 'Куба',
            CY: 'Кипр',
            CZ: 'Чехия',
            DK: 'Дания',
            DJ: 'Джибути',
            DM: 'Доминика',
            DO: 'Доминиканская Республика',
            EC: 'Эквадор',
            EG: 'Египет',
            SV: 'Сальвадор',
            GQ: 'Экваториальная Гвинея',
            ER: 'Эритрея',
            EE: 'Эстония',
            ET: 'Эфиопия',
            FK: 'Фолклендские острова',
            FO: 'Фарерские острова',
            FJ: 'Фиджи',
            FI: 'Финляндия',
            FR: 'Франция',
            GF: 'Гвиана',
            PF: 'Французская Полинезия',
            TF: 'Французские Южные и Антарктические территории',
            GA: 'Габон',
            GM: 'Гамбия',
            GE: 'Грузия',
            DE: 'Германия',
            GH: 'Гана',
            GI: 'Гибралтар',
            GR: 'Греция',
            GL: 'Гренландия',
            GD: 'Гренада',
            GP: 'Гваделупа',
            GU: 'Гуам',
            GT: 'Гватемала',
            GG: 'Гернси',
            GN: 'Гвинея',
            GW: 'Гвинея-Бисау',
            GY: 'Гайана',
            HT: 'Гаити',
            HM: 'Остров Херд и острова Макдональд',
            VA: 'Ватикан',
            HN: 'Гондурас',
            HK: 'Гонк Конг',
            HU: 'Венгрия',
            IS: 'исландия',
            IN: 'Индия',
            ID: 'Индонезия',
            IR: 'Иран',
            IQ: 'Ирак',
            IE: 'Ирландия',
            IM: 'Остров Мэн',
            IL: 'Израиль',
            IT: 'Италия',
            JM: 'Ямайка',
            JP: 'Япония',
            JE: 'Джерси',
            JO: 'Иордания',
            KZ: 'Казахстан',
            KE: 'Кения',
            KI: 'Кирибати',
            KR: 'Корея',
            KW: 'Кувейт',
            KG: 'Киргизия',
            LA: 'Лаос',
            LV: 'Латвия',
            LB: 'Ливан',
            LS: 'Лесото',
            LR: 'Либерия',
            LY: 'Ливия',
            LI: 'Лихтенштейн',
            LT: 'Литва',
            LU: 'Люксембург',
            MO: 'Макао',
            MK: 'Македония',
            MG: 'Мадагаскар',
            MW: 'Малави',
            MY: 'Малайзия',
            MV: 'Мальдивы',
            ML: 'Мали',
            MT: 'Мальта',
            MH: 'Маршалловы Острова',
            MQ: 'Мартиника',
            MR: 'Мавритания',
            MU: 'Маврикий',
            YT: 'Майотта',
            MX: 'Мексика',
            FM: 'Микронезия',
            MD: 'Молдова',
            MC: 'Монако',
            MN: 'Монголия',
            ME: 'Черногория',
            MS: 'Монтсеррат',
            MA: 'Марокко',
            MZ: 'Мозамбик',
            MM: 'Мьянма',
            NA: 'Намибия',
            NR: 'Науру',
            NP: 'Непал',
            NL: 'Нидерланды',
            AN: 'Нидерландские Антильские острова',
            NC: 'Новая Каледония',
            NZ: 'Новая Зеландия',
            NI: 'Никарагуа',
            NE: 'Нигер',
            NG: 'Нигерия',
            NU: 'Ниуэ',
            NF: 'Норфолк',
            MP: 'Северные Марианские острова',
            NO: 'Норвегия',
            OM: 'Оман',
            PK: 'Пакистан',
            PW: 'Палау',
            PS: 'Палестинские Территории',
            PA: 'Панама',
            PG: 'Папуа — НоваяГвинея',
            PY: 'Парагвай',
            PE: 'Перу',
            PH: 'Филиппины',
            PN: 'Острова Питкэрн',
            PL: 'Польша',
            PT: 'Португалия',
            PR: 'Пуэрто-Рико',
            QA: 'Катар',
            RE: 'Реюньон',
            RO: 'Румыния',
            RU: 'Россия',
            RW: 'Руанда',
            BL: 'Сен-Бартелеми',
            SH: 'Остров Святой Елены',
            KN: 'Сент-Китс и Невис',
            LC: 'Сент-Люсия',
            MF: 'Сен-Мартен',
            PM: 'Сен-Пьер и Микелон',
            VC: 'Сент-Винсент и Гренадины',
            WS: 'Самоа',
            SM: 'Сан Марино',
            ST: 'Сан-Томе и Принсипи',
            SA: 'Саудовская Арабия',
            SN: 'Сенегал',
            RS: 'Сербия',
            SC: 'Сейшелы',
            SL: 'Сьерра-Леоне',
            SG: 'Сингапур',
            SK: 'Словакия',
            SI: 'Словения',
            SB: 'Соломоновы Острова',
            SO: 'Сомали',
            ZA: 'ЮАР',
            GS: 'Южная Георгия и Южные Сандвичевы Острова',
            ES: 'Испания',
            LK: 'Шри-Ланка',
            SD: 'Судан',
            SR: 'Суринам',
            SJ: 'Шпицберген и Ян-Майен',
            SZ: 'Эсватини',
            SE: 'Швеция',
            CH: 'Швейцария',
            SY: 'Сирия',
            TW: 'тайвань',
            TJ: 'Таджикистан',
            TZ: 'Танзания',
            TH: 'Тайланд',
            TL: 'Восточный Тимор',
            TG: 'Того',
            TK: 'Токелау',
            TO: 'Тонга',
            TT: 'Тринидад и Тобаго',
            TN: 'Тунис',
            TR: 'Турция',
            TM: 'Туркменистан',
            TC: 'Тёркс и Кайкос',
            TV: 'Тувалу',
            UG: 'Уганда',
            UA: 'Украина',
            AE: 'ОАЭ',
            GB: 'Великобритания',
            US: 'США',
            UM: 'Внешние малые острова США',
            UY: 'Уругвай',
            UZ: 'Узбекистан',
            VU: 'Вануату',
            VE: 'Венесуэла',
            VN: 'Вьетнам',
            VG: 'Виргинские Острова',
            VI: 'Виргинские Острова (США)',
            WF: 'Уоллис и Футуна',
            EH: 'Западная Сахара',
            YE: 'Йемен',
            ZM: 'Замбия',
            ZW: 'Зимбабве'
        },
    },
    pl: {
        _name: 'Polski',
        timePickerModal: {
            title: 'Close In Time'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'TAKE PROFIT & STOP LOSS',
            takeProfit: ' Take Profit',
            stopLoss: ' Stop Loss',
            updateOrder: ' Zaktualizuj zamówienie'
        },
        balanceModal: {
            balance: 'Saldo',
            credit: ' Kredyt',
            equity: ' Kapital',
            practice: ' PRAKTYKA',
            real: ' REALNY',
            account: ' KONTO',
            invested: ' Zainwestowany',
            profit: ' Zysk',
            loss: ' Strata',
            margin: ' Margines',
            marginLevel: 'Poziom marginesu',
            marginFree: 'Wolny margines'
        },
        header: {
            cfd: 'CFD',
            platform: ' Platforma',
            deposit: ' Depozyt'
        },
        sideMenu: {
            marketWatch: 'Obserwacja rynku',
            activeOrders: 'Aktywne zamówienia',
            tradingHistory: 'Historia handlu',
            economicCalendar: 'Kalendarz ekonomiczny',
            marketNews: 'Wiadomosci rynkowe'
        },
        closePositionModal: {
            closeOrder: 'Zamknij pozycje',
            areYouSure: 'Czy na pewno zamkniesz pozycje',
            buy: 'Kupic',
            sell: 'Sprzedac',
            yes: 'Tak',
            no: 'Nie',
        },
        pciDssModal: {
            desc: `Zgodność ze standardem bezpieczeństwa danych branży płatniczej (PCI DSS) jest wymagana od wszystkich podmiotów, które przechowują, przetwarzają lub przesyłają dane posiadaczy kart Visa/Master Card/Stripe, w tym instytucji finansowych, handlowców i dostawców usług. 
            Jest to globalny standard bezpieczeństwa dla wszystkich podmiotów, które przechowują, przetwarzają lub przesyłają dane posiadaczy kart i/lub wrażliwe dane uwierzytelniające. PCI DSS ustanawia podstawowy poziom ochrony dla konsumentów i pomaga zmniejszyć oszustwa oraz naruszenia danych w całym ekosystemie płatności.`
        },
        chart: {
            addNewChart: 'Dodaj nowy wykres',
        },
        symbolsModal: {
            watchlist: 'Lista obserwowanych',
            asset: 'Aktywa',
            price: 'Cena',
            changePct: 'Wymiana 24h'
        },
        pendingModal: {
            pending: 'W oczekiwaniu',
            assetPrice: 'Cena aktywów',
            current: ' Aktualny',
            revert: 'Powrót do ceny rynkowej',
            automatically: 'Pozycja zostanie otwarta automatycznie, gdy cena osiegnie ten poziom'
        },
        orderMenu: {
            default: 'default',
            volume: 'Pojemnosc',
            lots: 'partii',
            units: ' jednostki',
            currency: 'waluta',
            contractSize: 'Wielkosc kontraktu',
            position: 'Pozycja',
            margin: 'Margines',
            freeMargin: 'Wolny margines',
            takeProfitStopLoss: 'Odbierz zysk i zatrzymaj strate',
            pending: 'W oczekiwaniu',
            market: 'Rynek',
            leverage: 'Dźwignia',
            spread: 'Spread',
            notSet: 'Nie ustawiony',
            at: 'w',
            buy: 'kupić',
            sell: 'sprzedać',
        },
        footer: {
            supportBanner: 'KAZDEGO DNIA PRZEZ CALE DOBE',
            currentTime: 'OBECNY CZAS',
            liveChat: 'Czat na zywo'
        },
        statusBar: {
            balance: 'Saldo',
            credit: 'Kredyt',
            equity: 'Kapital',
            margin: 'Margines',
            marginLevel: 'Poziom marginesu',
            freeMargin: 'Wolny margines',
            pnl: 'PnL',
            profitTotal: 'Lifetime PnL'
        },
        accountModal: {
            dateRegistered: 'Data rejestracji',
            userId: 'Identyfikator uzytkownika',
            dashboard: 'Dashboard',
            personalData: 'Dane osobiste',
            deposit: 'Depozyt',
            withdrawFunds: 'Wypłatę srodków',
            savings: 'Oszczednosci',
            settings: 'Ustawienia',
            logout: 'Wyloguj sie'
        },
        depositModal: {
            BT_INVOICE: 'Enter amount and generate invoice',
            BT_TITLE: 'Our banking details',
            PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
            account: 'Account',
            bank_name: 'Bank Name',
            beneficiary: 'Beneficiary',
            beneficiary_address: 'Beneficiary Address',
            swift: 'Swift',
            reference: 'Reference',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Amount',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Description',
            TOKEN_PAYMENT_DETAILS: 'Payment Details',
            makeDeposit: 'Zlozyc depozyt',
            practiceAccount: 'Konto treningowe',
            realAccount: 'Prawdziwe konto',
            noWithdrawals: 'Brak wyplat',
            easyWithdrawals: 'latwe wyplaty',
            allAssets: 'Wszystkie aktywy dostepne',
            fullFledged: 'Pelnoprawna platforma',
            fillUpTo: 'Wypelnij do',
            freeReplenishment: 'Bezplatne uzupelnianie',
            topUp: 'Doladuj swoje konto',
            minimumAmount: 'Stawka minimalna',
            canSwitch: 'Mozesz przelaczac sie miedzy swoimi kontami w dowolnym momencie'
        },
        gridsModal: {
            chartGrids: 'SIATKI WYKRESÓW',
            chart1: '1 wykres',
            chart2: '2 wykres',
            chart3: '3 wykres',
            chart4: '4 wykres',
        },
        noConnectionModal: {
            connectionLost: 'Polaczenie z serwerem zostalo utracone',
            retryNow: 'Spróbuj teraz',
        },
        loginModal: {
            loginToTradeRoom: 'Zaloguj sie do Traderoom',
            email: 'Adres e-mail',
            enterEmail: 'Wpisz swój e-mail',
            twoFactor: 'Kod 2FA (jesli jest wlaczony)',
            twoFactorAuth: 'Uwierzytelnianie dwuskladnikowe',
            password: 'Haslo',
            yourPassword: 'Twoje haslo',
            signIn: 'Zaloguj sie',
            dontHaveAccount: 'Don’t have an account?',
            restore: 'Restore',
            fullName: 'Full Name',
            havePromo: 'I have a promo-code',
            promo: 'Promo code',
            login: 'Login',
            haveAccount: 'Already have an account?',
            hide: 'Hide',
            goTo: 'Go to back'
        },
        newPasswordModal: {
            setNewPassword: 'Ustaw nowe haslo',
            newPassword: 'Nasza zalety',
            confirmNewPassword: 'Potwierdź nowe hasło',
            setButton: 'Ustaw'
        },
        registerModal: {
            repeatPassword: 'Repeat Password',
            createNewAccount: 'Utwórz nowe konto',
            email: 'Adres e-mail',
            firstName: 'Imie',
            lastName: 'Nazwisko',
            country: 'Kraj',
            phone: 'Telefon',
            password: 'Haslo',
            createAccount: 'Utwórz konto',
            currency: 'Waluta',
            privacyAndPolicy: "Prywatność i Polityka",
            customerAgreement: 'Umowa z klientem',
        },
        forgotPasswordModal: {
            forgotPassword: 'Nie pamietam hasla?',
            submitEmail: 'Prosimy o podanie e-maila uzytego do rejestracji, sprawdzenie skrzynki odbiorczej i postepowanie zgodnie z podanymi instrukcjami.',
            submit: 'Przeslij'
        },
        notifications: {
            error: 'Blad',
            success: 'Sukces',
            deposit: 'Depozyt',
            withdrawal: 'Wycofanie',
            depositApproved: 'Depozyt zostal zatwierdzony',
            depositDeclined: 'Depozyt zostal odrzucony',
            withdrawalApproved: 'Wycofanie zatwierdzone',
            withdrawalDeclined: 'Odmówiono wyplaty'
        },
        markets: {
            forex: 'Forex',
            stocks: 'Akcje',
            commodities: 'Towary',
            indices: 'Wskazniki',
            crypto: 'Kryptowaluta',
            metals: 'Metale',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'BUY',
            profitCalculator: 'Profit Calculator',
            sell: 'SELL',
            volume: 'Volume',
            entryPrice: 'Entry Price',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            maxPosition: 'Max Position',
            calculate: 'Calculate',
            calculationsFor: 'Calculations for',
            leverage: 'Leverage',
            requiredMargin: 'Required Margin',
            profitFromTP: 'Profit from TP',
            lossFromSL: 'Loss from SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Deposit successful',
            text: 'Your deposit has been processed successfully!',
        },
        depositFailModal: {
            title: 'Deposit failed',
            text: 'Your deposit has not been processed.',
        },
        widgetMarketWatch: {
            marketWatch: 'Obserwacja rynku',
            search: 'Szukac  ...',
        },
        widgetOrders: {
            commission: 'Commission',
            volume: 'Tom',
            margin: 'Marza',
            active: 'Aktywny',
            pending: 'W trakcie realizacji',
            activeOrders: 'Zamówienia aktywne',
            portfolio: 'Portfolio',
            allPositions: 'Wszystkie stanowiska',
            noPositionsLine1: 'Nie masz otwartego',
            noPositionsLine2: 'jeszcze stanowiska',
            show: 'Pokaz',
            more: 'wiecej',
            less: 'mniej',
            purchaseTime: 'Czas zakupu',
            closePosition: 'Pozycja zamkniecia',
            priceOpen: 'Cena zakupu',
            priceSl: 'Cena Stop Loss',
            priceTp: 'Cena Take Profit',
            type: 'Pozycja Kierunek',
            pnl: 'Zysk/Strata',
            cancelOrder: 'Anuluj zamówienie',
            orderType: 'Typ zamówienia',
            cancelled: 'Anulowane',
            tradingHistory: 'Historia handlu',
            noHistoryLine1: 'Nie masz zadnych',
            noHistoryLine2: 'jeszcze zamkniete transakcje',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Kalendarz ekonomiczny'
        },
        widgetMarketNews: {
            marketNews: 'Wiadomosci rynkowe'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'Zamówienia Aktywne',
            ordersHistory: 'Historia zamówien',
            id: 'ID',
            symbol: 'Symbol',
            type: 'Typ',
            volume: 'Tom',
            openPrice: 'Cena wywolawcza',
            openTime: 'Czas otwarcia',
            closePrice: 'Zamknij cene',
            closeTime: 'Czas zamkniecia',
            sl: 'SL',
            tp: 'TP',
            price: 'Cena',
            pnl: 'PnL',
            actions: 'Dzialania',
            edit: 'Edytować',
            close: 'Zamknać',
            commission: 'Commission',
            swap: 'Swap',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short'
        },
        assetInfoModal: {
            assetInfo: 'Asset Info',
            tradingConditions: 'Trading Conditions',
            information: 'Session Change',
            bid: 'Bid',
            ask: 'Ask',
            sessionChange: 'Session Change',
            tradeNow: 'Trade Now',
            opens: 'Opens',
            closes: 'Closes',
            at: 'at',
            open247: 'Open 24/7',
            today: 'today',
            tomorrow: 'tomorrow',
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            contractSpecification: 'Contract Specification',
            symbol: 'Symbol',
            name: 'Name',
            market: 'Market',
            baseCurrency: 'Base Currency',
            digits: 'Digits',
            lotSize: 'Lot Size',
            lotStep: 'Lot Step',
            minVolume: 'Min Volume',
            maxVolume: 'Max Volume',
            leverage: 'Leverage',
            commission: 'Commission',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short',
            schedule: 'Schedule',
            weekday: 'Weekday',
            tradingTime: 'Trading Time',
            closed: 'closed',
            sell: 'Sell',
            buy: 'Buy',
            low: 'Low',
            high: 'High',
            oneHourChange: '1 hour change',
            oneDayChange: '1 day change',
            oneWeekChange: '1 week change',
            oneMonthChange: '1 month change',
            threeMonthsChange: '3 months change',
            oneYearChange: '1 year change',
            loading: 'Loading...',
        },
        months: {
            Jan: 'Styczen',
            Feb: 'Luty',
            Mar: 'Marzec',
            Apr: 'Kwiecien',
            May: 'Maj',
            Jun: 'Czerwiec',
            Jul: 'Lipiec',
            Aug: 'Sierpien',
            Sep: 'Wrzesien',
            Oct: 'Pazdziernik',
            Nov: 'Listopad',
            Dec: 'Grudzien'
        },
        autoTrader: {
            days: 'days',
            title: 'Auto Trader',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Risk Level: Low',
            riskLevelMedium: 'Risk Level: Medium',
            riskLevelHigh: 'Risk Level: High',
            perDay: 'Trades per day:',
            desc: `Trading CFDs and other leveraged products can lead to losses. Before trading, clients should
                read the
                relevant risk statements on our Risk Disclosure page. Automated trading does not guarantee results. The
                company accepts no responsibility for the loss of funds in automatic trading. Please make sure that you
                fully understand the risks and take measures to manage risks.`,
            accept: 'Accept',
            save: 'Zapisz',
            cancel: 'Anuluj',
        },
        cardBox: {
            name: 'Imię',
            cardNumber: 'Numer karty',
            expiration: 'Wygasa (mm/rr)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "Handel",
            startTrading: 'Rozpocznij Handel',
            deposit: 'Dokonaj wplaty',
            dashboard: 'Pulpit',
            personalInfo: 'Aktualizacja danych osobowych',
            contactInfo: 'Informacje kontaktowe',
            withdrawal: 'Wypłata środków',
            verification: 'Weryfikacja tożsamości',
            accounts: 'Zarządzaj kontami',
            liveChat: 'Czat na żywo',
            savings: 'Lokaty',
            settings: 'Ustawienia platformy',
            logOut: 'Wyloguj się',
        },
        fullScreenDeposit: {
            methods: 'METHODS',
            confirm: 'Confirm in the application of your bank',
            sendOtp: 'Send otp',
            otpCode: 'Otp code',
            addWalletAddress: 'Wallet for this deposit method is not set. Please contact your agent or support team.',
            addressReceipt: 'Please wait for the address to load',
            makeDeposit: 'DOKONAJ WPLATY DEPOZYTU',
            instant: 'Natychmiast',
            minutes: 'minuty',
            hours: 'godziny',
            days: 'dni',
            amount: 'Kwota',
            continue: 'Kontynuuj',
            qrCode: 'KOD QR',
            depositAddress: 'ADRES DEPOZYTOWY',
            copy: 'Kopia',
            last: 'OSTATNI',
            deposits: 'DEPOZYTY',
            time: 'Czas',
            currency: 'Waluta',
            status: 'Status',
            info: 'Informacje',
        },
        fullScreenCcExt: {
            pleaseWait: 'Prosze czekac. Polaczenie z dostawca uslug platniczych...',
            cardPayment: 'Platnosc karta',
            inOrder: 'W celu wyslania kwoty',
            pleaseProvide: 'Prosze podac dane karty:'
        },
        fullScreenDashboard: {
            completeVerification: 'Please Complete Verification',
            noOrderYet: 'No order yet',
            totalBalance: 'Całkowite Saldo',
            totalPnl: 'Całkowity PNL',
            profitableOrders: 'Zyskowne pozycję',
            roi: 'ROI',
            activityLog: 'Lista Aktywności',
            loginFromIp: 'Login from IP',
            tradingResults: 'Trading Results',
            week: 'Tydzień',
            month: 'Miesiąc',
            year: 'Rok',
            successRate: 'Wskaźnik Sukcesu',
            closedWithProfit: 'Zamknięte z Zyskiem',
            closedWithLoss: 'Zamknięte ze stratą',
            account: 'Konto',
            balance: 'Saldo',
            leverage: 'Dźwignia',
            credit: 'Kredyt',
            tradeNow: 'Rozpocznij Handel',
            usingCurrentRate: 'using current exchange rate',
            totalDeposits: 'Total Deposits'
        },
        fullScreenPersonal: {
            personalDetails: 'Dane osobowe',
            profilePhoto: 'Zdjecie profilowe',
            firstName: 'Imię',
            country: 'Kraj',
            lastName: 'Nazwisko',
            address: 'Adres',
            email: 'E-mail',
            phone: 'Numer telefonu',
            saveChanges: 'Zapisac zmiany',
            state: 'Miasto',
            postcode: 'Kod pocztowy',
            created: "Konto utworzone"
        },
        fullScreenAvatar: {
            dropFile: 'Wrzuc plik na okrag powyzej, aby go przeslac',
            notAllowed: 'Niedozwolone jest publikowanie',
            notAllowedLine1: 'Zdjecia o charakterze jednoznacznie seksualnym lub pornograficznym',
            notAllowedLine2: 'Obrazy majace na celu podzeganie do nienawisci lub agresji na tle etnicznym lub rasowym',
            notAllowedLine3: 'Zdjecia z udzialem osób ponizej 18 roku zycia',
            notAllowedLine4: 'Zdjecia chronione prawami autorskimi osób trzecich',
            notAllowedLine5: 'Obrazy wieksze niz 5 MB i w formacie innym niz JPG, GIF lub PNG',
            requirements: 'Twoja twarz musi byc wyraznie widoczna na zdjeciu. Wszystkie zdjecia i filmy zamieszczane przez Ciebie musza spelniac te wymagania, w przeciwnym razie moga zostac usuniete.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Please contact your account manager for withdrawal details',
            cryptocurrency: 'Cryptocurrency',
            withdrawal: 'Withdrawal',
            requestNewWithdrawal: 'WNIOSEK O NOWA WYPŁATĘ',
            amount: 'Kwota',
            currentBalance: 'Stan Twojego rachunku biezacego wynosi',
            withdrawAll: 'Wycofac wszystkie',
            requestWithdrawal: 'Wypłać',
            last: 'OSTATNI',
            withdrawalRequests: 'Historia',
            time: 'Czas',
            currency: 'WALUTA',
            info: 'Informacje',
            status: 'Status',
            bank: 'Bank',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            card: 'Karta',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Szczególy wypłaty',
            address: 'Adres portfela',
            bankAccount: 'Numer konta',
            bankHolder: 'Nazwa posiadacza konta',
            bankIban: 'IBAN',
            bankSwift: 'Kod SWIFT banku',
            recipientAddress: 'Adres odbiorcy',
            routingNumber: 'Numer rozliczeniowy',
            bankBeneficiary: 'Nazwa banku beneficjenta',
            bankAddress: 'Adres banku',
            bankCode: 'Kod banku',
            desc: 'Opis',
            cardNumber: 'Numer karty',
            cardHolder: 'Nazwa posiadacza karty',
        },
        fullScreenVerification: {
            dragAndDrop: 'przeciagnij i upuść dokument na ten obszar',
            documentsList: 'LISTA PRZESLANYCH DOKUMENTÓW',
            document: 'Dokument',
            timeUploaded: 'Wyslano',
            timeProcessed: 'Zweryfikowano',
            status: 'Status',
            types: ['Przód dowodu', 'Dokument potwierdzający adres zamieszkania', 'Przód karty kredytowej', 'Tył karty kredytowej', 'Tył dowodu', 'Selfie'],
            types2: ['Proof of identification (POI)', 'Proof of residential address', 'Credit Card Front', 'Credit Card Back', 'Proof of identification Back', 'Selfie with ID'],
            upload: 'Wyślij',
            isConfirmed: 'jest potwierdzony',
            uploadAnotherDocument: 'Wyślij inny dokument',
            isUnderReview: 'jest w trakcie przegladu',
            questionnaire: 'Kwestionariusz',
            sendAnswers: 'Wyslij odpowiedzi',
            progressBarOf: 'z',
            progressBarText: 'twoich dokumentów zostały wgrane i potwierdzone'
        },
        fullScreenAccounts: {
            manageAccounts: 'Przelewaj srodki pomiedzy swoimi kontami',
            transferFrom: 'Z konta',
            transferTo: 'Na konto',
            amount: 'Kwota',
            makeTransfer: 'Dokonaj transferu',
            yourAccounts: 'Twoje konta',
            account: 'Konto',
            currency: 'Waluta',
            balance: 'Saldo',
            credit: 'Kredyt',
            makeActive: 'Uczyn aktywnym',
            active: 'Aktywny'
        },
        fullScreenSupport: {
            sendMessage: 'Napisz wiadomość...'
        },
        fullScreenSavings: {
            pct_0: 'Dynamiczna',
            pct_30: 'Terminowa miesiąc',
            pct_90: 'Terminowa kwartał',
            pct_180: 'Terminowa 6 miesięcy',
            pct_365: 'Terminowa rok',
            savings: 'Oszczednosci',
            detailedInformation: 'Szczególowe informacje',
            pleaseSelect: 'Prosze wybrac walute i okres',
            openSavings: 'Oszczednosci otwarte',
            currentTime: 'Aktualny czas',
            releaseTime: 'Czas wydania',
            currencyChosen: 'Wybrana waluta',
            periodChosen: 'Wybrany okres',
            yourRate: 'Twoja stawka',
            yourEarnings: 'Twoje zarobki',
            accountFrom: 'Z konta',
            enterAmount: 'Wprowadz kwote',
            in: 'W',
            openAndInvest: 'Otwórz konto oszczednosciowe i inwestuj',
            investment: 'Lokata',
            period: 'Okres',
            daysTotal: 'Dni',
            finalEarnings: 'Potencjialny zysk',
            created: 'Utworzony',
            daysElapsed: 'Dni, które uplynely',
            days: 'dni',
            earnings: 'Zarobki',
            fundsReleasedIn: 'Odbłokowane za',
            claim: 'Roszczenie',
        },
        fullScreenSettings: {
            updatePassword: 'AKTUALIZACJA HASLA',
            currentPassword: 'Aktualne haslo',
            newPassword: 'Nowe hasło',
            changePassword: 'Zmiana hasła',
            activationForGoogle: 'Kod aktywacyjny dla Google Authenticator',
            activateAndEnter: 'Aktywuj kod uwierzytelniający i wprowadź wygenerowany kod w polu poniżej',
            qrCode: 'Kod QR',
            activateProtection: 'Aktywuj ochrone 2FA',
            protectionActive: 'Ochrona 2FA jest aktywna',
            twoFactorCode: 'Kod 2FA',
            disableProtection: 'Wylacz ochrone 2FA',
            language: 'Jezyk',
            dashboardCurrency: 'Waluta na Panelu',
            confirmNewPassword: 'Potwierdź nowe hasło',
        },
        fullScreenReferrals: {
            yourLink: 'TWÓJ LINK POLECAJACY',
            referredFriends: 'POLECENI ZNAJOMI',
            id: 'ID',
            name: 'Imie',
            registered: 'Zarejestrowany',
            level: 'Poziom',
            payout: 'Wypłatę',
            lastPayouts: 'OSTATNIE WYPLATY ZA POLECENIE',
            time: 'Czas',
            amount: 'Kwota',
            currency: 'Walut',
            info: 'Informacje'
        },
        orderTypes: ['Kupic', 'Sprzedac', 'Wykupic limit', 'Limit sprzedazy', 'Zakup Stop', 'Sprzedac Stop'],
        statuses: ['W trakcie realizacji', 'Zatwierdzony', 'Odrzucony', 'Obróbka'],
        errors: {
            NOT_FOUND: 'Blad zadania',
            FORBIDDEN: 'Odmowa dostepu. Prosimy o ponowne zalogowanie.',
            SERVER_ERROR: 'Akcja nie powiodla sie. Prosze spróbowac ponownie pózniej',
            INVALID_ACCOUNT: 'Akcja nie powiodla sie. Prosze spróbowac ponownie pózniej',
            INVALID_EMAIL: 'Nieprawidlowy adres e-mail',
            INVALID_PHONE: 'Nieprawidlowy numer telefonu',
            INVALID_COUNTRY: 'Nieprawidlowy kraj',
            INVALID_LOGIN: 'Nieprawidlowe poswiadczenia logowania',
            USER_BLOCKED: 'Konto jest zablokowane. Prosimy o kontakt z obsluga',
            USER_REGISTERED: 'Uzytkownik o podanym adresie e-mail lub numerze telefonu jest juz zarejestrowany',
            INVALID_USER: 'Uzytkownik nie zostal znaleziony',
            REJECTED: 'Dzialanie nie jest dozwolone z powodu ograniczen platformy',
            INVALID_OLD_PASSWORD: 'Stare haslo jest niewazne',
            INVALID_SYMBOL: 'Nieprawidlowy symbol handlowy',
            INVALID_SYMBOL_PRECISION: 'Nieprawidlowa precyzja symbolu',
            INVALID_FRAME: 'Nieprawidlowa ramka wykresu',
            INVALID_CURRENCY: 'Nieprawidlowa waluta',
            INVALID_PRODUCT: 'Nieprawidlowy typ handlowy konta',
            INSUFFICIENT_FUNDS: 'Niewystarczajace srodki finansowe',
            INVALID_WITHDRAWAL: 'Wycofanie nie zostalo odnalezione',
            INVALID_STATUS: 'Nieprawidlowy status',
            INVALID_FILE: 'Nieprawidlowe rozszerzenie pliku lub plik jest zbyt duzy',
            INVALID_DOCUMENT: 'Dokument nie zostal znaleziony',
            INVALID_NAME: 'Nieprawidlowa nazwa',
            INVALID_LOT_SIZE: 'Nieprawidlowa wielkosc dzialki',
            INVALID_LOT_STEP: 'Nieprawidlowy krok partii',
            INVALID_MIN_VOLUME: 'Nieprawidlowa objetosc minimalna',
            INVALID_MAX_VOLUME: 'Nieprawidlowa objetosc maksymalna',
            INVALID_GROUP: 'Nieprawidlowa grupa',
            INVALID_SPREAD: 'Niewazny rozrzut',
            REQUEST_TIMEOUT: 'Zadanie zostalo przerwane. Prosze spróbowac ponownie pózniej',
            EXISTING_OPEN_ORDERS: 'Masz otwarte zamówienie, najpierw zamknij zamówienie dla tego użytkownika i spróbuj ponownie.',
            INVALID_TYPE: 'Nieprawidlowy typ zamówienia',
            INVALID_VOLUME: 'Nieprawidlowa wielkosc zamówienia',
            INVALID_SL: 'Nieprawidlowa wartosc stop loss',
            INVALID_TP: 'Nieprawidlowa wartosc take profit',
            INVALID_PRICE: 'Nieprawidlowa cena zamówienia',
            INVALID_EXPIRATION: 'Nieprawidlowy termin waznosci zamówienia',
            NOT_ENOUGH_MARGIN: 'Niewystarczajaca marza',
            INVALID_ORDER: 'Zamówienie nie zostalo znalezione',
            MARKET_CLOSED: 'Handel nie jest dostepny. Prosze spróbowac ponownie',
            INVALID_BALANCE_TYPE: 'Nieprawidlowy typ operacji bilansowej',
            INVALID_HASH: 'Nieprawidlowy hash',
            HASH_EXPIRED: 'Twój link do resetowania hasla wygasl. Popros o nowe haslo.',
            INVALID_CODE: 'Nieprawidlowy kod 2FA',
            CHAT_DISABLED: 'Dostep do czatu pomocy technicznej zostal zamkniety',
            WITHDRAWAL_NOT_ALLOWED: 'Dostep do wyplat zostal zamkniety',
            TRADING_DISABLED: 'Trading is disabled for current account',
            PENDING_DEPOSITS_LIMIT: 'Deposits limit exceeded',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Only one pending withdrawal allowed',
            LOCAL_DEPOSIT_ONLY_REAL: 'Deposit is available only for real account',
            LOCAL_DEPOSIT_POSITIVE: 'Amount of deposit must be more than 0',
            LOCAL_INVALID_CARD_NUMBER: 'Invalid card number',
            LOCAL_INVALID_CARD_EXPIRATION: 'Invalid card expiration',
            LOCAL_INVALID_CVC: 'Invalid CVV/CVC code',
            LOCAL_PASSWORDS_NOT_MATCH: 'Passwords do not match, please re-enter',
            LOCAL_NO_TRADING_ACCOUNTS: 'You do not have trading accounts available. Please contact the support',
            LOCAL_MIN_DEPOSIT: 'Minimum deposit is $',
            LOCAL_MIN_WITHDRAWAL: 'Minimum withdrawal amount is $',
            LOCAL_INVALID_FILE_TYPE: 'File format not supported. Please attach only JPG, PNG or PDF',
            FLOOD_REJECTED: 'You are sending messages too often. Please try again later',
            LOCAL_MIN_STAKING: 'Minimum deposit for this currency is '
        },
        countries: {
            AF: 'Afganistan',
            AX: 'Wyspy Alandzkie',
            AL: 'Albania',
            DZ: 'Algieria',
            AS: 'Samoa Amerykanskie',
            AD: 'Andora',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarktyda',
            AG: 'Antigua i Barbuda',
            AR: 'Argentyna',
            AM: 'Armenia',
            AW: 'Aruba',
            AU: 'Australia',
            AT: 'Austria',
            AZ: 'Azerbejdzan',
            BS: 'Bahamy',
            BH: 'Bahrajn',
            BD: 'Bangladesz',
            BB: 'Barbados',
            BY: 'Bialorus',
            BE: 'Belgia',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermudy',
            BT: 'Bhutan',
            BO: 'Boliwia',
            BA: 'Bosnia i Hercegowina',
            BW: 'Botswana',
            BV: 'Wyspa Bouveta',
            BR: 'Brazylia',
            IO: 'Brytyjskie Terytorium Oceanu Indyjskiego',
            BN: 'Panstwo Brunei Darussalam',
            BG: 'Bulgaria',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Kambodza',
            CM: 'Kamerun',
            CA: 'Kanada',
            CV: 'Republika Zielonego Przyladka',
            KY: 'Kajmany',
            CF: 'Republika Srodkowoafrykanska',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'Chiny',
            CX: 'Wyspa Bozego Narodzenia',
            CC: 'Wyspy Kokosowe (Keelinga)',
            CO: 'Kolumbia',
            KM: 'Komory',
            CG: 'Kongo',
            CD: 'Kongo, Republika Demokratyczna',
            CK: 'Wyspy Cooka',
            CR: 'Kostaryka',
            CI: 'Wybrzeze Kosci Sloniowej',
            HR: 'Chorwacja',
            CU: 'Kuba',
            CY: 'Cypr',
            CZ: 'Republika Czeska',
            DK: 'Dania',
            DJ: 'Dzibuti',
            DM: 'Dominika',
            DO: 'Republika Dominikanska',
            EC: 'Ekwador',
            EG: 'Egipt',
            SV: 'Salwador',
            GQ: 'Gwinea Równikowa',
            ER: 'Erytrea',
            EE: 'Estonia',
            ET: 'Etiopia',
            FK: 'Wyspy Falklandzkie (Malwiny)',
            FO: 'Wyspy Owcze',
            FJ: 'Fidzi',
            FI: 'Finlandia',
            FR: 'Francja',
            GF: 'Gujana Francuska',
            PF: 'Polinezja Francuska',
            TF: 'Francuskie Terytoria Poludniowe',
            GA: 'Gabon',
            GM: 'Gambia',
            GE: 'Georgia',
            DE: 'Niemcy',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Grecja',
            GL: 'Grenlandia',
            GD: 'Grenada',
            GP: 'Gwadelupa',
            GU: 'Guam',
            GT: 'Gwatemala',
            GG: 'Guernsey',
            GN: 'Gwinea',
            GW: 'Gwinea Bissau',
            GY: 'Gujana',
            HT: 'Haiti',
            HM: 'Wyspa Heard i Wyspy Mcdonalda',
            VA: 'Stolica Apostolska (Panstwo Watykanskie)',
            HN: 'Honduras',
            HK: 'Hongkong',
            HU: 'Wegry',
            IS: 'Islandia',
            IN: 'Indie',
            ID: 'Indonezja',
            IR: 'Iran',
            IQ: 'Irak',
            IE: 'Irlandia',
            IM: 'Wyspa Man',
            IL: 'Izrael',
            IT: 'Wlochy',
            JM: 'Jamajka',
            JP: 'Japonia',
            JE: 'Jersey',
            JO: 'Jordan',
            KZ: 'Kazachstan',
            KE: 'Kenia',
            KI: 'Kiribati',
            KR: 'Korea',
            KW: 'Kuwejt',
            KG: 'Kirgistan',
            LA: 'Laotanska Republika Ludowo-Demokratyczna',
            LV: 'Lotwa',
            LB: 'Liban',
            LS: 'Lesotho',
            LR: 'Liberia',
            LY: 'Libijska Arabska Dzamahirija',
            LI: 'Liechtenstein',
            LT: 'Litwa',
            LU: 'Luksemburg',
            MO: 'Macao',
            MK: 'Macedonia',
            MG: 'Madagaskar',
            MW: 'Malawi',
            MY: 'Malezja',
            MV: 'Malediwy',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Wyspy Marshalla',
            MQ: 'Martynika',
            MR: 'Mauretania',
            MU: 'Mauritius',
            YT: 'Majotta',
            MX: 'Meksyk',
            FM: 'Mikronezja',
            MD: 'Moldawia',
            MC: 'Monako',
            MN: 'Mongolia',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Maroko',
            MZ: 'Mozambik',
            MM: 'Myanmar',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Niderlandy',
            AN: 'Antyle Holenderskie',
            NC: 'Nowa Kaledonia',
            NZ: 'Nowa Zelandia',
            NI: 'Nikaragua',
            NE: 'Niger',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Wyspa Norfolk',
            MP: 'Mariany Pólnocne',
            NO: 'Norwegia',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Terytorium Palestynskie, Okupowane',
            PA: 'Panama',
            PG: 'Papua Nowa Gwinea',
            PY: 'Paragwaj',
            PE: 'Peru',
            PH: 'Filipiny',
            PN: 'Pitcairn',
            PL: 'Polska',
            PT: 'Portugalia',
            PR: 'Puerto Rico',
            QA: 'Katar',
            RE: 'Zjazd',
            RO: 'Rumunia',
            RU: 'Rosja',
            RW: 'Rwanda',
            BL: 'Swiety Bartymeusz',
            SH: 'Swieta Helena',
            KN: 'Saint Kitts i Nevis',
            LC: 'Saint Lucia (Lucja Pólnocna)',
            MF: 'Swiety Marcin',
            PM: 'Saint Pierre i Miquelon',
            VC: 'Saint Vincent i Grenadyny',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Wyspy Swietego Tomasza i Ksiazeca',
            SA: 'Arabia Saudyjska',
            SN: 'Senegal',
            RS: 'Serbia',
            SC: 'Seszele',
            SL: 'Sierra Leone',
            SG: 'Singapur',
            SK: 'Slowacja',
            SI: 'Slowenia',
            SB: 'Wyspy Salomona',
            SO: 'Somalia',
            ZA: 'Republika Poludniowej Afryki',
            GS: 'Poludniowa Georgia i Sandwich Isl.',
            ES: 'Hiszpania',
            LK: 'Sri Lanka',
            SD: 'Sudan',
            SR: 'Surinam',
            SJ: 'Svalbard i Jan Mayen',
            SZ: 'Suazi',
            SE: 'Szwecja',
            CH: 'Szwajcaria',
            SY: 'Syryjska Republika Arabska',
            TW: 'Taiwan',
            TJ: 'Tadzykistan',
            TZ: 'Tanzania',
            TH: 'Tajlandia',
            TL: 'Timor Wschodni',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trynidad i Tobago',
            TN: 'Tunezja',
            TR: 'Turcja',
            TM: 'Turkmenistan',
            TC: 'Wyspy Turks i Caicos',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukraina',
            AE: 'Zjednoczone Emiraty Arabskie',
            GB: 'Zjednoczone Królestwo',
            US: 'Stany Zjednoczone',
            UM: 'Wyspy Zewnetrzne Stanów Zjednoczonych',
            UY: 'Urugwaj',
            UZ: 'Uzbekistan',
            VU: 'Vanuatu',
            VE: 'Wenezuela',
            VN: 'Wietnam',
            VG: 'Brytyjskie Wyspy Dziewicze',
            VI: 'Wyspy Dziewicze, U.S.',
            WF: 'Wallis i Futuna',
            EH: 'Sahara Zachodnia',
            YE: 'Jemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe'
        },
    },
    cz: {
        _name: 'Čeština',
        timePickerModal: {
            title: 'Close In Time'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Take Profit & Stop Loss',
            takeProfit: 'Přijmout zisk',
            stopLoss: 'Zastavení ztráty',
            updateOrder: 'Aktualizace objednávky',
        },
        balanceModal: {
            balance: 'Rozvaha',
            credit: 'Kredit',
            equity: 'Kapitál',
            practice: 'PRAKTIKA',
            real: 'REÁLNÉ',
            account: 'ÚČET',
            invested: 'Investováno',
            profit: 'Zisk',
            loss: 'Ztráty',
            margin: 'Marže',
            marginLevel: 'Úroveň marže',
            marginFree: 'Volná marže'
        },
        header: {
            cfd: 'CFD',
            platform: 'Platforma',
            deposit: 'Vklad'
        },
        sideMenu: {
            marketWatch: 'Sledování trhu',
            activeOrders: 'Aktivní objednávky',
            tradingHistory: 'Historie obchodování',
            economicCalendar: 'Ekonomický kalendář',
            marketNews: 'Zprávy z trhu'
        },
        closePositionModal: {
            closeOrder: 'Zavřít pozici',
            areYouSure: 'Určitě chcete pozici uzavřít',
            buy: 'Koupit',
            sell: 'Prodej',
            yes: 'Ano',
            no: 'Ne',
        },
        pciDssModal: {
            desc: `Dodržování standardu bezpečnosti dat odvětví platebních karet (PCI DSS) je vyžadováno od všech subjektů, které uchovávají, zpracovávají nebo přenášejí údaje držitelů karet Visa/Master Card/Stripe, včetně finančních institucí, obchodníků a poskytovatelů služeb. 
            Jedná se o globální bezpečnostní standard pro všechny subjekty, které uchovávají, zpracovávají nebo přenášejí údaje držitelů karet a/nebo citlivé autentizační údaje. PCI DSS stanoví základní úroveň ochrany pro spotřebitele a pomáhá snižovat podvody a úniky dat v celém platebním ekosystému.`,
        },
        chart: {
            addNewChart: 'Přidat nový graf',
        },
        symbolsModal: {
            watchlist: 'Seznam sledování',
            asset: 'Majetek',
            price: 'Cena',
            changePct: 'Změna 24h'
        },
        pendingModal: {
            pending: 'Čekající',
            assetPrice: 'Cena aktiv',
            current: 'Aktuální',
            revert: 'Návrat k tržní ceně',
            automatically: 'Pozice se otevře automaticky, jakmile cena dosáhne této úrovně.'
        },
        orderMenu: {
            default: 'default',
            volume: 'Svazek',
            lots: 'šarže',
            units: 'jednotky',
            currency: 'měna',
            contractSize: 'Velikost smlouvy',
            position: 'Pozice',
            margin: 'Marže',
            freeMargin: 'Volná marže',
            takeProfitStopLoss: 'Take Profit & Stop Loss',
            pending: 'Čekající',
            market: 'Trh',
            leverage: 'Pákový efekt',
            spread: 'Rozšíření',
            notSet: 'Není nastaveno',
            at: 'na',
            buy: 'Koupit',
            sell: 'Prodej',
        },
        footer: {
            supportBanner: 'KAŽDÝ DEN, NEPŘETRŽITĚ',
            currentTime: 'BĚŽNÝ ČAS',
            liveChat: 'Živý chat'
        },
        statusBar: {
            balance: 'Balance',
            credit: 'Kredit',
            equity: 'Kapitál',
            margin: 'Marže',
            marginLevel: 'Úroveň marže',
            freeMargin: 'Volná marže',
            pnl: 'PnL',
            profitTotal: 'Lifetime PnL'
        },
        accountModal: {
            dateRegistered: 'Datum registrace',
            userId: 'ID uživatele',
            dashboard: 'Dashboard',
            personalData: 'Osobní údaje',
            deposit: 'Vklad',
            withdrawFunds: 'Výběr finančních prostředků',
            savings: 'Úspory',
            settings: 'Nastavení',
            logout: 'Odhlášení'
        },
        depositModal: {
            BT_INVOICE: 'Enter amount and generate invoice',
            BT_TITLE: 'Our banking details',
            PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
            account: 'Account',
            bank_name: 'Bank Name',
            beneficiary: 'Beneficiary',
            beneficiary_address: 'Beneficiary Address',
            swift: 'Swift',
            reference: 'Reference',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Amount',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Description',
            TOKEN_PAYMENT_DETAILS: 'Payment Details',
            makeDeposit: 'Provést vklad',
            practiceAccount: 'Účet pro praxi',
            realAccount: 'Skutečný účet',
            noWithdrawals: 'Žádné výběry',
            easyWithdrawals: 'Snadné výběry',
            allAssets: 'Všechna dostupná aktiva',
            fullFledged: 'Plnohodnotná platforma',
            fillUpTo: 'Naplnit do',
            freeReplenishment: 'Bezplatné doplňování',
            topUp: 'Dobití účtu',
            minimumAmount: 'Minimální částka',
            canSwitch: 'Mezi účty můžete kdykoli přepínat'
        },
        gridsModal: {
            chartGrids: 'GRAFICKÉ MŘÍŽKY',
            chart1: '1 diagram',
            chart2: '2 diagram',
            chart3: '3 diagram',
            chart4: '4 diagram',
        },
        noConnectionModal: {
            connectionLost: 'Spojení se serverem je ztraceno',
            retryNow: 'Zkusit to znovu',
        },
        loginModal: {
            loginToTradeRoom: 'Přihlášení do Traderoom',
            email: 'E-mailová adresa',
            enterEmail: 'Zadejte svůj e-mail',
            twoFactor: 'Kód 2FA (je-li povolen)',
            twoFactorAuth: 'Dvoufaktorové ověřování',
            password: 'Heslo',
            yourPassword: 'Vaše heslo',
            signIn: 'Přihlásit se',
            dontHaveAccount: 'Don’t have an account?',
            restore: 'Restore',
            fullName: 'Full Name',
            havePromo: 'I have a promo-code',
            promo: 'Promo code',
            login: 'Login',
            haveAccount: 'Already have an account?',
            hide: 'Hide',
            goTo: 'Go to back'
        },
        newPasswordModal: {
            setNewPassword: 'Nastavení nového hesla',
            newPassword: 'Nové heslo',
            confirmNewPassword: 'Potvrzení nového hesla',
            setButton: 'Sada'
        },
        registerModal: {
            repeatPassword: 'Repeat Password',
            createNewAccount: 'Vytvořit nový účet',
            email: 'E-mailová adresa',
            firstName: 'Křestní jméno',
            lastName: 'Příjmení',
            country: 'Stát',
            phone: 'Telefon',
            password: 'Heslo',
            createAccount: 'Vytvořit účet',
            currency: 'Měna',
            privacyAndPolicy: "Ochrana osobních údajů a podmínky",
            customerAgreement: 'Smlouva se zákazníkem',

        },
        forgotPasswordModal: {
            forgotPassword: 'Zapomenuté heslo?',
            submitEmail: 'Odešlete e-mail použitý k registraci, zkontrolujte si doručenou poštu a postupujte podle uvedených pokynů.',
            submit: 'Odeslat'
        },
        notifications: {
            error: 'Chyba',
            success: 'Úspěch',
            deposit: 'Vklad',
            withdrawal: 'Stažení',
            depositApproved: 'Vklad byl schválen',
            depositDeclined: 'Vklad byl odmítnut',
            withdrawalApproved: 'Stažení bylo schváleno',
            withdrawalDeclined: 'Stažení bylo zamítnuto'
        },
        markets: {
            forex: 'Forex',
            stocks: 'Stocks',
            commodities: 'Komodity',
            indices: 'Indexy',
            crypto: 'Crypto',
            metals: 'Kovy',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'BUY',
            profitCalculator: 'Profit Calculator',
            sell: 'SELL',
            volume: 'Volume',
            entryPrice: 'Entry Price',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            maxPosition: 'Max Position',
            calculate: 'Calculate',
            calculationsFor: 'Calculations for',
            leverage: 'Leverage',
            requiredMargin: 'Required Margin',
            profitFromTP: 'Profit from TP',
            lossFromSL: 'Loss from SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Deposit successful',
            text: 'Your deposit has been processed successfully!',
        },
        depositFailModal: {
            title: 'Deposit failed',
            text: 'Your deposit has not been processed.',
        },
        widgetMarketWatch: {
            marketWatch: 'Sledování trhu',
            search: 'Vyhledávání...',
        },
        widgetOrders: {
            commission: 'Commission',
            volume: 'Svazek',
            margin: 'Marže',
            active: 'Aktivní',
            pending: 'Čekající',
            activeOrders: 'Aktivní objednávky',
            portfolio: 'Portfolio',
            allPositions: 'Všechny pozice',
            noPositionsLine1: 'Nemáte žádné otevřené',
            noPositionsLine2: 'pozice zatím',
            show: 'Zobrazit',
            more: 'více',
            less: 'méně',
            purchaseTime: 'Čas nákupu',
            closePosition: 'Zavřít pozici',
            priceOpen: 'Nákupní cena',
            priceSl: 'Cena Stop Loss',
            priceTp: 'Cena Take Profit',
            type: 'Pozice Směr',
            pnl: 'Zisk/ztráta',
            cancelOrder: 'Zrušit objednávku',
            orderType: 'Typ objednávky',
            cancelled: 'Zrušeno',
            tradingHistory: 'Historie obchodování',
            noHistoryLine1: 'Nemáte žádné',
            noHistoryLine2: 'dosud uzavřené obchody',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Ekonomický kalendář'
        },
        widgetMarketNews: {
            marketNews: 'Zprávy z trhu'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'Objednávky Aktivní',
            ordersHistory: 'Historie objednávek',
            id: 'ID',
            symbol: 'Symbol',
            type: 'Typ',
            volume: 'Svazek',
            openPrice: 'Otevřená cena',
            openTime: 'Otevřený čas',
            closePrice: 'Zavřít Cena',
            closeTime: 'Tiempo de cierre',
            sl: 'SL',
            tp: 'TP',
            price: 'Cena',
            pnl: 'PnL',
            actions: 'Akce',
            edit: 'Upravit',
            close: 'Zavřít',
            commission: 'Commission',
            swap: 'Swap',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short'
        },
        assetInfoModal: {
            assetInfo: 'Asset Info',
            tradingConditions: 'Trading Conditions',
            information: 'Session Change',
            bid: 'Bid',
            ask: 'Ask',
            sessionChange: 'Session Change',
            tradeNow: 'Trade Now',
            opens: 'Opens',
            closes: 'Closes',
            at: 'at',
            open247: 'Open 24/7',
            today: 'today',
            tomorrow: 'tomorrow',
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            contractSpecification: 'Contract Specification',
            symbol: 'Symbol',
            name: 'Name',
            market: 'Market',
            baseCurrency: 'Base Currency',
            digits: 'Digits',
            lotSize: 'Lot Size',
            lotStep: 'Lot Step',
            minVolume: 'Min Volume',
            maxVolume: 'Max Volume',
            leverage: 'Leverage',
            commission: 'Commission',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short',
            schedule: 'Schedule',
            weekday: 'Weekday',
            tradingTime: 'Trading Time',
            closed: 'closed',
            sell: 'Sell',
            buy: 'Buy',
            low: 'Low',
            high: 'High',
            oneHourChange: '1 hour change',
            oneDayChange: '1 day change',
            oneWeekChange: '1 week change',
            oneMonthChange: '1 month change',
            threeMonthsChange: '3 months change',
            oneYearChange: '1 year change',
            loading: 'Loading...',
        },
        autoTrader: {
            days: 'days',
            title: 'Auto Trader',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Risk Level: Low',
            riskLevelMedium: 'Risk Level: Medium',
            riskLevelHigh: 'Risk Level: High',
            perDay: 'Trades per day:',
            desc: `Trading CFDs and other leveraged products can lead to losses. Before trading, clients should
                read the
                relevant risk statements on our Risk Disclosure page. Automated trading does not guarantee results. The
                company accepts no responsibility for the loss of funds in automatic trading. Please make sure that you
                fully understand the risks and take measures to manage risks.`,
            accept: 'Accept',
            save: 'Uložit',
            cancel: 'Zrušit',
        },
        cardBox: {
            name: 'Jméno',
            cardNumber: 'Číslo karty',
            expiration: 'Expirace (mm/rr)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "Obchod",
            startTrading: 'Začít Obchodovat',
            deposit: 'Provést vklad',
            dashboard: 'Dashboard',
            personalInfo: 'Aktualizace osobních údajů',
            contactInfo: 'Kontaktní informace',
            withdrawal: 'Žádost o stažení',
            verification: 'Ověřování totožnosti',
            accounts: 'Správa účtů',
            liveChat: 'Živý chat',
            savings: 'Spoření',
            settings: 'Nastavení platformy',
            logOut: 'Odhlášení',
        },
        fullScreenDeposit: {
            methods: 'METHODS',
            confirm: 'Confirm in the application of your bank',
            sendOtp: 'Send otp',
            otpCode: 'Otp code',
            addWalletAddress: 'Wallet for this deposit method is not set. Please contact your agent or support team.',
            addressReceipt: 'Please wait for the address to load',
            makeDeposit: 'UPLATNIT ZÁLOHU',
            instant: 'Instantní',
            minutes: 'minuty',
            hours: 'hodin',
            days: 'dny',
            amount: 'Částka',
            continue: 'Pokračovat',
            qrCode: 'QR KÓD',
            depositAddress: 'ADRESA VKLADU',
            copy: 'Kopírovat',
            last: 'POSLEDNÍ',
            deposits: 'DEPOSITY',
            time: 'Čas',
            currency: 'Měna',
            status: 'Status',
            info: 'Informace',
        },
        fullScreenCcExt: {
            pleaseWait: 'Počkejte prosím. Připojení k poskytovateli platebních služeb...',
            cardPayment: 'Platba kartou',
            inOrder: 'Za účelem odeslání částky',
            pleaseProvide: 'uveďte prosím údaje o kartě:'
        },
        fullScreenDashboard: {
            completeVerification: 'Please Complete Verification',
            noOrderYet: 'No order yet',
            totalBalance: 'Finální Balance',
            totalPnl: 'Totální Pnl',
            profitableOrders: 'Výdělečné Objednávky',
            roi: 'ROI',
            activityLog: 'Aktivační Log',
            loginFromIp: 'Login from IP',
            tradingResults: 'Tržní Výsledky ',
            week: 'Týden',
            month: 'Měsíc',
            year: 'Rok',
            successRate: 'Úspěšná Hodnota',
            closedWithProfit: 'Zavřeno s Výdělkem',
            closedWithLoss: 'Zavřeno s Prodělkem',
            account: 'Profil',
            balance: 'Balance',
            leverage: 'Obchodní Rameno',
            credit: 'Úvěr',
            tradeNow: 'Obchod Nyní',
            usingCurrentRate: 'using current exchange rate',
            totalDeposits: 'Total Deposits'
        },
        fullScreenPersonal: {
            personalDetails: 'Osobní údaje',
            profilePhoto: 'Profilová fotografie',
            firstName: 'Jméno',
            country: 'Stát',
            lastName: 'Příjmení',
            address: 'Adresa',
            email: 'E-mail',
            phone: 'Telefonní číslo',
            saveChanges: 'Uložit změny',
            state: 'Město',
            postcode: 'PSČ',
            created: "Účet vytvořen"
        },
        fullScreenAvatar: {
            dropFile: 'Nahrajte soubor do výše uvedeného kroužku.',
            notAllowed: 'Není povoleno zveřejňovat',
            notAllowedLine1: 'Fotografie vysloveně sexuální nebo pornografické povahy',
            notAllowedLine2: 'Snímky podněcující etnickou nebo rasovou nenávist nebo agresi.',
            notAllowedLine3: 'Fotografie osob mladších 18 let',
            notAllowedLine4: 'Fotografie třetích stran chráněné autorskými právy',
            notAllowedLine5: 'Obrázky větší než 5 MB a v jiném formátu než JPG, GIF nebo PNG.',
            requirements: 'Na fotografii musí být jasně vidět váš obličej. Všechny vámi nahrané fotografie a videa musí splňovat tyto požadavky, jinak mohou být odstraněny.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Please contact your account manager for withdrawal details',
            cryptocurrency: 'Cryptocurrency',
            withdrawal: 'Withdrawal',
            requestNewWithdrawal: 'POŽÁDAT O NOVÝ VÝBĚR',
            amount: 'Částka',
            currentBalance: 'Zůstatek na vašem běžném účtu je',
            withdrawAll: 'Stáhněte všechny',
            requestWithdrawal: 'Žádost o stažení',
            last: 'POSLEDNÍ',
            withdrawalRequests: 'ŽÁDOSTI O STAŽENÍ',
            time: 'Čas',
            currency: 'Měna',
            info: 'Informace',
            status: 'Status',
            bank: 'Bank',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            card: 'Karta',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Podrobnosti o stažení',
            address: 'Adresa peněženky',
            bankAccount: 'Číslo účtu',
            bankHolder: 'Jméno držitele účtu',
            bankIban: 'IBAN',
            bankSwift: 'Bankovní SWIFT kód',
            recipientAddress: 'Adresa příjemce',
            routingNumber: 'Směrové číslo',
            bankBeneficiary: 'Název banky příjemce',
            bankAddress: 'Adresa banky',
            bankCode: 'Bankovní kód',
            desc: 'Popis',
            cardNumber: 'Číslo karty',
            cardHolder: 'Jméno držitele karty',
        },
        fullScreenVerification: {
            dragAndDrop: 'přetáhněte dokument do této oblasti',
            documentsList: 'SEZNAM NAHRANÝCH DOKUMENTŮ',
            document: 'Dokument',
            timeUploaded: 'Čas nahrání',
            timeProcessed: 'Zpracovaný čas',
            status: 'Status',
            types: ['Doklad totožnosti', 'Doklad o bydlišti', 'Přední strana kreditní karty', 'Kreditní karta zpět', 'Doklad o totožnosti zpět', 'Selfie'],
            types2: ['Proof of identification (POI)', 'Proof of residential address', 'Credit Card Front', 'Credit Card Back', 'Proof of identification Back', 'Selfie with ID'],
            upload: 'Nahrát',
            isConfirmed: 'je potvrzeno',
            uploadAnotherDocument: 'Nahrát další dokument',
            isUnderReview: 'je předmětem přezkumu',
            questionnaire: 'Dotazník',
            sendAnswers: 'Odeslat odpovědi',
            progressBarOf: 'of',
            progressBarText: 'of your documents have been uploaded and confirmed'
        },
        fullScreenAccounts: {
            manageAccounts: 'Převádění prostředků mezi účty',
            transferFrom: 'Z účtu',
            transferTo: 'Na účet',
            amount: 'Částka',
            makeTransfer: 'Proveďte přenos',
            yourAccounts: 'Vaše účty',
            account: 'Účet',
            currency: 'Měna',
            balance: 'Balance',
            credit: 'Kredit',
            makeActive: 'Udělat aktivní',
            active: 'Aktivní'
        },
        fullScreenSupport: {
            sendMessage: 'Odeslat zprávu'
        },
        fullScreenSavings: {
            pct_0: 'Flexibilní',
            pct_30: 'Uzamčeno na 1 měsíc',
            pct_90: 'Uzamčeno 1 čtvrtletí',
            pct_180: 'Uzamčeno na 6 měsíců',
            pct_365: 'Uzamčeno na 1 rok',
            savings: 'Úspory',
            detailedInformation: 'Podrobné informace',
            pleaseSelect: 'Vyberte měnu a období',
            openSavings: 'Otevřené spoření',
            currentTime: 'Aktuální čas',
            releaseTime: 'Čas vydání',
            currencyChosen: 'Vybraná měna',
            periodChosen: 'Vybrané období',
            yourRate: 'Vaše sazba',
            yourEarnings: 'Vaše příjmy',
            accountFrom: 'Účet, ze kterého se mají převádět prostředky',
            enterAmount: 'Zadejte částku',
            in: 'V',
            openAndInvest: 'Otevřete si spořicí účet a investujte',
            investment: 'Investice',
            period: 'Období',
            daysTotal: 'Dny celkem',
            finalEarnings: 'Konečné příjmy',
            created: 'Vytvořeno',
            daysElapsed: 'Uplynulé dny',
            days: 'dny',
            earnings: 'Výdělky',
            fundsReleasedIn: 'Uvolněné prostředky v',
            claim: 'Reklamace',
        },
        fullScreenSettings: {
            updatePassword: 'AKTUALIZOVAT HESLO',
            currentPassword: 'Aktuální heslo',
            newPassword: 'Nové heslo',
            changePassword: 'Změna hesla',
            activationForGoogle: 'Aktivační kód pro Google Authenticator',
            activateAndEnter: 'Aktivujte Authenticator a zadejte vygenerovaný kód do pole níže.',
            qrCode: 'QR kód',
            activateProtection: 'Aktivace ochrany 2FA',
            protectionActive: 'Ochrana 2FA je aktivní',
            twoFactorCode: '2FA Kód',
            disableProtection: 'Vypnutí ochrany 2FA',
            language: 'Jazyk',
            dashboardCurrency: 'měna na nástěnce',
            confirmNewPassword: 'Potvrzení nového hesla',
        },
        fullScreenReferrals: {
            yourLink: 'VÁŠ DOPORUČUJÍCÍ ODKAZ',
            referredFriends: 'DOPORUČENÉ PŘÁTELE',
            id: 'ID',
            name: 'Jméno',
            registered: 'Registrováný',
            level: 'Úroveň',
            payout: 'Výplata',
            lastPayouts: 'POSLEDNÍ VÝPLATY ZA DOPORUČENÍ',
            time: 'Čas',
            amount: 'Částka',
            currency: 'Měna',
            info: 'Informace'
        },
        months: {
            Jan: 'Leden',
            Feb: 'Únor',
            Mar: 'Březen',
            Apr: 'Duben',
            May: 'Květen',
            Jun: 'Červen',
            Jul: 'Červenec',
            Aug: 'Srpen',
            Sep: 'Září',
            Oct: 'Říjen',
            Nov: 'Listopad',
            Dec: 'Prosinec'
        },
        orderTypes: ['Koupit', 'Prodej', 'Nákupní limit', 'Prodejní limit', 'Koupit Stop', 'Prodejní zastávka'],
        statuses: ['Čekající', 'Schváleno', 'Odmítnuto', 'Zpracování'],
        errors: {
            NOT_FOUND: 'Chyba požadavku',
            FORBIDDEN: 'Přístup odepřen. Přihlaste se znovu',
            SERVER_ERROR: 'Akce se nezdařila. Zkuste to prosím později',
            INVALID_ACCOUNT: 'Zasedání skončilo. Znovu se přihlaste',
            INVALID_EMAIL: 'Neplatná e-mailová adresa',
            INVALID_PHONE: 'Neplatné telefonní číslo',
            INVALID_COUNTRY: 'Neplatný stát',
            INVALID_LOGIN: 'Neplatné přihlašovací údaje',
            USER_BLOCKED: 'Účet je zablokován. Kontaktujte prosím podpor',
            USER_REGISTERED: 'Uživatel se zadaným e-mailem nebo telefonním číslem je již registrován',
            INVALID_USER: 'Uživatel nebyl nalezen',
            REJECTED: 'Provoz není povolen z důvodu omezení platformy',
            INVALID_OLD_PASSWORD: 'Staré heslo je neplatné',
            INVALID_SYMBOL: 'Neplatný obchodní symbol',
            INVALID_SYMBOL_PRECISION: 'Nesprávná přesnost symbolu',
            INVALID_FRAME: 'Neplatný rámec grafu',
            INVALID_CURRENCY: 'Neplatná měna',
            INVALID_PRODUCT: 'Neplatný typ obchodního účtu',
            INSUFFICIENT_FUNDS: 'Nedostatek finančních prostředků',
            INVALID_WITHDRAWAL: 'Stažení není nalezeno',
            INVALID_STATUS: 'Neplatný stav',
            INVALID_FILE: 'Nesprávná přípona souboru nebo příliš velký soubor',
            INVALID_DOCUMENT: 'Dokument nebyl nalezen',
            INVALID_NAME: 'Nesprávné jméno',
            INVALID_LOT_SIZE: 'Nesprávná velikost pozemku',
            INVALID_LOT_STEP: 'Neplatný krok šarže',
            INVALID_MIN_VOLUME: 'Neplatný minimální objem',
            INVALID_MAX_VOLUME: 'Neplatný maximální objem',
            INVALID_GROUP: 'Neplatná skupina',
            INVALID_SPREAD: 'Neplatné rozpětí',
            REQUEST_TIMEOUT: 'Požadavek vypršel. Zkuste to prosím později',
            EXISTING_OPEN_ORDERS: 'Máte otevřenou objednávku, nejprve zavřete objednávku pro tohoto uživatele a zkuste to znovu.',
            INVALID_TYPE: 'Nesprávný typ objednávky',
            INVALID_VOLUME: 'Neplatný objem objednávky',
            INVALID_SL: 'Neplatná hodnota stop loss',
            INVALID_TP: 'Neplatná hodnota take profit',
            INVALID_PRICE: 'Neplatná cena objednávky',
            INVALID_EXPIRATION: 'Neplatné vypršení platnosti objednávky',
            NOT_ENOUGH_MARGIN: 'Nedostatečná marže',
            INVALID_ORDER: 'Objednávka nebyla nalezena',
            MARKET_CLOSED: 'Obchodování není k dispozici. Zkuste to prosím znovu',
            INVALID_BALANCE_TYPE: 'Nesprávný typ operace vyvážení',
            INVALID_HASH: 'Neplatný hash',
            HASH_EXPIRED: 'Platnost odkazu pro obnovení hesla vypršela. Vyžádejte si prosím nové',
            INVALID_CODE: 'Neplatný kód 2FA',
            CHAT_DISABLED: 'Přístup k chatu podpory byl uzavřen',
            WITHDRAWAL_NOT_ALLOWED: 'Přístup k výběrům byl uzavřen',
            TRADING_DISABLED: 'Trading is disabled for current account',
            PENDING_DEPOSITS_LIMIT: 'Deposits limit exceeded',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Only one pending withdrawal allowed',
            LOCAL_DEPOSIT_ONLY_REAL: 'Deposit is available only for real account',
            LOCAL_DEPOSIT_POSITIVE: 'Amount of deposit must be more than 0',
            LOCAL_INVALID_CARD_NUMBER: 'Invalid card number',
            LOCAL_INVALID_CARD_EXPIRATION: 'Invalid card expiration',
            LOCAL_INVALID_CVC: 'Invalid CVV/CVC code',
            LOCAL_PASSWORDS_NOT_MATCH: 'Passwords do not match, please re-enter',
            LOCAL_NO_TRADING_ACCOUNTS: 'You do not have trading accounts available. Please contact the support',
            LOCAL_MIN_DEPOSIT: 'Minimum deposit is $',
            LOCAL_MIN_WITHDRAWAL: 'Minimum withdrawal amount is $',
            LOCAL_INVALID_FILE_TYPE: 'File format not supported. Please attach only JPG, PNG or PDF',
            FLOOD_REJECTED: 'You are sending messages too often. Please try again later',
            LOCAL_MIN_STAKING: 'Minimum deposit for this currency is '
        },
        countries: {
            AF: 'Afghánistán',
            AX: 'Alandské ostrovy',
            AL: 'Albánie',
            DZ: 'Alžírsko',
            AS: 'Americká Samoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarktida',
            AG: 'Antigua a Barbuda',
            AR: 'Argentina',
            AM: 'Arménie',
            AW: 'Aruba',
            AU: 'Austrálie',
            AT: 'Austria',
            AZ: 'Ázerbájdžán',
            BS: 'Bahamy',
            BH: 'Bahrajn',
            BD: 'Bangladéš',
            BB: 'Barbados',
            BY: 'Bělorusko',
            BE: 'Belgie',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermudy',
            BT: 'Bhútán',
            BO: 'Bolívie',
            BA: 'Bosna a Hercegovina',
            BW: 'Botswana',
            BV: 'Bouvetův ostrov',
            BR: 'Brazílie',
            IO: 'Britské území v Indickém oceánu',
            BN: 'Brunej Darussalam',
            BG: 'Bulharsko',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Kambodža',
            CM: 'Kamerun',
            CA: 'Kanada',
            CV: 'Kapverdy',
            KY: 'Kajmanské ostrovy',
            CF: 'Středoafrická republika',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'Čína',
            CX: 'Vánoční ostrov',
            CC: 'Kokosové (Keelingovy) ostrovy',
            CO: 'Kolumbie',
            KM: 'Komory',
            CG: 'Kongo',
            CD: 'Kongo, Demokratická republika',
            CK: 'Cookovy ostrovy',
            CR: 'Kostarika',
            CI: 'Pobřeží slonoviny ',
            HR: 'Chorvatsko',
            CU: 'Kuba',
            CY: 'Kypr',
            CZ: 'Česká republika',
            DK: 'Dánsko',
            DJ: 'Džibutsko',
            DM: 'Dominika',
            DO: 'Dominikánská republika',
            EC: 'Ekvádor',
            EG: 'Egypt',
            SV: 'Salvador',
            GQ: 'Rovníková Guinea',
            ER: 'Eritrea',
            EE: 'Estonsko',
            ET: 'Etiopie',
            FK: 'Falklandy (Malvíny)',
            FO: 'Faerské ostrovy',
            FJ: 'Fidži',
            FI: 'Finsko',
            FR: 'Francie',
            GF: 'Francouzská Guyana',
            PF: 'Francouzská Polynésie',
            TF: 'Francouzská jižní území',
            GA: 'Gabon',
            GM: 'Gambie',
            GE: 'Georgia',
            DE: 'Německo',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Řecko',
            GL: 'Grónsko',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Heardův ostrov a Mcdonaldovy ostrovy',
            VA: 'Svatý stolec (Vatikánský městský stát)',
            HN: 'Honduras',
            HK: 'Hongkong',
            HU: 'Maďarsko',
            IS: 'Island',
            IN: 'Indie',
            ID: 'Indonésie',
            IR: 'Írán',
            IQ: 'Irák',
            IE: 'Irsko',
            IM: 'Ostrov Man',
            IL: 'Izrael',
            IT: 'Itálie',
            JM: 'Jamajka',
            JP: 'Japonsko',
            JE: 'Jersey',
            JO: 'Jordan',
            KZ: 'Kazachstán',
            KE: 'Keňa',
            KI: 'Kiribati',
            KR: 'Korea',
            KW: 'Kuvajt',
            KG: 'Kyrgyzstán',
            LA: 'Laoská lidově demokratická republika',
            LV: 'Lotyšsko',
            LB: 'Libanon',
            LS: 'Lesotho',
            LR: 'Libérie',
            LY: 'Libyjská arabská džamáhíríja',
            LI: 'Lichtenštejnsko',
            LT: 'Litva',
            LU: 'Lucembursko',
            MO: 'Macao',
            MK: 'Makedonie',
            MG: 'Madagaskar',
            MW: 'Malawi',
            MY: 'Malajsie',
            MV: 'Maledivy',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marshallovy ostrovy',
            MQ: 'Martinik',
            MR: 'Mauritánie',
            MU: 'Mauricius',
            YT: 'Mayotte',
            MX: 'Mexiko',
            FM: 'Mikronésie',
            MD: 'Moldavsko',
            MC: 'Monako',
            MN: 'Mongolsko',
            ME: 'Černá Hora',
            MS: 'Montserrat',
            MA: 'Maroko',
            MZ: 'Mosambik',
            MM: 'Myanmar',
            NA: 'Namibie',
            NR: 'Nauru',
            NP: 'Nepál',
            NL: 'Nizozemsko',
            AN: 'Nizozemské Antily',
            NC: 'Nová Kaledonie',
            NZ: 'Nový Zéland',
            NI: 'Nikaragua',
            NE: 'Niger',
            NG: 'Nigérie',
            NU: 'Niue',
            NF: 'Ostrov Norfolk',
            MP: 'Severní Mariany',
            NO: 'Norsko',
            OM: 'Omán',
            PK: 'Pákistán',
            PW: 'Palau',
            PS: 'Palestinské území, okupované území',
            PA: 'Panama',
            PG: 'Papua-Nová Guinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Filipíny',
            PN: 'Pitcairn',
            PL: 'Polsko',
            PT: 'Portugalsko',
            PR: 'Portoriko',
            QA: 'Katar',
            RE: 'Sraz',
            RO: 'Rumunsko',
            RU: 'Rusko',
            RW: 'Rwanda',
            BL: 'Svatý Barthelemy',
            SH: 'Svatá Helena',
            KN: 'Svatý Kryštof a Nevis',
            LC: 'Svatá Lucie',
            MF: 'Svatý Martin',
            PM: 'Svatý Pierre a Miquelon',
            VC: 'Svatý Vincenc a Grenadiny',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Svatý Tomáš a Princův',
            SA: 'Saúdská Arábie',
            SN: 'Senegal',
            RS: 'Srbsko',
            SC: 'Seychely',
            SL: 'Sierra Leone',
            SG: 'Singapur',
            SK: 'Slovensko',
            SI: 'Slovinsko',
            SB: 'Šalamounovy ostrovy',
            SO: 'Somálsko',
            ZA: 'Jižní Afrika',
            GS: 'Jižní Georgie a Sandwichovy ostrovy',
            ES: 'Španělsko',
            LK: 'Srí Lanka',
            SD: 'Súdán',
            SR: 'Surinam',
            SJ: 'Špicberky a Jan Mayen',
            SZ: 'Svazijsko',
            SE: 'Švédsko',
            CH: 'Švýcarsko',
            SY: 'Syrská arabská republika',
            TW: 'Tchaj-wan',
            TJ: 'Tádžikistán',
            TZ: 'Tanzanie',
            TH: 'Thajsko',
            TL: 'Východní Timor',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad a Tobago',
            TN: 'Tunisko',
            TR: 'Krocan',
            TM: 'Turkmenistán',
            TC: 'Ostrovy Turks a Caicos',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukrajina',
            AE: 'Arabské emiráty',
            GB: 'Velká Británie',
            US: 'Spojené státy americké',
            UM: 'Odlehlé ostrovy Spojených států',
            UY: 'Uruguay',
            UZ: 'Uzbekistán',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Vietnam',
            VG: 'Britské Panenské ostrovy',
            VI: 'Panenské ostrovy, USA',
            WF: 'Wallis a Futuna',
            EH: 'Západní Sahara',
            YE: 'Jemen',
            ZM: 'Zambie',
            ZW: 'Zimbabwe'
        }
    },
    de: {
        _name: 'Deutsch',
        timePickerModal: {
            title: 'Close In Time'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Gewinnmitnahme & Stop-Loss',
            takeProfit: ' Gewinnmitnahme',
            stopLoss: 'Stop-Loss',
            updateOrder: 'Handel aktualisieren',
        },
        balanceModal: {
            balance: 'Kontostand',
            credit: ' Kredit',
            equity: 'Eigenkapital ',
            practice: 'Praxis',
            real: 'Real',
            account: 'Konto',
            invested: 'Investiert',
            profit: 'Profit',
            loss: 'Verlust',
            margin: 'Margin',
            marginLevel: 'Margin Level',
            marginFree: 'Freie Margin'
        },
        header: {
            cfd: 'CFD',
            platform: 'Plattform',
            deposit: 'Einzahlung '
        },
        sideMenu: {
            marketWatch: 'Marktübersicht',
            activeOrders: 'Aktive Handel',
            tradingHistory: 'Handel Geschichte',
            economicCalendar: 'Wirtschaftlicher Kalender',
            marketNews: 'Markt Nachrichten'
        },
        closePositionModal: {
            closeOrder: 'Handel schließen',
            areYouSure: 'Sind Sie sicher, den Order zu schließen?',
            buy: 'Kaufen',
            sell: 'Verkaufen',
            yes: 'Ja',
            no: 'Nein',
        },
        pciDssModal: {
            desc: `Die Einhaltung des Payment Card Industry Data Security Standard (PCI DSS) ist für alle Unternehmen erforderlich, die Daten von Karteninhabern von Visa/Master Card/Stripe speichern, verarbeiten oder übertragen, einschließlich Finanzinstitute, Händler und Dienstleister. 
            Es handelt sich um den globalen Sicherheitsstandard für alle Unternehmen, die Karteninhaberdaten und/oder sensible Authentifizierungsdaten speichern, verarbeiten oder übertragen. PCI DSS legt einen grundlegenden Schutz für Verbraucher fest und trägt dazu bei, Betrug und Datenverstöße im gesamten Zahlungssystem zu reduzieren.`,
        },
        chart: {
            addNewChart: 'Neue Chart hinzufügen',
        },
        symbolsModal: {
            watchlist: 'Überwachungsliste',
            asset: 'Vermögenswert',
            price: 'Preise',
            changePct: '24h Wechsel'
        },
        pendingModal: {
            pending: 'Ausstehend',
            assetPrice: 'Vermögenswertpreis',
            current: 'aktuell',
            revert: 'Zurück zum Marktpreis',
            automatically: 'Handel wird automatisch eröffnet, wenn der Preis dieses Niveau erreicht'
        },
        orderMenu: {
            default: 'default',
            volume: 'Volumen',
            lots: 'lots',
            units: 'einheiten',
            currency: 'währung',
            contractSize: 'kontraktgröße',
            position: 'Position',
            margin: 'Margin',
            freeMargin: 'Freie Margin',
            takeProfitStopLoss: 'Gewinnmitnahme & Stop-Loss',
            pending: 'Ausstehend',
            market: 'Markt',
            leverage: 'Hebelwirkung',
            spread: 'Spread',
            notSet: 'Nicht eingestellt',
            at: 'um',
            buy: 'Kaufen',
            sell: 'Verkaufen',
        },
        footer: {
            supportBanner: 'JEDEN TAG, RUND UM DIE UHR',
            currentTime: 'AKTUELLE UHRZEIT',
            liveChat: 'Live Chat'
        },
        statusBar: {
            balance: 'Kontostand',
            credit: 'Kredit',
            equity: 'Eigenkapital',
            margin: 'Margin',
            marginLevel: 'Margin level',
            freeMargin: 'Freie Margin',
            pnl: 'PnL',
            profitTotal: 'Lifetime PnL'
        },
        accountModal: {
            dateRegistered: 'Registrierungsdatum',
            userId: 'Benutzer ID',
            dashboard: 'Dashboard',
            personalData: 'Persönliche Daten',
            deposit: 'Einzahlung',
            withdrawFunds: 'Auszahlung',
            savings: 'Ersparnisse',
            settings: 'Einstelungen',
            logout: 'Ausloggen'
        },
        depositModal: {
            BT_INVOICE: 'Enter amount and generate invoice',
            BT_TITLE: 'Our banking details',
            PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
            account: 'Account',
            bank_name: 'Bank Name',
            beneficiary: 'Beneficiary',
            beneficiary_address: 'Beneficiary Address',
            swift: 'Swift',
            reference: 'Reference',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Amount',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Description',
            TOKEN_PAYMENT_DETAILS: 'Payment Details',
            makeDeposit: 'Einzahlung tätigen',
            practiceAccount: 'Demokonto',
            realAccount: 'Reales Konto',
            noWithdrawals: 'Keine Auszahlungen',
            easyWithdrawals: 'Einfache Auszahlungen ',
            allAssets: 'Alle Vermögenswerte verfügbar',
            fullFledged: 'Vollgeführte Plattform',
            fillUpTo: 'Auffüllen bis',
            freeReplenishment: 'Kostenloser Nachschub',
            topUp: 'Laden Sie Ihr Konto auf',
            minimumAmount: 'Mindestbetrag',
            canSwitch: 'Sie können jederzeit zwischen Ihren Konten wechseln'
        },
        gridsModal: {
            chartGrids: 'Chartaufstellung',
            chart1: '1 chart',
            chart2: '2 charts',
            chart3: '3 charts',
            chart4: '4 charts',
        },
        noConnectionModal: {
            connectionLost: 'Die Verbindung mit dem Server wurde unterbrochen',
            retryNow: 'Jetzt erneut versuchen',
        },
        loginModal: {
            loginToTradeRoom: 'Melden Sie sich bei Handelsroom an',
            email: 'E-Mail-Addresse',
            enterEmail: 'Geben sie ihre E-Mail Adresse ein',
            twoFactor: '2FA-Code (falls aktiviert)',
            twoFactorAuth: 'Zwei-Faktor-Authentifizierung',
            password: 'Passwort',
            yourPassword: 'Ihr Passwort',
            signIn: 'Anmelden',
            dontHaveAccount: 'Don’t have an account?',
            restore: 'Restore',
            fullName: 'Full Name',
            havePromo: 'I have a promo-code',
            promo: 'Promo code',
            login: 'Login',
            haveAccount: 'Already have an account?',
            hide: 'Hide',
            goTo: 'Go to back'
        },
        newPasswordModal: {
            setNewPassword: 'Neues Passwort festlegen',
            newPassword: 'Neues Passwort',
            confirmNewPassword: 'Bestätige neues Passwort',
            setButton: 'Festsetzen'
        },
        registerModal: {
            repeatPassword: 'Repeat Password',
            createNewAccount: 'Ein neues Konto erstellen',
            email: 'E-Mail-Addresse',
            firstName: 'Vorname',
            lastName: 'Nachname',
            country: 'Land',
            phone: 'Telefon',
            password: 'Passwort',
            createAccount: 'Benutzerkonto erstellen',
            currency: 'Währung',
            privacyAndPolicy: "Datenschutz & Richtlinien",
            customerAgreement: 'Kundenvereinbarung',

        },
        forgotPasswordModal: {
            forgotPassword: 'Passwort vergessen?',
            submitEmail: 'Bitte senden Sie eine für die Registrierung verwendete E-Mail-Adresse, überprüfen Sie Ihren Posteingang und befolgen Sie die bereitgestellten Anweisungen',
            submit: 'Bestätigen'
        },
        notifications: {
            error: 'Fehler',
            success: 'Erfolg',
            deposit: 'Einzahlung',
            withdrawal: 'Auszahlung',
            depositApproved: 'Einzahlung wurde genehmigt',
            depositDeclined: 'Einzahlung wurde abgelehnt',
            withdrawalApproved: 'Auszahlung wurde genehmigt',
            withdrawalDeclined: 'Auszahlung wurde abgelehnt'
        },
        markets: {
            forex: 'Devisen',
            stocks: 'Aktien',
            commodities: 'Rohstoffe',
            indices: 'Indizes',
            crypto: 'Krypto',
            metals: 'Metalle',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'BUY',
            profitCalculator: 'Profit Calculator',
            sell: 'SELL',
            volume: 'Volume',
            entryPrice: 'Entry Price',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            maxPosition: 'Max Position',
            calculate: 'Calculate',
            calculationsFor: 'Calculations for',
            leverage: 'Leverage',
            requiredMargin: 'Required Margin',
            profitFromTP: 'Profit from TP',
            lossFromSL: 'Loss from SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Deposit successful',
            text: 'Your deposit has been processed successfully!',
        },
        depositFailModal: {
            title: 'Deposit failed',
            text: 'Your deposit has not been processed.',
        },
        widgetMarketWatch: {
            marketWatch: 'Marktübersicht',
            search: 'Suche...',
        },
        widgetOrders: {
            commission: 'Commission',
            volume: 'Volumen',
            margin: 'Margin',
            active: 'Aktiv',
            pending: 'Ausstehend',
            activeOrders: 'Aktive Orders',
            portfolio: 'Portfolio',
            allPositions: 'Alle Positionen',
            noPositionsLine1: 'Sie haben noch keine',
            noPositionsLine2: 'geöffneten Positionen',
            show: 'Anzeigen',
            more: 'mehr',
            less: 'weniger',
            purchaseTime: 'Transaktionszeit',
            closePosition: 'Position schließen',
            priceOpen: 'Transaktionspreis',
            priceSl: 'Stop Loss Preis',
            priceTp: 'Take Profit Pries',
            type: 'Positionsrichtung',
            pnl: 'Profit/Verlust',
            cancelOrder: 'Order stornieren',
            orderType: 'Ordertyp',
            cancelled: 'Storniert',
            tradingHistory: 'Handelsgeschichte',
            noHistoryLine1: 'Sie haben noch keine',
            noHistoryLine2: 'geschlossenen Positionen',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Wirtschaftskalender'
        },
        widgetMarketNews: {
            marketNews: 'Marktnachrichten'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
 timedOrder: 'Timed Order',
            ordersActive: 'Aktive Orders',
            ordersHistory: 'Handelsgeschichte',
            id: 'ID',
            symbol: 'Symbol',
            type: 'Type',
            volume: 'Volumen',
            openPrice: 'Eröffnungspreis',
            openTime: 'Eröffnungszeit',
            closePrice: 'Schlusskurs',
            closeTime: 'Schließzeit',
            sl: 'SL',
            tp: 'TP',
            price: 'Preis',
            pnl: 'PnL',
            actions: 'Aktionen',
            edit: 'Bearbeiten',
            close: 'Schließen',
            commission: 'Commission',
            swap: 'Swap',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short'
        },
        assetInfoModal: {
            assetInfo: 'Asset Info',
            tradingConditions: 'Trading Conditions',
            information: 'Session Change',
            bid: 'Bid',
            ask: 'Ask',
            sessionChange: 'Session Change',
            tradeNow: 'Trade Now',
            opens: 'Opens',
            closes: 'Closes',
            at: 'at',
            open247: 'Open 24/7',
            today: 'today',
            tomorrow: 'tomorrow',
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            contractSpecification: 'Contract Specification',
            symbol: 'Symbol',
            name: 'Name',
            market: 'Market',
            baseCurrency: 'Base Currency',
            digits: 'Digits',
            lotSize: 'Lot Size',
            lotStep: 'Lot Step',
            minVolume: 'Min Volume',
            maxVolume: 'Max Volume',
            leverage: 'Leverage',
            commission: 'Commission',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short',
            schedule: 'Schedule',
            weekday: 'Weekday',
            tradingTime: 'Trading Time',
            closed: 'closed',
            sell: 'Sell',
            buy: 'Buy',
            low: 'Low',
            high: 'High',
            oneHourChange: '1 hour change',
            oneDayChange: '1 day change',
            oneWeekChange: '1 week change',
            oneMonthChange: '1 month change',
            threeMonthsChange: '3 months change',
            oneYearChange: '1 year change',
            loading: 'Loading...',
        },
        autoTrader: {
            days: 'days',
            title: 'Auto Trader',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Risk Level: Low',
            riskLevelMedium: 'Risk Level: Medium',
            riskLevelHigh: 'Risk Level: High',
            perDay: 'Trades per day:',
            desc: `Trading CFDs and other leveraged products can lead to losses. Before trading, clients should
                read the
                relevant risk statements on our Risk Disclosure page. Automated trading does not guarantee results. The
                company accepts no responsibility for the loss of funds in automatic trading. Please make sure that you
                fully understand the risks and take measures to manage risks.`,
            accept: 'Accept',
            save: 'Speichern',
            cancel: 'Abbrechen',
        },
        cardBox: {
            name: 'Name',
            cardNumber: 'Kartennummer',
            expiration: 'Ablaufsdatum (mm/jj)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "Handel",
            startTrading: 'Handel starten',
            deposit: 'Einzahlung tätigen',
            dashboard: 'Dashboard',
            personalInfo: 'Persönliche Daten',
            contactInfo: 'Kontaktinformationen',
            withdrawal: 'Auszahlung',
            verification: 'Identitätsprüfung',
            accounts: 'Konten verwalten',
            liveChat: 'Live Chat',
            savings: 'Ersparnisse',
            settings: 'Plattformeinstellungen',
            logOut: 'Ausloggen',
        },
        fullScreenDeposit: {
            methods: 'METHODS',
            confirm: 'Confirm in the application of your bank',
            sendOtp: 'Send otp',
            otpCode: 'Otp code',
            addWalletAddress: 'Wallet for this deposit method is not set. Please contact your agent or support team.',
            addressReceipt: 'Please wait for the address to load',
            makeDeposit: 'MACHEN SIE EINE EINZAHLUNG',
            instant: 'Sofort',
            minutes: 'Minuten',
            hours: 'Stunden',
            days: 'Tage',
            amount: 'Betrag',
            continue: 'Fortsetzen',
            qrCode: 'QR-CODE',
            depositAddress: 'EINZAHLUNGSADRESSE',
            copy: 'Kopieren',
            last: 'ZULETZT',
            deposits: 'EINZAHLUNGEN',
            time: 'Zeit',
            currency: 'Währung',
            status: 'Status',
            info: 'Info',
        },
        fullScreenCcExt: {
            pleaseWait: 'Warten Sie mal. Verbindung zum Zahlungsdienstleister wird hergestellt...',
            cardPayment: 'Kartenzahlung',
            inOrder: 'Um den Betrag zu senden',
            pleaseProvide: 'Bitte geben Sie die Kartendaten an:'
        },
        fullScreenDashboard: {
            completeVerification: 'Please Complete Verification',
            noOrderYet: 'No order yet',
            totalBalance: 'Kontostand',
            totalPnl: 'PnL Insgesamt',
            profitableOrders: 'Erfolgreiche Trades',
            roi: 'ROI',
            activityLog: 'Aktivitätsprotokoll',
            loginFromIp: 'Login from IP',
            tradingResults: 'Handelsergebnisse',
            week: 'Woche',
            month: 'Monat',
            year: 'Jahr',
            successRate: 'Erfolgsquote',
            closedWithProfit: 'Geschlossen mit Gewinn',
            closedWithLoss: 'Geschlossen mit Verlust',
            account: 'Konto',
            balance: 'Saldo',
            leverage: 'Hebelwirkung',
            credit: 'Kredit',
            tradeNow: 'Zum Handel',
            usingCurrentRate: 'zum aktuellen Wechselkurs',
            totalDeposits: 'Total Deposits'
        },
        fullScreenPersonal: {
            personalDetails: 'Persönliche Daten',
            profilePhoto: 'Profilfoto',
            firstName: 'Vorname',
            country: 'Land',
            lastName: 'Nachname',
            address: 'Adresse',
            email: 'E-Mail',
            phone: 'Telefonnummer',
            saveChanges: 'Änderungen speichern',
            state: 'Stadt',
            postcode: 'PLZ',
            created: "Konto erstellt am",
        },
        fullScreenAvatar: {
            dropFile: 'Legen Sie eine Datei zum Hochladen auf dem Kreis oben ab',
            notAllowed: 'Es darf nicht veröffentlicht werden',
            notAllowedLine1: 'Fotos explizit sexueller oder pornografischer Natur',
            notAllowedLine2: 'Bilder, die darauf abzielen, zu ethnischem oder rassistischem Hass oder Aggression aufzustacheln',
            notAllowedLine3: 'Fotos mit Personen unter 18 Jahren',
            notAllowedLine4: 'Fotos mit Third-party Urheberrechte Schutz',
            notAllowedLine5: 'Bilder größer als 5 MB und in einem anderen Format als JPG, GIF oder PNG',
            requirements: 'Auf dem Foto muss Ihr Gesicht gut zu erkennen sein. Alle von Ihnen hochgeladenen Fotos und Videos müssen diese Anforderungen erfüllen, andernfalls können sie entfernt werden.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Please contact your account manager for withdrawal details',
            cryptocurrency: 'Cryptocurrency',
            withdrawal: 'Withdrawal',
            requestNewWithdrawal: 'NEUE AUSZAHLUNG ANFORDERN',
            amount: 'Betrag',
            currentBalance: 'Ihr aktueller Kontostand ist',
            withdrawAll: 'Alles auszahlen',
            requestWithdrawal: 'Auszahlung anfordern',
            last: 'ZULETZT',
            withdrawalRequests: 'AUSZAHLUNGSANTRÄGE',
            time: 'Zeit',
            currency: 'Währung',
            info: 'Info',
            status: 'Status',
            bank: 'Bank',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            card: 'Karte',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Bankverbindung',
            address: 'Wallet Address',
            bankAccount: 'Name des Kontoinhabers',
            bankHolder: 'Anmeldename/VR- Net Key/ Login',
            bankIban: 'IBAN',
            bankSwift: 'Pin/Passwort/TAN',
            recipientAddress: 'Empfängeradresse',
            routingNumber: 'Routing-Nummer',
            bankBeneficiary: 'Name der Bank des Begünstigten',
            bankAddress: 'Bankadresse',
            bankCode: 'Bankleitzahl',
            desc: 'Beschreibung',
            cardNumber: 'Kartennummer',
            cardHolder: 'Name des Karteninhabers',
        },
        fullScreenVerification: {
            dragAndDrop: 'Ziehen Sie das Dokument per Drag & Drop in diese Zelle',
            documentsList: 'LISTE DER HOCHGELADENEN DOKUMENTE',
            document: 'Dokument',
            timeUploaded: 'Zeit hochgeladen',
            timeProcessed: 'Zeit verarbeitet',
            status: 'Status',
            types: ['Ausweis', 'Nachweis des Wohnsitzes', 'Kreditkartenvorderseite', 'Kreditkartenrückseite', 'Ausweisrückseite', 'Selfie'],
            types2: ['Proof of identification (POI)', 'Proof of residential address', 'Credit Card Front', 'Credit Card Back', 'Proof of identification Back', 'Selfie with ID'],
            upload: 'Hochladen',
            isConfirmed: 'bestätigt',
            uploadAnotherDocument: 'Anderes Dokument hochladen',
            isUnderReview: 'wird überprüft',
            questionnaire: 'Fragebogen',
            sendAnswers: 'Antworten senden',
            progressBarOf: 'von',
            progressBarText: 'Ihrer Dokumente sind hochgeladen und geprüft'
        },
        fullScreenAccounts: {
            manageAccounts: 'Überweisen Sie Gelder zwischen Ihren Konten',
            transferFrom: 'Vom Konto',
            transferTo: 'Auf Konto ',
            amount: 'Betrag',
            makeTransfer: 'Überweisung vornehmen',
            yourAccounts: 'Ihre Konten',
            account: 'Konto',
            currency: 'Währung',
            balance: 'Kontostand',
            credit: 'Kredit',
            makeActive: 'Aktivieren',
            active: 'Aktiv'
        },
        fullScreenSupport: {
            sendMessage: 'Nachricht zu senden'
        },
        fullScreenSavings: {
            pct_0: 'Flexibel',
            pct_30: '1 Monat gesperrt',
            pct_90: '1 Quartal gesperrt',
            pct_180: '6 Monate gesperrt',
            pct_365: '1 Jahr gesperrt',
            savings: 'Ersparnisse',
            detailedInformation: 'Genaue Information',
            pleaseSelect: 'Bitte wählen Sie eine Währung und einen Zeitraum',
            openSavings: 'Ersparnisse öffnen',
            currentTime: 'Aktuelle Uhrzeit',
            releaseTime: 'Eröffnungsdatum',
            currencyChosen: 'Gewählte Währung',
            periodChosen: 'Gewählter Zeitraum',
            yourRate: 'Ihr Tarif',
            yourEarnings: 'Dein Verdienst',
            accountFrom: 'Konto, von dem Geld überwiesen werden soll',
            enterAmount: 'Enter Amount',
            in: 'In',
            openAndInvest: 'Sparkonto eröffnen und anlegen',
            investment: 'Investition',
            period: 'Zeitraum',
            daysTotal: 'Tage insgesamt',
            finalEarnings: 'Totales Einkommen',
            created: 'Erstellt',
            daysElapsed: 'Verstrichene Tage',
            days: 'Tage',
            earnings: 'Verdienste',
            fundsReleasedIn: 'Freigegebene Mittel in',
            claim: 'Versicherungsanspruch ',
        },
        fullScreenSettings: {
            updatePassword: 'Passwort AKTUALISIEREN',
            currentPassword: 'Aktuelles Passwort',
            newPassword: 'Neues Passwort',
            changePassword: 'Passwort ändern',
            activationForGoogle: 'Aktivierungscode für Google Authenticator',
            activateAndEnter: 'Aktivieren Sie Authenticator und geben Sie einen generierten Code in das Feld unten ein',
            qrCode: 'QR-Code',
            activateProtection: 'Aktivieren Sie den 2FA-Schutz',
            protectionActive: '2FA-Schutz ist aktiv',
            twoFactorCode: '2FA-Code',
            disableProtection: 'Deaktivieren Sie den 2FA-Schutz',
            language: 'Sprache',
            dashboardCurrency: 'Dashboard-Währung',
            confirmNewPassword: 'Bestätige neues Passwort',
        },
        fullScreenReferrals: {
            yourLink: 'IHR EMPFEHLUNGSLINK',
            referredFriends: 'EMPFOHLENE FREUNDE',
            id: 'ID',
            name: 'Name',
            registered: 'Registriert',
            level: 'Niveua',
            payout: 'Gewinnauszahlung',
            lastPayouts: 'LETZTE EMPFOHLENE Gewinnauszahlung',
            time: 'Zeit',
            amount: 'Betrag',
            currency: 'Währung',
            info: 'Info'
        },
        months: {
            Jan: 'Jan',
            Feb: 'Feb',
            Mar: 'Mar',
            Apr: 'Apr',
            May: 'May',
            Jun: 'Jun',
            Jul: 'Jul',
            Aug: 'Aug',
            Sep: 'Sep',
            Oct: 'Oct',
            Nov: 'Nov',
            Dec: 'Dec'
        },
        orderTypes: ['Kaufen', 'Verkaufen', 'Kaufen Limit', 'Verkaufen Limit', 'Kaufen Stop', 'Verkaufen Stop'],
        statuses: ['Ausstehend', 'Genehmigt', 'Abgelehnt', 'Wird bearbeitet'],
        errors: {
            NOT_FOUND: 'Anforderungsfehler',
            FORBIDDEN: 'Zugriff abgelehnt. Bitte neu einloggen',
            SERVER_ERROR: 'Aktion: fehlgeschlagen. Bitte versuchen Sie es später noch einmal.',
            INVALID_ACCOUNT: 'Sitzung abgelaufen. Bitte neu einloggen',
            INVALID_EMAIL: 'Ungültige E-Mail-Adresse',
            INVALID_PHONE: 'Ungültige Telefonnummer',
            INVALID_COUNTRY: 'Ungültiges Land',
            INVALID_LOGIN: 'Ungültige Login-Details',
            USER_BLOCKED: 'Konto ist gesperrt. Bitte wenden Sie sich an den Support.',
            USER_REGISTERED: 'Benutzer mit eingegebener E-Mail oder Telefonnummer ist bereits registriert',
            INVALID_USER: 'Benutzer wurde nicht gefunden',
            REJECTED: 'Der Betrieb ist aufgrund von Plattformbeschränkungen nicht erlaubt',
            INVALID_OLD_PASSWORD: 'Altes Passwort ist ungültig',
            INVALID_SYMBOL: 'Ungültiges Handelssymbol',
            INVALID_SYMBOL_PRECISION: 'Ungültige Symbolpräzision',
            INVALID_FRAME: 'Ungültiger Diagrammrahmen',
            INVALID_CURRENCY: 'Ungültige Währung',
            INVALID_PRODUCT: 'Ungültiger Typ des Handelskontos',
            INSUFFICIENT_FUNDS: 'Nicht genügend Geld auf dem Konto',
            INVALID_WITHDRAWAL: 'Auszahlung nicht gefunden',
            INVALID_STATUS: 'Ungültiger Status',
            INVALID_FILE: 'Ungültige Dateierweiterung oder Datei ist zu groß',
            INVALID_DOCUMENT: 'Dokument wurde nicht gefunden',
            INVALID_NAME: 'Ungültiger Name',
            INVALID_LOT_SIZE: 'Ungültige Lotgröße',
            INVALID_LOT_STEP: 'Ungültiger Lotschritt',
            INVALID_MIN_VOLUME: 'Ungültiges Mindestvolumen',
            INVALID_MAX_VOLUME: 'Ungültiges maximales Volumen',
            INVALID_GROUP: 'Ungültige Gruppe',
            INVALID_SPREAD: 'Ungültiger Spread',
            REQUEST_TIMEOUT: 'Zeitüberschreitung der Anforderung. Bitte versuchen Sie es später noch einmal',
            EXISTING_OPEN_ORDERS: 'Sie haben eine offene Bestellung, schließen Sie zuerst die Bestellung für diesen Benutzer und versuchen Sie es erneut.',
            INVALID_TYPE: 'Ungültiger Auftragstyp',
            INVALID_VOLUME: 'Ungültiges Auftragsvolumen',
            INVALID_SL: 'Ungültiger Stop-Verlust-Wert',
            INVALID_TP: 'Ungültiger Gewinnmitnahme-Wert',
            INVALID_PRICE: 'Ungültiger Auftragspreis',
            INVALID_EXPIRATION: 'Ungültiger Auftragsablauf',
            NOT_ENOUGH_MARGIN: 'Nicht genug Margin',
            INVALID_ORDER: 'Auftrag nicht gefunden',
            MARKET_CLOSED: 'Handel ist nicht verfügbar. Bitte versuche es erneut',
            INVALID_BALANCE_TYPE: 'Ungültiger Typ der Kontostandoperation',
            INVALID_HASH: 'Ungültiger Hash',
            HASH_EXPIRED: 'Ihr Link zum Zurücksetzen des Passworts ist abgelaufen. Bitte fordern Sie eine neue an',
            INVALID_CODE: 'Ungültiger 2FA-Code',
            CHAT_DISABLED: 'Zugriff auf den Support-Chat wurde geschlossen',
            WITHDRAWAL_NOT_ALLOWED: 'Zugriff auf die Auszahlungen wurde gesperrt',
            TRADING_DISABLED: 'Trading is disabled for current account',
            PENDING_DEPOSITS_LIMIT: 'Deposits limit exceeded',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Only one pending withdrawal allowed',
            LOCAL_DEPOSIT_ONLY_REAL: 'Deposit is available only for real account',
            LOCAL_DEPOSIT_POSITIVE: 'Amount of deposit must be more than 0',
            LOCAL_INVALID_CARD_NUMBER: 'Invalid card number',
            LOCAL_INVALID_CARD_EXPIRATION: 'Invalid card expiration',
            LOCAL_INVALID_CVC: 'Invalid CVV/CVC code',
            LOCAL_PASSWORDS_NOT_MATCH: 'Passwords do not match, please re-enter',
            LOCAL_NO_TRADING_ACCOUNTS: 'You do not have trading accounts available. Please contact the support',
            LOCAL_MIN_DEPOSIT: 'Minimum deposit is $',
            LOCAL_MIN_WITHDRAWAL: 'Minimum withdrawal amount is $',
            LOCAL_INVALID_FILE_TYPE: 'File format not supported. Please attach only JPG, PNG or PDF',
            LOCAL_MIN_STAKING: 'Minimum deposit for this currency is ',
            FLOOD_REJECTED: 'You are sending messages too often. Please try again later'
        },
        countries: {
            AF: 'Afghanistan',
            AX: 'Aland-Inseln',
            AL: 'Albanien',
            DZ: 'Algerien',
            AS: 'Amerikanisch-Samoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarktis',
            AG: 'Antigua und Barbuda',
            AR: 'Argentinien',
            AM: 'Armenien',
            AW: 'Aruba',
            AU: 'Australien',
            AT: 'Österreich',
            AZ: 'Aserbaidschan',
            BS: 'Bahamas',
            BH: 'Bahrain',
            BD: 'Bangladesch',
            BB: 'Barbados',
            BY: 'Weißrussland',
            BE: 'Belgien',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Bhutan',
            BO: 'Bolivien',
            BA: 'Bosnien und Herzegowina',
            BW: 'Botswana',
            BV: 'Bouvetinsel',
            BR: 'Brasilien',
            IO: 'Britisches Territorium im Indischen Ozean',
            BN: 'Brunei Darussalam',
            BG: 'Bulgarien',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Kambodscha',
            CM: 'Kamerun',
            CA: 'Kanada',
            CV: 'Kap Verde',
            KY: 'Kaimaninseln',
            CF: 'Zentralafrikanische Republik',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'China',
            CX: 'Weihnachtsinsel',
            CC: 'Cocos (Keeling) Inseln',
            CO: 'Kolumbien',
            KM: 'Komoren',
            CG: 'Kongo',
            CD: 'Kongo, Demokratische Republik',
            CK: 'Cookinseln',
            CR: 'Costa Rica',
            CI: 'Cote D\'Ivoire',
            HR: 'Kroatien',
            CU: 'Kuba',
            CY: 'Zypern',
            CZ: 'Tschechische Republik',
            DK: 'Dänemark',
            DJ: 'Dschibuti',
            DM: 'Dominica',
            DO: 'Dominikanische Republik',
            EC: 'Ecuador',
            EG: 'Ägypten',
            SV: 'El Salvador',
            GQ: 'Äquatorialguinea',
            ER: 'Eritrea',
            EE: 'Estland',
            ET: 'Äthiopien',
            FK: 'Falklandinseln (Malwinen)',
            FO: 'Färöer Inseln',
            FJ: 'Fidschi',
            FI: 'Finnland',
            FR: 'Frankreich',
            GF: 'Französisch-Guayana',
            PF: 'Französisch-Polynesien',
            TF: 'Französische Süd-Territorien',
            GA: 'Gabun',
            GM: 'Gambia',
            GE: 'Georgien',
            DE: 'Deutschland',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Griechenland',
            GL: 'Grönland',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Heard-Insel & Mcdonald-Inseln',
            VA: 'Heiliger Stuhl (Staat Vatikanstadt)',
            HN: 'Honduras',
            HK: 'Hongkong',
            HU: 'Ungarn',
            IS: 'Island',
            IN: 'Indien',
            ID: 'Indonesien',
            IR: 'Iran',
            IQ: 'Irak',
            IE: 'Irland',
            IM: 'Isle Of Man',
            IL: 'Israel',
            IT: 'Italien',
            JM: 'Jamaika',
            JP: 'Japan',
            JE: 'Jersey',
            JO: 'Jordanien',
            KZ: 'Kasachstan',
            KE: 'Kenia',
            KI: 'Kiribati',
            KR: 'Korea',
            KW: 'Kuwait',
            KG: 'Kirgisistan',
            LA: 'Demokratische Volksrepublik Laos',
            LV: 'Lettland',
            LB: 'Libanon',
            LS: 'Lesotho',
            LR: 'Liberia',
            LY: 'Libysch-Arabische Dschamahirija',
            LI: 'Liechtenstein',
            LT: 'Litauen',
            LU: 'Luxemburg',
            MO: 'Macao',
            MK: 'Mazedonien',
            MG: 'Madagaskar',
            MW: 'Malawi',
            MY: 'Malaysia',
            MV: 'Malediven',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marshallinseln',
            MQ: 'Martinique',
            MR: 'Mauretanien',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Mexiko',
            FM: 'Mikronesien',
            MD: 'Moldawien',
            MC: 'Monaco',
            MN: 'Mongolei',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Marokko',
            MZ: 'Mosambik',
            MM: 'Myanmar',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Niederlande',
            AN: 'Niederländische Antillen',
            NC: 'Neukaledonien',
            NZ: 'Neuseeland',
            NI: 'Nicaragua',
            NE: 'Niger',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Norfolkinsel',
            MP: 'Nördliche Marianen',
            NO: 'Norwegen',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Palästinensische Gebiete, Besetzt',
            PA: 'Panama',
            PG: 'Papua-Neuguinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Philippinen',
            PN: 'Pitcairn',
            PL: 'Polen',
            PT: 'Portugal',
            PR: 'Puerto Rico',
            QA: 'Katar',
            RE: 'Wiedersehen',
            RO: 'Rumänien',
            RU: 'Russland',
            RW: 'Ruanda',
            BL: 'St. Barthelemy',
            SH: 'Sankt Helena',
            KN: 'St. Kitts und Nevis',
            LC: 'St. Lucia',
            MF: 'Sankt Martin',
            PM: 'St. Pierre und Miquelon',
            VC: 'St. Vincent und Grenadinen',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tome und Principe',
            SA: 'Saudi-Arabien',
            SN: 'Senegal',
            RS: 'Serbien',
            SC: 'Seychellen',
            SL: 'Sierra Leone',
            SG: 'Singapur',
            SK: 'Slowakei',
            SI: 'Slowenien',
            SB: 'Salomoninseln',
            SO: 'Somalia',
            ZA: 'Südafrika',
            GS: 'Südgeorgien und Sandwichinseln',
            ES: 'Spanien',
            LK: 'Sri Lanka',
            SD: 'Sudan',
            SR: 'Surinam',
            SJ: 'Svalbard und Jan Mayen',
            SZ: 'Swasiland',
            SE: 'Schweden',
            CH: 'Schweiz',
            SY: 'Arabische Republik Syrien',
            TW: 'Taiwan',
            TJ: 'Tadschikistan',
            TZ: 'Tansania',
            TH: 'Thailand',
            TL: 'Timor-Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad und Tobago',
            TN: 'Tunesien',
            TR: 'Türkei',
            TM: 'Turkmenistan',
            TC: 'Turks- und Caicosinseln',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukraine',
            AE: 'Vereinigte Arabische Emirate',
            GB: 'Vereinigtes Königreich',
            US: 'Vereinigte Staaten',
            UM: 'Überseeische Inseln der Vereinigten Staaten',
            UY: 'Uruguay',
            UZ: 'Usbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Vietnam',
            VG: 'Jungferninseln, Britisch',
            VI: 'Jungferninseln, U.S.A.',
            WF: 'Wallis und Futuna',
            EH: 'Westsahara',
            YE: 'Jemen',
            ZM: 'Sambia',
            ZW: 'Simbabwe'
        },
    },
    fr: {
        _name: 'Français',
        timePickerModal: {
            title: 'Close In Time'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Take Profit & Stop Loss',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            updateOrder: 'Ordre de mise à jour',
        },
        balanceModal: {
            balance: 'Solde',
            credit: 'Crédit',
            equity: 'Equity',
            practice: 'PRACTICE',
            real: 'REAL',
            account: 'ACCOUNT',
            invested: 'Investi',
            profit: 'Profit',
            loss: 'Perte',
            margin: 'Marge',
            marginLevel: 'Marge Niveau',
            marginFree: 'Marge libre'
        },
        header: {
            cfd: 'CFD',
            platform: 'Plateforme',
            deposit: 'Dépôt'
        },
        sideMenu: {
            marketWatch: 'Surveillance du marché',
            activeOrders: 'Commandes actives',
            tradingHistory: 'Historique des transactions',
            economicCalendar: 'Calendrier économique',
            marketNews: 'Nouvelles du marché'
        },
        closePositionModal: {
            closeOrder: 'Position fermée',
            areYouSure: 'Etes-vous sûr de fermer la position',
            buy: 'Acheter',
            sell: 'Vendez',
            yes: 'Oui',
            no: 'Non',
        },
        pciDssModal: {
            desc: `La conformité au standard de sécurité des données de l'industrie des cartes de paiement (PCI DSS) est requise de tous les entités qui stockent, traitent ou transmettent les données des porteurs de cartes Visa/Master Card/Stripe, y compris les institutions financières, les marchands et les prestataires de services. 
            Il s'agit de la norme de sécurité mondiale pour toutes les entités qui stockent, traitent ou transmettent des données de porteurs de cartes et/ou des données d'authentification sensibles. Le PCI DSS établit un niveau de protection de base pour les consommateurs et contribue à réduire la fraude et les violations de données dans l'ensemble de l'écosystème de paiement.`
        },
        chart: {
            addNewChart: 'Ajouter un nouveau graphique',
        },
        symbolsModal: {
            watchlist: 'Liste de surveillance',
            asset: 'Active',
            price: 'Prix',
            changePct: 'Changement 24h'
        },
        pendingModal: {
            pending: 'En attente',
            assetPrice: 'Prix de l\'actif',
            current: 'Actuel',
            revert: 'Retour au prix du marché',
            automatically: 'La position sera ouverte automatiquement lorsque le prix atteindra ce niveau'
        },
        orderMenu: {
            default: 'default',
            volume: 'Volume',
            lots: 'lots',
            units: 'unités',
            currency: 'devise',
            contractSize: 'Taille du contrat',
            position: 'Position',
            margin: 'Marge',
            freeMargin: 'Marge libre',
            takeProfitStopLoss: 'Take Profit & Stop Loss',
            pending: 'En attente',
            market: 'Marché',
            leverage: 'Effet de levier',
            spread: 'Écartement',
            notSet: 'Non défini',
            at: 'à l\'adresse',
            buy: 'acheter',
            sell: 'vendre',
        },
        footer: {
            supportBanner: 'TOUS LES JOURS, 24 HEURES SUR 24',
            currentTime: 'HEURE ACTUELLE',
            liveChat: 'Support client'
        },
        statusBar: {
            balance: 'Balance',
            credit: 'Crédit',
            equity: 'Equity',
            margin: 'Marge',
            marginLevel: 'Niveau de marge',
            freeMargin: 'Marge libre',
            pnl: 'PnL',
            profitTotal: 'Lifetime PnL'
        },
        accountModal: {
            dateRegistered: 'Date d\'enregistrement',
            userId: 'ID utilisateur',
            dashboard: 'Tableau de bord',
            personalData: 'Données personnelles',
            deposit: 'Dépôt',
            withdrawFunds: 'Retirer des fonds',
            savings: 'Épargne',
            settings: 'Paramètres',
            logout: 'Déconnexion'
        },
        depositModal: {
            BT_INVOICE: 'Enter amount and generate invoice',
            BT_TITLE: 'Our banking details',
            PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
            account: 'Account',
            bank_name: 'Bank Name',
            beneficiary: 'Beneficiary',
            beneficiary_address: 'Beneficiary Address',
            swift: 'Swift',
            reference: 'Reference',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Amount',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Description',
            TOKEN_PAYMENT_DETAILS: 'Payment Details',
            makeDeposit: 'Effectuer un dépôt',
            practiceAccount: 'Effectuer un dépôt',
            realAccount: 'Compte réel',
            noWithdrawals: 'Pas de retraits',
            easyWithdrawals: 'Retraits faciles',
            allAssets: 'Tous les actifs disponibles',
            fullFledged: 'Une plateforme complète',
            fillUpTo: 'Remplir jusqu\'à',
            freeReplenishment: 'Réapprovisionnement gratuit',
            topUp: 'Rechargez votre compte',
            minimumAmount: 'Montant minimum',
            canSwitch: 'Vous pouvez passer d\'un compte à l\'autre à tout moment'
        },
        gridsModal: {
            chartGrids: 'GRILLES DE CARTES',
            chart1: '1 graphique',
            chart2: '2 graphiques',
            chart3: '3 graphiques',
            chart4: '4 graphiques',
        },
        noConnectionModal: {
            connectionLost: 'La connexion avec le serveur est perdue',
            retryNow: 'Réessayer maintenant',
        },
        loginModal: {
            loginToTradeRoom: 'Se connecter à Traderoom',
            email: 'Adresse électronique',
            enterEmail: 'Entrez votre courriel',
            twoFactor: 'Code 2FA (si activé)',
            twoFactorAuth: 'Authentification à deux facteurs',
            password: 'Mot de passe',
            yourPassword: 'Votre mot de passe',
            signIn: 'S\'inscrire',
            dontHaveAccount: 'Vous n\'avez pas de compte?',
            restore: 'Restaurer',
            fullName: 'Nom complet',
            havePromo: 'J\'ai un code promo',
            promo: 'Code promo',
            login: 'Connexion',
            haveAccount: 'Vous avez déjà un compte?',
            hide: 'Cacher',
            goTo: 'Retourner au début'
        },
        newPasswordModal: {
            setNewPassword: 'Définir un nouveau mot de passe',
            newPassword: 'Nouveau mot de passe',
            confirmNewPassword: 'Confirmer le nouveau mot de passe',
            setButton: 'Définir'
        },
        registerModal: {
            repeatPassword: 'Repeat Password',
            createNewAccount: 'Créer un nouveau compte',
            email: 'Adresse électronique',
            firstName: 'Prénom',
            lastName: 'Nom de famille',
            country: 'Pays',
            phone: 'Téléphone',
            password: 'Mot de passe',
            createAccount: 'Créer un compte',
            currency: 'Monnaie',
            privacyAndPolicy: "Confidentialité et Politique",
            customerAgreement: 'Accord client',

        },
        forgotPasswordModal: {
            forgotPassword: 'Mot de passe oublié?',
            submitEmail: 'Veuillez soumettre un courriel utilisé pour l\'inscription, vérifiez votre boîte de réception et suivez les instructions fournies.',
            submit: 'Soumettre'
        },
        notifications: {
            error: 'Erreur',
            success: 'Succès',
            deposit: 'Dépôt',
            withdrawal: 'Retrait',
            depositApproved: 'Le dépôt a été approuvé',
            depositDeclined: 'Le dépôt a été refusé',
            withdrawalApproved: 'Le retrait a été approuvé',
            withdrawalDeclined: 'Le retrait a été refusé'
        },
        markets: {
            forex: 'Forex',
            stocks: 'Stocks',
            commodities: 'Produits de base',
            indices: 'Indices',
            crypto: 'Crypto',
            metals: 'Métaux',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'BUY',
            profitCalculator: 'Profit Calculator',
            sell: 'SELL',
            volume: 'Volume',
            entryPrice: 'Entry Price',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            maxPosition: 'Max Position',
            calculate: 'Calculate',
            calculationsFor: 'Calculations for',
            leverage: 'Leverage',
            requiredMargin: 'Required Margin',
            profitFromTP: 'Profit from TP',
            lossFromSL: 'Loss from SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Dépôt réussi',
            text: 'Votre dépôt a été traité avec succès !',
        },
        depositFailModal: {
            title: 'Dépôt échoué',
            text: 'Votre dépôt n\'a pas été traité.',
        },
        widgetMarketWatch: {
            marketWatch: 'Surveillance du marché',
            search: 'Recherche...',
        },
        widgetOrders: {
            commission: 'Commission',
            volume: 'Volume',
            margin: 'Marge',
            active: 'Actif',
            pending: 'En attente',
            activeOrders: 'Commandes actives',
            portfolio: 'Portefeuille',
            allPositions: 'Tous les postes',
            noPositionsLine1: 'Vous n\'avez pas d\'ouverture',
            noPositionsLine2: 'postes encore',
            show: 'Afficher',
            more: 'plus',
            less: 'moins',
            purchaseTime: 'Temps d\'achat',
            closePosition: 'Position fermée',
            priceOpen: 'Prix d\'achat',
            priceSl: 'Prix du Stop Loss',
            priceTp: 'Prix du Take Profit',
            type: 'Envoyer les réponses',
            pnl: 'Bénéfice/perte',
            cancelOrder: 'Annuler la commande',
            orderType: 'Type de commande',
            cancelled: 'Annulé',
            tradingHistory: 'Historique des transactions',
            noHistoryLine1: 'Vous n\'avez pas de',
            noHistoryLine2: 'des affaires conclues pour le moment',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Calendrier économique'
        },
        widgetMarketNews: {
            marketNews: 'Nouvelles du marché'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'Commandes actives',
            ordersHistory: 'Historique des commandes',
            id: 'ID',
            symbol: 'Symbole',
            type: 'Type',
            volume: 'Volume',
            openPrice: 'Prix ouvert',
            openTime: 'Temps ouvert',
            closePrice: 'Fermer le prix',
            closeTime: 'Heure de fermeture',
            sl: 'SL',
            tp: 'TP',
            price: 'Prix',
            pnl: 'PnL',
            actions: 'Actions',
            edit: 'Modifier',
            close: 'Fermer',
            commission: 'Commission',
            swap: 'Swap',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short'
        },
        assetInfoModal: {
            assetInfo: 'Asset Info',
            tradingConditions: 'Trading Conditions',
            information: 'Session Change',
            bid: 'Bid',
            ask: 'Ask',
            sessionChange: 'Session Change',
            tradeNow: 'Trade Now',
            opens: 'Opens',
            closes: 'Closes',
            at: 'at',
            open247: 'Open 24/7',
            today: 'today',
            tomorrow: 'tomorrow',
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            contractSpecification: 'Contract Specification',
            symbol: 'Symbol',
            name: 'Name',
            market: 'Market',
            baseCurrency: 'Base Currency',
            digits: 'Digits',
            lotSize: 'Lot Size',
            lotStep: 'Lot Step',
            minVolume: 'Min Volume',
            maxVolume: 'Max Volume',
            leverage: 'Leverage',
            commission: 'Commission',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short',
            schedule: 'Schedule',
            weekday: 'Weekday',
            tradingTime: 'Trading Time',
            closed: 'closed',
            sell: 'Sell',
            buy: 'Buy',
            low: 'Low',
            high: 'High',
            oneHourChange: '1 hour change',
            oneDayChange: '1 day change',
            oneWeekChange: '1 week change',
            oneMonthChange: '1 month change',
            threeMonthsChange: '3 months change',
            oneYearChange: '1 year change',
            loading: 'Loading...',
        },
        autoTrader: {
            days: 'days',
            title: 'Auto Trader',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Niveau de risque: bas',
            riskLevelMedium: 'Niveau de risque: moyen',
            riskLevelHigh: 'Niveau de risque: élevé',
            perDay: 'Négoces par jour:',
            desc: `Trading CFDs and other leveraged products can lead to losses. Before trading, clients should
                read the
                relevant risk statements on our Risk Disclosure page. Automated trading does not guarantee results. The
                company accepts no responsibility for the loss of funds in automatic trading. Please make sure that you
                fully understand the risks and take measures to manage risks.`,
            accept: 'Accepter',
            save: 'Sauvegarder',
            cancel: 'Annuler',
        },
        cardBox: {
            name: 'Nom Prénom',
            cardNumber: 'Numéro de la carte',
            expiration: 'Expiration (mm/aa)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Salut',
            trade: "Les marchés",
            startTrading: 'Commencer à trader',
            deposit: 'Effectuer un dépôt',
            dashboard: 'Tableau de bord',
            personalInfo: 'Mise à jour des données personnelles',
            contactInfo: 'Coordonnées',
            withdrawal: 'Demander un retrait',
            verification: 'Vérification de l\'identité',
            accounts: 'Gérer les comptes',
            liveChat: 'Support client',
            savings: 'Épargne',
            settings: 'Paramètres de la plate-forme',
            logOut: 'Déconnexion',
        },
        fullScreenDeposit: {
            methods: 'METHODS',
            confirm: 'Confirm in the application of your bank',
            sendOtp: 'Send otp',
            otpCode: 'Otp code',
            addWalletAddress: 'Wallet for this deposit method is not set. Please contact your agent or support team.',
            addressReceipt: 'Please wait for the address to load',
            makeDeposit: 'FAIRE UN DÉPÔT',
            instant: 'Instantané',
            minutes: 'minutes',
            hours: 'heures',
            days: 'jours',
            amount: 'Montant',
            continue: 'Continuer',
            qrCode: 'QR CODE',
            depositAddress: 'ADRESSE DE DÉPÔT',
            copy: 'Copie',
            last: 'DERNIER',
            deposits: 'DÉPÔTS',
            time: 'Temps',
            currency: 'Monnaie',
            status: 'Statut',
            info: 'Info',
        },
        fullScreenCcExt: {
            pleaseWait: 'Veuillez patienter. Connexion au fournisseur de services de paiement...',
            cardPayment: 'Paiement par carte',
            inOrder: 'Afin d\'envoyer le montant',
            pleaseProvide: 'veuillez fournir les détails de la carte :'
        },
        fullScreenDashboard: {
            completeVerification: 'Please Complete Verification',
            noOrderYet: 'No order yet',
            totalBalance: 'Solde Total',
            totalPnl: 'PnL Total',
            profitableOrders: 'Commandes Rentables',
            roi: 'ROI',
            activityLog: "Journal d'activités",
            loginFromIp: 'Login from IP',
            tradingResults: 'Résultats de Trading',
            week: 'Semaine',
            month: 'Mois',
            year: 'Année',
            successRate: 'Taux de Réussite',
            closedWithProfit: 'Clôturé avec un Bénéfice',
            closedWithLoss: 'Clôturé avec une Perte',
            account: 'Compte',
            balance: 'Solde',
            leverage: 'Levier',
            credit: 'Crédit',
            tradeNow: 'Trading Maintenant',
            usingCurrentRate: 'using current exchange rate',
            totalDeposits: 'Total Deposits'
        },
        fullScreenPersonal: {
            personalDetails: 'Détails personnels',
            profilePhoto: 'Photo de profil',
            firstName: 'Prénom',
            country: 'Pays',
            lastName: 'Nom de famille',
            address: 'Adresse',
            email: 'Courriel',
            phone: 'Numéro de téléphone',
            saveChanges: 'Sauvegarder les changements',
            state: 'Ville',
            postcode: 'Code postal',
            created: "Compte créé le",
        },
        fullScreenAvatar: {
            dropFile: 'Déposez un fichier sur le cercle ci-dessus pour le télécharger',
            notAllowed: 'Il n\'est pas permis de publier',
            notAllowedLine1: 'Photos à caractère explicitement sexuel ou pornographique',
            notAllowedLine2: 'Images visant à inciter à la haine ou à l\'agression ethnique ou raciale',
            notAllowedLine3: 'Photos impliquant des personnes de moins de 18 ans',
            notAllowedLine4: 'Photos protégées par le droit d\'auteur de tiers',
            notAllowedLine5: 'Images de plus de 5 MB et dans un format autre que JPG, GIF ou PNG',
            requirements: 'Votre visage doit être clairement visible sur la photo. Toutes les photos et vidéos que vous mettez en ligne doivent respecter ces exigences, faute de quoi elles peuvent être supprimées.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Please contact your account manager for withdrawal details',
            cryptocurrency: 'Cryptocurrency',
            withdrawal: 'Withdrawal',
            requestNewWithdrawal: 'DEMANDER UN NOUVEAU RETRAIT',
            amount: 'Montant',
            currentBalance: 'Le solde de votre compte courant est de',
            withdrawAll: 'Retirer tout',
            requestWithdrawal: 'Demande de retrait',
            last: 'DERNIER',
            withdrawalRequests: 'DEMANDES DE RETRAIT',
            time: 'Temps',
            currency: 'Monnaie',
            info: 'Info',
            status: 'Statut',
            bank: 'Banque',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            card: 'Carte',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Détails du retrait',
            address: 'Adresse du portefeuille',
            bankAccount: 'Numéro de compte',
            bankHolder: 'Nom du titulaire du compte',
            bankIban: 'IBAN',
            bankSwift: 'Code SWIFT de la banque',
            recipientAddress: 'Adresse du destinataire',
            routingNumber: 'Numéro de routage',
            bankBeneficiary: 'Nom de la banque du bénéficiaire',
            bankAddress: 'Adresse de la banque',
            bankCode: 'Code bancaire',
            desc: 'Description',
            cardNumber: 'Numéro de la carte',
            cardHolder: 'Nom du titulaire de la carte',
        },
        fullScreenVerification: {
            dragAndDrop: 'glisser et déposer le document dans cette zone',
            documentsList: 'LISTE DES DOCUMENTS TÉLÉCHARGÉS',
            document: 'Document',
            timeUploaded: 'Temps téléchargé',
            timeProcessed: 'Temps traité',
            status: 'Statut',
            types: ['Preuve d\'identité', 'Preuve de résidence', 'Recto de la carte de crédit', 'Retour de la carte de crédit', 'Preuve d\'identité en arrière', 'Selfie'],
            types2: ['Proof of identification (POI)', 'Proof of residential address', 'Credit Card Front', 'Credit Card Back', 'Proof of identification Back', 'Selfie with ID'],
            upload: 'Télécharger',
            isConfirmed: 'est confirmé',
            uploadAnotherDocument: 'Télécharger un autre document',
            isUnderReview: 'est en cours de révision',
            questionnaire: 'Questionnaire',
            sendAnswers: 'Envoyer les réponses',
            progressBarOf: 'of',
            progressBarText: 'of your documents have been uploaded and confirmed'
        },
        fullScreenAccounts: {
            manageAccounts: 'Transférer des fonds entre vos comptes',
            transferFrom: 'Du compte',
            transferTo: 'Pour le compte',
            amount: 'Montant',
            makeTransfer: 'Effectuer un transfert',
            yourAccounts: 'Vos comptes',
            account: 'Compte',
            currency: 'Monnaie',
            balance: 'Balance',
            credit: 'Crédit',
            makeActive: 'Rendre actif',
            active: 'Actif'
        },
        fullScreenSupport: {
            sendMessage: 'Envoyer un message'
        },
        fullScreenSavings: {
            pct_0: 'Flexible',
            pct_30: 'Verrouillé 1 mois',
            pct_90: 'Verrouillé 1 trimestre',
            pct_180: 'Verrouillé 6 mois',
            pct_365: 'Verrouillé 1 an',
            savings: 'Épargne',
            detailedInformation: 'Informations détaillées',
            pleaseSelect: 'Veuillez sélectionner une devise et une période',
            openSavings: 'Épargne ouverte',
            currentTime: 'Heure actuelle',
            releaseTime: 'Heure de sortie',
            currencyChosen: 'Devise choisie',
            periodChosen: 'Période choisie',
            yourRate: 'Votre taux',
            yourEarnings: 'Vos revenus',
            accountFrom: 'Compte pour transférer des fonds de',
            enterAmount: 'Entrez le montant',
            in: 'Sur',
            openAndInvest: 'Ouvrir un compte d\'épargne et investir',
            investment: 'Investissement',
            period: 'Période',
            daysTotal: 'Nombre total de jours',
            finalEarnings: 'Revenu final',
            created: 'Créé',
            daysElapsed: 'Jours écoulés',
            days: 'jours',
            earnings: 'Revenus',
            fundsReleasedIn: 'Fonds libérés en',
            claim: 'Demande d\'indemnisation',
        },
        fullScreenSettings: {
            updatePassword: 'METTRE À JOUR LE MOT DE PASSE',
            currentPassword: 'Mot de passe actuel',
            newPassword: 'Nouveau mot de passe',
            changePassword: 'Changer le mot de passe',
            activationForGoogle: 'Code d\'activation pour Google Authenticator',
            activateAndEnter: 'Activez l\'Authenticator et entrez un code généré dans le champ ci-dessous.',
            qrCode: 'QR Code',
            activateProtection: 'Activer la protection 2FA',
            protectionActive: 'La protection 2FA est active',
            twoFactorCode: 'Code 2FA',
            disableProtection: 'Désactiver la protection 2FA',
            language: 'Langue',
            dashboardCurrency: 'Tableau de bord des devises',
            confirmNewPassword: 'Confirmer le nouveau mot de passe',
        },
        fullScreenReferrals: {
            yourLink: 'VOTRE LIEN DE RÉFÉRENCE',
            referredFriends: 'AMIS RÉFÉRENCÉS',
            id: 'ID',
            name: 'Nom Prénom',
            registered: 'Enregistré',
            level: 'Niveau',
            payout: 'Payout',
            lastPayouts: 'LES DERNIERS PAIEMENTS DE PARRAINAGE',
            time: 'Temps',
            amount: 'Montant',
            currency: 'Monnaie',
            info: 'Info'
        },
        months: {
            Jan: 'Jan',
            Feb: 'Fev',
            Mar: 'Mar',
            Apr: 'Abr',
            May: 'Mai',
            Jun: 'Juin',
            Jul: 'Jui',
            Aug: 'Aoû',
            Sep: 'Sep',
            Oct: 'Oct',
            Nov: 'Nov',
            Dec: 'Dec'
        },
        orderTypes: ['Acheter', 'Vendez', 'Limite d\'achat', 'Limite de vente', 'Stop à l\'achat', 'Stop à la vente'],
        statuses: ['En attente', 'Approuvé', 'Refusé', 'Traitement'],
        errors: {
            NOT_FOUND: 'Erreur de demande',
            FORBIDDEN: 'Accès refusé. Veuillez vous reconnecter',
            SERVER_ERROR: 'L\'action a échoué. Veuillez réessayer plus tard',
            INVALID_ACCOUNT: 'La session a expiré. Veuillez vous reconnecter',
            INVALID_EMAIL: 'Adresse électronique non valide',
            INVALID_PHONE: 'Numéro de téléphone non valide',
            INVALID_COUNTRY: 'Pays non valide',
            INVALID_LOGIN: 'Identifiants de connexion non valides',
            USER_BLOCKED: 'Le compte est bloqué. Veuillez contacter le support',
            USER_REGISTERED: 'L\'utilisateur dont l\'adresse électronique ou le numéro de téléphone a été saisi est déjà enregistré.',
            INVALID_USER: 'L\'utilisateur n\'est pas trouvé',
            REJECTED: 'L\'opération n\'est pas autorisée en raison des restrictions de la plate-forme',
            INVALID_OLD_PASSWORD: 'L\'ancien mot de passe n\'est pas valide',
            INVALID_SYMBOL: 'Symbole commercial non valide',
            INVALID_SYMBOL_PRECISION: 'Précision du symbole non valide',
            INVALID_FRAME: 'Cadre graphique non valide',
            INVALID_CURRENCY: 'Devise non valide',
            INVALID_PRODUCT: 'Type de trading de compte invalide',
            INSUFFICIENT_FUNDS: 'Fonds insuffisants',
            INVALID_WITHDRAWAL: 'Le retrait n\'est pas trouvé',
            INVALID_STATUS: 'Statut non valide',
            INVALID_FILE: 'Extension de fichier non valide ou fichier trop volumineux',
            INVALID_DOCUMENT: 'Le document n\'est pas trouvé',
            INVALID_NAME: 'Nom non valide',
            INVALID_LOT_SIZE: 'Taille de lot non valide',
            INVALID_LOT_STEP: 'Pas de lot invalide',
            INVALID_MIN_VOLUME: 'Volume minimal non valide',
            INVALID_MAX_VOLUME: 'Volume maximal invalide',
            INVALID_GROUP: 'Groupe non valide',
            INVALID_SPREAD: 'Diffusion non valide',
            REQUEST_TIMEOUT: 'La demande a expiré. Veuillez réessayer plus tard',
            EXISTING_OPEN_ORDERS: 'Vous avez une commande en cours, fermez d\'abord la commande pour cet utilisateur et réessayez.',
            INVALID_TYPE: 'Type de commande non valide',
            INVALID_VOLUME: 'Volume d\'ordre invalide',
            INVALID_SL: 'Valeur invalide de l\'excédent de pertes',
            INVALID_TP: 'Valeur de prise de bénéfices invalide',
            INVALID_PRICE: 'Prix de la commande invalide',
            INVALID_EXPIRATION: 'Expiration de la commande invalide',
            NOT_ENOUGH_MARGIN: 'Pas assez de marge',
            INVALID_ORDER: 'L\'ordre n\'est pas trouvé',
            MARKET_CLOSED: 'Le commerce n\'est pas disponible. Veuillez réessayer',
            INVALID_BALANCE_TYPE: 'Type d\'opération de balance non valide',
            INVALID_HASH: 'Hachage non valide',
            HASH_EXPIRED: 'Le lien de réinitialisation de votre mot de passe a expiré. Veuillez en demander un nouveau',
            INVALID_CODE: 'Code 2FA invalide',
            CHAT_DISABLED: 'L\'accès au chat d\'assistance a été fermé',
            WITHDRAWAL_NOT_ALLOWED: 'L\'accès aux retraits a été fermé',
            TRADING_DISABLED: 'Trading is disabled for current account',
            PENDING_DEPOSITS_LIMIT: 'Deposits limit exceeded',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Only one pending withdrawal allowed',
            LOCAL_DEPOSIT_ONLY_REAL: 'Deposit is available only for real account',
            LOCAL_DEPOSIT_POSITIVE: 'Amount of deposit must be more than 0',
            LOCAL_INVALID_CARD_NUMBER: 'Invalid card number',
            LOCAL_INVALID_CARD_EXPIRATION: 'Invalid card expiration',
            LOCAL_INVALID_CVC: 'Invalid CVV/CVC code',
            LOCAL_PASSWORDS_NOT_MATCH: 'Passwords do not match, please re-enter',
            LOCAL_NO_TRADING_ACCOUNTS: 'You do not have trading accounts available. Please contact the support',
            LOCAL_MIN_DEPOSIT: 'Minimum deposit is $',
            LOCAL_MIN_WITHDRAWAL: 'Minimum withdrawal amount is $',
            LOCAL_INVALID_FILE_TYPE: 'File format not supported. Please attach only JPG, PNG or PDF',
            FLOOD_REJECTED: 'You are sending messages too often. Please try again later',
            LOCAL_MIN_STAKING: 'Minimum deposit for this currency is '
        },
        countries: {
            AF: 'Afghanistan',
            AX: 'Aland Islands',
            AL: 'Albania',
            DZ: 'Algeria',
            AS: 'American Samoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarctica',
            AG: 'Antigua And Barbuda',
            AR: 'Argentina',
            AM: 'Armenia',
            AW: 'Aruba',
            AU: 'Australia',
            AT: 'Austria',
            AZ: 'Azerbaijan',
            BS: 'Bahamas',
            BH: 'Bahrain',
            BD: 'Bangladesh',
            BB: 'Barbados',
            BY: 'Belarus',
            BE: 'Belgium',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Bhutan',
            BO: 'Bolivia',
            BA: 'Bosnia And Herzegovina',
            BW: 'Botswana',
            BV: 'Bouvet Island',
            BR: 'Brazil',
            IO: 'British Indian Ocean Territory',
            BN: 'Brunei Darussalam',
            BG: 'Bulgaria',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Cambodia',
            CM: 'Cameroon',
            CA: 'Canada',
            CV: 'Cape Verde',
            KY: 'Cayman Islands',
            CF: 'Central African Republic',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'China',
            CX: 'Christmas Island',
            CC: 'Cocos (Keeling) Islands',
            CO: 'Colombia',
            KM: 'Comoros',
            CG: 'Congo',
            CD: 'Congo, Democratic Republic',
            CK: 'Cook Islands',
            CR: 'Costa Rica',
            CI: 'Cote D\'Ivoire',
            HR: 'Croatia',
            CU: 'Cuba',
            CY: 'Cyprus',
            CZ: 'Czech Republic',
            DK: 'Denmark',
            DJ: 'Djibouti',
            DM: 'Dominica',
            DO: 'Dominican Republic',
            EC: 'Ecuador',
            EG: 'Egypt',
            SV: 'El Salvador',
            GQ: 'Equatorial Guinea',
            ER: 'Eritrea',
            EE: 'Estonia',
            ET: 'Ethiopia',
            FK: 'Falkland Islands (Malvinas)',
            FO: 'Faroe Islands',
            FJ: 'Fiji',
            FI: 'Finland',
            FR: 'France',
            GF: 'French Guiana',
            PF: 'French Polynesia',
            TF: 'French Southern Territories',
            GA: 'Gabon',
            GM: 'Gambia',
            GE: 'Georgia',
            DE: 'Germany',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Greece',
            GL: 'Greenland',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Heard Island & Mcdonald Islands',
            VA: 'Holy See (Vatican City State)',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Hungary',
            IS: 'Iceland',
            IN: 'India',
            ID: 'Indonesia',
            IR: 'Iran',
            IQ: 'Iraq',
            IE: 'Ireland',
            IM: 'Isle Of Man',
            IL: 'Israel',
            IT: 'Italy',
            JM: 'Jamaica',
            JP: 'Japan',
            JE: 'Jersey',
            JO: 'Jordan',
            KZ: 'Kazakhstan',
            KE: 'Kenya',
            KI: 'Kiribati',
            KR: 'Korea',
            KW: 'Kuwait',
            KG: 'Kyrgyzstan',
            LA: 'Lao People\'s Democratic Republic',
            LV: 'Latvia',
            LB: 'Lebanon',
            LS: 'Lesotho',
            LR: 'Liberia',
            LY: 'Libyan Arab Jamahiriya',
            LI: 'Liechtenstein',
            LT: 'Lithuania',
            LU: 'Luxembourg',
            MO: 'Macao',
            MK: 'Macedonia',
            MG: 'Madagascar',
            MW: 'Malawi',
            MY: 'Malaysia',
            MV: 'Maldives',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marshall Islands',
            MQ: 'Martinique',
            MR: 'Mauritania',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Mexico',
            FM: 'Micronesia',
            MD: 'Moldova',
            MC: 'Monaco',
            MN: 'Mongolia',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Morocco',
            MZ: 'Mozambique',
            MM: 'Myanmar',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Netherlands',
            AN: 'Netherlands Antilles',
            NC: 'New Caledonia',
            NZ: 'New Zealand',
            NI: 'Nicaragua',
            NE: 'Niger',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Norfolk Island',
            MP: 'Northern Mariana Islands',
            NO: 'Norway',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Palestinian Territory, Occupied',
            PA: 'Panama',
            PG: 'Papua New Guinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Philippines',
            PN: 'Pitcairn',
            PL: 'Poland',
            PT: 'Portugal',
            PR: 'Puerto Rico',
            QA: 'Qatar',
            RE: 'Reunion',
            RO: 'Romania',
            RU: 'Russia',
            RW: 'Rwanda',
            BL: 'Saint Barthelemy',
            SH: 'Saint Helena',
            KN: 'Saint Kitts And Nevis',
            LC: 'Saint Lucia',
            MF: 'Saint Martin',
            PM: 'Saint Pierre And Miquelon',
            VC: 'Saint Vincent And Grenadines',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tome And Principe',
            SA: 'Saudi Arabia',
            SN: 'Senegal',
            RS: 'Serbia',
            SC: 'Seychelles',
            SL: 'Sierra Leone',
            SG: 'Singapore',
            SK: 'Slovakia',
            SI: 'Slovenia',
            SB: 'Solomon Islands',
            SO: 'Somalia',
            ZA: 'South Africa',
            GS: 'South Georgia And Sandwich Isl.',
            ES: 'Spain',
            LK: 'Sri Lanka',
            SD: 'Sudan',
            SR: 'Suriname',
            SJ: 'Svalbard And Jan Mayen',
            SZ: 'Swaziland',
            SE: 'Sweden',
            CH: 'Switzerland',
            SY: 'Syrian Arab Republic',
            TW: 'Taiwan',
            TJ: 'Tajikistan',
            TZ: 'Tanzania',
            TH: 'Thailand',
            TL: 'Timor-Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad And Tobago',
            TN: 'Tunisia',
            TR: 'Turkey',
            TM: 'Turkmenistan',
            TC: 'Turks And Caicos Islands',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukraine',
            AE: 'United Arab Emirates',
            GB: 'United Kingdom',
            US: 'United States',
            UM: 'United States Outlying Islands',
            UY: 'Uruguay',
            UZ: 'Uzbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Viet Nam',
            VG: 'Virgin Islands, British',
            VI: 'Virgin Islands, U.S.',
            WF: 'Wallis And Futuna',
            EH: 'Western Sahara',
            YE: 'Yemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe'
        },
    },
    es: {
        _name: 'Español',
        timePickerModal: {
            title: 'Close In Time'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Tomar ganancias y detener pérdida',
            takeProfit: 'Tomar ganancias',
            stopLoss: 'Detener la pérdida',
            updateOrder: 'Actualizar pedido',
        },
        balanceModal: {
            balance: 'Saldo',
            credit: 'Crédito',
            equity: 'Capital',
            practice: 'PRÁCTICA',
            real: 'VERDADERO',
            account: 'CUENTA',
            invested: 'Invertido',
            profit: 'Lucro',
            loss: 'Pérdida',
            margin: 'Margen',
            marginLevel: 'Nivel de margen',
            marginFree: 'Margen libre'
        },
        header: {
            cfd: 'CFD (Contratos por Diferencia)',
            platform: 'Plataforma',
            deposit: 'Depositar'
        },
        sideMenu: {
            marketWatch: 'Reloj de mercado',
            activeOrders: 'Pedidos Activos',
            tradingHistory: 'Historial de operaciones',
            economicCalendar: 'Calendario económico',
            marketNews: 'Noticias del mercado'
        },
        closePositionModal: {
            closeOrder: 'Cerrar Posición',
            areYouSure: '¿Está seguro de cerrar la posición?',
            buy: 'Comprar',
            sell: 'Vender',
            yes: 'Sí',
            no: 'No',
        },
        pciDssModal: {
            desc: `El cumplimiento del Estándar de Seguridad de Datos de la Industria de Tarjetas de Pago (PCI DSS) es obligatorio para todas las entidades que almacenan, procesan o transmiten datos de titulares de tarjetas Visa/Master Card/Stripe, incluidas las instituciones financieras, comerciantes y proveedores de servicios. 
            Es el estándar de seguridad global para todas las entidades que almacenan, procesan o transmiten datos de titulares de tarjetas y/o datos de autenticación sensibles. PCI DSS establece un nivel básico de protección para los consumidores y ayuda a reducir el fraude y las violaciones de datos en todo el ecosistema de pagos.`
        },
        chart: {
            addNewChart: 'Agregar nuevo gráfico',
        },
        symbolsModal: {
            watchlist: 'Lista de observación',
            asset: 'Activo',
            price: 'Precio',
            changePct: 'Cambio 24h'
        },
        pendingModal: {
            pending: 'Pendiente',
            assetPrice: 'Precios de los activos',
            current: 'Actual',
            revert: 'Volver al precio de mercado',
            automatically: 'La posición se abrirá automáticamente cuando el precio alcance este nivel'
        },
        orderMenu: {
            default: 'default',
            volume: 'Volumen',
            lots: 'lotes',
            units: 'unidades',
            currency: 'divisa',
            contractSize: 'Tamaño del contrato',
            position: 'Posición',
            margin: 'Margen',
            freeMargin: 'Margen libre',
            takeProfitStopLoss: 'Tomar ganancias y detener pérdidas',
            pending: 'Pendiente',
            market: 'Mercado',
            leverage: 'apalancamiento',
            spread: 'Spread',
            notSet: 'No establecido',
            at: 'en',
            buy: 'Comprar',
            sell: 'Vender',
        },
        footer: {
            supportBanner: 'TODOS LOS DÍAS, LAS 24 HORAS',
            currentTime: 'TIEMPO ACTUAL',
            liveChat: 'Chat en vivo'
        },
        statusBar: {
            balance: 'Saldo',
            credit: 'Crédito',
            equity: 'Capital',
            margin: 'Margen',
            marginLevel: 'Nivel de margen',
            freeMargin: 'Margen libre',
            pnl: 'PnL',
            profitTotal: 'Lifetime PnL'
        },
        accountModal: {
            dateRegistered: 'Fecha de registro',
            userId: 'ID de usuario',
            dashboard: 'Dashboard',
            personalData: 'Información personal',
            deposit: 'Depositar',
            withdrawFunds: 'Retirar Fondos',
            savings: 'Ahorros',
            settings: 'Ajustes',
            logout: 'Cerrar sesión'
        },
        depositModal: {
            BT_INVOICE: 'Enter amount and generate invoice',
            BT_TITLE: 'Our banking details',
            PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
            account: 'Account',
            bank_name: 'Bank Name',
            beneficiary: 'Beneficiary',
            beneficiary_address: 'Beneficiary Address',
            swift: 'Swift',
            reference: 'Reference',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Amount',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Description',
            TOKEN_PAYMENT_DETAILS: 'Payment Details',
            makeDeposit: 'Hacer un depósito',
            practiceAccount: 'Cuenta de práctica',
            realAccount: 'Cuenta real',
            noWithdrawals: 'Sin retiros',
            easyWithdrawals: 'Retiros Fáciles',
            allAssets: 'Todos los activos disponibles',
            fullFledged: 'Plataforma completa',
            fillUpTo: 'Llenar hasta',
            freeReplenishment: 'Reposición gratuita',
            topUp: 'Recargue su cuenta',
            minimumAmount: 'Monto minimo',
            canSwitch: 'Usted puede cambiar entre sus cuentas en cualquier momento'
        },
        gridsModal: {
            chartGrids: 'Cuadrículas de gráficos',
            chart1: '1 gráfico',
            chart2: '2 gráficos',
            chart3: '3 gráficos',
            chart4: '4 gráficos',
        },
        noConnectionModal: {
            connectionLost: 'Se perdió la conexión con el servidor',
            retryNow: 'Reintentar ahora',
        },
        loginModal: {
            loginToTradeRoom: 'Iniciar sesión en Traderoom',
            email: 'Dirección de correo electrónico',
            enterEmail: 'Introduzca su correo electrónico',
            twoFactor: 'Código de 2FA(si está activado)',
            twoFactorAuth: 'Verificación en dos pasos',
            password: 'Contraseña',
            yourPassword: 'Su contraseña',
            signIn: 'Iniciar sesión',
            dontHaveAccount: 'Don’t have an account?',
            restore: 'Restore',
            fullName: 'Full Name',
            havePromo: 'I have a promo-code',
            promo: 'Promo code',
            login: 'Login',
            haveAccount: 'Already have an account?',
            hide: 'Hide',
            goTo: 'Go to back'
        },
        newPasswordModal: {
            setNewPassword: 'Establecer nueva contraseña',
            newPassword: 'Nueva contraseña',
            confirmNewPassword: 'Confirmar nueva contraseña',
            setButton: 'Establecer'
        },
        registerModal: {
            repeatPassword: 'Repeat Password',
            createNewAccount: 'Crear una cuenta nueva',
            email: 'Dirección de correo electrónico',
            firstName: 'Nombre',
            lastName: 'Apellido',
            country: 'País',
            phone: 'Teléfono',
            password: 'Contraseña',
            createAccount: 'Crear una cuenta',
            currency: 'Divisa',
            privacyAndPolicy: "Privacidad y Política",
            customerAgreement: 'Acuerdo con el cliente',

        },
        forgotPasswordModal: {
            forgotPassword: 'Ha olvidado su contraseña?',
            submitEmail: 'Envíe un correo electrónico utilizado para el registro, verifique su bandeja de entrada y siga las instrucciones provistas.',
            submit: 'Enviar'
        },
        notifications: {
            error: 'Error',
            success: 'Éxito',
            deposit: 'Depositar',
            withdrawal: 'Retirar',
            depositApproved: 'El depósito ha sido aprobado',
            depositDeclined: 'El depósito ha sido rechazado',
            withdrawalApproved: 'Retiro ha sido aprobado',
            withdrawalDeclined: 'Retiro ha sido rechazado'
        },
        markets: {
            forex: 'Forex',
            stocks: 'Acciones',
            commodities: 'Mercancías',
            indices: 'Índices',
            crypto: 'Cripto',
            metals: 'Metales',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'BUY',
            profitCalculator: 'Profit Calculator',
            sell: 'SELL',
            volume: 'Volume',
            entryPrice: 'Entry Price',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            maxPosition: 'Max Position',
            calculate: 'Calculate',
            calculationsFor: 'Calculations for',
            leverage: 'Leverage',
            requiredMargin: 'Required Margin',
            profitFromTP: 'Profit from TP',
            lossFromSL: 'Loss from SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Deposit successful',
            text: 'Your deposit has been processed successfully!',
        },
        depositFailModal: {
            title: 'Deposit failed',
            text: 'Your deposit has not been processed.',
        },
        widgetMarketWatch: {
            marketWatch: 'Reloj de mercado',
            search: 'Buscar...',
        },
        widgetOrders: {
            commission: 'Commission',
            volume: 'Volumen',
            margin: 'Margen',
            active: 'Activo',
            pending: 'Pendiente',
            activeOrders: 'Órdenes activas',
            portfolio: 'Cartera',
            allPositions: 'Todas las posiciones',
            noPositionsLine1: 'No tienes abierto',
            noPositionsLine2: 'Posiciones todavía',
            show: 'mostrar',
            more: 'mas',
            less: 'menos',
            purchaseTime: 'Tiempo de compra',
            closePosition: 'Cerrar posición',
            priceOpen: 'Precio de compra',
            priceSl: 'precio de parada de perdida',
            priceTp: 'precio de toma de ganancias',
            type: 'Dirección de posición',
            pnl: 'Beneficios/pérdidas',
            cancelOrder: 'Cancelar orden',
            orderType: 'Tipo de orden',
            cancelled: 'Cancelado',
            tradingHistory: 'Historial comercial',
            noHistoryLine1: 'No tienes ninguno',
            noHistoryLine2: 'Acuerdos cerrados todavía',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Calendario económico'
        },
        widgetMarketNews: {
            marketNews: 'Noticias de mercado'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'Ordenes activas',
            ordersHistory: 'Historial de ordenes',
            id: 'ID',
            symbol: 'Símbolo',
            type: 'Tipo',
            volume: 'Volumen',
            openPrice: 'Precio de apertura',
            openTime: 'Tiempo de apertura',
            closePrice: 'Precio de cierre',
            closeTime: 'Tiempo de cierre',
            sl: 'SL',
            tp: 'TP',
            price: 'Precio',
            pnl: 'PnL',
            actions: 'Acciones',
            edit: 'Editar',
            close: 'Cerrar',
            commission: 'Commission',
            swap: 'Swap',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short'
        },
        assetInfoModal: {
            assetInfo: 'Asset Info',
            tradingConditions: 'Trading Conditions',
            information: 'Session Change',
            bid: 'Bid',
            ask: 'Ask',
            sessionChange: 'Session Change',
            tradeNow: 'Trade Now',
            opens: 'Opens',
            closes: 'Closes',
            at: 'at',
            open247: 'Open 24/7',
            today: 'today',
            tomorrow: 'tomorrow',
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            contractSpecification: 'Contract Specification',
            symbol: 'Symbol',
            name: 'Name',
            market: 'Market',
            baseCurrency: 'Base Currency',
            digits: 'Digits',
            lotSize: 'Lot Size',
            lotStep: 'Lot Step',
            minVolume: 'Min Volume',
            maxVolume: 'Max Volume',
            leverage: 'Leverage',
            commission: 'Commission',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short',
            schedule: 'Schedule',
            weekday: 'Weekday',
            tradingTime: 'Trading Time',
            closed: 'closed',
            sell: 'Sell',
            buy: 'Buy',
            low: 'Low',
            high: 'High',
            oneHourChange: '1 hour change',
            oneDayChange: '1 day change',
            oneWeekChange: '1 week change',
            oneMonthChange: '1 month change',
            threeMonthsChange: '3 months change',
            oneYearChange: '1 year change',
            loading: 'Loading...',
        },
        autoTrader: {
            days: 'days',
            title: 'Auto Trader',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Risk Level: Low',
            riskLevelMedium: 'Risk Level: Medium',
            riskLevelHigh: 'Risk Level: High',
            perDay: 'Trades per day:',
            desc: `Trading CFDs and other leveraged products can lead to losses. Before trading, clients should
                read the
                relevant risk statements on our Risk Disclosure page. Automated trading does not guarantee results. The
                company accepts no responsibility for the loss of funds in automatic trading. Please make sure that you
                fully understand the risks and take measures to manage risks.`,
            accept: 'Accept',
            save: 'Guardar',
            cancel: 'Cancelar',
        },
        cardBox: {
            name: 'Nombre',
            cardNumber: 'Número de tarjeta',
            expiration: 'Caducidad (mm/aa)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "Comercio",
            startTrading: 'Comenzar a operar',
            deposit: 'Hacer un depósito',
            dashboard: 'Dashboard',
            personalInfo: 'Actualizar datos personales',
            contactInfo: 'Información de contacto',
            withdrawal: 'Solicitar un Retiro',
            verification: 'Verificación de identidad',
            accounts: 'Cuentas de administración',
            liveChat: 'Chat en vivo',
            savings: 'Ahorros',
            settings: 'Configuración de la plataforma',
            logOut: 'Cerrar sesión',
        },
        fullScreenDeposit: {
            methods: 'METHODS',
            confirm: 'Confirm in the application of your bank',
            sendOtp: 'Send otp',
            otpCode: 'Otp code',
            addWalletAddress: 'Wallet for this deposit method is not set. Please contact your agent or support team.',
            addressReceipt: 'Please wait for the address to load',
            makeDeposit: 'HACER UN DEPÓSITO',
            instant: 'Instante',
            minutes: 'minutos',
            hours: 'horas',
            days: 'días',
            amount: 'Monto',
            continue: 'Continuar',
            qrCode: 'CÓDIGO QR',
            depositAddress: 'DIRECCIÓN DE DEPÓSITO',
            copy: 'Copiar',
            last: 'ÚLTIMOS',
            deposits: 'DEPÓSITOS',
            time: 'Hora',
            currency: 'Divisa',
            status: 'Estado',
            info: 'Información',
        },
        fullScreenCcExt: {
            pleaseWait: 'Espere por favor. Conectando con el proveedor de servicios de pago...',
            cardPayment: 'Pagar con tarjeta',
            inOrder: 'Para enviar el importe',
            pleaseProvide: 'por favor proporcione los detalles de la tarjeta:'
        },
        fullScreenDashboard: {
            completeVerification: 'Please Complete Verification',
            noOrderYet: 'No order yet',
            totalBalance: 'Saldo Total',
            totalPnl: 'PnL Total',
            profitableOrders: 'Pedidos Rentables',
            roi: 'ROI',
            activityLog: 'Registro de Actividad',
            loginFromIp: 'Login from IP',
            tradingResults: 'Resultados de Negocios',
            week: 'Semana',
            month: 'Mes',
            year: 'Año',
            successRate: 'Tasa de Éxito',
            closedWithProfit: 'Cerrado con Beneficio',
            closedWithLoss: 'Cerrado con Pérdida',
            account: 'Cuenta',
            balance: 'Saldo',
            leverage: 'Apalancamiento',
            credit: 'Crédito',
            tradeNow: 'Negociar Ahora',
            usingCurrentRate: 'using current exchange rate',
            totalDeposits: 'Total Deposits'
        },
        fullScreenPersonal: {
            personalDetails: 'Detalles personales',
            profilePhoto: 'Foto de perfil',
            firstName: 'Nombre',
            country: 'País',
            lastName: 'Apellido',
            address: 'Dirección',
            email: 'E-mail',
            phone: 'Número de teléfono',
            saveChanges: 'Guardar cambios',
            state: 'Ciudad',
            postcode: 'Código postal',
            created: "Cuenta creada el",
        },
        fullScreenAvatar: {
            dropFile: 'Soltar un archivo en el círculo de arriba para subirlo',
            notAllowed: 'No está permitido publicar',
            notAllowedLine1: 'Fotos de carácter explícitamente sexual o pornográfico',
            notAllowedLine2: 'Imágenes destinadas a incitar al odio o la agresión étnica o racial',
            notAllowedLine3: 'Fotos de personas menores de 18 años',
            notAllowedLine4: 'Fotos protegidas por derechos de autor de terceros',
            notAllowedLine5: 'Imágenes de más de 5 MB y en un formato que no sea JPG, GIF o PNG',
            requirements: 'Su cara debe ser claramente visible en la foto. Todas las fotos y videos subidos por usted deben cumplir con estos requisitos, de lo contrario, pueden ser eliminados.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Please contact your account manager for withdrawal details',
            cryptocurrency: 'Cryptocurrency',
            withdrawal: 'Withdrawal',
            requestNewWithdrawal: 'SOLICITE UNA NUEVA RETIRADA',
            amount: 'Cantidad',
            currentBalance: 'El saldo de su cuenta corriente es',
            withdrawAll: 'Retirar todo',
            requestWithdrawal: 'Solicitar retiro',
            last: 'ÚLTIMO',
            withdrawalRequests: 'SOLICITUDES DE RETIRAR',
            time: 'Tiempo',
            currency: 'Moneda',
            info: 'Información',
            status: 'Estado',
            bank: 'Banco',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            card: 'Tarjeta',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Detalles del retiro',
            address: 'Dirección de la billetera',
            bankAccount: 'Número de cuenta',
            bankHolder: 'Nombre del titular de la cuenta',
            bankIban: 'IBAN',
            bankSwift: 'Código SWIFT del banco',
            recipientAddress: 'Dirección del destinatario',
            routingNumber: 'Número de ruta',
            bankBeneficiary: 'Nombre del banco del beneficiario',
            bankAddress: 'Dirección del banco',
            bankCode: 'Código bancario',
            desc: 'Descripción',
            cardNumber: 'Número de tarjeta',
            cardHolder: 'Nombre del titular de la tarjeta',
        },
        fullScreenVerification: {
            dragAndDrop: 'Arrastrar y soltar el documento a esta área',
            documentsList: 'LISTA DE DOCUMENTOS SUBIDOS',
            document: 'Documento',
            timeUploaded: 'Tiempo subido',
            timeProcessed: 'Tiempo de procesamiento',
            status: 'Estado',
            types: ['Prueba de identificación', 'Prueba de residencia', 'Parte frontal de tarjeta de crédito', 'Parte trasera de tarjeta de crédito', 'Parte trasera de prueba de identificacion', 'Autofoto'],
            types2: ['Proof of identification (POI)', 'Proof of residential address', 'Credit Card Front', 'Credit Card Back', 'Proof of identification Back', 'Selfie with ID'],
            upload: 'Subir',
            isConfirmed: 'Está confirmado',
            uploadAnotherDocument: 'Subir otro documento',
            isUnderReview: 'Está bajo revisió',
            questionnaire: 'Cuestionario',
            sendAnswers: 'Enviar respuestas',
            progressBarOf: 'of',
            progressBarText: 'of your documents have been uploaded and confirmed'
        },
        fullScreenAccounts: {
            manageAccounts: 'Transferir fondos entre sus cuentas',
            transferFrom: 'De la cuenta',
            transferTo: 'A la cuenta',
            amount: 'Monto',
            makeTransfer: 'Hacer transferencia',
            yourAccounts: 'Sus Cuentas',
            account: 'Cuenta',
            currency: 'Divisa',
            balance: 'Saldo',
            credit: 'Crédito',
            makeActive: 'Activar',
            active: 'Activo'
        },
        fullScreenSupport: {
            sendMessage: 'Enviar mensaje'
        },
        fullScreenSavings: {
            pct_0: 'Flexible',
            pct_30: 'Bloqueado 1 mes',
            pct_90: 'Bloqueado 1 cuarto',
            pct_180: 'Bloqueado 6 meses',
            pct_365: 'Bloqueado 1 año',
            savings: 'Ahorros',
            detailedInformation: 'Información detallada',
            pleaseSelect: 'Seleccione una moneda y un período',
            openSavings: 'Ahorros abiertos',
            currentTime: 'Tiempo actual',
            releaseTime: 'Tiempo de lanzamiento',
            currencyChosen: 'Moneda elegida',
            periodChosen: 'Período elegido',
            yourRate: 'Tu tarifa',
            yourEarnings: 'Tus ganancias',
            accountFrom: 'Cuenta de la que se va transferir fondos',
            enterAmount: 'Introduzca la cantidad',
            in: 'V',
            openAndInvest: 'Abre una cuenta de ahorros e invierte',
            investment: 'Inversión',
            period: 'Período',
            daysTotal: 'Días totales',
            finalEarnings: 'Ganancias finales',
            created: 'Creado',
            daysElapsed: 'Días transcurridos',
            days: 'Días',
            earnings: 'Ganancias',
            fundsReleasedIn: 'Fondos liberados',
            claim: 'Reclamación',
        },
        fullScreenSettings: {
            updatePassword: 'ACTUALIZAR CONTRASEÑA',
            currentPassword: 'Contraseña actual',
            newPassword: 'Nueva contraseña',
            changePassword: 'Cambiar la contraseña',
            activationForGoogle: 'Código de activación para Google Authenticator',
            activateAndEnter: 'Active Authenticator e ingrese un código generado en el campo a continuación',
            qrCode: 'QR Código',
            activateProtection: 'Activar Protección de 2FA',
            protectionActive: 'La protección 2FA está activada',
            twoFactorCode: 'Código de 2FA',
            disableProtection: 'Desactivar protección de 2F',
            language: 'Idioma',
            dashboardCurrency: 'moneda del panel',
            confirmNewPassword: 'Confirmar nueva contraseña',
        },
        fullScreenReferrals: {
            yourLink: 'SU ENLACE DE REFERENCIA',
            referredFriends: 'AMIGOS RECOMENDADOS',
            id: 'ID',
            name: 'Nombre',
            registered: 'Registrado',
            level: 'Nivel',
            payout: 'Pagar',
            lastPayouts: 'ÚLTIMOS PAGOS DE REFERENCIA',
            time: 'Hora',
            amount: 'Monto',
            currency: 'Divisa',
            info: 'Información'
        },
        months: {
            Jan: 'Enero',
            Feb: 'Febrero',
            Mar: 'Marzo',
            Apr: 'Abril',
            May: 'Mayo',
            Jun: 'Junio',
            Jul: 'Julio',
            Aug: 'Agosto',
            Sep: 'Septiembre',
            Oct: 'Octubre',
            Nov: 'Noviembre',
            Dec: 'Diciembre'
        },
        orderTypes: ['Comprar', 'Vender', 'Limite de compra', 'Límite de venta', 'Parada de compra', 'Parada de venta'],
        statuses: ['Pendiente', 'Aprobado', 'Declinado', 'Procesamiento'],
        errors: {
            NOT_FOUND: 'Solicitar error',
            FORBIDDEN: 'Acceso denegado.Vuelve a iniciar sesión,',
            SERVER_ERROR: 'La acción falló. Inténtalo de nuevo más tarde',
            INVALID_ACCOUNT: 'La sesión expiró.Vuelve a iniciar sesión,',
            INVALID_EMAIL: 'Dirección de correo electrónico no válida',
            INVALID_PHONE: 'Número de teléfono no válido',
            INVALID_COUNTRY: 'País no válido',
            INVALID_LOGIN: 'Credenciales de acceso invalidos',
            USER_BLOCKED: 'La cuenta está bloqueada. Póngase en contacto con el soporte técnico',
            USER_REGISTERED: 'El usuario con correo electrónico o número de teléfono introducido ya está registrado',
            INVALID_USER: 'No se encuentra al usuario',
            REJECTED: 'La operación no está permitida debido a las restricciones de la plataforma',
            INVALID_OLD_PASSWORD: 'La contraseña antigua no es válida',
            INVALID_SYMBOL: 'Símbolo comercial no válido',
            INVALID_SYMBOL_PRECISION: 'Precisión del símbolo no válida',
            INVALID_FRAME: 'Marco de gráfico no válido',
            INVALID_CURRENCY: 'Moneda no válida',
            INVALID_PRODUCT: 'Tipo de operación de cuenta no válida',
            INSUFFICIENT_FUNDS: 'Fondos insuficientes',
            INVALID_WITHDRAWAL: 'No se encuentra la retirada',
            INVALID_STATUS: 'Estado no válido',
            INVALID_FILE: 'La extensión de archivo o archivo no válido es demasiado grande',
            INVALID_DOCUMENT: 'No se encuentra el documento',
            INVALID_NAME: 'Nombre no válido',
            INVALID_LOT_SIZE: 'Tamaño de lote no válido',
            INVALID_LOT_STEP: 'Escalón de lote no válido',
            INVALID_MIN_VOLUME: 'Volumen mínimo no válido',
            INVALID_MAX_VOLUME: 'Volumen máximo no válido',
            INVALID_GROUP: 'Grupo no válido',
            INVALID_SPREAD: 'Extienda no válida',
            REQUEST_TIMEOUT: 'La solicitud agotó el tiempo de espera. Inténtalo de nuevo más tarde',
            EXISTING_OPEN_ORDERS: 'Tienes un pedido abierto, primero cierra el pedido para este usuario y vuelve a intentarlo.',
            INVALID_TYPE: 'Tipo de orden no válido',
            INVALID_VOLUME: 'Volumen de pedidos no valido',
            INVALID_SL: 'Valor de perdida no valido',
            INVALID_TP: 'Valor de beneficio de toma no válido',
            INVALID_PRICE: 'Precio de pedido no válido',
            INVALID_EXPIRATION: 'Caducidad de orden no válida',
            NOT_ENOUGH_MARGIN: 'No hay suficiente margen',
            INVALID_ORDER: 'No se encuentra el pedido',
            MARKET_CLOSED: 'El comercio no está disponible. Por favor, inténtelo de nuevo',
            INVALID_BALANCE_TYPE: 'Tipo de operación de saldo no válido',
            INVALID_HASH: 'Hash no válido',
            HASH_EXPIRED: 'El enlace de restablecimiento de contraseña ha caducado. Por favor, solicite uno nuevo',
            INVALID_CODE: 'Código 2FA no válido',
            CHAT_DISABLED: 'Se ha cerrado el acceso al chat de soporte',
            WITHDRAWAL_NOT_ALLOWED: 'Se ha cerrado el acceso a los retiros',
            TRADING_DISABLED: 'Trading is disabled for current account',
            PENDING_DEPOSITS_LIMIT: 'Deposits limit exceeded',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Only one pending withdrawal allowed',
            LOCAL_DEPOSIT_ONLY_REAL: 'Deposit is available only for real account',
            LOCAL_DEPOSIT_POSITIVE: 'Amount of deposit must be more than 0',
            LOCAL_INVALID_CARD_NUMBER: 'Invalid card number',
            LOCAL_INVALID_CARD_EXPIRATION: 'Invalid card expiration',
            LOCAL_INVALID_CVC: 'Invalid CVV/CVC code',
            LOCAL_PASSWORDS_NOT_MATCH: 'Passwords do not match, please re-enter',
            LOCAL_NO_TRADING_ACCOUNTS: 'You do not have trading accounts available. Please contact the support',
            LOCAL_MIN_DEPOSIT: 'Minimum deposit is $',
            LOCAL_MIN_WITHDRAWAL: 'Minimum withdrawal amount is $',
            LOCAL_INVALID_FILE_TYPE: 'File format not supported. Please attach only JPG, PNG or PDF',
            LOCAL_MIN_STAKING: 'Minimum deposit for this currency is ',
            FLOOD_REJECTED: 'You are sending messages too often. Please try again later'
        },
        countries: {
            AF: 'Afganistán',
            AX: 'Islas Aland',
            AL: 'Albania',
            DZ: 'Algeria',
            AS: 'Samoa Americana',
            AD: 'Andora',
            AO: 'Angola',
            AI: 'Anguila',
            AQ: 'Antarctida',
            AG: 'Antigua And Barbuda',
            AR: 'Argentina',
            AM: 'Armenia',
            AW: 'Aruba',
            AU: 'Australia',
            AT: 'Austria',
            AZ: 'Azerbaiyan',
            BS: 'Bahamas',
            BH: 'Barein',
            BD: 'Banglades',
            BB: 'Barbados',
            BY: 'Bielorrusia',
            BE: 'Belgica',
            BZ: 'Belice',
            BJ: 'Benin',
            BM: 'Islas Bermudas',
            BT: 'Butan',
            BO: 'Bolivia',
            BA: 'Bosnia y Herzegovin',
            BW: 'Botsuana',
            BV: 'Islas Bouvet',
            BR: 'Brasil',
            IO: 'Territorio Británico del Océano Indico',
            BN: 'Brunei Darassalam',
            BG: 'Bulgaria',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Camboya',
            CM: 'Camerun',
            CA: 'Canada',
            CV: 'Cabo Verde',
            KY: 'Islas Caiman',
            CF: 'República Centroafricana',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'China',
            CX: 'Isla de Navidad',
            CC: 'Islas de Cocos',
            CO: 'Colombia',
            KM: 'Comoras',
            CG: 'Congo',
            CD: 'República Dominicana',
            CK: 'Islas Cook',
            CR: 'Costa Rica',
            CI: 'Costa de Marfil',
            HR: 'Croacia',
            CU: 'Cuba',
            CY: 'Chipre',
            CZ: 'República Checa',
            DK: 'Dinamarca',
            DJ: 'Yibuti',
            DM: 'Dominica',
            DO: 'República Dominicana',
            EC: 'Ecuador',
            EG: 'Egipto',
            SV: 'El Salvador',
            GQ: 'Guinea Ecuatorial',
            ER: 'Eritrea',
            EE: 'Estonia',
            ET: 'Etiopía',
            FK: 'Islas Malvinas',
            FO: 'Islas Faroe',
            FJ: 'Fiyi',
            FI: 'Finlandia',
            FR: 'Francia',
            GF: 'Guayana Francesa',
            PF: 'Polinesia Frances',
            TF: 'Territorios Franceses del Sur',
            GA: 'Gabon',
            GM: 'Gambia',
            GE: 'Georgia',
            DE: 'Alemania',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Grecia',
            GL: 'Groenlandia',
            GD: 'Granada',
            GP: 'Guadelupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernesey',
            GN: 'Guinea',
            GW: 'Guinea-Bisau',
            GY: 'Guayana',
            HT: 'Haiti',
            HM: 'Isla Mcdonald',
            VA: 'Estado de la Ciudad del Vaticano',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Hungría',
            IS: 'Islandia',
            IN: 'India',
            ID: 'Indonesia',
            IR: 'Irán',
            IQ: 'Irak',
            IE: 'Irlanda',
            IM: 'Isla del Hombre',
            IL: 'Israel',
            IT: 'Italia',
            JM: 'Jamaica',
            JP: 'Japón',
            JE: 'Jersey',
            JO: 'Jordan',
            KZ: 'Kazaistan',
            KE: 'Kenia',
            KI: 'Kiribati',
            KR: 'Corea',
            KW: 'Kuwait',
            KG: 'Kirguistán',
            LA: 'República Democrática Popular de Laos',
            LV: 'Letonia',
            LB: 'Líbano',
            LS: 'Lesoto',
            LR: 'Liberia',
            LY: 'Jamahiriya Arabe Libia',
            LI: 'Liechtenstein',
            LT: 'Lituania',
            LU: 'Luxemburgo',
            MO: 'Macao',
            MK: 'Macedonia',
            MG: 'Madagascar',
            MW: 'Malauí',
            MY: 'Malasia',
            MV: 'Maldivas',
            ML: 'Malí',
            MT: 'Malta',
            MH: 'Islas Marshall',
            MQ: 'Martinica',
            MR: 'Mauritania',
            MU: 'Mauricio',
            YT: 'Mayotte',
            MX: 'México',
            FM: 'Micronesia',
            MD: 'Moldavia',
            MC: 'Monaco',
            MN: 'Mongolia',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Marruecos',
            MZ: 'Mozambique',
            MM: 'Birmania',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Países Bajos',
            AN: 'Antillas Holandesas',
            NC: 'Nueva Caledonia',
            NZ: 'Nueva Zelanda',
            NI: 'Nicaragua',
            NE: 'Niger',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Isla Norfolk',
            MP: 'Islas Marianas del Norte',
            NO: 'Noruega',
            OM: 'Omán',
            PK: 'Pakistán',
            PW: 'Palaos',
            PS: 'Territorio Palestino Ocupado',
            PA: 'Panama',
            PG: 'Papúa Nueva Guinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Filipinas',
            PN: 'Pitcairn',
            PL: 'Polonia',
            PT: 'Portugal',
            PR: 'Puerto Rico',
            QA: 'Katar',
            RE: 'Reunion',
            RO: 'Rumania',
            RU: 'Rusia',
            RW: 'Ruanda',
            BL: 'San Bartolomé',
            SH: 'Santa Elena',
            KN: 'San Cristóbal y Nieves',
            LC: 'Santa Lucía',
            MF: 'San Martín',
            PM: 'San Pedro y Miquelon',
            VC: 'San Vicente y las Granadillas',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tomé And Principe',
            SA: 'Arabia Saudita',
            SN: 'Senegal',
            RS: 'Serbia',
            SC: 'Seychelles',
            SL: 'Sierra Leona',
            SG: 'Singapur',
            SK: 'Eslovaquia',
            SI: 'Eslovenia',
            SB: 'Islas Solomon',
            SO: 'Somalia',
            ZA: 'Sudáfrica',
            GS: 'Islas Georgias del Sur y Sandwich.',
            ES: 'España',
            LK: 'Sri Lanka',
            SD: 'Sudán',
            SR: 'Surinam',
            SJ: 'Svalbard And Jan Mayen',
            SZ: 'Suazilandia',
            SE: 'Suecia',
            CH: 'Suiza',
            SY: 'República Árabe Siria',
            TW: 'Taiwán',
            TJ: 'Tayikistán',
            TZ: 'Tanzania',
            TH: 'Tailandia',
            TL: 'Timor Oriental',
            TG: 'Ir',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad y Tobago',
            TN: 'Túnez',
            TR: 'Pavo',
            TM: 'Turkmenistán',
            TC: 'Islas Turcas y Caicos',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ucrania',
            AE: 'Emiratos Árabes Unidos',
            GB: 'Reino Unido',
            US: 'Estados Unidos',
            UM: 'Islas periféricas de Estados Unidos',
            UY: 'Uruguay',
            UZ: 'Uzbekistan',
            VU: 'Vanuata',
            VE: 'Venezuela',
            VN: 'Vietnam',
            VG: 'Islas Virgenes Británicas',
            VI: 'Islas Virgenes.',
            WF: 'Wallis y Futuna',
            EH: 'Sáhara Occidental',
            YE: 'Yemen',
            ZM: 'Zambia',
            ZW: 'Zimbabue'
        },
    },
    dk: {
        _name: 'Dansk',
        timePickerModal: {
            title: 'Close In Time'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Tag overskud  & Stop Tab',
            takeProfit: 'Tag overskud',
            stopLoss: 'Stop Tab',
            updateOrder: 'Opdatering af ordre',
        },
        balanceModal: {
            balance: 'Balance',
            credit: 'Kredit',
            equity: 'Egenkapital',
            practice: 'PRAKTIK',
            real: 'REAL',
            account: 'KONTO',
            invested: 'Investeret',
            profit: 'Overskud',
            loss: 'Tab',
            margin: 'Margen',
            marginLevel: 'Margin-niveau',
            marginFree: 'Fri margen'
        },
        header: {
            cfd: 'CFD',
            platform: 'Platform',
            deposit: 'Depositum'
        },
        sideMenu: {
            marketWatch: 'Markedsovervågning',
            activeOrders: 'Aktive ordrer',
            tradingHistory: 'Handelshistorie',
            economicCalendar: 'Økonomisk kalender',
            marketNews: 'Nyheder om markedet'
        },
        closePositionModal: {
            closeOrder: 'Luk position',
            areYouSure: 'Er du sikker på, at du vil lukke stillingen',
            buy: 'Køb',
            sell: 'Sælg',
            yes: 'Ja',
            no: 'Nej',
        },
        pciDssModal: {
            desc: `Overholdelse af Payment Card Industry Data Security Standard (PCI DSS) er påkrævet for alle enheder, der gemmer, behandler eller transmitterer data fra Visa/Master Card/Stripe kortindehavere, herunder finansielle institutioner, forhandlere og tjenesteudbydere. 
            Det er den globale sikkerhedsstandard for alle enheder, der gemmer, behandler eller transmitterer kortindehaverdata og/eller følsomme autentificeringsdata. PCI DSS fastlægger et grundlæggende beskyttelsesniveau for forbrugerne og hjælper med at reducere svindel og dataovertrædelser på tværs af hele betalingsekosystemet.`
        },
        chart: {
            addNewChart: 'Tilføj nyt diagram',
        },
        symbolsModal: {
            watchlist: 'Overvågningsliste',
            asset: 'Formue',
            price: 'Pris',
            changePct: 'Ændring 24 timer'
        },
        pendingModal: {
            pending: 'I vente',
            assetPrice: 'Aktivpris',
            current: 'Nuværende',
            revert: 'Tilbage til markedsprisen',
            automatically: 'Positionen vil blive åbnet automatisk, når prisen når dette niveau'
        },
        orderMenu: {
            default: 'default',
            volume: 'Volumen',
            lots: 'partier',
            units: 'enheder',
            currency: 'valuta',
            contractSize: 'Kontraktens størrelse',
            position: 'Position',
            margin: 'Margen',
            freeMargin: 'Fri margen',
            takeProfitStopLoss: 'Tag overskud  & Stop Tab',
            pending: 'I vente',
            market: 'Marked',
            leverage: 'Løftestang',
            spread: 'Spred',
            notSet: 'Ikke indstillet',
            at: 'på',
            buy: 'Køb',
            sell: 'Sælg',
        },
        footer: {
            supportBanner: 'HVER DAG, DØGNET RUNDT',
            currentTime: 'AKTUEL TID',
            liveChat: 'Live Chat'
        },
        statusBar: {
            balance: 'Balance',
            credit: 'Kredit',
            equity: 'Egenkapital',
            margin: 'Margen',
            marginLevel: 'Margen-niveau',
            freeMargin: 'Fri margen',
            pnl: 'PnL',
            profitTotal: 'Livstid PnL'
        },
        accountModal: {
            dateRegistered: 'Dato for registrering',
            userId: 'Bruger ID',
            dashboard: 'Instrumentbræt',
            personalData: 'Personlige data',
            deposit: 'Depositum',
            withdrawFunds: 'Trække penge ud',
            savings: 'Opsparing',
            settings: 'Indstillinger',
            logout: 'Logout'
        },
        depositModal: {
            BT_INVOICE: 'Enter amount and generate invoice',
            BT_TITLE: 'Our banking details',
            PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
            account: 'Account',
            bank_name: 'Bank Name',
            beneficiary: 'Beneficiary',
            beneficiary_address: 'Beneficiary Address',
            swift: 'Swift',
            reference: 'Reference',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Amount',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Description',
            TOKEN_PAYMENT_DETAILS: 'Payment Details',
            makeDeposit: 'Foretag en indbetaling',
            practiceAccount: 'Praksiskonto',
            realAccount: 'Rigtig konto',
            noWithdrawals: 'No Withdrawals',
            easyWithdrawals: 'Nemme udbetalinger',
            allAssets: 'Alle disponible aktiver',
            fullFledged: 'En fuldgyldig platform',
            fillUpTo: 'Fyld op til',
            freeReplenishment: 'Gratis genopfyldning',
            topUp: 'Opfyld din konto',
            minimumAmount: 'Mindste beløb',
            canSwitch: 'Du kan skifte mellem dine konti når som helst'
        },
        gridsModal: {
            chartGrids: 'DIAGRAMMER',
            chart1: '1 diagram',
            chart2: '2 diagrammer',
            chart3: '3 diagrammer',
            chart4: '4 diagrammer',
        },
        noConnectionModal: {
            connectionLost: 'Forbindelsen med serveren er mistet',
            retryNow: 'Prøv igen nu',
        },
        loginModal: {
            loginToTradeRoom: 'Log ind på Traderoom',
            email: 'E-mail-adresse',
            enterEmail: 'Indtast din e-mail',
            twoFactor: '2FA-kode (hvis aktiveret)',
            twoFactorAuth: 'To-faktor-autentifikation',
            password: 'Adgangskode',
            yourPassword: 'Your password',
            signIn: 'Log ind',
            dontHaveAccount: 'Har du ikke en konto?',
            restore: 'Gendan',
            fullName: 'Fuldt navn',
            havePromo: 'Jeg har en promo-kode',
            promo: 'Promo-kode',
            login: 'Login',
            haveAccount: 'Har du allerede en konto?',
            hide: 'Skjul',
            goTo: 'Gå tilbage'
        },
        newPasswordModal: {
            setNewPassword: 'Indstil nyt kodeord',
            newPassword: 'Ny adgangskode',
            confirmNewPassword: 'Bekræft ny adgangskode',
            setButton: 'Indstil'
        },
        registerModal: {
            repeatPassword: 'Repeat Password',
            createNewAccount: 'Opret en ny konto',
            email: 'E-mail-adresse',
            firstName: 'Fornavn',
            lastName: 'Efternavn',
            country: 'Land',
            phone: 'Telefon',
            password: 'Adgangskode',
            createAccount: 'Opret konto',
            currency: 'Valuta',
            privacyAndPolicy: "Privatliv og Politik",
            customerAgreement: 'Kundeaftale',

        },
        forgotPasswordModal: {
            forgotPassword: 'Har du glemt din adgangskode?',
            submitEmail: 'Send venligst en e-mail, der anvendes til registrering, tjek din indbakke og følg instruktionerne',
            submit: 'Indsend'
        },
        notifications: {
            error: 'Fejl',
            success: 'Succes',
            deposit: 'Depositum',
            withdrawal: 'Tilbagetrækning',
            depositApproved: 'Depositum er blevet godkendt',
            depositDeclined: 'Depositum er blevet afvist',
            withdrawalApproved: 'Tilbagetrækningen er blevet godkendt',
            withdrawalDeclined: 'Tilbagetrækning er blevet afvist'
        },
        markets: {
            forex: 'Forex',
            stocks: 'Aktier',
            commodities: 'Råvarer',
            indices: 'Indekser',
            crypto: 'Crypto',
            metals: 'Metals',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'KØB',
            sell: 'SÆLG',
            volume: 'Volumen',
            entryPrice: 'Indgangspris',
            takeProfit: 'Tag overskud',
            stopLoss: 'Stop Tab',
            maxPosition: 'Max Position',
            calculate: 'Beregn',
            calculationsFor: 'Beregninger for',
            leverage: 'Løftestang',
            requiredMargin: 'Nødvendig margen',
            profitFromTP: 'Overskud fra TP',
            lossFromSL: 'Tab fra SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Depositum lykkedes',
            text: 'Din indbetaling er blevet behandlet med succes!',
        },
        depositFailModal: {
            title: 'Depositum mislykkedes',
            text: 'Din indbetaling er ikke blevet behandlet.',
        },
        widgetMarketWatch: {
            marketWatch: 'Markedsovervågning',
            search: 'Søg på...',
        },
        widgetOrders: {
            commission: 'Commission',
            volume: 'Volumen',
            margin: 'Margen',
            active: 'Aktiv',
            pending: 'I vente',
            activeOrders: 'Aktive ordrer',
            portfolio: 'Portefølje',
            allPositions: 'Alle stillinger',
            noPositionsLine1: 'Du har ingen åbne',
            noPositionsLine2: 'stillinger endnu',
            show: 'Vis',
            more: 'mere',
            less: 'mindre',
            purchaseTime: 'Tidspunkt for køb',
            closePosition: 'Luk position',
            priceOpen: 'Købspris',
            priceSl: 'Stop Loss-pris',
            priceTp: 'Take Profit Pris',
            type: 'Position Retning',
            pnl: 'Overskud/tab',
            cancelOrder: 'Annuller bestilling',
            orderType: 'Bestillingstype',
            cancelled: 'Annulleret',
            tradingHistory: 'Handelshistorie',
            noHistoryLine1: 'Du har ikke nogen',
            noHistoryLine2: 'endnu ikke indgået aftaler',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Økonomisk kalender'
        },
        widgetMarketNews: {
            marketNews: 'Nyheder om markedet'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'Aktive ordrer',
            ordersHistory: 'Ordrehistorik',
            id: 'ID',
            symbol: 'Symbol',
            type: 'Type',
            volume: 'Volumen',
            openPrice: 'Åben pris',
            openTime: 'Åben tid',
            closePrice: 'Luk pris',
            closeTime: 'Luk tid',
            sl: 'SL',
            tp: 'TP',
            price: 'Pris',
            pnl: 'PnL',
            actions: 'Foranstaltninger',
            edit: 'Rediger',
            close: 'Luk',
            commission: 'Kommissionen',
            swap: 'Byt',
            swapLong: 'Bytte lang',
            swapShort: 'Bytte kort'
        },
        assetInfoModal: {
            assetInfo: 'Oplysninger om aktiver',
            tradingConditions: 'Handelsbetingelser',
            information: 'Ændring af session',
            bid: 'Bud',
            ask: 'Spørg',
            sessionChange: 'Ændring af session',
            tradeNow: 'Handel nu',
            opens: 'Åbner',
            closes: 'Lukker',
            at: 'på',
            open247: 'Åben 24/7',
            today: 'i dag',
            tomorrow: 'i morgen',
            sunday: 'Søndag',
            monday: 'Mandag',
            tuesday: 'Tirsdag',
            wednesday: 'Onsdag',
            thursday: 'Torsdag',
            friday: 'Fredag',
            saturday: 'Lørdag',
            contractSpecification: 'Kontraktspecifikation',
            symbol: 'Symbol',
            name: 'Navn',
            market: 'Marked',
            baseCurrency: 'Basisvaluta',
            digits: 'Cifre',
            lotSize: 'Størrelse af parti',
            lotStep: 'Parti trin',
            minVolume: 'Min Volumen',
            maxVolume: 'Max Volumen',
            leverage: 'Løftestang',
            commission: 'Kommissionen',
            swapLong: 'Bytte lang',
            swapShort: 'Bytte kort',
            schedule: 'Tidsplan',
            weekday: 'Hverdag',
            tradingTime: 'Handelstid',
            closed: 'lukket',
            sell: 'Sælg',
            buy: 'Køb',
            low: 'Lav',
            high: 'Høj',
            oneHourChange: '1 times ændring',
            oneDayChange: '1 dags ændring',
            oneWeekChange: '1 uges ændring',
            oneMonthChange: '1 måneds ændring',
            threeMonthsChange: '3 måneders ændring',
            oneYearChange: '1 års ændring',
            loading: 'Indlæsning...',
        },
        autoTrader: {
            days: 'days',
            title: 'Auto Trader',
            riskLevelLow: 'Risk Level: Low',
            riskLevelMedium: 'Risk Level: Medium',
            riskLevelHigh: 'Risk Level: High',
            perDay: 'Trades per day:',
            desc: `Trading CFDs and other leveraged products can lead to losses. Before trading, clients should
                read the
                relevant risk statements on our Risk Disclosure page. Automated trading does not guarantee results. The
                company accepts no responsibility for the loss of funds in automatic trading. Please make sure that you
                fully understand the risks and take measures to manage risks.`,
            accept: 'Accept',
            save: 'Gem',
            cancel: 'Annuller',

        },
        cardBox: {
            name: 'Navn',
            cardNumber: 'Kortnummer',
            expiration: 'Udløb (mm/åå)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "Handel",
            startTrading: 'Start handel',
            deposit: 'Depositum',
            dashboard: 'Instrumentbræt',
            personalInfo: 'Personlig information',
            contactInfo: 'Kontaktinformation',
            withdrawal: 'Tilbagetrækning',
            verification: 'Verifikation',
            accounts: 'Regnskaber',
            liveChat: 'Live Chat',
            savings: 'Opsparing',
            settings: 'Indstillinger',
            logOut: 'Log ud',
        },
        fullScreenDeposit: {
            methods: 'METHODS',
            confirm: 'Confirm in the application of your bank',
            sendOtp: 'Send otp',
            otpCode: 'Otp code',
            addWalletAddress: 'Wallet for this deposit method is not set. Please contact your agent or support team.',
            addressReceipt: 'Please wait for the address to load',
            makeDeposit: 'GØR ET INDBETALING',
            instant: 'Øjeblikkelig',
            minutes: 'minutter',
            hours: 'timer',
            days: 'dage',
            amount: 'Beløb',
            continue: 'Fortsæt',
            qrCode: 'QR CODE',
            depositAddress: 'INDBETALINGSADRESSE',
            copy: 'Kopier',
            last: 'SIDSTE',
            deposits: 'INDBETALINGER',
            time: 'Tid',
            currency: 'Valuta',
            status: 'Status',
            info: 'Info',
        },
        fullScreenCcExt: {
            pleaseWait: 'Vent venligst. Oprettelse af forbindelse til udbyder af betalingstjenester...',
            cardPayment: 'Card payment',
            inOrder: 'For at sende beløbet',
            pleaseProvide: 'Angiv venligst kortoplysninger:'
        },
        fullScreenDashboard: {
            completeVerification: 'Please Complete Verification',
            noOrderYet: 'No order yet',
            totalBalance: 'Samlet saldo',
            totalPnl: 'Samlet PNL',
            profitableOrders: 'Rentable ordrer',
            roi: 'ROI',
            activityLog: 'Aktivitetslogbog',
            loginFromIp: 'Login fra IP',
            tradingResults: 'Handelsresultater',
            week: 'Uge',
            month: 'Måned',
            year: 'År',
            successRate: 'Succesrate',
            closedWithProfit: 'Lukket med overskud',
            closedWithLoss: 'Lukket med tab',
            account: 'Konto',
            balance: 'Balance',
            leverage: 'Løftestang',
            credit: 'Kredit',
            tradeNow: 'Handel nu',
            usingCurrentRate: 'ved anvendelse af den aktuelle valutakurs',
            totalDeposits: 'Total Deposits'
        },
        fullScreenPersonal: {
            personalDetails: 'Personlige oplysninger',
            profilePhoto: 'Profilbillede',
            firstName: 'Fornavn',
            country: 'Land',
            lastName: 'Efternavn',
            address: 'Adresse',
            email: 'E-mail',
            phone: 'Telefon nummer',
            saveChanges: 'Gem ændringer',
            state: 'By',
            postcode: 'Postnummer',
            created: "Konto oprettet den",
        },
        fullScreenAvatar: {
            dropFile: 'Smid en fil på cirklen ovenfor for at uploade',
            notAllowed: 'Det er ikke tilladt at offentliggøre',
            notAllowedLine1: 'Billeder af eksplicit seksuel eller pornografisk karakter',
            notAllowedLine2: 'Billeder, der har til formål at tilskynde til etnisk eller racemæssigt had eller aggression',
            notAllowedLine3: 'Billeder, der involverer personer under 18 år',
            notAllowedLine4: 'Ophavsretligt beskyttede fotos fra tredjeparter',
            notAllowedLine5: 'Billeder, der er større end 5 MB og i et andet format end JPG, GIF eller PNG',
            requirements: 'Dit ansigt skal være tydeligt synligt på billedet. Alle billeder og videoer, som du uploader, skal opfylde disse krav, ellers kan de blive fjernet.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Please contact your account manager for withdrawal details',
            cryptocurrency: 'Cryptocurrency',
            withdrawal: 'Tilbagetrækning',
            requestNewWithdrawal: 'ANMODE OM EN NY UDBETALING',
            amount: 'Beløb',
            currentBalance: 'Din nuværende kontosaldo er',
            withdrawAll: 'Tilbagetrækning af alle',
            requestWithdrawal: 'Anmodning om tilbagetrækning',
            last: 'SIDSTE',
            withdrawalRequests: 'ANMODNINGER OM TILBAGETRÆKNING',
            time: 'Tid',
            currency: 'Valuta',
            info: 'Info',
            status: 'Status',
            bank: 'Bank',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            card: 'Kort',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Oplysninger om tilbagetrækning',
            address: 'Tegnebogens adresse',
            bankAccount: 'Kontonummer',
            bankHolder: 'Kontohaverens navn',
            bankIban: 'IBAN',
            bankSwift: 'Bankens SWIFT-kode',
            recipientAddress: 'Modtagerens adresse',
            routingNumber: 'Rutenummer',
            bankBeneficiary: 'Navn på modtagers bank',
            bankAddress: 'Bankadresse',
            bankCode: 'Bankkode',
            desc: 'Beskrivelse',
            cardNumber: 'Kortnummer',
            cardHolder: 'Kortindehaverens navn',
        },
        fullScreenVerification: {
            dragAndDrop: 'træk og slip dokumentet til dette område',
            documentsList: 'LISTE OVER UPLOADEDE DOKUMENTER',
            document: 'Dokument',
            timeUploaded: 'Tidspunkt uploadet',
            timeProcessed: 'Behandlet tid',
            status: 'Status',
            types: ['Bevis for id', 'Bevis for bopæl', 'Kreditkort forside', 'Kreditkort tilbage', 'Bevis for id tilbage', 'Selfie'],
            types2: ['Proof of identification (POI)', 'Proof of residential address', 'Credit Card Front', 'Credit Card Back', 'Proof of identification Back', 'Selfie with ID'],
            upload: 'Overfør',
            isConfirmed: 'er bekræftet',
            uploadAnotherDocument: 'Upload et andet dokument',
            isUnderReview: 'er under revision',
            questionnaire: 'Spørgeskema',
            sendAnswers: 'Send svar',
            progressBarOf: 'på',
            progressBarText: 'alle dine dokumenter er blevet uploadet og bekræftet'
        },
        fullScreenAccounts: {
            manageAccounts: 'Overfør penge mellem dine konti',
            transferFrom: 'Fra konto',
            transferTo: 'Til regnskab',
            amount: 'Beløb',
            makeTransfer: 'Foretag overførsel',
            yourAccounts: 'Dine konti',
            account: 'Konto',
            currency: 'Valuta',
            balance: 'Balance',
            credit: 'Kredit',
            makeActive: 'Gør aktiv',
            active: 'Aktiv'
        },
        fullScreenSupport: {
            sendMessage: 'Send besked'
        },
        fullScreenSavings: {
            pct_0: 'Fleksibel',
            pct_30: 'Låst 1 måned',
            pct_90: 'Låst 1 kvartal',
            pct_180: 'Låst 6 måneder',
            pct_365: 'Låst 1 år',
            savings: 'Opsparing',
            detailedInformation: 'Detaljerede oplysninger',
            pleaseSelect: 'Vælg venligst en valuta og en periode',
            openSavings: 'Åben opsparing',
            currentTime: 'Nuværende tid',
            releaseTime: 'Udgivelsestidspunkt',
            currencyChosen: 'Valgt valuta',
            periodChosen: 'Valgt periode',
            yourRate: 'Din pris',
            yourEarnings: 'Din indtjening',
            accountFrom: 'Konto til overførsel af midler fra',
            enterAmount: 'Indtast beløbet',
            in: 'På',
            openAndInvest: 'Åbn en opsparingskonto og invester',
            investment: 'Investering',
            period: 'Periode',
            daysTotal: 'Antal dage i alt',
            finalEarnings: 'Endelig indtjening',
            created: 'Oprettet',
            daysElapsed: 'Forløbne dage',
            days: 'dage',
            earnings: 'Indtjening',
            fundsReleasedIn: 'Midler frigivet i',
            claim: 'Krav',
        },
        fullScreenSettings: {
            updatePassword: 'OPDATERE ADGANGSKODE',
            currentPassword: 'Nuværende adgangskode',
            newPassword: 'Nyt kodeord',
            changePassword: 'Ændre adgangskode',
            activationForGoogle: 'Aktiveringskode til Google Authenticator',
            activateAndEnter: 'Aktivér Authenticator, og indtast en genereret kode i feltet nedenfor',
            qrCode: 'QR-kode',
            activateProtection: 'Aktiver 2FA-beskyttelse',
            protectionActive: '2FA-beskyttelse er aktiv',
            twoFactorCode: '2FA-kode',
            disableProtection: 'Deaktivere 2FA-beskyttelse',
            language: 'Sprog',
            dashboardCurrency: 'Dashboard Valuta',
            confirmNewPassword: 'Bekræft ny adgangskode',
        },
        fullScreenReferrals: {
            yourLink: 'DIT HENVISNINGSLINK',
            referredFriends: 'HENVISTE VENNER',
            id: 'ID',
            name: 'Navn',
            registered: 'Registreret',
            level: 'Niveau',
            payout: 'Udbetaling',
            lastPayouts: 'SENESTE UDBETALINGER VED HENVISNING',
            time: 'Tid',
            amount: 'Beløb',
            currency: 'Valuta',
            info: 'Info'
        },
        months: {
            Jan: 'Jan',
            Feb: 'Feb',
            Mar: 'Mar',
            Apr: 'Apr',
            May: 'Maj',
            Jun: 'Jun',
            Jul: 'Jul',
            Aug: 'Aug',
            Sep: 'Sep',
            Oct: 'Oct',
            Nov: 'Nov',
            Dec: 'Dec'
        },
        orderTypes: ['Køb', 'Salg', 'Køb Limit', 'Salg Limit', 'Køb Stop', 'Salg Stop'],
        statuses: ['Afventer', 'Godkendt', 'Afvist', 'Behandling'],
        errors: {
            NOT_FOUND: 'Fejl i anmodningen',
            FORBIDDEN: 'Adgang nægtet. Log venligst ind igen',
            SERVER_ERROR: 'Indsatsen mislykkedes. Prøv venligst igen senere',
            INVALID_ACCOUNT: 'Sessionen udløb. Log venligst ind igen',
            INVALID_EMAIL: 'Ugyldig e-mail-adresse',
            INVALID_PHONE: 'Ugyldigt telefonnummer',
            INVALID_COUNTRY: 'Ugyldigt land',
            INVALID_LOGIN: 'Ugyldige loginoplysninger',
            USER_BLOCKED: 'Kontoen er spærret. Kontakt venligst support',
            USER_REGISTERED: 'Brugeren med det indtastede e-mail eller telefonnummer er allerede registreret',
            INVALID_USER: 'Brugeren er ikke fundet',
            REJECTED: 'Drift er ikke tilladt på grund af platformsrestriktioner',
            INVALID_OLD_PASSWORD: 'Det gamle password er ugyldigt',
            INVALID_SYMBOL: 'Ugyldigt handelssymbol',
            INVALID_SYMBOL_PRECISION: 'Ugyldig symbolpræcision',
            INVALID_FRAME: 'Ugyldig diagramramme',
            INVALID_CURRENCY: 'Ugyldig valuta',
            INVALID_PRODUCT: 'Ugyldig konto handelstype',
            INSUFFICIENT_FUNDS: 'Utilstrækkelige midler',
            INVALID_WITHDRAWAL: 'Tilbagetrækning er ikke fundet',
            INVALID_STATUS: 'Ugyldig status',
            INVALID_FILE: 'Ugyldig filudvidelse eller filen er for stor',
            INVALID_DOCUMENT: 'Dokumentet er ikke fundet',
            INVALID_NAME: 'Ugyldigt navn',
            INVALID_LOT_SIZE: 'Ugyldig partistørrelse',
            INVALID_LOT_STEP: 'Ugyldigt partitrin',
            INVALID_MIN_VOLUME: 'Ugyldig minimumsvolumen',
            INVALID_MAX_VOLUME: 'Ugyldig maksimal volumen',
            INVALID_GROUP: 'Ugyldig gruppe',
            INVALID_SPREAD: 'Ugyldig spredning',
            REQUEST_TIMEOUT: 'Anmodningen er udløbet. Prøv venligst igen senere',
            EXISTING_OPEN_ORDERS: 'Du har en åben ordre, luk først ordren for denne bruger og prøv igen.',
            INVALID_TYPE: 'Ugyldig bestillingstype',
            INVALID_VOLUME: 'Ugyldig ordremængde',
            INVALID_SL: 'Ugyldig stop loss-værdi',
            INVALID_TP: 'Ugyldig take profit-værdi',
            INVALID_PRICE: 'Ugyldig ordrepris',
            INVALID_EXPIRATION: 'Ugyldig ordreudløb',
            NOT_ENOUGH_MARGIN: 'Ikke tilstrækkelig margen',
            INVALID_ORDER: 'Ordren er ikke fundet',
            MARKET_CLOSED: 'Handel er ikke tilgængelig. Prøv venligst igen',
            INVALID_BALANCE_TYPE: 'Ugyldig type af balanceoperation',
            INVALID_HASH: 'Ugyldig hash',
            HASH_EXPIRED: 'Dit link til nulstilling af adgangskode er udløbet. Anmod venligst om et nyt',
            INVALID_CODE: 'Ugyldig 2FA-kode',
            CHAT_DISABLED: 'Adgang til supportchatten er blevet lukket',
            WITHDRAWAL_NOT_ALLOWED: 'Adgangen til tilbagekøb er blevet lukket',
            TRADING_DISABLED: 'Handel er deaktiveret for den løbende konto',
            PENDING_DEPOSITS_LIMIT: 'Kun én igangværende indbetaling er tilladt',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Kun én igangværende udbetaling er tilladt',
            LOCAL_DEPOSIT_ONLY_REAL: 'Indbetaling er kun tilgængelig for rigtige konti',
            LOCAL_DEPOSIT_POSITIVE: 'Indbetalingsbeløbet skal være over 0',
            LOCAL_INVALID_CARD_NUMBER: 'Ugyldigt kortnummer',
            LOCAL_INVALID_CARD_EXPIRATION: 'Ugyldigt kortudløb',
            LOCAL_INVALID_CVC: 'Ugyldig CVV/CVC-kode',
            LOCAL_PASSWORDS_NOT_MATCH: 'Adgangskoderne stemmer ikke overens, indtast venligst igen',
            LOCAL_NO_TRADING_ACCOUNTS: 'Du har ingen handelskonti til rådighed. Kontakt venligst supporten',
            LOCAL_MIN_DEPOSIT: 'Mindste indbetaling er $',
            LOCAL_MIN_WITHDRAWAL: 'Mindste udbetalingsbeløb er $',
            LOCAL_INVALID_FILE_TYPE: 'Filformat ikke understøttet. Vedhæft venligst kun JPG, PNG eller PDF',
            LOCAL_MIN_STAKING: 'Minimum deposit for this currency is ',
            FLOOD_REJECTED: 'You are sending messages too often. Please try again later'
        },
        countries: {
            AF: 'Afghanistan',
            AX: 'Aland-øerne',
            AL: 'Albanien',
            DZ: 'Algeriet',
            AS: 'Amerikanske Samoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarktis',
            AG: 'Antigua og Barbuda',
            AR: 'Argentina',
            AM: 'Armenien',
            AW: 'Aruba',
            AU: 'Australien',
            AT: 'Østrig',
            AZ: 'Aserbajdsjan',
            BS: 'Bahamas',
            BH: 'Bahrain',
            BD: 'Bangladesh',
            BB: 'Barbados',
            BY: 'Hviderusland',
            BE: 'Belgien',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Bhutan',
            BO: 'Bolivia',
            BA: 'Bosnien og Hercegovina',
            BW: 'Botswana',
            BV: 'Bouvet-øen',
            BR: 'Brasilien',
            IO: 'Britisk territorium i Det Indiske Ocean',
            BN: 'Brunei Darussalam',
            BG: 'Bulgarien',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Cambodja',
            CM: 'Cameroun',
            CA: 'Canada',
            CV: 'Kap Verde',
            KY: 'Caymanøerne',
            CF: 'Den Centralafrikanske Republik',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'Kina',
            CX: 'Juleøen',
            CC: 'Cocos- (Keeling) øerne',
            CO: 'Colombia',
            KM: 'Comorerne',
            CG: 'Congo',
            CD: 'Congo, Den Demokratiske Republik',
            CK: 'Cookøerne',
            CR: 'Costa Rica',
            CI: 'Elfenbenskysten',
            HR: 'Kroatien',
            CU: 'Cuba',
            CY: 'Cypern',
            CZ: 'Tjekkiet',
            DK: 'Danmark',
            DJ: 'Djibouti',
            DM: 'Dominica',
            DO: 'Den Dominikanske Republik',
            EC: 'Ecuador',
            EG: 'Egypten',
            SV: 'El Salvador',
            GQ: 'Ækvatorialguinea',
            ER: 'Eritrea',
            EE: 'Estland',
            ET: 'Etiopien',
            FK: 'Falklandsøerne (Malvinas)',
            FO: 'Færøerne',
            FJ: 'Fiji',
            FI: 'Finland',
            FR: 'Frankrig',
            GF: 'Fransk Guyana',
            PF: 'Fransk Polynesien',
            TF: 'Franske sydlige territorier',
            GA: 'Gabon',
            GM: 'Gambia',
            GE: 'Georgien',
            DE: 'Tyskland',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Grækenland',
            GL: 'Grønland',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Heard Island og Mcdonald-øerne',
            VA: 'Den Hellige Stol (Vatikanstaten)',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Ungarn',
            IS: 'Island',
            IN: 'Indien',
            ID: 'Indonesien',
            IR: 'Iran',
            IQ: 'Irak',
            IE: 'Irland',
            IM: 'Man-øen',
            IL: 'Israel',
            IT: 'Italien',
            JM: 'Jamaica',
            JP: 'Japan',
            JE: 'Jersey',
            JO: 'Jordan',
            KZ: 'Kasakhstan',
            KE: 'Kenya',
            KI: 'Kiribati',
            KR: 'Korea',
            KW: 'Kuwait',
            KG: 'Kirgisistan',
            LA: 'Den Demokratiske Folkerepublik Laos',
            LV: 'Letland',
            LB: 'Libanon',
            LS: 'Lesotho',
            LR: 'Liberia',
            LY: 'Libysk-Arabisk Jamahiriya',
            LI: 'Liechtenstein',
            LT: 'Litauen',
            LU: 'Luxembourg',
            MO: 'Macao',
            MK: 'Makedonien',
            MG: 'Madagaskar',
            MW: 'Malawi',
            MY: 'Malaysia',
            MV: 'Maldiverne',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marshalløerne',
            MQ: 'Martinique',
            MR: 'Mauritanien',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Mexico',
            FM: 'Mikronesien',
            MD: 'Moldova',
            MC: 'Monaco',
            MN: 'Mongoliet',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Morokko',
            MZ: 'Mozambique',
            MM: 'Myanmar',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Nederlandene',
            AN: 'De Nederlandske Antiller',
            NC: 'Ny Kaledonien',
            NZ: 'New Zealand',
            NI: 'Nicaragua',
            NE: 'Niger',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Norfolk-øen',
            MP: 'Nordmarianerne',
            NO: 'Norge',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Palæstinensisk område, besat',
            PA: 'Panama',
            PG: 'Papua Ny Guinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Filippinerne',
            PN: 'Pitcairn',
            PL: 'Polen',
            PT: 'Portugal',
            PR: 'Puerto Rico',
            QA: 'Qatar',
            RE: 'Reunion',
            RO: 'Rumænien',
            RU: 'Rusland',
            RW: 'Rwanda',
            BL: 'Saint Barthelemy',
            SH: 'Saint Helena',
            KN: 'Saint Kitts And Nevis',
            LC: 'Saint Lucia',
            MF: 'Saint Martin',
            PM: 'Saint Pierre og Miquelon',
            VC: 'Saint Vincent og Grenadinerne',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tome Og Principe',
            SA: 'Saudi Arabien',
            SN: 'Senegal',
            RS: 'Serbien',
            SC: 'Seychellerne',
            SL: 'Sierra Leone',
            SG: 'Singapore',
            SK: 'Slovakiet',
            SI: 'Slovenien',
            SB: 'Salomonøerne',
            SO: 'Somalia',
            ZA: 'Sydafrika',
            GS: 'Sydgeorgien og Sandwichøerne',
            ES: 'Spanien',
            LK: 'Sri Lanka',
            SD: 'Sudan',
            SR: 'Surinam',
            SJ: 'Svalbard og Jan Mayen',
            SZ: 'Swaziland',
            SE: 'Sverige',
            CH: 'Schweiz',
            SY: 'Den Syriske Arabiske Republik',
            TW: 'Taiwan',
            TJ: 'Tadsjikistan',
            TZ: 'Tanzania',
            TH: 'Thailand',
            TL: 'Timor-Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad Og Tobago',
            TN: 'Tunisien',
            TR: 'Turkiet',
            TM: 'Turkmenistan',
            TC: 'Turks- og Caicosøerne',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukraine',
            AE: 'Forenede Arabiske Emirater',
            GB: 'Det Forenede Kongerige',
            US: 'Forenede Stater',
            UM: 'United States Outlying Islands',
            UY: 'Uruguay',
            UZ: 'Uzbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Viet Nam',
            VG: 'Jomfruøerne, Britiske',
            VI: 'Virgin Islands, U.S.',
            WF: 'Wallis Og Futuna',
            EH: 'Vestsahara',
            YE: 'Yemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe'
        },
    },
    nl: {
        _name: 'Nederlandse taal',
        timePickerModal: {
            title: 'Close In Time'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Neem Winst & Stoppen Verlies',
            takeProfit: 'Neem Winst ',
            stopLoss: 'Stoppen Verlies',
            updateOrder: 'Bestelling Bijwerken',
        },
        balanceModal: {
            balance: 'Balans',
            credit: 'krediet',
            equity: 'Equity',
            practice: 'PRAKTIJK',
            real: 'ECHT',
            account: 'ACCOUNT',
            invested: 'Geïnvesteerd',
            profit: 'Winst',
            loss: 'Verlies',
            margin: 'marge',
            marginLevel: 'Onderhoudsmarge',
            marginFree: 'Vrij Marge'
        },
        header: {
            cfd: 'CFD',
            platform: 'Platform',
            deposit: 'Storting'
        },
        sideMenu: {
            marketWatch: 'Markt bekijken',
            activeOrders: 'Open Posities',
            tradingHistory: 'Handel Geschiedenis',
            economicCalendar: 'Economisch Kalender',
            marketNews: 'Markt Nieuws'
        },
        closePositionModal: {
            closeOrder: 'Gesloten positie ',
            areYouSure: 'Weet je zeker dat je de positie sluit?',
            buy: 'Kopen',
            sell: 'Verkopen',
            yes: 'Ja',
            no: 'Nee',
        },
        pciDssModal: {
            desc: `Naleving van de Payment Card Industry Data Security Standard (PCI DSS) is vereist voor alle entiteiten die Visa/Master Card/Stripe-kaarthoudergegevens opslaan, verwerken of verzenden, inclusief financiële instellingen, handelaren en dienstverleners. Het is de wereldwijde beveiligingsstandaard voor alle entiteiten die kaarthoudergegevens en/of gevoelige authenticatiegegevens opslaan, verwerken of verzenden. PCI DSS stelt een basisniveau van bescherming voor consumenten vast en helpt fraude en datalekken in het hele betalingsecosysteem te verminderen.`
        },
        chart: {
            addNewChart: 'Nieuwe grafiek toevoegen',
        },
        symbolsModal: {
            watchlist: 'Volglijst',
            asset: 'Bedrijfsmiddel',
            price: 'Prijs',
            changePct: 'verandering 24u'
        },
        pendingModal: {
            pending: 'Laden',
            assetPrice: 'Bedrijfsmiddel Prijs',
            current: 'Actueel',
            revert: 'Terug naar Markt Prijs',
            automatically: 'Positie wordt automatisch geopend wanneer de prijs dit niveau bereikt'
        },
        orderMenu: {
            default: 'default',
            volume: 'Volume',
            lots: 'lots',
            units: 'units',
            currency: 'valuta',
            contractSize: 'Contractgrootte',
            position: 'Positie',
            margin: 'Marge',
            freeMargin: 'Vrij Marge',
            takeProfitStopLoss: 'Neem Winst & Stoppen Verlies',
            pending: 'Laden',
            market: 'Markt',
            leverage: 'Hefboom',
            spread: 'Verspreiding',
            notSet: 'Niet ingesteld',
            at: 'Bij',
            buy: 'kopen',
            sell: 'verkopen',
        },
        footer: {
            supportBanner: 'ELKE DAG, DE KLOK ROND',
            currentTime: 'ACTUEEL TIJD',
            liveChat: 'Live chatten'
        },
        statusBar: {
            balance: 'Balans',
            credit: 'Credit',
            equity: 'billijkheid',
            margin: 'Marge',
            marginLevel: 'Onderhoudsmarge',
            freeMargin: 'Vrij Marge',
            pnl: 'PNL',
            profitTotal: 'Winst totaal'
        },
        accountModal: {
            dateRegistered: 'Datum Geregistreerd',
            userId: 'Gebruiker ID',
            dashboard: 'Dashboard',
            personalData: 'Persoonlijke gegevens',
            deposit: 'Storting',
            withdrawFunds: 'Geld opnemen',
            savings: 'Spaargeld',
            settings: 'Instellingen',
            logout: 'Uitloggen'
        },
        depositModal: {
            BT_INVOICE: 'Enter amount and generate invoice',
            BT_TITLE: 'Our banking details',
            PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
            account: 'Account',
            bank_name: 'Bank Name',
            beneficiary: 'Beneficiary',
            beneficiary_address: 'Beneficiary Address',
            swift: 'Swift',
            reference: 'Reference',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Amount',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Description',
            TOKEN_PAYMENT_DETAILS: 'Payment Details',
            makeDeposit: 'Storting doen',
            practiceAccount: 'praktijk account',
            realAccount: 'Echte account',
            noWithdrawals: 'Geen opnames',
            easyWithdrawals: 'Gemakkelijke opnames',
            allAssets: 'Alle middelen beschikbaar',
            fullFledged: 'Full-ledged platform',
            fillUpTo: 'Fill Up To',
            freeReplenishment: 'Gratis aanvulling',
            topUp: 'Waardeer je account op',
            minimumAmount: 'Minimale bedrag',
            canSwitch: 'U kunt op elk moment tussen uw accounts schakelen'
        },
        gridsModal: {
            chartGrids: 'GRAFIEKRASTERS',
            chart1: '1 grafiek',
            chart2: '2 grafieken',
            chart3: '3 grafieken',
            chart4: '4 grafieken',
        },
        noConnectionModal: {
            connectionLost: 'Verbinding met de server is verbroken',
            retryNow: 'Probeer het nu opnieuw',
        },
        loginModal: {
            loginToTradeRoom: 'Inloggen op Traderoom',
            email: 'E-mailadres',
            enterEmail: 'Vul je e-mailadres in',
            twoFactor: '2FA-code (indien ingeschakeld)',
            twoFactorAuth: 'Tweefactorauthenticatie',
            password: 'Wachtwoord',
            yourPassword: 'Uw wachtwoord',
            signIn: 'Log in',
            dontHaveAccount: 'Heb je geen account?',
            restore: 'Herstellen',
            fullName: 'Voor-en achternaam',
            havePromo: 'Ik heb een promotiecode',
            promo: 'Promotiecode',
            login: 'Login',
            haveAccount: 'Heb je al een account?',
            hide: 'Zich verstoppen',
            goTo: 'Ga naar terug'
        },
        newPasswordModal: {
            setNewPassword: 'Nieuw wachtwoord instellen',
            newPassword: 'Nieuw wachtwoord',
            confirmNewPassword: 'Bevestig nieuw wachtwoord',
            setButton: 'Set'
        },
        registerModal: {
            repeatPassword: 'Repeat Password',
            createNewAccount: 'Maak een nieuw account aan',
            email: 'E-mailadres',
            firstName: 'Voornaam',
            lastName: 'Achternaam',
            country: 'Land',
            phone: 'Telefoon',
            password: 'Wachtwoord',
            createAccount: 'Maak account',
            currency: 'Valuta',
            privacyAndPolicy: "Privacy & Beleid",
            customerAgreement: 'Klantovereenkomst',

        },
        forgotPasswordModal: {
            forgotPassword: 'Wachtwoord vergeten?',
            submitEmail: 'Dien een e-mail in die is gebruikt voor registratie, controleer uw inbox en volg de instructies',
            submit: 'Indienen'
        },
        notifications: {
            error: 'Fout',
            success: 'Succes',
            deposit: 'Storting',
            withdrawal: 'Opname',
            depositApproved: 'Aanbetaling is goedgekeurd',
            depositDeclined: 'Aanbetaling is geweigerd',
            withdrawalApproved: 'Opname goedgekeurd',
            withdrawalDeclined: 'Opname geweigerd'
        },
        markets: {
            forex: 'Forex',
            stocks: 'Aandelen',
            commodities: 'Goederen',
            indices: 'Indices',
            crypto: 'Crypto',
            metals: 'Metalen',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'Kopen',
            sell: 'Verkopen',
            volume: 'Volume',
            entryPrice: 'Toegangs prijs',
            takeProfit: 'Winst nemen',
            stopLoss: 'Stop-Loss',
            maxPosition: 'Maximale positie',
            calculate: 'Berekenen',
            calculationsFor: 'Berekeningen voor',
            leverage: 'Hefboom',
            requiredMargin: 'Vereiste marge',
            profitFromTP: 'Profiteer van TP',
            lossFromSL: 'Verlies van SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Storting succesvol',
            text: 'Your deposit has been processed successfully!',
        },
        depositFailModal: {
            title: 'Storting mislukt',
            text: 'Uw storting is niet verwerkt.',
        },
        widgetMarketWatch: {
            marketWatch: 'Markt bekijken',
            search: 'Zoeken...',
        },
        widgetOrders: {
            commission: 'Commission',
            volume: 'Volume',
            margin: 'Margen',
            active: 'Actief',
            pending: 'Laden',
            activeOrders: 'Open posities',
            portfolio: 'Portefeuille',
            allPositions: 'Alle posities',
            noPositionsLine1: 'Je hebt geen open',
            noPositionsLine2: 'posities nog',
            show: 'Tonen',
            more: 'meer',
            less: 'minder',
            purchaseTime: 'Aankooptijd',
            closePosition: 'Positie sluiten',
            priceOpen: 'Aankoopprijs',
            priceSl: 'Stop Loss-prijs',
            priceTp: 'Winstprijs nemen',
            type: 'Positie Richting',
            pnl: 'Winst/verlies',
            cancelOrder: 'Annuleer bestelling',
            orderType: 'Order Type',
            cancelled: 'Geannuleerd',
            tradingHistory: 'Geschiedenis',
            noHistoryLine1: 'Je hebt er geen',
            noHistoryLine2: 'nog gesloten deals',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Economische kalender'
        },
        widgetMarketNews: {
            marketNews: 'Marktnieuws'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'Open posities',
            ordersHistory: 'Bestelgeschiedenis',
            id: 'ID',
            symbol: 'Symbool',
            type: 'Type',
            volume: 'Volume',
            openPrice: 'Open prijs',
            openTime: 'Open tijd',
            closePrice: 'Sluit prijs',
            closeTime: 'Sluitingstijd',
            sl: 'SL',
            tp: 'TP',
            price: 'Prijs',
            pnl: 'PnL',
            actions: 'Acties',
            edit: 'Bewerken',
            close: 'Sluiten',
            commission: 'Commissie',
            swap: 'Swap',
            swapLong: 'Ruil lang',
            swapShort: 'Wissel kort'
        },
        assetInfoModal: {
            assetInfo: 'Activa-info',
            tradingConditions: 'Handelsvoorwaarden',
            information: 'Sessie wijzigen',
            bid: 'Bod',
            ask: 'Vragen',
            sessionChange: 'Sessie wijzigen',
            tradeNow: 'Nu handelen',
            opens: 'Opent',
            closes: 'Sluit',
            at: 'bij',
            open247: 'Open 24/7',
            today: 'Vandaag',
            tomorrow: 'Morgen',
            sunday: 'Zondag',
            monday: 'Maandag',
            tuesday: 'Dinsdag',
            wednesday: 'Woensdag',
            thursday: 'Donderdag',
            friday: 'Vrijdag',
            saturday: 'Zaterdag',
            contractSpecification: 'Contractspecificatie',
            symbol: 'Symbool',
            name: 'Naam',
            market: 'Markt',
            baseCurrency: 'Basisvaluta',
            digits: 'Cijfers',
            lotSize: 'Kavelgrootte',
            lotStep: 'Lot stap',
            minVolume: 'Min Volume',
            maxVolume: 'Max Volume',
            leverage: 'Hefboom',
            commission: 'Commissie',
            swapLong: 'Ruil lang',
            swapShort: 'Wissel kort',
            schedule: 'Schema',
            weekday: 'Weekdag',
            tradingTime: 'Handelstijd',
            closed: 'gesloten',
            sell: 'Verkopen',
            buy: 'Kopen',
            low: 'Laag',
            high: 'Hoog',
            oneHourChange: '1 uur wissel',
            oneDayChange: '1 dag verandering',
            oneWeekChange: '1 week wissel',
            oneMonthChange: '1 maand verandering',
            threeMonthsChange: '3 maanden verandering',
            oneYearChange: '1 jaar verandering',
            loading: 'Bezig met laden...',
        },
        autoTrader: {
            days: 'days',
            title: 'Auto Trader',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Risk Level: Low',
            riskLevelMedium: 'Risk Level: Medium',
            riskLevelHigh: 'Risk Level: High',
            perDay: 'Trades per day:',
            desc: `Trading CFDs and other leveraged products can lead to losses. Before trading, clients should
                read the
                relevant risk statements on our Risk Disclosure page. Automated trading does not guarantee results. The
                company accepts no responsibility for the loss of funds in automatic trading. Please make sure that you
                fully understand the risks and take measures to manage risks.`,
            accept: 'Accept',
            save: 'Opslaan',
            cancel: 'Annuleren',
        },
        cardBox: {
            name: 'Naam',
            cardNumber: 'Kaartnummer',
            expiration: 'Vervaldatum (mm/jj)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "Handel",
            startTrading: 'Begin met handelen',
            deposit: 'Storting',
            dashboard: 'Dashboard',
            personalInfo: 'Persoonlijke informatie',
            contactInfo: 'Contactgegevens',
            withdrawal: 'Opname',
            verification: 'Verificatie',
            accounts: 'Rekeningen',
            liveChat: 'Live chatten',
            savings: 'Spaargeld',
            settings: 'Instellingen',
            logOut: 'Uitloggen',
        },
        fullScreenDeposit: {
            methods: 'METHODS',
            confirm: 'Confirm in the application of your bank',
            sendOtp: 'Send otp',
            otpCode: 'Otp code',
            addWalletAddress: 'Wallet for this deposit method is not set. Please contact your agent or support team.',
            addressReceipt: 'Please wait for the address to load',
            makeDeposit: 'STORTING DOEN',
            instant: 'Instant',
            minutes: 'minuten',
            hours: 'uur',
            days: 'dagen',
            amount: 'Bedrag',
            continue: 'Doorgaan met',
            qrCode: 'QR CODE',
            depositAddress: 'BETALINGS ADRES',
            copy: 'Kopiëren',
            last: 'LAATSTE',
            deposits: 'DEPOSITO\'S',
            time: 'Tijd',
            currency: 'Valuta',
            status: 'Toestand',
            info: 'Info',
        },
        fullScreenCcExt: {
            pleaseWait: 'Even geduld aub. Verbinding maken met betalingsdienstaanbieder...',
            cardPayment: 'Kaart betaling',
            inOrder: 'Om het bedrag te verzenden',
            pleaseProvide: 'geef kaartgegevens op:'
        },
        fullScreenDashboard: {
            completeVerification: 'Please Complete Verification',
            noOrderYet: 'No order yet',
            totalBalance: 'Eigen Vermogen',
            totalPnl: 'Totaal PNL',
            profitableOrders: 'Winstgevende posties',
            roi: 'ROI',
            activityLog: 'Activiteiten logboek',
            loginFromIp: 'Inloggen vanaf IP',
            tradingResults: 'Handelsresultaten',
            week: 'Week',
            month: 'Maand',
            year: 'Jaar',
            successRate: 'Slaagkans',
            closedWithProfit: 'Gesloten met winst',
            closedWithLoss: 'Gesloten met verlies',
            account: 'Account',
            balance: 'Balans',
            leverage: 'Hefboom',
            credit: 'Credit',
            tradeNow: 'Nu handelen',
            usingCurrentRate: 'met huidige wisselkoers',
            totalDeposits: 'Total Deposits'
        },
        fullScreenPersonal: {
            personalDetails: 'Persoonlijke gegevens',
            profilePhoto: 'Profielfoto',
            firstName: 'Voornaam',
            country: 'Land',
            lastName: 'Achternaam',
            address: 'Adres',
            email: 'E-mail',
            phone: 'Telefoonnummer',
            saveChanges: 'Wijzigingen opslaan',
            state: 'Stad',
            postcode: 'Postcode',
            created: "Account aangemaakt op",
        },
        fullScreenAvatar: {
            dropFile: 'Zet een bestand in de cirkel hierboven om te uploaden',
            notAllowed: 'Het is niet toegestaan ​​om te publiceren',
            notAllowedLine1: 'Foto\'s van expliciet seksuele of pornografische aard',
            notAllowedLine2: 'Afbeeldingen gericht op het aanzetten tot etnische of rassenhaat of agressie',
            notAllowedLine3: 'Foto\'s met personen jonger dan 18 jaar',
            notAllowedLine4: 'Auteursrechtelijk beschermde foto\'s van derden',
            notAllowedLine5: 'Afbeeldingen groter dan 5 MB en in een ander formaat dan JPG, GIF of PNG',
            requirements: 'Je gezicht moet duidelijk zichtbaar zijn op de foto. Alle door jou geüploade foto\'s en video\'s moeten aan deze eisen voldoen, anders kunnen ze worden verwijderd.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Please contact your account manager for withdrawal details',
            cryptocurrency: 'Cryptocurrency',
            withdrawal: 'Uitbetalen',
            requestNewWithdrawal: 'EEN NIEUWE OPNAME AANVRAGEN',
            amount: 'Bedrag',
            currentBalance: 'Uw huidige rekeningsaldo is',
            withdrawAll: 'Alles uitlaten betalen',
            requestWithdrawal: 'Uitbetaling aanvragen',
            last: 'LAATSTE',
            withdrawalRequests: 'INTREKKING VERZOEKEN',
            time: 'Tijd',
            currency: 'Valuta',
            info: 'Info',
            status: 'Toestand',
            bank: 'Bank',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            card: 'Kaart',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Uitbetalingsdetails',
            address: 'Portemonnee-adres',
            bankAccount: 'Rekeningnummer',
            bankHolder: 'Naam rekeninghouder',
            bankIban: 'IBAN',
            bankSwift: 'Bank SWIFT code',
            recipientAddress: 'Ontvangeradres',
            routingNumber: 'Routingsnummer',
            bankBeneficiary: 'Naam van bank van begunstigde',
            bankAddress: 'Bankadres',
            bankCode: 'Bankcode',
            desc: 'Beschrijving',
            cardNumber: 'Kaartnummer',
            cardHolder: 'Naam pashouder',
        },

        fullScreenVerification: {
            dragAndDrop: 'slepen en neerzetten van het document naar dit gebied',
            documentsList: 'LIJST MET GEUPLOAD DOCUMENTEN',
            document: 'Document',
            timeUploaded: 'Tijd geüpload',
            timeProcessed: 'Tijd verwerkt',
            status: 'Toestand',
            types: ['Bewijs van Id', 'Bewijs van Residentie', 'Creditcard voorzijde', 'Creditcard achterzijde', 'Bewijs van identiteitsbewijs achterzijde', 'Selfie'],
            types2: ['Proof of identification (POI)', 'Proof of residential address', 'Credit Card Front', 'Credit Card Back', 'Proof of identification Back', 'Selfie with ID'],
            upload: 'Uploaden',
            isConfirmed: 'bevestigd',
            uploadAnotherDocument: 'Nog een document uploaden',
            isUnderReview: 'wordt beoordeeld',
            questionnaire: 'Vragenlijst',
            sendAnswers: 'Send Answers',
            progressBarOf: 'van',
            progressBarText: 'Van uw documenten zijn geüpload en bevestigd'
        },
        fullScreenAccounts: {
            manageAccounts: 'Geld overboeken tussen uw rekeningen',
            transferFrom: 'Van account',
            transferTo: 'Verklaren',
            amount: 'Bedrag',
            makeTransfer: 'Overboeking maken',
            yourAccounts: 'Uw rekeningen',
            account: 'Account',
            currency: 'Valuta',
            balance: 'Balans',
            credit: 'Credit',
            makeActive: 'Maak actief',
            active: 'Actief'
        },
        fullScreenSupport: {
            sendMessage: 'Bericht versturen'
        },
        fullScreenSavings: {
            pct_0: 'Flexibel',
            pct_30: '1 maand vergrendeld',
            pct_90: '1 Kwart vergrendeld',
            pct_180: '6 maanden vergrendeld',
            pct_365: '1 jaar vergrendeld',
            savings: 'Besparingen',
            detailedInformation: 'Gedetailleerde informatie',
            pleaseSelect: 'Selecteer een valuta en een periode',
            openSavings: 'Spaargeld openen',
            currentTime: 'Huidige tijd',
            releaseTime: 'Vrijgavetijd',
            currencyChosen: 'Valuta gekozen',
            periodChosen: 'Periode gekozen',
            yourRate: 'Uw tarief',
            yourEarnings: 'Uw verdiensten',
            accountFrom: 'Rekening om geld van over te boeken',
            enterAmount: 'Voer een bedrag in',
            in: 'In',
            openAndInvest: 'Spaarrekening openen en beleggen',
            investment: 'Investering',
            period: 'Periode',
            daysTotal: 'Dagen totaal',
            finalEarnings: 'Eindinkomsten',
            created: 'gemaakt',
            daysElapsed: 'Verstreken dagen',
            days: 'dagen',
            earnings: 'Verdiensten',
            fundsReleasedIn: 'Fondsen vrijgegeven in',
            claim: 'Beweren',
        },
        fullScreenSettings: {
            updatePassword: 'VERNIEUW WACHTWOORD',
            currentPassword: 'Huidig ​​wachtwoord',
            newPassword: 'Nieuw wachtwoord',
            changePassword: 'Verander wachtwoord',
            activationForGoogle: 'Activation code for Google Authenticator',
            activateAndEnter: 'Activeer Authenticator en voer een gegenereerde code in het onderstaande veld in',
            qrCode: 'QR Code',
            activateProtection: 'Activeer 2FA-beveiliging',
            protectionActive: '2FA-beveiliging is actief',
            twoFactorCode: '2FA-Code',
            disableProtection: 'Schakel 2FA-beveiliging uit',
            language: 'Taal',
            dashboardCurrency: 'Dashboardvaluta',
            confirmNewPassword: 'Bevestig nieuw wachtwoord',
        },
        fullScreenReferrals: {
            yourLink: 'JOUW VERWIJSLINK',
            referredFriends: 'VERWEZEN VRIENDEN',
            id: 'ID',
            name: 'Naam',
            registered: 'geregistreerd',
            level: 'Niveau',
            payout: 'Uitbetaling',
            lastPayouts: 'LAATSTE VERWIJZINGSUITBETALINGEN',
            time: 'Tijd',
            amount: 'Bedrag',
            currency: 'Valuta',
            info: 'Info'
        },
        months: {
            Jan: 'Jan',
            Feb: 'Feb',
            Mar: 'Mar',
            Apr: 'Apr',
            May: 'Mei',
            Jun: 'Jun',
            Jul: 'Jul',
            Aug: 'Aug',
            Sep: 'Sep',
            Oct: 'Okt',
            Nov: 'Nov',
            Dec: 'Dec'
        },
        orderTypes: ['Kopen', 'Verkopen', 'Kooplimiet', 'Verkooplimiet', 'Koopstop', 'Verkoopstop'],
        statuses: ['In behandeling', 'Goedgekeurd', 'Geweigerd', 'Bezig met verwerken'],
        errors: {
            NOT_FOUND: 'Verzoek fout',
            FORBIDDEN: 'Toegang geweigerd. Gelieve opnieuw in te loggen',
            SERVER_ERROR: 'Actie: mislukt. Probeer het later opnieuw',
            INVALID_ACCOUNT: 'Sessie verlopen. Gelieve opnieuw in te loggen',
            INVALID_EMAIL: 'Ongeldig e-mailadres',
            INVALID_PHONE: 'Ongeldig telefoonnummer',
            INVALID_COUNTRY: 'Ongeldig land',
            INVALID_LOGIN: 'Ongeldige inloggegevens',
            USER_BLOCKED: 'Account is geblokkeerd. Neem contact op met ondersteuning',
            USER_REGISTERED: 'Gebruiker met ingevoerd e-mailadres of telefoonnummer is al geregistreerd',
            INVALID_USER: 'Gebruiker is niet gevonden',
            REJECTED: 'Bediening is niet toegestaan ​​vanwege platformbeperkingen',
            INVALID_OLD_PASSWORD: 'Oud wachtwoord is ongeldig',
            INVALID_SYMBOL: 'Ongeldig handelssymbool',
            INVALID_SYMBOL_PRECISION: 'Invalid symbol precision',
            INVALID_FRAME: 'Ongeldig kaartframe',
            INVALID_CURRENCY: 'Ongeldige valuta',
            INVALID_PRODUCT: 'Ongeldig handelstype account',
            INSUFFICIENT_FUNDS: 'Onvoldoende middelen',
            INVALID_WITHDRAWAL: 'Opname niet gevonden',
            INVALID_STATUS: 'Ongeldige status',
            INVALID_FILE: 'Ongeldige bestandsextensie of bestand is te groot',
            INVALID_DOCUMENT: 'Document niet gevonden',
            INVALID_NAME: 'Ongeldige naam',
            INVALID_LOT_SIZE: 'Ongeldige partijgrootte',
            INVALID_LOT_STEP: 'Ongeldige partijstap',
            INVALID_MIN_VOLUME: 'Ongeldig minimaal volume',
            INVALID_MAX_VOLUME: 'Ongeldig maximaal volume',
            INVALID_GROUP: 'Ongeldige groep',
            INVALID_SPREAD: 'Ongeldige spreiding',
            REQUEST_TIMEOUT: 'Verzoek time-out. Probeer het later opnieuw',
            EXISTING_OPEN_ORDERS: 'U heeft een openstaande bestelling, sluit eerst de bestelling voor deze gebruiker en probeer het opnieuw.',
            INVALID_TYPE: 'Ongeldig ordertype',
            INVALID_VOLUME: 'Ongeldig bestelvolume',
            INVALID_SL: 'Ongeldige stop loss-waarde',
            INVALID_TP: 'Ongeldige winstwaarde',
            INVALID_PRICE: 'Ongeldige bestelprijs',
            INVALID_EXPIRATION: 'Ongeldige vervaldatum van bestelling',
            NOT_ENOUGH_MARGIN: 'Niet genoeg marge',
            INVALID_ORDER: 'Bestelling niet gevonden',
            MARKET_CLOSED: 'Handelen is niet mogelijk. Probeer het opnieuw',
            INVALID_BALANCE_TYPE: 'Ongeldig type saldobewerking',
            INVALID_HASH: 'Ongeldige hash',
            HASH_EXPIRED: 'De link voor het opnieuw instellen van uw wachtwoord is verlopen. Vraag een nieuwe aan',
            INVALID_CODE: 'Ongeldige 2FA-code',
            CHAT_DISABLED: 'Toegang tot de supportchat is gesloten',
            WITHDRAWAL_NOT_ALLOWED: 'Toegang tot de opnames is gesloten',
            TRADING_DISABLED: 'Handelen is uitgeschakeld voor lopende rekening',
            PENDING_DEPOSITS_LIMIT: 'Slechts één lopende storting toegestaan',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Slechts één lopende opname toegestaan',
            LOCAL_DEPOSIT_ONLY_REAL: 'Storting is alleen beschikbaar voor een echt account',
            LOCAL_DEPOSIT_POSITIVE: 'Het aanbetalingsbedrag moet meer dan 0 zijn',
            LOCAL_INVALID_CARD_NUMBER: 'Ongeldig kaart nummer',
            LOCAL_INVALID_CARD_EXPIRATION: 'Ongeldige kaart vervalt',
            LOCAL_INVALID_CVC: 'Ongeldige CVV/CVC code',
            LOCAL_PASSWORDS_NOT_MATCH: 'Wachtwoorden komen niet overeen, gelieve opnieuw in te voeren',
            LOCAL_NO_TRADING_ACCOUNTS: 'U heeft geen handelsaccounts beschikbaar. Neem contact op met de ondersteuning',
            LOCAL_MIN_DEPOSIT: 'Minimum deposit is $',
            LOCAL_MIN_WITHDRAWAL: 'Minimum opnamebedrag is $',
            LOCAL_INVALID_FILE_TYPE: 'Bestands formaat niet ondersteund. Gelieve alleen JPG, PNG of PDF bij te voegen',
            LOCAL_MIN_STAKING: 'Minimum deposit for this currency is ',
            FLOOD_REJECTED: 'You are sending messages too often. Please try again later'
        },
        countries: {
            AF: 'Afghanistan',
            AX: 'Aland-eilanden',
            AL: 'Albanië',
            DZ: 'Algerije',
            AS: 'Amerikaans Samoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarctica',
            AG: 'Antigua En Barbuda',
            AR: 'Argentinië',
            AM: 'Armenië',
            AW: 'Aruba',
            AU: 'Australië',
            AT: 'Oostenrijk',
            AZ: 'Azerbeidzjan',
            BS: 'Bahamas',
            BH: 'Bahrein',
            BD: 'Bangladesh',
            BB: 'Barbados',
            BY: 'Wit-Rusland',
            BE: 'België',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Bhutan',
            BO: 'Bolivia',
            BA: 'Bosnië en Herzegovina',
            BW: 'Botswana',
            BV: 'Bouvet-eiland',
            BR: 'Brazilië',
            IO: 'Brits Territorium in de Indische Oceaan',
            BN: 'Brunei Darussalam',
            BG: 'Bulgarije',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Cambodja',
            CM: 'Kameroen',
            CA: 'Canada',
            CV: 'Kaapverdië',
            KY: 'Kaaimaneilanden',
            CF: 'Centraal-Afrikaanse Republiek',
            TD: 'Tsjaad',
            CL: 'Chili',
            CN: 'China',
            CX: 'Christmas Island',
            CC: 'Cocos (Keeling) Islands',
            CO: 'Colombia',
            KM: 'Comoren',
            CG: 'Congo',
            CD: 'Congo, Democratische Republiek',
            CK: 'Cook Islands',
            CR: 'Costa Rica',
            CI: 'Ivoorkust',
            HR: 'Kroatië',
            CU: 'Cuba',
            CY: 'Cyprus',
            CZ: 'Tsjechië',
            DK: 'Denemarken',
            DJ: 'Djibouti',
            DM: 'Dominica',
            DO: 'Dominicaanse Republiek',
            EC: 'Ecuador',
            EG: 'Egypte',
            SV: 'El Salvador',
            GQ: 'Equatoriaal-Guinea',
            ER: 'Eritrea',
            EE: 'Estland',
            ET: 'Ethiopië',
            FK: 'Falklandeilanden (Malvinas)',
            FO: 'Faeröer Eilanden',
            FJ: 'Fiji',
            FI: 'Finland',
            FR: 'Frankrijk',
            GF: 'Frans-Guyana',
            PF: 'Frans-Polynesië',
            TF: 'Franse zuidelijke gebieden',
            GA: 'Gabon',
            GM: 'Gambia',
            GE: 'Georgië',
            DE: 'Duitsland',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Griekenland',
            GL: 'Groenland',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haïti',
            HM: 'Heard Island & Mcdonald Islands',
            VA: 'Heilige Stoel (Vaticaanstad)',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Hongarije',
            IS: 'IJsland',
            IN: 'India',
            ID: 'Indonesië',
            IR: 'Iran',
            IQ: 'Irak',
            IE: 'Ierland',
            IM: 'Isle Of Man',
            IL: 'Israëll',
            IT: 'Italië',
            JM: 'Jamaica',
            JP: 'Japan',
            JE: 'Jersey',
            JO: 'Jordanië',
            KZ: 'Kazachstan',
            KE: 'Kenia',
            KI: 'Kiribati',
            KR: 'Korea',
            KW: 'Koeweit',
            KG: 'Kirgizië',
            LA: 'Lao Democratische Volksrepubliek',
            LV: 'Letland',
            LB: 'Libanon',
            LS: 'Lesotho',
            LR: 'Liberia',
            LY: 'Libische Arabische Jamahiriya',
            LI: 'Liechtenstein',
            LT: 'Litouwen',
            LU: 'Luxembourg',
            MO: 'Macao',
            MK: 'Macedonië',
            MG: 'Madagascar',
            MW: 'Malawi',
            MY: 'Maleisië',
            MV: 'Malediven',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marshalleilanden',
            MQ: 'Martinique',
            MR: 'Mauritanië',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Mexico',
            FM: 'Micronesië',
            MD: 'Moldavië',
            MC: 'Monaco',
            MN: 'Mongolië',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Morokko',
            MZ: 'Mozambique',
            MM: 'Myanmar',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Nederland',
            AN: 'Nederlandse Antillen',
            NC: 'Nieuw-Caledonië',
            NZ: 'Nieuw-Zeeland',
            NI: 'Nicaragua',
            NE: 'Niger',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Norfolkeiland',
            MP: 'Noordelijke Marianen',
            NO: 'Noorwegen',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Palestijns grondgebied, bezet',
            PA: 'Panama',
            PG: 'Papoea-Nieuw-Guinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Filipijnen',
            PN: 'Pitcairn',
            PL: 'Polen',
            PT: 'Portugal',
            PR: 'Puerto Rico',
            QA: 'Qatar',
            RE: 'Reunion',
            RO: 'Roemenië',
            RU: 'Rusland',
            RW: 'Rwanda',
            BL: 'Saint Barthelemy',
            SH: 'Saint Helena',
            KN: 'Saint Kitts En Nevis',
            LC: 'Saint Lucia',
            MF: 'Saint Martin',
            PM: 'Saint Pierre En Miquelon',
            VC: 'Saint Vincent En Grenadines',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tomé en Principe',
            SA: 'Saoedi-Arabië',
            SN: 'Senegal',
            RS: 'Servië',
            SC: 'Seychellen',
            SL: 'Sierra Leone',
            SG: 'Singapore',
            SK: 'Slovakije',
            SI: 'Slovenië',
            SB: 'Salomonseilanden',
            SO: 'Somalië',
            ZA: 'Zuid-Afrika',
            GS: 'Zuid-Georgië en Sandwich-eilanden',
            ES: 'Spanje',
            LK: 'Sri Lanka',
            SD: 'Soedan',
            SR: 'Suriname',
            SJ: 'Svalbard En Jan Mayen',
            SZ: 'Swaziland',
            SE: 'Zweden',
            CH: 'Zwitserland',
            SY: 'Syrische Arabische Republiek',
            TW: 'Taiwan',
            TJ: 'Tadzjikistan',
            TZ: 'Tanzania',
            TH: 'Thailand',
            TL: 'Timor Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad En Tobago',
            TN: 'Tuvalu',
            TR: 'Turkije',
            TM: 'Turkmenistan',
            TC: 'Turks- en Caicoseilanden',
            TV: 'Tuvalu',
            UG: 'Oeganda',
            UA: 'Oekraïne',
            AE: 'Verenigde Arabische Emiraten',
            GB: 'Verenigd Koninkrijk',
            US: 'Verenigde Staten',
            UM: 'Verenigde Staten perifere eilanden',
            UY: 'Uruguay',
            UZ: 'Oezbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Vietnam',
            VG: 'Maagdeneilanden, Brits',
            VI: 'Maagdeneilanden, VS',
            WF: 'Wallis En Futuna',
            EH: 'Westelijke Sahara',
            YE: 'Jemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe'
        },
    },
    sa: {
        _name: 'عربي',
        timePickerModal: {
            title: 'Close In Time'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'جني الربح وإيقاف الخسارة',
            takeProfit: 'جني الربح',
            stopLoss: 'إيقاف الخسارة',
            updateOrder: 'تحديث الطلب',
        },
        balanceModal: {
            balance: 'الرصيد',
            credit: 'الائتمان',
            equity: 'حقوق الملكية',
            practice: 'تدريب',
            real: 'حقيقى',
            account: 'حساب',
            invested: 'المُستثمَر',
            profit: 'الربح',
            loss: 'الخسارة',
            margin: 'الهامش',
            marginLevel: 'مستوى الهامش',
            marginFree: 'الهامش الحر'
        },
        header: {
            cfd: 'العقود مقابل الفروقات (CFD)',
            platform: 'المنصة',
            deposit: 'الإيداع'
        },
        sideMenu: {
            marketWatch: 'مراقبة السوق',
            activeOrders: 'الطلبات النشطة',
            tradingHistory: 'تاريخ التداول',
            economicCalendar: 'التقويم الاقتصادي',
            marketNews: 'أخبار السوق'
        },
        closePositionModal: {
            closeOrder: 'إغلاق الموضع',
            areYouSure: 'هل أنت متأكد من إغلاق الموضع',
            buy: 'شراء',
            sell: 'بيع',
            yes: 'نعم',
            no: 'لا',
        },
        pciDssModal: {
            desc: `يُطلب الامتثال لمعيار أمان بيانات صناعة بطاقات الدفع (PCI DSS) من جميع الكيانات التي تخزن أو تعالج أو تنقل بيانات حاملي بطاقات Visa/Master Card/Stripe، بما في ذلك المؤسسات المالية والتجار ومقدمي الخدمات. إنه المعيار الأمني العالمي لجميع الكيانات التي تخزن أو تعالج أو تنقل بيانات حاملي البطاقات و/أو بيانات المصادقة الحساسة. يحدد معيار PCI DSS مستوى قاعديًا من الحماية للمستهلكين ويساعد في تقليل الاحتيال وانتهاكات البيانات عبر النظام البياني للدفع برمته.`
        },
        chart: {
            addNewChart: 'إضافة جدول بياني جديد',
        },
        symbolsModal: {
            watchlist: 'قائمة المراقبة',
            asset: 'الأصل',
            price: 'السعر',
            changePct: 'التغيير إلى نظام 24 ساعة'
        },
        pendingModal: {
            pending: 'قيد الانتظار',
            assetPrice: 'سعر الأصل',
            current: 'الحالى',
            revert: 'العودة إلى سعر السوق',
            automatically: 'سوف يتم فتح الموضع بشكل تلقائى عندما يصل السعر إلى هذا المستوى'
        },
        orderMenu: {
            default: 'default',
            volume: 'الحجم',
            lots: 'العقود',
            units: 'الوحدات',
            currency: 'العملة',
            contractSize: 'حجم العقد',
            position: 'الموضع',
            margin: 'الهامش',
            freeMargin: 'الهامش الحر',
            takeProfitStopLoss: 'جني الربح وإيقاف الخسارة',
            pending: 'قيد الانتظار',
            market: 'السوق',
            leverage: 'الرافعة المالية',
            spread: 'عرض السعر',
            notSet: 'لم يتم تحديده',
            at: 'حتى',
            buy: 'شراء',
            sell: 'بيع',
        },
        footer: {
            supportBanner: 'كل يوم ، وكل الساعة',
            currentTime: 'الوقت الحالي',
            liveChat: 'محادثة مباشرة'
        },
        statusBar: {
            balance: 'الرصيد',
            credit: 'الائتمان',
            equity: 'حقوق الملكية',
            margin: 'الهامش',
            marginLevel: 'مستوى الهامش',
            freeMargin: 'الهامش الحر',
            pnl: 'قيمة PnL',
            profitTotal: 'قيمة PnL مدى الحياة'
        },
        accountModal: {
            dateRegistered: 'تاريخ التسجيل',
            userId: 'رقم تعريف المستخدم',
            dashboard: 'لوحة التحكُّم',
            personalData: 'البيانات الشخصية',
            deposit: 'الإيداع',
            withdrawFunds: 'سحب الأموال',
            savings: 'المُدخَرات',
            settings: 'الإعدادات',
            logout: 'تسجيل الخروج'
        },
        depositModal: {
            BT_INVOICE: 'Enter amount and generate invoice',
            BT_TITLE: 'Our banking details',
            PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
            account: 'Account',
            bank_name: 'Bank Name',
            beneficiary: 'Beneficiary',
            beneficiary_address: 'Beneficiary Address',
            swift: 'Swift',
            reference: 'Reference',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Amount',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Description',
            TOKEN_PAYMENT_DETAILS: 'Payment Details',
            makeDeposit: 'برجاء القيام بعملية إيداع',
            practiceAccount: 'حساب تجريبي',
            realAccount: 'حساب حقيقي',
            noWithdrawals: 'لا توجد عمليات سحب',
            easyWithdrawals: 'عمليات سحب يسيرة',
            allAssets: 'جميع الأصول المتاحة',
            fullFledged: 'منصة كاملة متكاملة',
            fillUpTo: 'يعمل حتى',
            freeReplenishment: 'تجديد مجاني',
            topUp: 'اشحن حسابك',
            minimumAmount: 'الحد الأدنى للقيمة المالية',
            canSwitch: 'يمكنك التبديل بين حساباتك في أي وقت تُريد'
        },
        gridsModal: {
            chartGrids: 'شبكات الرسم البياني',
            chart1: '1 رسم بيانى',
            chart2: '2 رسم بيانى',
            chart3: '3 رسوم بيانية',
            chart4: '4 رسوم بيانية',
        },
        noConnectionModal: {
            connectionLost: 'لقد تم فقد الاتصال بالخادم',
            retryNow: 'أعد المحاولة الآن',
        },
        loginModal: {
            loginToTradeRoom: 'تسجيل الدخول إلى Traderoom',
            email: 'عنوان البريد الإلكتروني',
            enterEmail: 'أدخل بريدك الإلكتروني',
            twoFactor: 'كود 2FA (إذا كان مُتاح)',
            twoFactorAuth: 'تحقيق الهوية بخطوتين',
            password: 'كلمة المرور',
            yourPassword: 'كلمة مرورك',
            signIn: 'سجِّل الدخول',
            dontHaveAccount: 'ليس لديك حساب؟',
            restore: 'إستعادة حسابى',
            fullName: 'الاسم بالكامل',
            havePromo: 'لديَّ رمز ترويجي',
            promo: 'الرمز الترويجي',
            login: 'تسجيل الدخول',
            haveAccount: 'هل لديك حساب بالفعل؟',
            hide: 'إخفاء',
            goTo: 'العودة إلى الخلف'
        },
        newPasswordModal: {
            setNewPassword: 'حدِّد كلمة مرور جديدة',
            newPassword: 'كلمة مرور جديدة',
            confirmNewPassword: 'تأكيد كلمة المرور الجديدة',
            setButton: 'تعيين'
        },
        registerModal: {
            repeatPassword: 'Repeat Password',
            createNewAccount: 'إنشاء حساب جديد',
            email: 'عنوان البريد الإلكتروني',
            firstName: 'الاسم الأول',
            lastName: 'اللقب',
            country: 'الدولة',
            phone: 'رقم الهاتف',
            password: 'كلمة المرور',
            createAccount: 'إنشاء حساب',
            currency: 'العملة',
            privacyAndPolicy: "الخصوصية والسياسة",
            customerAgreement: 'اتفاقية العميل',

        },
        forgotPasswordModal: {
            forgotPassword: 'هل نسيت كلمة المرور؟',
            submitEmail: 'من فضلك، قم بالتأكيد على البريد الإلكتروني المُستخَدم للتسجيل ، وتحقق من صندوق الوارد لديك، ثم اتبع التعليمات الملحَقة',
            submit: 'تأكيد'
        },
        notifications: {
            error: 'خطأ',
            success: 'تم بنجاح',
            deposit: 'الإيداع',
            withdrawal: 'سحب',
            depositApproved: 'تم التصديق على الإيداع',
            depositDeclined: 'تم رفض الإيداع',
            withdrawalApproved: 'تم التصديق على السحب',
            withdrawalDeclined: 'تم رفض السحب'
        },
        markets: {
            forex: 'فوركس',
            stocks: 'الأسهم',
            commodities: 'السلع',
            indices: 'المؤشرات',
            crypto: 'العملات المشفَّرة',
            metals: 'المعادن',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'شراء',
            sell: 'بيع',
            volume: 'الحجم',
            entryPrice: 'سعر الدخول',
            takeProfit: 'جني الربح',
            stopLoss: 'إيقاف الخسارة',
            maxPosition: 'أقصى موضع',
            calculate: 'احسب',
            calculationsFor: 'حسابات',
            leverage: 'الرافعة المالية',
            requiredMargin: 'الهامش المطلوب',
            profitFromTP: 'الربح من TP',
            lossFromSL: 'الخسارة من SL',
            roe: 'سعر الصرف (ROE)',
            profitCalculator: 'حساب الربح',
        },
        depositSuccessModal: {
            title: 'لقد تم الإيداع بنجاح',
            text: 'لقد تمت معالجة إيداعك بنجاح!',
        },
        depositFailModal: {
            title: 'لقد فشل الإيداع',
            text: 'لم تتم معالجة إيداعك.',
        },
        widgetMarketWatch: {
            marketWatch: 'مراقبة السوق',
            search: 'بحث...',
        },
        widgetOrders: {
            commission: 'العمولة',
            volume: 'الحجم',
            margin: 'الهامش',
            active: 'نشط',
            pending: 'قيد الانتظار',
            activeOrders: 'الطلبات النشطة',
            portfolio: 'المَحفَظَة',
            allPositions: 'جميع المواضع',
            noPositionsLine1: 'ليس لديك موضع مفتوح',
            noPositionsLine2: 'المواضع حتى الآن',
            show: 'عرض',
            more: 'أكثر',
            less: 'أقل',
            purchaseTime: 'وقت الشراء',
            closePosition: 'إغلاق الموضع',
            priceOpen: 'سعر الشراء',
            priceSl: 'سعر إيقاف الخسارة',
            priceTp: 'سعر جني الأرباح',
            type: 'اتجاه الموضع',
            pnl: 'الربح/الخسارة',
            cancelOrder: 'إلغاء الطلب',
            orderType: 'نوع الطلب',
            cancelled: 'تم الإلغاء',
            tradingHistory: 'تاريخ التداول',
            noHistoryLine1: 'ليس لديك أى',
            noHistoryLine2: 'صفقات مُغلَقة بعد',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'التقويم الاقتصادي'
        },
        widgetMarketNews: {
            marketNews: 'أخبار السوق'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
        timedOrder: 'Timed Order',
            ordersActive: 'الطلبات النشطة',
            ordersHistory: 'سجل الطلبات',
            id: 'رقم التعريف',
            symbol: 'الرمز',
            type: 'النوع',
            volume: 'الحجم',
            openPrice: 'سعر الفتح',
            openTime: 'وقت الفتح',
            closePrice: 'سعر الإغلاق',
            closeTime: 'وقت الإغلاق',
            sl: 'SL',
            tp: 'TP',
            price: 'السعر',
            pnl: 'قيمة PnL',
            actions: 'الإجراءات',
            edit: 'تعديل',
            close: 'إغلاق',
            commission: 'العمولة',
            swap: 'مُبادلة',
            swapLong: 'مقايضة إلى وضع الطويل',
            swapShort: 'مُقايضة إلى وضع القصير'
        },
        assetInfoModal: {
            assetInfo: 'معلومات الأصول',
            tradingConditions: 'شروط التداول',
            information: 'تغيير الجلسة',
            bid: 'العطاء',
            ask: 'طلب',
            sessionChange: 'تغيير الجلسة',
            tradeNow: 'تداول الآن',
            opens: 'مرات الفتح',
            closes: 'مرات الإغلاق',
            at: 'حتى',
            open247: 'مفتوح 24/7',
            today: 'اليوم',
            tomorrow: 'الغد',
            sunday: 'الأحد',
            monday: 'الإثنين',
            tuesday: 'الثلاثاء',
            wednesday: 'الأربعاء',
            thursday: 'الخميس',
            friday: 'الجمعة',
            saturday: 'السبت',
            contractSpecification: 'مواصفات العقد',
            symbol: 'الرمز',
            name: 'الاسم',
            market: 'السوق',
            baseCurrency: 'العملة الأساسية',
            digits: 'الأرقام',
            lotSize: 'حجم العقد',
            lotStep: 'درجة العقد',
            minVolume: 'أدنى حجم',
            maxVolume: 'أقصى حجم',
            leverage: 'الرافعة المالية',
            commission: 'العمولة',
            swapLong: 'مقايضة إلى وضع الطويل',
            swapShort: 'مُقايضة إلى وضع القصير',
            schedule: 'الجدول الزمنى',
            weekday: 'يوم من أيام الأسبوع',
            tradingTime: 'وقت التداول',
            closed: 'تم الإغلاق',
            sell: 'بيع',
            buy: 'شراء',
            low: 'منخفض',
            high: 'عالى',
            oneHourChange: 'تغيير لمدة ساعة',
            oneDayChange: 'تغيير لمدة يوم واحد',
            oneWeekChange: 'تغيير لمدة أسبوع واحد',
            oneMonthChange: 'تغيير لمدة شهر واحد',
            threeMonthsChange: 'تغيير لمدة 3 شهور',
            oneYearChange: 'تغيير لمدة عام واحد',
            loading: 'جاري التحميل...',
        },
        autoTrader: {
            days: 'days',
            title: 'Auto Trader',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Risk Level: Low',
            riskLevelMedium: 'Risk Level: Medium',
            riskLevelHigh: 'Risk Level: High',
            perDay: 'Trades per day:',
            desc: `Trading CFDs and other leveraged products can lead to losses. Before trading, clients should
                read the
                relevant risk statements on our Risk Disclosure page. Automated trading does not guarantee results. The
                company accepts no responsibility for the loss of funds in automatic trading. Please make sure that you
                fully understand the risks and take measures to manage risks.`,
            accept: 'Accept',
            save: 'حفظ', 
            cancel: 'إلغاء',
        },
        cardBox: {
            name: 'اسم',
            cardNumber: 'رقم البطاقة',
            expiration: 'انتهاء الصلاحية (شهر/سنة)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "تداول الآن",
            startTrading: 'ابدأ التداول',
            deposit: 'الإيداع',
            dashboard: 'لوحة التحكُّم',
            personalInfo: 'المعلومات الشخصية',
            contactInfo: 'معلومات الاتصال',
            withdrawal: 'سحب',
            verification: 'التحقق',
            accounts: 'الحسابات',
            liveChat: 'محادثة مباشرة',
            savings: 'المُدخَرات',
            settings: 'الإعدادات',
            logOut: 'تسجيل الخروج',
        },
        fullScreenDeposit: {
            methods: 'METHODS',
            confirm: 'Confirm in the application of your bank',
            sendOtp: 'Send otp',
            otpCode: 'Otp code',
            addWalletAddress: 'لم يتم تعيين المحفظة لطريقة الإيداع هذه. يُرجى الاتصال بوكيلك أو فريق الدعم',
            addressReceipt: 'يُرجى الانتظار حتى يتم تحميل العنوان',
            makeDeposit: 'برجاء القيام بعملية إيداع',
            instant: 'فى الحال',
            minutes: 'دقائق',
            hours: 'ساعات',
            days: 'الأيام',
            amount: 'المبلغ',
            continue: 'استمر',
            qrCode: 'رمز الاستجابة السريعة',
            depositAddress: 'عنوان الإيداع',
            copy: 'نسخ',
            last: 'آخر',
            deposits: 'الإيداعات',
            time: 'الوقت',
            currency: 'العملة',
            status: 'الحالة',
            info: 'معلومات',
        },
        fullScreenCcExt: {
            pleaseWait: 'يُرجى الانتظار. جاري الاتصال بمزود خدمة الدفع...',
            cardPayment: 'دفع البطاقة',
            inOrder: 'من أجل إرسال المبلغ',
            pleaseProvide: 'يُرجى تقديم تفاصيل البطاقة'
        },
        fullScreenDashboard: {
            completeVerification: 'Please Complete Verification',
            noOrderYet: 'No order yet',
            totalBalance: 'الرصيد الإجمالى',
            totalPnl: 'PNLالإجمالى',
            profitableOrders: 'الطلبات المُربحة',
            roi: 'ROI',
            activityLog: 'سجل النشاط',
            loginFromIp: 'تسجيل الدخول من عنوان IP',
            tradingResults: 'نتائج التداول',
            week: 'الأسبوع',
            month: 'الشهر',
            year: 'العام',
            successRate: 'معدَّل النجاح',
            closedWithProfit: 'تم الإغلاق بالربح',
            closedWithLoss: 'تم الإغلاق بالخسارة',
            account: 'حساب',
            balance: 'الرصيد',
            leverage: 'الرافعة المالية',
            credit: 'الائتمان',
            tradeNow: 'تداول الآن',
            usingCurrentRate: 'باستخدام سعر الصرف الحالي',
            totalDeposits: 'إجمالى الإيداعات'
        },
        fullScreenPersonal: {
            personalDetails: 'التفاصيل الشخصية',
            profilePhoto: 'صورة الملف الشخصي',
            firstName: 'الاسم الأول',
            country: 'الدولة',
            lastName: 'اللقب',
            address: 'العنوان',
            email: 'البريد الإلكتروني',
            phone: 'رقم الهاتف',
            saveChanges: 'حفظ التغييرات',
            state: 'مدينة',
            postcode: 'الرمز البريدي',
            created: "تم إنشاء الحساب في",
        },
        fullScreenAvatar: {
            dropFile: 'أسقط ملفًا على الدائرة التى بالأعلى لبدء تحميله',
            notAllowed: 'ممنوع نشر',
            notAllowedLine1: 'صور ذات طبيعة جنسية أو إباحية صريحة',
            notAllowedLine2: 'أو الصور التي تهدف إلى التحريض على الكراهية أو العدوان العرقي',
            notAllowedLine3: 'أو صور لأشخاص تقل أعمارهم عن 18 عام',
            notAllowedLine4: 'أو الصور المحمية بحقوق الطبع والنشر من جهات خارجية',
            notAllowedLine5: 'أو الصور التي يزيد حجمها عن 5 ميغابايت وبتنسيق غير JPG أو GIF أو PNG',
            requirements: ' يجب أن يكون وجهك ظاهرًا في الصورة بوضوح. إن جميع الصور ومقاطع الفيديو التي قمت بتحميلها يجب أن تتوافق مع هذه المتطلبات ، وإلا يمكنكَ إزالتها',
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Please contact your account manager for withdrawal details',
            cryptocurrency: 'Cryptocurrency',
            withdrawal: 'سحب',
            requestNewWithdrawal: 'طلب سحب جديد',
            amount: 'المبلغ',
            currentBalance: 'رصيد حسابك الجاري هو',
            withdrawAll: 'سحب كل المبلغ',
            requestWithdrawal: 'طلب سحب',
            last: 'آخر',
            withdrawalRequests: 'طلبات السحب',
            time: 'الوقت',
            currency: 'العملة',
            info: 'معلومات',
            status: 'الحالة',
            bank: 'البنك',
            bitcoin: 'البيتكوين',
            zelle: 'Zelle',
            card: 'البطاقة',
            ethereum: 'الإيثريوم',
            usdt: 'USDT',
            withdrawalDetails: 'تفاصيل السحب',
            address: 'عنوان المحفظة',
            bankAccount: 'رقم الحساب',
            bankHolder: 'اسم صاحب الحساب',
            bankIban: 'IBAN',
            bankSwift: 'رمز SWIFT للبنك',
            recipientAddress: 'عنوان المستلم',
            routingNumber: 'رقم التوجيه',
            bankBeneficiary: 'اسم بنك المستفيد',
            bankAddress: 'عنوان البنك',
            bankCode: 'رمز البنك',
            desc: 'الوصف',
            cardNumber: 'رقم البطاقة',
            cardHolder: 'اسم حامل البطاقة',
        },
        fullScreenVerification: {
            dragAndDrop: 'اسحب وأفلت المستند في هذه المنطقة',
            documentsList: 'قائمة المستندات التى تحميلها',
            document: 'المستند',
            timeUploaded: 'وقت التحميل',
            timeProcessed: 'وقت المعالجة',
            status: 'الحالة',
            types: ['إثبات الهوية', 'إثبات الإقامة', 'واجهة بطاقة الائتمان', 'بطاقة الائتمان من الخلف', 'بطاقة الهوية من الخلف', 'صورة شخصية'],
            types2: ['Proof of identification (POI)', 'Proof of residential address', 'Credit Card Front', 'Credit Card Back', 'Proof of identification Back', 'Selfie with ID'],
            upload: 'الرفع',
            isConfirmed: 'تم تأكيده',
            uploadAnotherDocument: 'رفع وثيقة أخرى',
            isUnderReview: 'قيد المراجعة',
            questionnaire: 'استطلاع',
            sendAnswers: 'إرسال الإجابات',
            progressBarOf: 'من',
            progressBarText: 'تم تحميل مستنداتك والتحقُّق منها'
        },
        fullScreenAccounts: {
            manageAccounts: 'تحويل الأموال بين حساباتك',
            transferFrom: 'من الحساب',
            transferTo: 'إلى الحساب',
            amount: 'المبلغ',
            makeTransfer: 'إجراء تحويل أموال',
            yourAccounts: 'حساباتك',
            account: 'حساب',
            currency: 'العملة',
            balance: 'الرصيد',
            credit: 'الائتمان',
            makeActive: 'تنشيط',
            active: 'نشط'
        },
        fullScreenSupport: {
            sendMessage: 'أرسل رسالة'
        },
        fullScreenSavings: {
            pct_0: 'مرن',
            pct_30: 'مُغلَق لمدة شهر',
            pct_90: 'مُغلَق لمدة ربع سنة واحد',
            pct_180: 'مُغلَق لمدة 6 شهور',
            pct_365: 'مُغلَق لمدة عام واحد',
            savings: 'المُدخَرات',
            detailedInformation: 'معلومات تفصيلية',
            pleaseSelect: 'يُرجى تحديد العملة والفترة',
            openSavings: 'المُدَّخرات المفتوحة',
            currentTime: 'الوقت الحالي',
            releaseTime: 'وقت الإنطلاق',
            currencyChosen: 'العملة التى تم اختيارها',
            periodChosen: 'الفترة التى تم اختيارها',
            yourRate: 'سعرك',
            yourEarnings: 'أرباحك',
            accountFrom: 'الحساب المراد تحويل الأموال منه',
            enterAmount: 'أدخل المبلغ',
            in: 'فى',
            openAndInvest: 'افتح حساب التوفير واستثمر',
            investment: 'الاستثمار',
            period: 'الفترة',
            daysTotal: 'إجمالى الأيام',
            finalEarnings: 'الأرباح النهائية',
            created: 'تم الإنشاء',
            daysElapsed: 'الأيام المنقضية',
            days: 'الأيام',
            earnings: 'الأرباح',
            fundsReleasedIn: 'الأموال التي تم إصدارها في',
            claim: 'مُطالَبة',
        },
        fullScreenSettings: {
            updatePassword: 'تحديث كلمة المرور',
            currentPassword: 'كلمة المرور الحالية',
            newPassword: 'كلمة مرور جديدة',
            changePassword: 'تغيير كلمة المرور',
            activationForGoogle: 'رمز تنشيط تطبيق جوجل للتحقُّق',
            activateAndEnter: 'قم بتنشيط المصادقة وأدخل الرمز الموجود في الحقل أدناه',
            qrCode: 'رمز الاستجابة السريعة',
            activateProtection: 'قم بتنشيط حماية 2FA',
            protectionActive: 'إن حماية 2FA نشطة',
            twoFactorCode: 'رمز 2FA',
            disableProtection: 'تعطيل حماية 2FA',
            language: 'اللغة',
            dashboardCurrency: 'لوحة التحكم فى العملات',
            confirmNewPassword: 'تأكيد كلمة المرور الجديدة',
        },
        fullScreenReferrals: {
            yourLink: 'رابط التحويل لديك',
            referredFriends: 'الأصدقاء الذين تمت الإحالة إليهم',
            id: 'رقم التعريف',
            name: 'الاسم',
            registered: 'التسجيل',
            level: 'المستوى',
            payout: 'الدفع',
            lastPayouts: 'مدفوعات الإحالة الأخيرة',
            time: 'الوقت',
            amount: 'المبلغ',
            currency: 'العملة',
            info: 'معلومات'
        },
        months: {
            Jan: 'يناير',
            Feb: 'قبراير',
            Mar: 'مارس',
            Apr: 'أبريل',
            May: 'مايو',
            Jun: 'يونيو',
            Jul: 'يوليو',
            Aug: 'أغسطس',
            Sep: 'سبتمبر',
            Oct: 'أكتوبر',
            Nov: 'نوفمبر',
            Dec: 'ديسمبر'
        },
        orderTypes: ['شراء', 'بيع', 'حد الشراء', 'حد البيع', 'إيقاف الشراء', 'إيقاف البيع'],
        statuses: ['قيد الانتظار', 'تم التصديق عليه', 'تم الرفض', 'جارى المعالجة'],
        errors: {
            NOT_FOUND: 'خطأ في الطلب',
            FORBIDDEN: 'الوصول مرفوض. يُرجى إعادة تسجيل الدخول',
            SERVER_ERROR: 'فشل الإجراء.. يُرجى المحاولة لاحقًا.',
            INVALID_ACCOUNT: 'انتهت الجلسة. يُرجى إعادة تسجيل الدخول',
            INVALID_EMAIL: 'عنوان البريد الإلكتروني غير صالح',
            INVALID_PHONE: 'رقم الهاتف غير صحيح',
            INVALID_COUNTRY: 'الدولة غير صحيحة',
            INVALID_LOGIN: 'بيانات اعتماد تسجيل الدخول غير صالحة',
            USER_BLOCKED: 'لقد تم حظر الحساب. من فضلك اتصل بالدعم',
            USER_REGISTERED: 'المستخدم الذي لديه ذلك بريد إلكتروني أو رقم الهاتف مسجَّل بالفعل',
            INVALID_USER: 'المستخدم غير موجود',
            REJECTED: 'التشغيل غير مسموح به بسبب قيود المنصة',
            INVALID_OLD_PASSWORD: 'كلمة المرور القديمة غير صالحة',
            INVALID_SYMBOL: 'رمز التداول غير صالح',
            INVALID_SYMBOL_PRECISION: 'دقة الرمز غير صالحة',
            INVALID_FRAME: 'إطار جدول بيانى غير صالح',
            INVALID_CURRENCY: 'عملة غير صالحة',
            INVALID_PRODUCT: 'نوع تداول الحساب غير صالح',
            INSUFFICIENT_FUNDS: 'ليس لديك تمويل كافى',
            INVALID_WITHDRAWAL: 'عملية السحب غير موجودة',
            INVALID_STATUS: 'لبحالة غير صالحة',
            INVALID_FILE: 'امتداد الملف غير صالح أو أن حجمه كبير جدًا',
            INVALID_DOCUMENT: 'لم يتم العثور على المستند',
            INVALID_NAME: 'اسم غير صالح',
            INVALID_LOT_SIZE: 'حجم العقد غير صالح',
            INVALID_LOT_STEP: 'درجة عقد غير صالحة',
            INVALID_MIN_VOLUME: 'حجم أدنى غير صالح',
            INVALID_MAX_VOLUME: 'حجم أقصى غير صالح',
            INVALID_GROUP: 'مجموعة غير صالحة',
            INVALID_SPREAD: 'عرض سعر غير صالح',
            REQUEST_TIMEOUT: 'انتهت مهلة الطلب. يُرجى المحاولة لاحقًا.',
            EXISTING_OPEN_ORDERS: 'لديك طلب مفتوح، أغلق الطلب لهذا المستخدم أولاً وحاول مرة أخرى.',
            INVALID_TYPE: 'نوع الطلب غير صالح',
            INVALID_VOLUME: 'حجم الطلب غير صالح',
            INVALID_SL: 'قيمة إيقاف الخسارة غير صالحة',
            INVALID_TP: 'قيمة جني الأرباح غير صالحة',
            INVALID_PRICE: 'سعر الطلب غير صالح',
            INVALID_EXPIRATION: 'وقت انتهاء صلاحية الطلب غير صالح',
            NOT_ENOUGH_MARGIN: 'الهامش غير كافى',
            INVALID_ORDER: 'لم يتم العثور على الطلب',
            MARKET_CLOSED: 'التداول غير متاح. يُرجى المحاولة لاحقًا',
            INVALID_BALANCE_TYPE: 'نوع غير صالح لعملية الرصيد',
            INVALID_HASH: 'تجزئة غير صالحة',
            HASH_EXPIRED: 'لقد انتهت صلاحية رابط إعادة تعيين كلمة المرور. يُرجى طلب رابط جديد',
            INVALID_CODE: 'رمز 2FA غير صالح',
            CHAT_DISABLED: 'تم إغلاق الوصول إلى دردشة الدعم',
            WITHDRAWAL_NOT_ALLOWED: 'تم إغلاق الوصول إلى عمليات السحب',
            TRADING_DISABLED: 'التداول مُعطَّل للحساب الجاري',
            PENDING_DEPOSITS_LIMIT: 'تم تجاوز حد الإيداعات',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'مسموح بسحب مُعلَّق واحد فقط',
            LOCAL_DEPOSIT_ONLY_REAL: 'الإيداع متاح فقط للحساب الحقيقي',
            LOCAL_DEPOSIT_POSITIVE: 'يجب أن يكون مبلغ الإيداع أكثر من 0',
            LOCAL_INVALID_CARD_NUMBER: 'رقم البطاقة غير صالح',
            LOCAL_INVALID_CARD_EXPIRATION: 'تاريخ انتهاء صلاحية البطاقة غير صالح',
            LOCAL_INVALID_CVC: 'رمز CVV / CVC غير صالح',
            LOCAL_PASSWORDS_NOT_MATCH: 'كلمات المرور غير متطابقة ، يُرجى إعادة إدخال كلمة المرور',
            LOCAL_NO_TRADING_ACCOUNTS: 'ليس لديك حسابات تداول متاحة. من فضلك اتصل بالدعم',
            LOCAL_MIN_DEPOSIT: 'الحد الأدنى للإيداع هو $',
            LOCAL_MIN_WITHDRAWAL: 'الحد الأدنى للسحب هو $',
            LOCAL_INVALID_FILE_TYPE: 'تنسيق الملف غير مدعوم. يرجى إرفاق الملفات ذات الإمتدادات JPG أو PNG أو PDF فقط',
            LOCAL_MIN_STAKING: 'الحد الأدنى للإيداع لهذه العملة هو',
            FLOOD_REJECTED: 'نك ترسل الرسائل بشكل متكرر. يُرجى المحاولة لاحقًا'
        },
        countries: {
            AF: 'أفغانستان',
            AX: 'جزر آلاند',
            AL: 'ألبانيا',
            DZ: 'الجزائر',
            AS: 'ساموا الأمريكية',
            AD: 'أندورا',
            AO: 'أنغولا',
            AI: 'أنغيلا',
            AQ: 'أنتاركتيكا',
            AG: 'أنتيغوا وبربودا',
            AR: 'الأرجنتين',
            AM: 'أرمينيا',
            AW: 'أروبا',
            AU: 'أستراليا',
            AT: 'النمسا',
            AZ: 'أذربيجان',
            BS: 'جزر البهاما',
            BH: 'البحرين',
            BD: 'بنغلاديش',
            BB: 'باربادوس',
            BY: 'بيلاروسيا',
            BE: 'بلجيكا',
            BZ: 'بليز',
            BJ: 'بنين',
            BM: 'برمودا',
            BT: 'بوتان',
            BO: 'بوليفيا',
            BA: 'البوسنة والهرسك',
            BW: 'بوتسوانا',
            BV: 'جزيرة بوفيت',
            BR: 'البرازيل',
            IO: 'إقليم المحيط الهندي البريطاني',
            BN: 'بروناي دار السلام',
            BG: 'بلغاريا',
            BF: 'بوركينا فاسو',
            BI: 'بوروندي',
            KH: 'كمبوديا',
            CM: 'الكاميرون',
            CA: 'كندا',
            CV: 'الرأس الأخضر',
            KY: 'جزر كايمان',
            CF: 'جمهورية أفريقيا الوسطى',
            TD: 'تشاد',
            CL: 'تشيلي',
            CN: 'الصين',
            CX: 'جزيرة الكريسماس',
            CC: 'جزر كوكوس (كيلينغ)',
            CO: 'كولومبيا',
            KM: 'جزر القمر',
            CG: 'الكونغو',
            CD: 'جمهورية الكونغو الديمقراطية',
            CK: 'جزر كوك',
            CR: 'كوستاريكا',
            CI: 'كوت ديفوار',
            HR: 'كرواتيا',
            CU: 'كوبا',
            CY: 'قبرص',
            CZ: 'جمهورية التشيك',
            DK: 'الدنمارك',
            DJ: 'جيبوتى',
            DM: 'دومينيكا',
            DO: 'جمهورية الدومينيكان',
            EC: 'الإكوادور',
            EG: 'مصر',
            SV: 'السلفادور',
            GQ: 'غينيا الاستوائية',
            ER: 'إريتريا',
            EE: 'إستونيا',
            ET: 'إثيوبيا',
            FK: 'جزر فولكلاند (مالفيناس)',
            FO: 'جزر فارو',
            FJ: 'فيجي',
            FI: 'فنلندا',
            FR: 'فرنسا',
            GF: 'جويانا الفرنسية',
            PF: 'بولينيزيا الفرنسية',
            TF: 'الأقاليم الجنوبية الفرنسية',
            GA: 'الجابون',
            GM: 'غامبيا',
            GE: 'جورجيا',
            DE: 'ألمانيا',
            GH: 'غانا',
            GI: 'جبل طارق',
            GR: 'اليونان',
            GL: 'جرينلاند',
            GD: 'غرينادا',
            GP: 'جوادلوب',
            GU: 'غوام',
            GT: 'غواتيمالا',
            GG: 'غيرنسي',
            GN: 'غينيا',
            GW: 'غينيا بيساو',
            GY: 'غيانا',
            HT: 'هايتي',
            HM: 'جزيرة هيرد وجزر ماكدونالد',
            VA: 'الكرسي الرسولي (دولة الفاتيكان)',
            HN: 'هندوراس',
            HK: 'هونج كونج',
            HU: 'المجر',
            IS: 'أيسلندا',
            IN: 'الهند',
            ID: 'إندونيسيا',
            IR: 'إيران',
            IQ: 'العراق',
            IE: 'أيرلندا',
            IM: 'جزيرة مان',
            IL: 'إسرائيل',
            IT: 'إيطاليا',
            JM: 'جامايكا',
            JP: 'اليابان',
            JE: 'جيرسي',
            JO: 'الأردن',
            KZ: 'كازاخستان',
            KE: 'كينيا',
            KI: 'كيريباتي',
            KR: 'كوريا',
            KW: 'الكويت',
            KG: 'قيرغيزستان',
            LA: 'جمهورية لاو الديمقراطية الشعبية',
            LV: 'لاتفيا',
            LB: 'لبنان',
            LS: 'ليسوتو',
            LR: 'ليبيريا',
            LY: 'الجماهيرية العربية الليبية',
            LI: 'ليختنشتاين',
            LT: 'ليتوانيا',
            LU: 'لوكسمبورغ',
            MO: 'ماكاو',
            MK: 'مقدونيا',
            MG: 'مدغشقر',
            MW: 'ملاوى',
            MY: 'ماليزيا',
            MV: 'جزر المالديف',
            ML: 'مالى',
            MT: 'مالطا',
            MH: 'جزر مارشال',
            MQ: 'مارتينيك',
            MR: 'موريتانيا',
            MU: 'موريشيوس',
            YT: 'مايوت',
            MX: 'المكسيك',
            FM: 'ميكرونيزيا',
            MD: 'مولدوفا',
            MC: 'موناكو',
            MN: 'منغوليا',
            ME: 'الجبل الأسود',
            MS: 'مونتسيرات',
            MA: 'المغرب',
            MZ: 'موزمبيق',
            MM: 'ميانمار',
            NA: 'ناميبيا',
            NR: 'ناورو',
            NP: 'نيبال',
            NL: 'هولندا',
            AN: 'جزر الأنتيل الهولندية',
            NC: 'كاليدونيا الجديدة',
            NZ: 'نيوزيلندا',
            NI: 'نيكاراغوا',
            NE: 'النيجر',
            NG: 'نيجيريا',
            NU: 'نيوي',
            NF: 'جزيرة نورفولك',
            MP: 'جزر ماريانا الشمالية',
            NO: 'النرويج',
            OM: 'عمان',
            PK: 'باكستان',
            PW: 'بالاو',
            PS: 'الأراضي الفلسطينية المحتلة',
            PA: 'بنما',
            PG: 'بابوا غينيا الجديدة',
            PY: 'باراغواي',
            PE: 'بيرو',
            PH: 'الفلبين',
            PN: 'بيتكيرن',
            PL: 'بولندا',
            PT: 'البرتغال',
            PR: 'بورتوريكو',
            QA: 'قطر',
            RE: 'ريونيون',
            RO: 'رومانيا',
            RU: 'روسيا',
            RW: 'رواندا',
            BL: 'سانت بارتيليمي',
            SH: 'سانت هيلانة',
            KN: 'سانت كيتس ونيفيس',
            LC: 'سانت لوسيا',
            MF: 'سانت مارتن',
            PM: 'سان بيير وميكلون',
            VC: 'سانت فنسنت وجزر غرينادين',
            WS: 'ساموا',
            SM: 'سان مارينو',
            ST: 'ساو تومي وبرينسيبي',
            SA: 'المملكة العربية السعودية',
            SN: 'السنغال',
            RS: 'صربيا',
            SC: 'سيشل',
            SL: 'سيراليون',
            SG: 'سنغافورة',
            SK: 'سلوفاكيا',
            SI: 'سلوفينيا',
            SB: 'جزر سليمان',
            SO: 'الصومال',
            ZA: 'جنوب إفريقيا',
            GS: 'جورجيا الجنوبية وجزيرة ساندويتش.',
            ES: 'إسبانيا',
            LK: 'سريلانكا',
            SD: 'السودان',
            SR: 'سورينام',
            SJ: 'سفالبارد وجان ماين',
            SZ: 'سوازيلاند',
            SE: 'السويد',
            CH: 'سويسرا',
            SY: 'الجمهورية العربية السورية',
            TW: 'تايوان',
            TJ: 'طاجيكستان',
            TZ: 'تنزانيا',
            TH: 'تايلاند',
            TL: 'تيمور الشرقية',
            TG: 'توغو',
            TK: 'توكيلاو',
            TO: 'تونجا',
            TT: 'ترينيداد وتوباغو',
            TN: 'تونس',
            TR: 'تركيا',
            TM: 'تركمانستان',
            TC: 'جزر تركس وكايكوس',
            TV: 'توفالو',
            UG: 'أوغندا',
            UA: 'أوكرانيا',
            AE: 'الإمارات العربية المتحدة',
            GB: 'المملكة المتحدة',
            US: 'الولايات المتحدة الأمريكية',
            UM: 'جزر الولايات المتحدة النائية',
            UY: 'أوروغواي',
            UZ: 'أوزبكستان',
            VU: 'فانواتو',
            VE: 'فنزويلا',
            VN: 'فيتنام',
            VG: 'جزر فيرجن البريطانية',
            VI: 'جزر فيرجن الأمريكية.',
            WF: 'واليس وفوتونا',
            EH: 'الصحراء الغربية',
            YE: 'اليمن',
            ZM: 'زامبيا',
            ZW: 'زيمبابوي'
        },
    },
    it: {
        _name: 'Italiano',
        timePickerModal: {
            title: 'Close In Time'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Trai profitto & blocca le perdite',
            takeProfit: 'Trai profitto',
            stopLoss: 'Blocca le perdite',
            updateOrder: 'Aggiorna ordine',
        },
        balanceModal: {
            balance: 'Saldo',
            credit: 'Credito',
            equity: 'Equità',
            practice: 'PRATICA',
            real: 'REALE',
            account: 'CONTO',
            invested: 'Investito',
            profit: 'Profitto',
            loss: 'Perdita',
            margin: 'Margine',
            marginLevel: 'Livello di margine',
            marginFree: 'Margine gratuito'
        },
        header: {
            cfd: 'CFD',
            platform: 'Piattaforma',
            deposit: 'Deposito'
        },
        sideMenu: {
            marketWatch: 'Osservatorio di mercato',
            activeOrders: 'Ordini attivi',
            tradingHistory: 'Storia di trading',
            economicCalendar: 'Calendario economico',
            marketNews: 'Notizie di mercato'
        },
        closePositionModal: {
            closeOrder: 'Posizione chiusa',
            areYouSure: 'Sei sicuro di chiudere la posizione',
            buy: 'Acquista',
            sell: 'Vendi',
            yes: 'Sì',
            no: 'No',
        },
        pciDssModal: {
            desc: `La conformità allo standard di sicurezza dei dati del settore delle carte di pagamento (PCI DSS) è richiesta per tutte le entità che memorizzano, elaborano o trasmettono dati del titolare della carta Visa/Master Card/Stripe, incluse istituzioni finanziarie, commercianti e fornitori di servizi. È lo standard di sicurezza globale per tutte le entità che memorizzano, elaborano o trasmettono dati del titolare della carta e/o dati di autenticazione sensibili. PCI DSS stabilisce un livello base di protezione per i consumatori e aiuta a ridurre frodi e violazioni dei dati in tutto l'ecosistema dei pagamenti.`
        },
        chart: {
            addNewChart: 'Aggiungi nuova tabella',
        },
        symbolsModal: {
            watchlist: 'Elenco di controllo',
            asset: 'Attività',
            price: 'Prezzo',
            changePct: 'Cambio 24h'
        },
        pendingModal: {
            pending: 'In sospeso',
            assetPrice: 'Prezzo dell\'attività',
            current: 'Attuale',
            revert: 'Ritorno al prezzo di mercato',
            automatically: 'La posizione verrà aperta automaticamente quando il prezzo raggiungerà questo livello'
        },
        orderMenu: {
            default: 'default',
            volume: 'Volume',
            lots: 'lotti',
            units: 'unità',
            currency: 'valuta',
            contractSize: 'Dimensione del contratto',
            position: 'Posizione',
            margin: 'Margine',
            freeMargin: 'Margine gratuito',
            takeProfitStopLoss: 'Trai profitto & blocca le perdite',
            pending: 'Sospeso',
            market: 'Mercato',
            leverage: 'Leva',
            spread: 'Spread',
            notSet: 'Non impostato',
            at: 'a',
            buy: 'acquista',
            sell: 'vendi',
        },
        footer: {
            supportBanner: 'OGNI GIORNO, 24 ORE SU 24',
            currentTime: 'ORA ATTUALE',
            liveChat: 'Chat dal vivo'
        },
        statusBar: {
            balance: 'Saldo',
            credit: 'Credito',
            equity: 'Equità',
            margin: 'Margine',
            marginLevel: 'Livello del margine',
            freeMargin: 'Margine gratuito',
            pnl: 'PnL',
            profitTotal: 'Durata della vita PnL'
        },
        accountModal: {
            dateRegistered: 'Data di registrazione',
            userId: 'ID utente',
            dashboard: 'Pannello',
            personalData: 'Dati personali',
            deposit: 'Deposito',
            withdrawFunds: 'Prelievo di fondi',
            savings: 'Risparmi',
            settings: 'Impostazioni',
            logout: 'Disconnessione'
        },
        depositModal: {
            BT_INVOICE: 'Enter amount and generate invoice',
            BT_TITLE: 'Our banking details',
            PENDING_DEPOSITS_LIMIT: 'Deposit Limit',
            account: 'Account',
            bank_name: 'Bank Name',
            beneficiary: 'Beneficiary',
            beneficiary_address: 'Beneficiary Address',
            swift: 'Swift',
            reference: 'Reference',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Amount',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Description',
            TOKEN_PAYMENT_DETAILS: 'Payment Details',
            makeDeposit: 'Effettuare un deposito',
            practiceAccount: 'Conto corrente',
            realAccount: 'Conto reale',
            noWithdrawals: 'Nessun prelievo',
            easyWithdrawals: 'Prelievi facili',
            allAssets: 'Tutte le attività disponibili',
            fullFledged: 'Piattaforma completa',
            fillUpTo: 'Riempire fino a',
            freeReplenishment: 'Rifornimento gratuito',
            topUp: 'Ricaricare il conto',
            minimumAmount: 'Importo minimo',
            canSwitch: 'Si può passare da un conto all\'altro in qualsiasi momento'
        },
        gridsModal: {
            chartGrids: 'GRIGLIE DELLA TABELLA',
            chart1: '1 tabella',
            chart2: '2 tabelle',
            chart3: '3 tabelle',
            chart4: '4 tabelle',
        },
        noConnectionModal: {
            connectionLost: 'La connessione con il server è stata persa',
            retryNow: 'Riprova ora',
        },
        loginModal: {
            loginToTradeRoom: 'Accedi a Traderoom',
            email: 'Indirizzo e-mail',
            enterEmail: 'Inserisci la tua e-mail',
            twoFactor: '2FA Codice (se abilitato)',
            twoFactorAuth: 'Autenticazione a due fattori',
            password: 'Password',
            yourPassword: 'La tua password',
            signIn: 'Accedi',
            dontHaveAccount: 'Non hai un account?',
            restore: 'Ripristina',
            fullName: 'Nome completo',
            havePromo: 'Non ho codici promozionale',
            promo: 'Codice promozionale',
            login: 'Accedi',
            haveAccount: 'Hai già un conto?',
            hide: 'Nascondi',
            goTo: 'Torna indietro'
        },
        newPasswordModal: {
            setNewPassword: 'Imposta nuova password',
            newPassword: 'Nuova password',
            confirmNewPassword: 'Conferma nuova password',
            setButton: 'Impostazione'
        },
        registerModal: {
            repeatPassword: 'Repeat Password',
            createNewAccount: 'Crea un nuovo conto',
            email: 'Indirizzo e-mail',
            firstName: 'Nome',
            lastName: 'Cognome',
            country: 'Paese',
            phone: 'Telefono',
            password: 'Password',
            createAccount: 'Crea conto',
            currency: 'Valuta',
            privacyAndPolicy: "Privacy e Politica",
            customerAgreement: 'Accordo con il cliente',

        },
        forgotPasswordModal: {
            forgotPassword: 'Password dimenticata?',
            submitEmail: 'Per favore, invia l\'e-mail utilizzata per la registrazione, controlla la tua casella di posta e segui le istruzioni fornite',
            submit: 'Invia'
        },
        notifications: {
            error: 'Errore',
            success: 'Successo',
            deposit: 'Deposito',
            withdrawal: 'Prelievo',
            depositApproved: 'Il deposito è stato approvato',
            depositDeclined: 'Il deposito è stato rifiutato',
            withdrawalApproved: 'Il prelievo è stato approvato',
            withdrawalDeclined: 'Il prelievo è stato rifiutato'
        },
        markets: {
            forex: 'Forex',
            stocks: 'Stocks',
            commodities: 'Materie prime',
            indices: 'Indici',
            crypto: 'Crypto',
            metals: 'Metalli',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'ACQUISTA',
            profitCalculator: 'Calcolatore di profitto',
            sell: 'VENDI',
            volume: 'Volume',
            entryPrice: 'Prezzo di ingresso',
            takeProfit: 'Trai profitto',
            stopLoss: 'Blocca le perdite',
            maxPosition: 'Posizione massima',
            calculate: 'Calcolare',
            calculationsFor: 'Calcoli per',
            leverage: 'Leva',
            requiredMargin: 'Margine richiesto',
            profitFromTP: 'Utile da TP',
            lossFromSL: 'Perdita da SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Deposito andato a buon fine',
            text: 'Il tuo deposito è stato elaborato con successo!',
        },
        depositFailModal: {
            title: 'Deposito fallito',
            text: 'Il tuo deposito non è stato elaborato.',
        },
        widgetMarketWatch: {
            marketWatch: 'Osservatorio del mercato',
            search: 'Ricerca...',
        },
        widgetOrders: {
            commission: 'Commissione',
            volume: 'Volume',
            margin: 'Margine',
            active: 'Attivo',
            pending: 'In sospeso',
            activeOrders: 'Ordini attivi',
            portfolio: 'Portfolio',
            allPositions: 'Tutte le posizioni',
            noPositionsLine1: 'Non hai aperte',
            noPositionsLine2: 'posizioni ancora',
            show: 'Mostra',
            more: 'di più',
            less: 'meno',
            purchaseTime: 'Tempo di acquisto',
            closePosition: 'Posizione chiusa',
            priceOpen: 'Prezzo di acquisto',
            priceSl: 'Prezzo del blocco della perdita',
            priceTp: 'Prezzo di presa di profitto',
            type: 'Direzione della posizione',
            pnl: 'Profitto/Perdita',
            cancelOrder: 'Annullare l\'ordine',
            orderType: 'Tipo di ordine',
            cancelled: 'Cancellato',
            tradingHistory: 'Storia del trading',
            noHistoryLine1: 'Non hai ancora',
            noHistoryLine2: 'concluso alcun affare',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Calendario economico'
        },
        widgetMarketNews: {
            marketNews: 'Notizie di mercato'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'Ordini attivi',
            ordersHistory: 'Cronologia degli ordini',
            id: 'ID',
            symbol: 'Simbolo',
            type: 'Tipo',
            volume: 'Volume',
            openPrice: 'Prezzo di apertura',
            openTime: 'Orario di apertura',
            closePrice: 'Prezzo di chiusura',
            closeTime: 'Orario di chiusura',
            sl: 'SL',
            tp: 'TP',
            price: 'Prezzo',
            pnl: 'PnL',
            actions: 'Azioni',
            edit: 'Modifica',
            close: 'Chiusi',
            commission: 'Commissione',
            swap: 'Scambio',
            swapLong: 'Scambio lungo',
            swapShort: 'Scambio breve'
        },
        assetInfoModal: {
            assetInfo: 'Info sulle attività',
            tradingConditions: 'Condizioni di trading',
            information: 'Cambio sessione',
            bid: 'Offerta',
            ask: 'Chiedi',
            sessionChange: 'Cambio sessione',
            tradeNow: 'Scambia ora',
            opens: 'Apri',
            closes: 'Chiudi',
            at: 'a',
            open247: 'Aperto 24/7',
            today: 'oggi',
            tomorrow: 'domani',
            sunday: 'Domenica',
            monday: 'Lunedì',
            tuesday: 'Martedì',
            wednesday: 'Mercoledì',
            thursday: 'Giovedì',
            friday: 'Venerdì',
            saturday: 'Sabato',
            contractSpecification: 'Specifiche del contratto',
            symbol: 'Simbolo',
            name: 'Nome',
            market: 'Mercato',
            baseCurrency: 'Valuta base',
            digits: 'Digita',
            lotSize: 'Dimensione del lotto',
            lotStep: 'Passo del lotto',
            minVolume: 'Volume minimo',
            maxVolume: 'Volume massimo',
            leverage: 'Leva',
            commission: 'Commissione',
            swapLong: 'Scambio lungo',
            swapShort: 'Scambio corto',
            schedule: 'Programma',
            weekday: 'Giorno feriale',
            tradingTime: 'Tempo di trading',
            closed: 'chiuso',
            sell: 'Vendi',
            buy: 'Acquista',
            low: 'Basso',
            high: 'Alto',
            oneHourChange: 'Cambio di 1 ora',
            oneDayChange: 'Cambio di 1 giorno',
            oneWeekChange: 'Cambio di 1 settimana',
            oneMonthChange: 'Cambio di 1 mese',
            threeMonthsChange: 'Cambio di 3 mesi',
            oneYearChange: 'Cambio di 1 anno',
            loading: 'Caricando...',
        },
        autoTrader: {
            days: 'days',
            title: 'Auto Trader',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Risk Level: Low',
            riskLevelMedium: 'Risk Level: Medium',
            riskLevelHigh: 'Risk Level: High',
            perDay: 'Trades per day:',
            desc: `Trading CFDs and other leveraged products can lead to losses. Before trading, clients should
                read the
                relevant risk statements on our Risk Disclosure page. Automated trading does not guarantee results. The
                company accepts no responsibility for the loss of funds in automatic trading. Please make sure that you
                fully understand the risks and take measures to manage risks.`,
            accept: 'Accept',
            save: 'Salva', 
            cancel: 'Annulla',
        },
        cardBox: {
            name: 'Nome',
            cardNumber: 'Numero di carta',
            expiration: 'Scadenza (mm/aa)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "Commercio",
            startTrading: 'Iniziare il trading',
            deposit: 'Deposito',
            dashboard: 'Pannello',
            personalInfo: 'Informazioni personali',
            contactInfo: 'Informazioni di contatto',
            withdrawal: 'Prelievo',
            verification: 'Verifica',
            accounts: 'Conti',
            liveChat: 'Chat in diretta',
            savings: 'Risparmi',
            settings: 'Impostazioni',
            logOut: 'Disconnessione',
        },
        fullScreenDeposit: {
            methods: 'METHODS',
            confirm: 'Confirm in the application of your bank',
            sendOtp: 'Send otp',
            otpCode: 'Otp code',
            addWalletAddress: 'Il portafoglio per questo metodo di deposito non è impostato. Si prega di contattare il proprio agente o il team di supporto.',
            addressReceipt: 'Attendere il caricamento dell\'indirizzo',
            makeDeposit: 'EFFETTUARE UN DEPOSITO',
            instant: 'Immediato',
            minutes: 'minuti',
            hours: 'ore',
            days: 'giorni',
            amount: 'Importo',
            continue: 'Continua',
            qrCode: 'CODICE QR',
            depositAddress: 'INDIRIZZO DI DEPOSITO',
            copy: 'Copia',
            last: 'ULTIMO',
            deposits: 'DEPOSITI',
            time: 'Orario',
            currency: 'Valuta',
            status: 'Status',
            info: 'Info',
        },
        fullScreenCcExt: {
            pleaseWait: 'Attendere prego. Connessione al fornitore di servizi di pagamento...',
            cardPayment: 'Pagamento con carta',
            inOrder: 'Per inviare l\'importo',
            pleaseProvide: 'si prega di fornire i dati della carta:'
        },
        fullScreenDashboard: {
            completeVerification: 'Please Complete Verification',
            noOrderYet: 'No order yet',
            totalBalance: 'Saldo totale',
            totalPnl: 'Totale PNL',
            profitableOrders: 'Ordini redditizi',
            roi: 'ROI',
            activityLog: 'Registro delle attività',
            loginFromIp: 'Accesso da IP',
            tradingResults: 'Risultati del trading',
            week: 'Settimana',
            month: 'Mese',
            year: 'Anno',
            successRate: 'Tasso di successo',
            closedWithProfit: 'Chiuso con profitto',
            closedWithLoss: 'Chiuso con perdita',
            account: 'Conto',
            balance: 'Saldo',
            leverage: 'Leva',
            credit: 'Credito',
            tradeNow: 'Scambia ora',
            usingCurrentRate: 'al tasso di cambio corrente',
            totalDeposits: 'Totale depositi'
        },
        fullScreenPersonal: {
            personalDetails: 'Dettagli personali',
            profilePhoto: 'Foto profilo',
            firstName: 'Nome',
            country: 'Paese',
            lastName: 'Cognome',
            address: 'Indirizzo',
            email: 'E-mail',
            phone: 'Numero di telefono',
            saveChanges: 'Salva i cambiamenti',
            state: 'Città',
            postcode: 'CAP',
            created: "Account creato il",
        },
        fullScreenAvatar: {
            dropFile: 'Lascia un file nel cerchio qui sopra per caricarlo',
            notAllowed: 'Non è consentito pubblicare',
            notAllowedLine1: 'Foto di natura esplicitamente sessuale o pornografica',
            notAllowedLine2: 'Immagini che incitano all\'odio o all\'aggressione etnica o razziale.',
            notAllowedLine3: 'Foto che coinvolgono persone di età inferiore ai 18 anni',
            notAllowedLine4: 'Foto protette da copyright di terzi',
            notAllowedLine5: 'Immagini di dimensioni superiori a 5 MB e in un formato diverso da JPG, GIF o PNG',
            requirements: 'Il tuo volto deve essere chiaramente visibile nella foto. Tutte le foto e i video caricati dall\'utente devono essere conformi a questi requisiti, altrimenti potranno essere rimossi.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Please contact your account manager for withdrawal details',
            cryptocurrency: 'Cryptocurrency',
            withdrawal: 'Prelievo',
            requestNewWithdrawal: 'RICHIEDERE UN NUOVO PRELIEVO',
            amount: 'Importo',
            currentBalance: 'Il saldo del conto corrente è',
            withdrawAll: 'Prelevare tutti',
            requestWithdrawal: 'Richiesta di ritiro',
            last: 'ULTIMO',
            withdrawalRequests: 'RICHIESTE DI PRELIEVO',
            time: 'Orario',
            currency: 'Valuta',
            info: 'Info',
            status: 'Status',
            bank: 'Banca',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            card: 'Carta',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Dettagli del prelievo',
            address: 'Indirizzo portafoglio',
            bankAccount: 'Numero conto',
            bankHolder: 'Nome del titolare del conto',
            bankIban: 'IBAN',
            bankSwift: 'Codice SWIFT della banca',
            recipientAddress: 'Indirizzo del destinatario',
            routingNumber: 'Numero di instradamento',
            bankBeneficiary: 'Nome della banca del beneficiario',
            bankAddress: 'Indirizzo della banca',
            bankCode: 'Codice bancario',
            desc: 'Descrizione',
            cardNumber: 'Numero carta',
            cardHolder: 'Nome del titolare della carta',
        },
        fullScreenVerification: {
            dragAndDrop: 'trascinare e rilasciare il documento in quest\'area',
            documentsList: 'ELENCO DEI DOCUMENTI CARICATI',
            document: 'Documento',
            timeUploaded: 'Ora di caricamento',
            timeProcessed: 'Orario elaborato',
            status: 'Status',
            types: ['Prova d\'identità', 'Prova di residenza', 'Fronte carta di credito', 'Retro della carta di credito', 'Retro del documento d\'identità', 'Selfie'],
            types2: ['Proof of identification (POI)', 'Proof of residential address', 'Credit Card Front', 'Credit Card Back', 'Proof of identification Back', 'Selfie with ID'],
            upload: 'Carica',
            isConfirmed: 'è confermato',
            uploadAnotherDocument: 'Caricare un altro documento',
            isUnderReview: 'è in fase di revisione',
            questionnaire: 'Questionario',
            sendAnswers: 'Invia risposte',
            progressBarOf: 'di',
            progressBarText: 'i documenti sono stati caricati e confermati'
        },
        fullScreenAccounts: {
            manageAccounts: 'Trasferire fondi tra i vostri conti',
            transferFrom: 'Da un conto',
            transferTo: 'Ad un conto',
            amount: 'Importo',
            makeTransfer: 'Effettuare il trasferimento',
            yourAccounts: 'I tuoi conti',
            account: 'Conto',
            currency: 'Valuta',
            balance: 'Saldo',
            credit: 'Credito',
            makeActive: 'Rendi attivo',
            active: 'Attivo'
        },
        fullScreenSupport: {
            sendMessage: 'Invia messaggio'
        },
        fullScreenSavings: {
            pct_0: 'Flessibile',
            pct_30: 'Bloccato 1 mese',
            pct_90: 'Bloccato 1 trimestre',
            pct_180: 'Bloccato 6 mesi',
            pct_365: 'Bloccato 1 anno',
            savings: 'Risparmi',
            detailedInformation: 'Informazioni dettagliate',
            pleaseSelect: 'Selezionare una valuta e un periodo',
            openSavings: 'Risparmi aperti',
            currentTime: 'Ora corrente',
            releaseTime: 'Tempo di rilascio',
            currencyChosen: 'Valuta scelta',
            periodChosen: 'Periodo scelto',
            yourRate: 'La tua tariffa',
            yourEarnings: 'I tuoi guadagni',
            accountFrom: 'Conto da cui trasferire i fondi',
            enterAmount: 'Inserire importo',
            in: 'In',
            openAndInvest: 'Aprire un conto di risparmio e investire',
            investment: 'Investimento',
            period: 'Periodo',
            daysTotal: 'Giorni totali',
            finalEarnings: 'Guadagni finali',
            created: 'Creato',
            daysElapsed: 'Giorni rimanenti',
            days: 'giorni',
            earnings: 'Guadagni',
            fundsReleasedIn: 'Fondi erogati in',
            claim: 'Richiedi',
        },
        fullScreenSettings: {
            updatePassword: 'AGGIORNA PASSWORD',
            currentPassword: 'Password corrente',
            newPassword: 'Nuova Password',
            changePassword: 'Cambia Password',
            activationForGoogle: 'Codice di attivazione per Google Authenticator',
            activateAndEnter: 'Attiva l\'Autenticatore e inserisci un codice generato nel campo sottostante',
            qrCode: 'Codice QR',
            activateProtection: 'Attivare la protezione 2FA',
            protectionActive: 'La protezione 2FA è attiva',
            twoFactorCode: 'Codice 2FA',
            disableProtection: 'Disattivare la protezione 2FA',
            language: 'Lingua',
            dashboardCurrency: 'Pannello valuta',
            confirmNewPassword: 'Conferma nuova password',
        },
        fullScreenReferrals: {
            yourLink: 'IL TUO LINK DI AFFILIAZIONE',
            referredFriends: 'AMICI INDIRIZZATI',
            id: 'ID',
            name: 'Nome',
            registered: 'Registra',
            level: 'Livello',
            payout: 'Pagamento',
            lastPayouts: 'ULTIMI PAGAMENTI DI AFFILIAZIONE',
            time: 'Tempo',
            amount: 'Importo',
            currency: 'Valuta',
            info: 'Info'
        },
        months: {
            Jan: 'Gen',
            Feb: 'Feb',
            Mar: 'Mar',
            Apr: 'Apr',
            May: 'Mag',
            Jun: 'Giu',
            Jul: 'Lug',
            Aug: 'Ago',
            Sep: 'Set',
            Oct: 'Ott',
            Nov: 'Nov',
            Dec: 'Dic'
        },
        orderTypes: ['Acquista', 'Vendi', 'Limite di acquisto', 'Limite di vendita', 'Stop acquisti', 'Stop vendita'],
        statuses: ['In sospeso', 'Approvato', 'Rifiutato', 'Elaborazione'],
        errors: {
            NOT_FOUND: 'Errore di richiesta',
            FORBIDDEN: 'Accesso negato. Effettuare nuovamente il login',
            SERVER_ERROR: 'Azione fallita. Riprovare più tardi',
            INVALID_ACCOUNT: 'Sessione scaduta. Effettuare nuovamente il login',
            INVALID_EMAIL: 'Indirizzo e-mail non valido',
            INVALID_PHONE: 'Numero di telefono non valido',
            INVALID_COUNTRY: 'Paese non valido',
            INVALID_LOGIN: 'Credenziali di accesso non valide',
            USER_BLOCKED: 'L\'account è bloccato. Contattare l\'assistenza',
            USER_REGISTERED: 'L\'utente con l\'e-mail o il numero di telefono inseriti è già registrato',
            INVALID_USER: 'L\'utente non è stato trovato',
            REJECTED: 'Il funzionamento non è consentito a causa delle restrizioni della piattaforma',
            INVALID_OLD_PASSWORD: 'La vecchia password non è valida',
            INVALID_SYMBOL: 'Simbolo di trading non valido',
            INVALID_SYMBOL_PRECISION: 'Invalid symbol precision',
            INVALID_FRAME: 'Cornice della tabella non valida',
            INVALID_CURRENCY: 'Valuta non valida',
            INVALID_PRODUCT: 'Tipo di trading del conto non valido',
            INSUFFICIENT_FUNDS: 'Fondi insufficienti',
            INVALID_WITHDRAWAL: 'Withdrawal is not found',
            INVALID_STATUS: 'Status non valido',
            INVALID_FILE: 'Estensione file non valida o file troppo grande',
            INVALID_DOCUMENT: 'Il documento non è stato trovato',
            INVALID_NAME: 'Nome non valido',
            INVALID_LOT_SIZE: 'Dimensione del lotto non valida',
            INVALID_LOT_STEP: 'Fase del lotto non valida',
            INVALID_MIN_VOLUME: 'Volume minimo non valido',
            INVALID_MAX_VOLUME: 'Volume massimo non valido',
            INVALID_GROUP: 'Gruppo non valido',
            INVALID_SPREAD: 'Spread non valido',
            REQUEST_TIMEOUT: 'La richiesta è scaduta. Riprovare più tardi',
            EXISTING_OPEN_ORDERS: 'Hai un ordine aperto, chiudi prima l\'ordine per questo utente e riprova.',
            INVALID_TYPE: 'Tipo di ordine non valido',
            INVALID_VOLUME: 'Volume dell\'ordine non valido',
            INVALID_SL: 'Valore di arresto della perdita non valido',
            INVALID_TP: 'Valore di presa di profitto non valido',
            INVALID_PRICE: 'Prezzo dell\'ordine non valido',
            INVALID_EXPIRATION: 'Scadenza dell\'ordine non valida',
            NOT_ENOUGH_MARGIN: 'Margine insufficiente',
            INVALID_ORDER: 'L\'ordine non è stato trovato',
            MARKET_CLOSED: 'Il trading non è disponibile. Si prega di riprovare',
            INVALID_BALANCE_TYPE: 'Tipo di operazione del saldo non valido',
            INVALID_HASH: 'Hash non valido',
            HASH_EXPIRED: 'Il link per la reimpostazione della password è scaduto. Richiedine una nuova',
            INVALID_CODE: 'Codice 2FA non valido',
            CHAT_DISABLED: 'L\'accesso alla chat di supporto è stato chiuso',
            WITHDRAWAL_NOT_ALLOWED: 'L\'accesso ai prelievi è stato chiuso',
            TRADING_DISABLED: 'Il trading è disabilitato per il conto corrente',
            PENDING_DEPOSITS_LIMIT: 'Superamento del limite dei depositi',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'È consentito un solo prelievo in sospeso',
            LOCAL_DEPOSIT_ONLY_REAL: 'Il deposito è disponibile solo per il conto reale',
            LOCAL_DEPOSIT_POSITIVE: 'L\'importo del deposito deve essere superiore a 0',
            LOCAL_INVALID_CARD_NUMBER: 'Numero di carta non valido',
            LOCAL_INVALID_CARD_EXPIRATION: 'Scadenza della carta non valida',
            LOCAL_INVALID_CVC: 'Codice CVV/CVC non valido',
            LOCAL_PASSWORDS_NOT_MATCH: 'Le password non corrispondono, inserire nuovamente',
            LOCAL_NO_TRADING_ACCOUNTS: 'Non sono disponibili conti di trading. Si prega di contattare l\'assistenza',
            LOCAL_MIN_DEPOSIT: 'Il deposito minimo è di $',
            LOCAL_MIN_WITHDRAWAL: 'L\'importo minimo di prelievo è di $',
            LOCAL_INVALID_FILE_TYPE: 'Formato di file non supportato. Allegare solo JPG, PNG o PDF',
            LOCAL_MIN_STAKING: 'Il deposito minimo per questa valuta è',
            FLOOD_REJECTED: 'L\'invio di messaggi è troppo frequente. Riprovare più tardi'
        },
        countries: {
            AF: 'Afghanistan',
            AX: 'Isole Aland',
            AL: 'Albania',
            DZ: 'Algeria',
            AS: 'Samoa Americane',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antartide',
            AG: 'Antigua e Barbuda',
            AR: 'Argentina',
            AM: 'Armenia',
            AW: 'Aruba',
            AU: 'Australia',
            AT: 'Austria',
            AZ: 'Azerbaijan',
            BS: 'Bahamas',
            BH: 'Bahrain',
            BD: 'Bangladesh',
            BB: 'Barbados',
            BY: 'Biellorussia',
            BE: 'Belgio',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Bhutan',
            BO: 'Bolivia',
            BA: 'Bosnia ed Erzegovina',
            BW: 'Botswana',
            BV: 'Isola di Bouvet',
            BR: 'Brasile',
            IO: 'Territorio britannico dell\'Oceano Indiano',
            BN: 'Brunei Darussalam',
            BG: 'Bulgaria',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Cambogia',
            CM: 'Camerun',
            CA: 'Canada',
            CV: 'Capo Verde',
            KY: 'Isole Cayman',
            CF: 'Repubblica Centrafricana',
            TD: 'Ciad',
            CL: 'Cile',
            CN: 'Cina',
            CX: 'Isola di Natale',
            CC: 'Isole Cocos (Keeling)',
            CO: 'Colombia',
            KM: 'Comore',
            CG: 'Congo',
            CD: 'Congo, Repubblica Democratica',
            CK: 'Isole Cook',
            CR: 'Costa Rica',
            CI: 'Cote D\'Avorio',
            HR: 'Croazia',
            CU: 'Cuba',
            CY: 'Cipro',
            CZ: 'Repubblica Ceca',
            DK: 'Danimarca',
            DJ: 'Djibouti',
            DM: 'Dominica',
            DO: 'Repubblica Dominicana',
            EC: 'Ecuador',
            EG: 'Egitto',
            SV: 'El Salvador',
            GQ: 'Guinea Equatoriale',
            ER: 'Eritrea',
            EE: 'Estonia',
            ET: 'Etiopia',
            FK: 'Isole Falkland (Malvinas)',
            FO: 'Isole Faroe',
            FJ: 'Fiji',
            FI: 'Finlandia',
            FR: 'Francia',
            GF: 'Guiana Francese',
            PF: 'Polinesia francese',
            TF: 'Territori meridionali francesi',
            GA: 'Gabon',
            GM: 'Gambia',
            GE: 'Georgia',
            DE: 'Germania',
            GH: 'Ghana',
            GI: 'Gibilterra',
            GR: 'Grecia',
            GL: 'Groenlandia',
            GD: 'Grenada',
            GP: 'Guadalupa',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Isola Heard e Isole Mcdonald',
            VA: 'Santa Sede (Stato della Città del Vaticano)',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Ungheria',
            IS: 'Islanda',
            IN: 'India',
            ID: 'Indonesia',
            IR: 'Iran',
            IQ: 'Iraq',
            IE: 'Irlanda',
            IM: 'Isola di Man',
            IL: 'Israele',
            IT: 'Italia',
            JM: 'Giamaica',
            JP: 'Giappone',
            JE: 'Jersey',
            JO: 'Giordania',
            KZ: 'Kazakhstan',
            KE: 'Kenya',
            KI: 'Kiribati',
            KR: 'Corea',
            KW: 'Kuwait',
            KG: 'Kyrgyzstan',
            LA: 'Repubblica Democratica Popolare del Laos',
            LV: 'Lettonia',
            LB: 'Libano',
            LS: 'Lesotho',
            LR: 'Liberia',
            LY: 'Giamahiria Araba Libica',
            LI: 'Liechtenstein',
            LT: 'Lituania',
            LU: 'Lussemburgo',
            MO: 'Macao',
            MK: 'Macedonia',
            MG: 'Madagascar',
            MW: 'Malawi',
            MY: 'Malesia',
            MV: 'Maldive',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Isole Marshall',
            MQ: 'Martinica',
            MR: 'Mauritania',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Mexico',
            FM: 'Micronesia',
            MD: 'Moldavia',
            MC: 'Monaco',
            MN: 'Mongolia',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Marocco',
            MZ: 'Mozambico',
            MM: 'Myanmar',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Olanda',
            AN: 'Antille Olandesi',
            NC: 'Nuova Caledonia',
            NZ: 'Nuova Zelanda',
            NI: 'Nicaragua',
            NE: 'Niger',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Isola di Norfolk',
            MP: 'Isole Marianne Settentrionali',
            NO: 'Norvegia',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Territorio palestinese occupato',
            PA: 'Panama',
            PG: 'Papua New Guinea',
            PY: 'Paraguay',
            PE: 'Perù',
            PH: 'Filippine',
            PN: 'Pitcairn',
            PL: 'Polonia',
            PT: 'Portogallo',
            PR: 'Puerto Rico',
            QA: 'Qatar',
            RE: 'Reunion',
            RO: 'Romania',
            RU: 'Russia',
            RW: 'Rwanda',
            BL: 'San Bartolomeo',
            SH: 'Sant\'Elena',
            KN: 'Saint Kitts e Nevis',
            LC: 'Santa Lucia',
            MF: 'San Martino',
            PM: 'Saint-Pierre e Miquelon',
            VC: 'Saint Vincent e Grenadine',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'São Tomé e Principe',
            SA: 'Arabia Saudita',
            SN: 'Senegal',
            RS: 'Serbia',
            SC: 'Seychelles',
            SL: 'Sierra Leone',
            SG: 'Singapore',
            SK: 'Slovacchia',
            SI: 'Slovenia',
            SB: 'Isole Salomone',
            SO: 'Somalia',
            ZA: 'Sudafrica',
            GS: 'Georgia del Sud e isole Sandwich',
            ES: 'Spagna',
            LK: 'Sri Lanka',
            SD: 'Sudan',
            SR: 'Suriname',
            SJ: 'Svalbard e Jan Mayen',
            SZ: 'Swaziland',
            SE: 'Svezia',
            CH: 'Svizzera',
            SY: 'Repubblica Araba Siriana',
            TW: 'Taiwan',
            TJ: 'Tajikistan',
            TZ: 'Tanzania',
            TH: 'Tailandia',
            TL: 'Timor-Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad e Tobago',
            TN: 'Tunisia',
            TR: 'Turchia',
            TM: 'Turkmenistan',
            TC: 'Isole Turks e Caicos',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ucraina',
            AE: 'Emirati Arabi Uniti',
            GB: 'Regno Unito',
            US: 'Stati Uniti',
            UM: 'Isole periferiche degli Stati Uniti',
            UY: 'Uruguay',
            UZ: 'Uzbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Vietnam',
            VG: 'Isole Vergini britanniche',
            VI: 'Isole Vergini, Stati Uniti',
            WF: 'Wallis e Futuna',
            EH: 'Sahara occidentale',
            YE: 'Yemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe'
        },
    },
    sk: {
        _name: 'Slovak',
        timePickerModal: {
            title: 'Close In Time'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Take Profit a Stop Loss',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            updateOrder: 'Upraviť Objednávku',
        },
        balanceModal: {
            balance: 'Zostatok',
            credit: 'Kredit',
            equity: 'Hodnota',
            practice: 'DEMO',
            real: 'REAL',
            account: 'ÚČET',
            invested: 'Investované',
            profit: 'Profit',
            loss: 'Strata',
            margin: 'Marža',
            marginLevel: 'Úroveň marže',
            marginFree: 'Voľná marža'
        },
        header: {
            cfd: 'CFD',
            platform: 'Platforma',
            deposit: 'Vklad'
        },
        sideMenu: {
            marketWatch: 'Sledovanie trhu',
            activeOrders: 'Aktívne objednávky',
            tradingHistory: 'História obchodov',
            economicCalendar: 'Ekonomický kalendár',
            marketNews: 'Správy z trhu'
        },
        closePositionModal: {
            closeOrder: 'Zatvoriť pozíciu',
            areYouSure: 'Určite chcete zatvoriť pozíciu',
            buy: 'Buy',
            sell: 'Sell',
            yes: 'Áno',
            no: 'Nie',
        },
        pciDssModal: {
            desc: `Dodržiavanie štandardu bezpečnosti údajov priemyslu platobných kariet (PCI DSS) je vyžadované od všetkých subjektov, ktoré ukladajú, spracúvajú alebo prenášajú údaje držiteľa karty Visa/Master Card/Stripe, vrátane finančných inštitúcií, obchodníkov a poskytovateľov služieb. Je to celosvetový bezpečnostný štandard pre všetky subjekty, ktoré ukladajú, spracúvajú alebo prenášajú údaje držiteľa karty a/alebo citlivé autentifikačné údaje. PCI DSS stanovuje základnú úroveň ochrany pre spotrebiteľov a pomáha znížiť podvody a porušenia údajov v celom platobnom ekosystéme.`
        },
        chart: {
            addNewChart: 'Pridať nový graf',
        },
        symbolsModal: {
            watchlist: 'Zoznam sledovaných',
            asset: 'Inštrument',
            price: 'Cena',
            changePct: 'Zmena za 24h'
        },
        pendingModal: {
            pending: 'Čakajúca',
            assetPrice: 'Cena inštrumentu',
            current: 'Aktuálna',
            revert: 'Zmeniť na trhovú cenu',
            automatically: 'Pozícia sa automaticky otvorí, keď cena dosiahne túto úroveň'
        },
        orderMenu: {
            default: 'predvolené',
            volume: 'Objem',
            lots: 'loty',
            units: 'jednotky',
            currency: 'mena',
            contractSize: 'Veľkosť kontraktu',
            position: 'Pozícia',
            margin: 'Marža',
            freeMargin: 'Voľná marža',
            takeProfitStopLoss: 'Take Profit a Stop Loss',
            pending: 'Čakajúca',
            market: 'Trhová',
            leverage: 'Páka',
            spread: 'Spread',
            notSet: 'Nenastavené',
            at: 'na',
            buy: 'buy',
            sell: 'sell',
        },
        footer: {
            supportBanner: 'NONSTOP KAŽDÝ DEŇ',
            currentTime: 'AKTUÁLNY ČAS',
            liveChat: 'Živý Chat'
        },
        statusBar: {
            balance: 'Zostatok',
            credit: 'Kredit',
            equity: 'Hodnota',
            margin: 'Marža',
            marginLevel: 'Úroveň marže',
            freeMargin: 'Voľná marža',
            pnl: 'PnL',
            profitTotal: 'Celkové PnL'
        },
        accountModal: {
            dateRegistered: 'Dátum registrácie',
            userId: 'ID užívateľa',
            dashboard: 'Prehľad',
            personalData: 'Osobné údaje',
            deposit: 'Vklad',
            withdrawFunds: 'Výber prostriedkov',
            savings: 'Sporenie',
            settings: 'Nastavenia',
            logout: 'Odhlásiť'
        },
        depositModal: {
            BT_INVOICE: 'Zadajte sumu a generujte faktúru',
            BT_TITLE: 'Naše bankové údaje',
            account: 'Účet',
            bank_name: 'Názov banky',
            beneficiary: 'Príjemca',
            beneficiary_address: 'Adresa príjemcu',
            swift: 'Swift',
            reference: 'Reference',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Suma',
            TOKEN_SUBTOTAL: 'Medzisúčet',
            TOKEN_TOTAL: 'Spolu',
            TOKEN_DESCRIPTION: 'Popis',
            TOKEN_PAYMENT_DETAILS: 'Platobné údaje',
            PENDING_DEPOSITS_LIMIT: 'Vkladový limit',
            makeDeposit: 'Urobiť vklad',
            practiceAccount: 'Demo účet',
            realAccount: 'Real účet',
            noWithdrawals: 'Bez výberov',
            easyWithdrawals: 'Jednoduché výbery',
            allAssets: 'Dostupné všetky inštrumenty',
            fullFledged: 'Plnohodnotná platforma',
            fillUpTo: 'Doplňte až do',
            freeReplenishment: 'Bezplatné doplnenie',
            topUp: 'Doplňte svoj účet',
            minimumAmount: 'Minimálna suma',
            canSwitch: 'Medzi rôznymi účtami môžete kedykoľvek prepínať'
        },
        gridsModal: {
            chartGrids: 'MRIEŽKA GRAFOV',
            chart1: '1 graf',
            chart2: '2 grafy',
            chart3: '3 grafy',
            chart4: '4 grafy',
        },
        noConnectionModal: {
            connectionLost: 'Spojenie so serverom bolo stratené',
            retryNow: 'Skúsiť teraz',
        },
        loginModal: {
            loginToTradeRoom: 'Prihlásiť do Traderoom',
            email: 'E-mailová adresa',
            enterEmail: 'Zadajte svoj e-mail',
            twoFactor: '2FA Kód (ak je aktivovaný)',
            twoFactorAuth: 'Dvojfaktorová autentifikácia',
            password: 'Heslo',
            yourPassword: 'Vaše heslo',
            signIn: 'Prihlásiť',
            dontHaveAccount: 'Ešte nemáte účet',
            restore: 'Obnoviť',
            fullName: 'Celé meno',
            havePromo: 'Mám promo kód',
            promo: 'Promo kód',
            login: 'Prihlásiť',
            haveAccount: 'Už máte účet?',
            hide: 'Skryť',
            goTo: 'Späť'
        },
        newPasswordModal: {
            setNewPassword: 'Nastaviť nové heslo',
            newPassword: 'Nové heslo',
            confirmNewPassword: 'Potvrďte nové heslo',
            setButton: 'Nastaviť'
        },
        registerModal: {
            repeatPassword: 'Zopakujte heslo',
            createNewAccount: 'Vytvoriť nový účet',
            email: 'E-mailová adresa',
            firstName: 'Krstné meno',
            lastName: 'Priezvisko',
            country: 'Štát',
            phone: 'Tel. číslo',
            password: 'Heslo',
            createAccount: 'Vytvoriť účet',
            currency: 'Mena',
            privacyAndPolicy: "Ochrana súkromia a politika",
            customerAgreement: 'Zmluva so zákazníkom',

        },
        forgotPasswordModal: {
            forgotPassword: 'Zabudnuté heslo?',
            submitEmail: 'Prosím, zadajte e-mailovú adresu, ktorá bola použitá pri registrácii, skontrolujte prijatú poštu pre inštrukcie',
            submit: 'Odoslať'
        },
        notifications: {
            error: 'Chyba',
            success: 'Úspešné',
            deposit: 'Vklad',
            withdrawal: 'Výber',
            depositApproved: 'Vklad bol schválený',
            depositDeclined: 'Vklad bol zamietnutý',
            withdrawalApproved: 'Výber bol schválený',
            withdrawalDeclined: 'Výber bol zamietnutý'
        },
        markets: {
            forex: 'Forex',
            stocks: 'Akcia',
            commodities: 'Komodity',
            indices: 'Indexy',
            crypto: 'Crypto',
            metals: 'Kovy',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'BUY',
            profitCalculator: 'Kalkulačka profitu',
            sell: 'SELL',
            volume: 'Objem',
            entryPrice: 'Vstupná cena',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            maxPosition: 'Max pozícia',
            calculate: 'Kalkulovať',
            calculationsFor: 'Kalkulácie pre',
            leverage: 'Páka',
            requiredMargin: 'Potrebná marža',
            profitFromTP: 'Profit z TP',
            lossFromSL: 'Strata zo SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Vklad bol úspešný',
            text: 'Váš vklad bol úspešne spracovaný!',
        },
        depositFailModal: {
            title: 'Vklad zlyhal',
            text: 'Váš vklad nebol spracovaný.',
        },
        widgetMarketWatch: {
            marketWatch: 'Sledovanie trhu',
            search: 'Vyhľadávanie...',
        },
        widgetOrders: {
            commission: 'Komisia',
            volume: 'Objem',
            margin: 'Marža',
            active: 'Aktívne',
            pending: 'Čakajúce',
            activeOrders: 'Aktívne objednávky',
            portfolio: 'Portfólio',
            allPositions: 'Všetky pozície',
            noPositionsLine1: 'Ešte nemáte otvorené',
            noPositionsLine2: 'žiadne pozície',
            show: 'Zobraziť',
            more: 'viac',
            less: 'menej',
            purchaseTime: 'Čas nákupu',
            closePosition: 'Uzavrieť pozíciu',
            priceOpen: 'Nákupná cena',
            priceSl: 'Cena Stop Loss',
            priceTp: 'Cena Take Profit',
            type: 'Smer pozície',
            pnl: 'Profit/Strata',
            cancelOrder: 'Zrušiť objednávku',
            orderType: 'Typ objednávky',
            cancelled: 'Zrušená',
            tradingHistory: 'História obchodov',
            noHistoryLine1: 'Ešte nemáte žiadne',
            noHistoryLine2: 'uzavreté obchody',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Ekonomický kalendár'
        },
        widgetMarketNews: {
            marketNews: 'Správy z trhu'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'Aktívne objednávky',
            ordersHistory: 'História objednávok',
            id: 'ID',
            symbol: 'Symbol',
            type: 'Typ',
            volume: 'Objem',
            openPrice: 'Otváracia cena',
            openTime: 'Otváracia čas',
            closePrice: 'Zatváracia cena',
            closeTime: 'Zatváracia čas',
            sl: 'SL',
            tp: 'TP',
            price: 'Cena',
            pnl: 'PnL',
            actions: 'Možnosti',
            edit: 'Upraviť',
            close: 'Zavrieť',
            commission: 'Poplatok',
            swap: 'Swap',
            swapLong: 'Swap Short',
            swapShort: 'Swap Long'
        },
        assetInfoModal: {
            assetInfo: 'Informácie o inštrumente',
            tradingConditions: 'Obchodné podmienky',
            information: 'Informácie',
            bid: 'Bid',
            ask: 'Ask',
            sessionChange: 'Zmena seansy',
            tradeNow: 'Obchodovať teraz',
            opens: 'Otvára sa',
            closes: 'Zatvára sa',
            at: 'o',
            open247: 'Otvorené 24/7',
            today: 'dnes',
            tomorrow: 'zajtra',
            sunday: 'Nedeľa',
            monday: 'Pondelok',
            tuesday: 'Utorok',
            wednesday: 'Streda',
            thursday: 'Štvrtok',
            friday: 'Piatok',
            saturday: 'Sobota',
            contractSpecification: 'Špecifikácia kontraktu',
            symbol: 'Symbol',
            name: 'Názov',
            market: 'Trh',
            baseCurrency: 'Základná mena',
            digits: 'Desatinné miesta',
            lotSize: 'Veľkosť lotu',
            lotStep: 'Krok lotu',
            minVolume: 'Minimálny objem',
            maxVolume: 'Maximálny objem',
            leverage: 'Páka',
            commission: 'Komisia',
            swapLong: 'Swap Long',
            swapShort: 'Swap Short',
            schedule: 'Rozvrh',
            weekday: 'Pracovný deň',
            tradingTime: 'Čas obchodovania',
            closed: 'uzavreté',
            sell: 'Sell',
            buy: 'Buy',
            low: 'Nízka',
            high: 'Vysoká',
            oneHourChange: 'Zmena za 1 hodinu',
            oneDayChange: 'Zmena za 1 deň',
            oneWeekChange: 'Zmena za 1 týždeň',
            oneMonthChange: 'Zmena za 1 mesiac',
            threeMonthsChange: 'Zmena za 3 mesiace',
            oneYearChange: 'Zmena za 1 rok',
            loading: 'Načítavanie...',
        },
        autoTrader: {
            days: 'days',
            title: 'Auto Trader',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Risk Level: Low',
            riskLevelMedium: 'Risk Level: Medium',
            riskLevelHigh: 'Risk Level: High',
            perDay: 'Trades per day:',
            desc: `Trading CFDs and other leveraged products can lead to losses. Before trading, clients should
                read the
                relevant risk statements on our Risk Disclosure page. Automated trading does not guarantee results. The
                company accepts no responsibility for the loss of funds in automatic trading. Please make sure that you
                fully understand the risks and take measures to manage risks.`,
            accept: 'Accept',
            save: 'Uložiť',
            cancel: 'Zrušiť',
        },
        cardBox: {
            name: 'Meno',
            cardNumber: 'Číslo karty',
            expiration: 'Expirácia (mm/rr)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "Obchodovať",
            startTrading: 'Začať obchodovať',
            deposit: 'Vklad',
            dashboard: 'Prehľad',
            personalInfo: 'Osobné údaje',
            contactInfo: 'Kontaktné informácie',
            withdrawal: 'Výber',
            verification: 'Overenie',
            accounts: 'Účty',
            liveChat: 'Živý Chat',
            savings: 'Sporenie',
            settings: 'Nastavenia',
            logOut: 'Odhlásiť',
        },
        fullScreenDeposit: {
            methods: 'METHODS',
            sendOtp: 'Poslať otp kód',
            otpCode: 'Otp kód',
            addWalletAddress: 'Peňaženka pre tento spôsob vkladu nie je nastavená. Obráťte sa prosím na svojho zástupcu alebo tím podpory.',
            addressReceipt: 'Počkajte, kým sa adresa načíta',
            makeDeposit: 'UROBIŤ VKLAD',
            instant: 'Okamžité',
            minutes: 'minúty',
            hours: 'hodiny',
            days: 'dni',
            amount: 'Suma',
            continue: 'Pokračovať',
            qrCode: 'QR KÓD',
            depositAddress: 'ADRESA VKLADU',
            copy: 'Kopírovať',
            last: 'POSLEDNÉ',
            deposits: 'VKLADY',
            time: 'Čas',
            currency: 'Mena',
            status: 'Stav',
            info: 'Info',
        },
        fullScreenCcExt: {
            pleaseWait: 'Prosím, počkajte. Pripájanie k poskytovateľovi platobného systému...',
            cardPayment: 'Platba kartou',
            inOrder: 'Aby ste mohli odoslať danú sumu,',
            pleaseProvide: 'prosím, poskytnite údaje karty:'
        },
        fullScreenDashboard: {
            completeVerification: 'Please Complete Verification',
            noOrderYet: 'No order yet',
            totalBalance: 'Celkový zostatok',
            totalPnl: 'Celkové PNL',
            profitableOrders: 'Ziskové objednávky',
            roi: 'ROI',
            activityLog: 'Log aktivity',
            loginFromIp: 'Prihlásenie z IP',
            tradingResults: 'Obchodné výsledky',
            week: 'Týždeň',
            month: 'Mesiac',
            year: 'Rok',
            successRate: 'Pomer úspešných',
            closedWithProfit: 'Uzavreté s profitom',
            closedWithLoss: 'Uzavreté so stratou',
            account: 'Účet',
            balance: 'Zostatok',
            leverage: 'Páka',
            credit: 'Kredit',
            tradeNow: 'Obchodovať teraz',
            usingCurrentRate: 's použitím aktuálnych výmenných kurzov',
            totalDeposits: 'Celkové vklady'
        },
        fullScreenPersonal: {
            personalDetails: 'Osobné údaje',
            profilePhoto: 'Profilová fotka',
            firstName: 'Krstné meno',
            country: 'Štát',
            lastName: 'Priezvisko',
            address: 'Adresa',
            email: 'E-mail',
            phone: 'Tel. číslo',
            saveChanges: 'Uložiť zmeny',
            state: 'Mesto',
            postcode: 'PSČ',
            created: "Účet vytvorený",
        },
        fullScreenAvatar: {
            dropFile: 'Pretiahnite súbor na kruh vyššie pre nahratie',
            notAllowed: 'Nie je povolené zverejniť',
            notAllowedLine1: 'Fotografie s explicitne sexuálnym alebo pornografickým obsahom',
            notAllowedLine2: 'Obrázky s cieľom podnecovať etnickú alebo rasovú nenávisť alebo agresiu',
            notAllowedLine3: 'Fotografie zobrazujúce osoby mladšie ako 18 rokov',
            notAllowedLine4: 'Fotografie chránené autorskými právami tretích strán',
            notAllowedLine5: 'Obrázky väčšie ako 5 MB a v inom formáte ako JPG, GIF alebo PNG',
            requirements: 'Vaša tvár musí byť na fotografii jasne viditeľná. Všetky fotografie a videá odoslané vami musia spĺňať tieto požiadavky, inak môžu byť odstránené.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Prosím, kontaktujte vášho správcu účtu pre podrobnosti o výbere',
            cryptocurrency: 'Cryptocurrency',
            withdrawal: 'Výber',
            requestNewWithdrawal: 'POŽIADAŤ O NOVÝ VÝBER',
            amount: 'Suma',
            currentBalance: 'Váš aktuálny zostatok na účte je',
            withdrawAll: 'Vybrať všetko',
            requestWithdrawal: 'Požiadať o výber',
            last: 'POSLEDNÉ',
            withdrawalRequests: 'POŽIADAVKY O VÝBER',
            time: 'Čas',
            currency: 'Mena',
            info: 'Info',
            status: 'Stav',
            bank: 'Banka',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            card: 'Karta',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Podrobnosti o výbere',
            address: 'Adresa peňaženky',
            bankAccount: 'Číslo účtu',
            bankHolder: 'Meno držiteľa účtu',
            bankIban: 'IBAN',
            bankSwift: 'SWIFT kód banky',
            recipientAddress: 'Adresa príjemcu',
            routingNumber: 'Smerové číslo',
            bankBeneficiary: 'Názov banky príjemcu',
            bankAddress: 'Adresa banky',
            bankCode: 'Bankový kód',
            desc: 'Popis',
            cardNumber: 'Číslo karty',
            cardHolder: 'Meno držiteľa karty'
        },
        fullScreenVerification: {
            dragAndDrop: 'presuňte dokument do tejto oblasti',
            documentsList: 'ZOZNAM NAHRATÝCH SÚBOROV',
            document: 'Doklad',
            timeUploaded: 'Čas nahratia',
            timeProcessed: 'Čas spracovania',
            status: 'Stav',
            types: ['Doklad totožnosti', 'Doklad o adrese', 'Predná strana kreditnej karty', 'Zadná strana kreditnej karty', 'Doklad totožnosti zadná strana', 'Selfie'],
            types2: ['Doklad totožnosti (POI)', 'Doklad o trvalom bydlisku', 'Predná strana kreditnej karty', 'Zadná strana kreditnej karty', 'Doklad totožnosti zadná strana', 'Selfie spolu s dokladom'],
            upload: 'Nahrať',
            isConfirmed: 'potvrdené',
            uploadAnotherDocument: 'Nahrať ďalší dokument',
            isUnderReview: 'posudzuje sa',
            questionnaire: 'Dotazník',
            sendAnswers: 'Odoslať odpovede',
            progressBarOf: 'z',
            progressBarText: 'vašich dokumentov je nahraných a potvrdených'
        },
        fullScreenAccounts: {
            manageAccounts: 'Previesť prostriedky medzi vašimi účtami',
            transferFrom: 'Z účtu',
            transferTo: 'Na účet',
            amount: 'Čiastka',
            makeTransfer: 'Previesť',
            yourAccounts: 'Vaše účty',
            account: 'Účet',
            currency: 'Mena',
            balance: 'Zostatok',
            credit: 'Kredit',
            makeActive: 'Aktivovať',
            active: 'Aktívny'
        },
        fullScreenSupport: {
            sendMessage: 'Odoslať správu'
        },
        fullScreenSavings: {
            pct_0: 'Flexibilné',
            pct_30: 'Uzamknuté na 1 mesiac',
            pct_90: 'Uzamknuté na 1 kvartál',
            pct_180: 'Uzamknuté na 6 mesiacov',
            pct_365: 'Uzamknuté na 1 rok',
            savings: 'Sporenie',
            detailedInformation: 'Podrobné informácie',
            pleaseSelect: 'Prosím, vyberte menu a časové obdobie',
            openSavings: 'Otvoriť sporenie',
            currentTime: 'Aktuálny čas',
            releaseTime: 'Čas odomknutia',
            currencyChosen: 'Vybraná mena',
            periodChosen: 'Vybrané obdobie',
            yourRate: 'Vaša sadzba',
            yourEarnings: 'Vaše zárobky',
            accountFrom: 'Účet, z ktorého sa prevedú prostriedky',
            enterAmount: 'Zadajte sumu',
            in: 'V',
            openAndInvest: 'Otvoriť sporiaci účet a investovať',
            investment: 'Investícia',
            period: 'Obdobie',
            daysTotal: 'Spolu dní',
            finalEarnings: 'Finálne zárobky',
            created: 'Vytvorené',
            daysElapsed: 'Ubehlo dní',
            days: 'dní',
            earnings: 'zárobky',
            fundsReleasedIn: 'Prostriedky odomknuté',
            claim: 'Prevziať',
        },
        fullScreenSettings: {
            updatePassword: 'AKTUALIZOVAŤ HESLO',
            currentPassword: 'Aktuálne heslo',
            newPassword: 'Nové heslo',
            changePassword: 'Zmeniť heslo',
            activationForGoogle: 'Aktivačný kód pre Google Authenticator',
            activateAndEnter: 'Aktivujte Authenticator a zadajte generovaný kód do poľa nižšie',
            qrCode: 'QR kód',
            activateProtection: 'Aktivovať ochranu 2FA',
            protectionActive: 'Ochrana 2FA je aktívna',
            twoFactorCode: 'Kód 2FA',
            disableProtection: 'Vypnúť ochranu 2FA',
            language: 'Jazyk',
            dashboardCurrency: 'Mena prehľadu',
            confirmNewPassword: 'Potvrďte nové heslo',
        },
        fullScreenReferrals: {
            yourLink: 'VÁŠ REFERRAL ODKAZ',
            referredFriends: 'ODKÁZANÍ PRIATELIA',
            id: 'ID',
            name: 'Meno',
            registered: 'Registrovaný',
            level: 'Level',
            payout: 'Vyplatenie',
            lastPayouts: 'POSLEDNÉ REFERRAL VYPLATENIA',
            time: 'Čas',
            amount: 'Suma',
            currency: 'Mena',
            info: 'Info'
        },
        months: {
            Jan: 'Jan',
            Feb: 'Feb',
            Mar: 'Mar',
            Apr: 'Apr',
            May: 'Máj',
            Jun: 'Jún',
            Jul: 'Júl',
            Aug: 'Aug',
            Sep: 'Sep',
            Oct: 'Okt',
            Nov: 'Nov',
            Dec: 'Dec'
        },
        orderTypes: ['Buy', 'Sell', 'Buy Limit', 'Sell Limit', 'Buy Stop', 'Sell Stop'],
        statuses: ['Čakajúca', 'Schválená', 'Zamietnutá', 'Spracúva sa'],
        errors: {
            NOT_FOUND: 'Chyba požiadavky',
            FORBIDDEN: 'Prístup zamietnutý. Prosím, prihláste sa znova',
            SERVER_ERROR: 'Akcia zlyhala. Skúste to prosím neskôr',
            INVALID_ACCOUNT: 'Platnosť relácie vypršala. Prosím, prihláste sa znova',
            INVALID_EMAIL: 'Neplatná e-mailová adresa',
            INVALID_PHONE: 'Neplatné telefónne číslo',
            INVALID_COUNTRY: 'Neplatný štát',
            INVALID_LOGIN: 'Neplatné prihlasovacie údaje',
            USER_BLOCKED: 'Účet je blokovaný. Prosím, obráťte sa na podporu',
            USER_REGISTERED: 'Používateľ so zadanou e-mailovou adresou alebo telefónnym číslom je už zaregistrovaný',
            INVALID_USER: 'Používateľ nebol nájdený',
            REJECTED: 'Operácia nie je povolená kvôli obmedzeniam platformy',
            INVALID_OLD_PASSWORD: 'Neplatné staré heslo',
            INVALID_SYMBOL: 'Neplatný obchodný symbol',
            INVALID_SYMBOL_PRECISION: 'Neplatná presnosť symbolu',
            INVALID_FRAME: 'Neplatný časový rámec grafu',
            INVALID_CURRENCY: 'Neplatná mena',
            INVALID_PRODUCT: 'Neplatný typ obchodovania účtu',
            INSUFFICIENT_FUNDS: 'Nedostatok prostriedkov',
            INVALID_WITHDRAWAL: 'Výber sa nenašiel',
            INVALID_STATUS: 'Neplatný stav',
            INVALID_FILE: 'Neplatná prípona súboru alebo súbor je príliš veľký',
            INVALID_DOCUMENT: 'Dokument sa nenašiel',
            INVALID_NAME: 'Neplatné meno',
            INVALID_LOT_SIZE: 'Neplatná veľkosť lotu',
            INVALID_LOT_STEP: 'Neplatný krok lotu',
            INVALID_MIN_VOLUME: 'Neplatný minimálny objem',
            INVALID_MAX_VOLUME: 'Neplatný maximálny objem',
            INVALID_GROUP: 'Neplatná skupina',
            INVALID_SPREAD: 'Neplatný spread',
            REQUEST_TIMEOUT: 'Vypršal časový limit požiadavky. Skúste to prosím neskôr',
            EXISTING_OPEN_ORDERS: 'Máte otvorenú objednávku, najprv uzavrite objednávku pre tohto používateľa a skúste to znova.',
            INVALID_TYPE: 'Neplatný typ objednávky',
            INVALID_VOLUME: 'Neplatný objem objednávky',
            INVALID_SL: 'Neplatná hodnota stop loss',
            INVALID_TP: 'Neplatná hodnota take profit',
            INVALID_PRICE: 'Neplatná cena objednávky',
            INVALID_EXPIRATION: 'Neplatná expirácia objednávky',
            NOT_ENOUGH_MARGIN: 'Nedostatočná marža',
            INVALID_ORDER: 'Objednávka sa nenašla',
            MARKET_CLOSED: 'Obchodovanie nie je k dispozícii. Skúste to prosím znova',
            INVALID_BALANCE_TYPE: 'Neplatný typ operácie so zostatkom',
            INVALID_HASH: 'Neplatný hash',
            HASH_EXPIRED: 'Platnosť vášho odkazu na obnovenie hesla vypršala. Požiadajte prosím o nový',
            INVALID_CODE: 'Neplatný 2FA kód',
            CHAT_DISABLED: 'Prístup k chatu s podporou bol uzatvorený',
            WITHDRAWAL_NOT_ALLOWED: 'Prístup k výberom bol uzatvorený',
            TRADING_DISABLED: 'Obchodovanie je zakázané pre aktuálny účet',
            PENDING_DEPOSITS_LIMIT: 'Prekročený limit vkladov',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Povolený je len jeden čakajúci výber',
            LOCAL_DEPOSIT_ONLY_REAL: 'Vklad je možný iba pre reálny účet',
            LOCAL_DEPOSIT_POSITIVE: 'Suma vkladu musí byť viac ako 0',
            LOCAL_INVALID_CARD_NUMBER: 'Neplatné číslo karty',
            LOCAL_INVALID_CARD_EXPIRATION: 'Neplatná platnosť karty',
            LOCAL_INVALID_CVC: 'Neplatný kód CVV/CVC',
            LOCAL_PASSWORDS_NOT_MATCH: 'Heslá sa nezhodujú, zadajte ich prosím znova',
            LOCAL_NO_TRADING_ACCOUNTS: 'Nemáte k dispozícii žiadne obchodné účty. Obráťte sa prosím na podporu',
            LOCAL_MIN_DEPOSIT: 'Minimálny vklad je ',
            LOCAL_MIN_WITHDRAWAL: 'Minimálna čiastka výberu je ',
            LOCAL_INVALID_FILE_TYPE: 'Nepodporovaný formát súboru. Pripojte prosím iba súbory JPG, PNG alebo PDF',
            LOCAL_MIN_STAKING: 'Minimálny vklad pre túto menu je ',
            FLOOD_REJECTED: 'Posielate správy príliš často. Skúste to prosím neskôr'
        },
        countries: {
            AF: 'Afganistan',
            AX: 'Alandské ostrovy',
            AL: 'Albánsko',
            DZ: 'Alžírsko',
            AS: 'Americká Samoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarktída',
            AG: 'Antigua a Barbuda',
            AR: 'Argentína',
            AM: 'Arménsko',
            AW: 'Aruba',
            AU: 'Austrália',
            AT: 'Rakúsko',
            AZ: 'Azerbajdžan',
            BS: 'Bahamy',
            BH: 'Bahrajn',
            BD: 'Bangladéš',
            BB: 'Barbados',
            BY: 'Bielorusko',
            BE: 'Belgicko',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermudy',
            BT: 'Bhután',
            BO: 'Bolívia',
            BA: 'Bosna a Hercegovina',
            BW: 'Botswana',
            BV: 'Bouvetov ostrov',
            BR: 'Brazília',
            IO: 'Britské indickooceánske územie',
            BN: 'Brunej Darussalam',
            BG: 'Bulharsko',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Kambodža',
            CM: 'Kamerun',
            CA: 'Kanada',
            CV: 'Kapverdy',
            KY: 'Kajmanské ostrovy',
            CF: 'Stredoafrická republika',
            TD: 'Čad',
            CL: 'Čile',
            CN: 'Čína',
            CX: 'Vianočný ostrov',
            CC: 'Kokosové (Keelingove) ostrovy',
            CO: 'Kolumbia',
            KM: 'Komory',
            CG: 'Kongo',
            CD: 'Konžská demokratická republika',
            CK: 'Cookove ostrovy',
            CR: 'Kostarika',
            CI: 'Pobrežie Slonoviny',
            HR: 'Chorvátsko',
            CU: 'Kuba',
            CY: 'Cyprus',
            CZ: 'Česká republika',
            DK: 'Dánsko',
            DJ: 'Džibutsko',
            DM: 'Dominika',
            DO: 'Dominikánska republika',
            EC: 'Ekvádor',
            EG: 'Egypt',
            SV: 'Salvador',
            GQ: 'Rovníková Guinea',
            ER: 'Eritrea',
            EE: 'Estónsko',
            ET: 'Etiópia',
            FK: 'Falklandské ostrovy (Malvíny)',
            FO: 'Faerské ostrovy',
            FJ: 'Fidži',
            FI: 'Fínsko',
            FR: 'Francúzsko',
            GF: 'Francúzska Guyana',
            PF: 'Francúzska Polynézia',
            TF: 'Francúzske južné a antarktické územia',
            GA: 'Gabon',
            GM: 'Gambie',
            GE: 'Gruzínsko',
            DE: 'Nemecko',
            GH: 'Ghana',
            GI: 'Gibraltár',
            GR: 'Grécko',
            GL: 'Grónsko',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Heardov ostrov a McDonaldove ostrovy',
            VA: 'Svätá stolica (Vatikánsky mestský štát)',
            HN: 'Honduras',
            HK: 'Hongkong',
            HU: 'Maďarsko',
            IS: 'Island',
            IN: 'India',
            ID: 'Indonézia',
            IR: 'Irán',
            IQ: 'Irak',
            IE: 'Írsko',
            IM: 'Ostrov Man',
            IL: 'Izrael',
            IT: 'Taliansko',
            JM: 'Jamajka',
            JP: 'Japonsko',
            JE: 'Jersey',
            JO: 'Jordánsko',
            KZ: 'Kazachstan',
            KE: 'Keňa',
            KI: 'Kiribati',
            KR: 'Kórejská republika',
            KW: 'Kuvajt',
            KG: 'Kirgizsko',
            LA: 'Laoská ľudovodemokratická republika',
            LV: 'Lotyšsko',
            LB: 'Libanon',
            LS: 'Lesotho',
            LR: 'Libéria',
            LY: 'Líbya',
            LI: 'Lichtenštajnsko',
            LT: 'Litva',
            LU: 'Luxembursko',
            MO: 'Macao',
            MK: 'Macedónsko',
            MG: 'Madagaskar',
            MW: 'Malawi',
            MY: 'Malajzia',
            MV: 'Maldivy',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marshallove ostrovy',
            MQ: 'Martinik',
            MR: 'Mauritánia',
            MU: 'Maurícius',
            YT: 'Mayotte',
            MX: 'Mexiko',
            FM: 'Mikronézia',
            MD: 'Moldavsko',
            MC: 'Monako',
            MN: 'Mongolsko',
            ME: 'Čierna Hora',
            MS: 'Montserrat',
            MA: 'Maroko',
            MZ: 'Mozambik',
            MM: 'Mjanmarsko (Barma)',
            NA: 'Namíbia',
            NR: 'Nauru',
            NP: 'Nepál',
            NL: 'Holandsko',
            AN: 'Holandské Antily',
            NC: 'Nová Kaledónia',
            NZ: 'Nový Zéland',
            NI: 'Nikaragua',
            NE: 'Niger',
            NG: 'Nigéria',
            NU: 'Niue',
            NF: 'Ostrov Norfolk',
            MP: 'Severné Mariány',
            NO: 'Nórsko',
            OM: 'Omán',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Palestínske územie, okupované',
            PA: 'Panama',
            PG: 'Papua-Nová Guinea',
            PY: 'Paraguaj',
            PE: 'Peru',
            PH: 'Filipíny',
            PN: 'Pitcairnove ostrovy',
            PL: 'Poľsko',
            PT: 'Portugalsko',
            PR: 'Portoriko',
            QA: 'Katar',
            RE: 'Réunion',
            RO: 'Rumunsko',
            RU: 'Rusko',
            RW: 'Rwanda',
            BL: 'Svätý Bartolomej',
            SH: 'Svätá Helena',
            KN: 'Svätý Krištof a Nevis',
            LC: 'Svätá Lucia',
            MF: 'Svätý Martin',
            PM: 'Saint Pierre a Miquelon',
            VC: 'Svätý Vincent a Grenadíny',
            WS: 'Samoa',
            SM: 'San Maríno',
            ST: 'Svätý Tomáš a Princov ostrov',
            SA: 'Saudská Arábia',
            SN: 'Senegal',
            RS: 'Srbsko',
            SC: 'Seychely',
            SL: 'Sierra Leone',
            SG: 'Singapur',
            SK: 'Slovensko',
            SI: 'Slovinsko',
            SB: 'Šalamúnove ostrovy',
            SO: 'Somálsko',
            ZA: 'Južná Afrika',
            GS: 'Južná Georgia a Južné Sandwichove ostrovy',
            ES: 'Španielsko',
            LK: 'Srí Lanka',
            SD: 'Sudán',
            SR: 'Surinam',
            SJ: 'Svalbard a Jan Mayen',
            SZ: 'Svazijsko',
            SE: 'Švédsko',
            CH: 'Švajčiarsko',
            SY: 'Sýria',
            TW: 'Taiwan',
            TJ: 'Tadžikistan',
            TZ: 'Tanzánia',
            TH: 'Thajsko',
            TL: 'Východný Timor',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad a Tobago',
            TN: 'Tunisko',
            TR: 'Turecko',
            TM: 'Turkménsko',
            TC: 'Turks a Caicos',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukrajina',
            AE: 'Spojené arabské emiráty',
            GB: 'Spojené kráľovstvo',
            US: 'Spojené štáty',
            UM: 'Menšie odľahlé ostrovy USA',
            UY: 'Uruguaj',
            UZ: 'Uzbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Vietnam',
            VG: 'Britské Panenské ostrovy',
            VI: 'Americké Panenské ostrovy',
            WF: 'Wallis a Futuna',
            EH: 'Západná Sahara',
            YE: 'Jemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe'
        },
    },
    hu: {
        _name: 'Hungarian',
        timePickerModal: {
            title: 'Close In Time'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Take Profit és Stop Loss',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            updateOrder: 'Megbízás frissítése ',
        },
        balanceModal: {
            balance: 'Egyenleg',
            credit: 'Kredit',
            equity: 'Mennyiség',
            practice: 'Gyakorlás',
            real: 'Éles ',
            account: 'SZÁMLA',
            invested: 'Befektetve',
            profit: 'Profit',
            loss: 'Veszteség',
            margin: 'Tőke',
            marginLevel: 'Tőke szint',
            marginFree: 'Szabad Tőke'
        },
        header: {
            cfd: 'CFD',
            platform: 'Platform',
            deposit: 'Befizetés'
        },
        sideMenu: {
            marketWatch: 'Piacfigyelő ',
            activeOrders: 'Aktív megbizások',
            tradingHistory: 'Számlatörénet',
            economicCalendar: 'Gazdasági naptár',
            marketNews: 'Piaci hírek'
        },
        closePositionModal: {
            closeOrder: 'Pozíció zárása',
            areYouSure: 'Biztos, hogy zárni akarja a pozíciót?',
            buy: 'Vétel',
            sell: 'Eladás',
            yes: 'Igen',
            no: 'Nem',
        },
        pciDssModal: {
            desc: `A Payment Card Industry Data Security Standard (PCI DSS) szabvány betartása kötelező minden olyan szervezetre, amely tárolja, feldolgozza vagy továbbítja a Visa/Master Card/Stripe kártyabirtokosok adatait, beleértve a pénzintézeteket, kereskedőket és szolgáltatókat. Ez a globális biztonsági szabvány minden olyan szervezetre vonatkozik, amely tárolja, feldolgozza vagy továbbítja a kártyabirtokosok adatait és/vagy érzékeny hitelesítési adatokat. A PCI DSS alapvető szintű védelmet biztosít a fogyasztók számára, és segít csökkenteni a csalásokat és az adatszivárgásokat az egész fizetési ökoszisztémában.`
        },
        chart: {
            addNewChart: 'új chart megnyitása',
        },
        symbolsModal: {
            watchlist: 'Megfigyelő lista',
            asset: 'Eszköz',
            price: 'Ár',
            changePct: 'Napi mozgás'
        },
        pendingModal: {
            pending: 'Függőben',
            assetPrice: 'Eszköz ára',
            current: 'Aktuális',
            revert: 'Vissza a piaci árhoz',
            automatically: 'A pozíció automatikusan megnyílik, ha az ár eléri ezt a szintet.'
        },
        orderMenu: {
            default: 'alapértelmezett',
            volume: 'Volumen',
            lots: 'lot-ok',
            units: 'egységek',
            currency: 'pénznem',
            contractSize: 'Kötési méret',
            position: 'Pozíció',
            margin: 'Tőke',
            freeMargin: 'Szabad Tőke',
            takeProfitStopLoss: 'Take Profit és Stop Loss',
            pending: 'Függőben',
            market: 'Piac',
            leverage: 'Áttét',
            spread: 'Szórás',
            notSet: 'Nincs beállítva',
            at: '-nál',
            buy: 'vétel',
            sell: 'eladás',
        },
        footer: {
            supportBanner: 'MINDEN NAP, ÉJJEL-NAPPAL',
            currentTime: 'AKTUÁLIS IDŐ',
            liveChat: 'Élő Chat'
        },
        statusBar: {
            balance: 'Egyenleg',
            credit: 'Kredit',
            equity: 'Mennyiség',
            margin: 'Tőke',
            marginLevel: 'Tőke szint',
            freeMargin: 'Szabad tőke',
            pnl: 'PnL',
            profitTotal: 'Összesített PnL'
        },
        accountModal: {
            dateRegistered: 'Nyilvántartásba vétel dátuma',
            userId: 'Felhasználó ID',
            dashboard: 'Vezérlőpult',
            personalData: 'Személyes Adat',
            deposit: 'Befizetés',
            withdrawFunds: 'Kivont alapok',
            savings: 'Megtakarítások',
            settings: 'Beállítások',
            logout: 'Kijelentkezés'
        },
        depositModal: {
            BT_INVOICE: 'Az összeg megadása és a számla létrehozása',
            BT_TITLE: 'Banki részletek',
            account: 'Felhasználó',
            bank_name: 'Bank neve',
            beneficiary: 'Kedvezményezett',
            beneficiary_address: 'Beneficiary Address',
            swift: 'Swift',
            reference: 'Reference',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Mennyiség',
            TOKEN_SUBTOTAL: 'Részösszeg',
            TOKEN_TOTAL: 'Összesített',
            TOKEN_DESCRIPTION: 'Leírás',
            TOKEN_PAYMENT_DETAILS: 'Utalási részletek',
            PENDING_DEPOSITS_LIMIT: 'Befizetési Limit',
            makeDeposit: 'Fizessen be',
            practiceAccount: 'Gyakorló számla',
            realAccount: 'Élő számla',
            noWithdrawals: 'Nincs kifizetés',
            easyWithdrawals: 'Egyszerű kifizetés',
            allAssets: 'Minden eszköz elérhető',
            fullFledged: 'Teljes körű platform',
            fillUpTo: 'Töltse fel',
            freeReplenishment: 'Ingyenes feltöltés',
            topUp: 'Töltse fel számláját',
            minimumAmount: 'Minimum mennyiség',
            canSwitch: 'Bármikor válthat a számlái közt'
        },
        gridsModal: {
            chartGrids: 'CHART Rácsok',
            chart1: '1 chart',
            chart2: '2 chart',
            chart3: '3 chart',
            chart4: '4 chart',
        },
        noConnectionModal: {
            connectionLost: 'Megszakadt a kapcsolat a szerverrel',
            retryNow: 'Újrapróbálkozás',
        },
        loginModal: {
            loginToTradeRoom: 'Bejelentkezés a kereskedőfelületre',
            email: 'Email cím',
            enterEmail: 'Írja be az email címét',
            twoFactor: '2FA Kód (ha engedélyezett)',
            twoFactorAuth: 'Két faktoros azonosítás',
            password: 'Jelszó',
            yourPassword: 'Az ön jelszava',
            signIn: 'Bejelentkezés',
            dontHaveAccount: 'Nincs még számlája?',
            restore: 'Visszaállítás',
            fullName: 'Teljes név',
            havePromo: 'Van promóciós kódom',
            promo: 'Promóciós kód',
            login: 'Bejelentkezés',
            haveAccount: 'Már van számlája?',
            hide: 'Elrejtés',
            goTo: 'Lépjen vissza'
        },
        newPasswordModal: {
            setNewPassword: 'Új jelszó beállítása',
            newPassword: 'Új jelszó',
            confirmNewPassword: 'Új jelszó megerősítése',
            setButton: 'Beállítás'
        },
        registerModal: {
            repeatPassword: 'Jelszó ismét',
            createNewAccount: 'Készítsen új fiókot',
            email: 'Email cím',
            firstName: 'Keresztnév',
            lastName: 'Vezetéknév',
            country: 'Ország',
            phone: 'Telefonszám',
            password: 'Jelszó',
            createAccount: 'Fiók létrehozása',
            currency: 'Valuta',
            privacyAndPolicy: "Adatvédelem és Szabályzat",
            customerAgreement: 'Ügyfélmegállapodás',

        },
        forgotPasswordModal: {
            forgotPassword: 'Elfelejtette a jelszavát?',
            submitEmail: 'Kérjük, küldje el a regisztrációhoz használt e-mailt, ellenőrizze postaládáját, és kövesse a megadott utasításokat.',
            submit: 'Elküldés'
        },
        notifications: {
            error: 'Hiba',
            success: 'Sikeres',
            deposit: 'Befizetés',
            withdrawal: 'Kivétel',
            depositApproved: 'A befizetést jóvá hagyták',
            depositDeclined: 'A befizetést elutasították',
            withdrawalApproved: 'A kivételt jóvá hagyták',
            withdrawalDeclined: 'A kivételt elutasították'
        },
        markets: {
            forex: 'Forex',
            stocks: 'Kötvények',
            commodities: 'Áruk',
            indices: 'Indexek',
            crypto: 'Kriptó',
            metals: 'Nyersanyagok',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'VÉTEL',
            profitCalculator: 'Profit Kalkulátor',
            sell: 'ELADÁS',
            volume: 'Volumen',
            entryPrice: 'Belépési ár',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            maxPosition: 'Maximum pozíció',
            calculate: 'Kalkuláció',
            calculationsFor: 'Kalkuláció',
            leverage: 'Tőkeáttét',
            requiredMargin: 'Elvárt tőke',
            profitFromTP: 'Profit a TP-ből',
            lossFromSL: 'Veszteség a SL-ból',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Befizetés sikeres',
            text: 'A befizetését sikeresen feldolgoztuk!',
        },
        depositFailModal: {
            title: 'Befizetés elutasítva',
            text: 'Az Ön befizetése nem került feldolgozásra.',
        },
        widgetMarketWatch: {
            marketWatch: 'Piacfigyelő',
            search: 'Keresés...',
        },
        widgetOrders: {
            commission: 'Bizottság',
            volume: 'Volumen',
            margin: 'Tőke',
            active: 'Aktív',
            pending: 'Függő',
            activeOrders: 'Aktív ügyletek',
            portfolio: 'Portfólió',
            allPositions: 'Összes Pozíció',
            noPositionsLine1: 'Nincsenek nyitott',
            noPositionsLine2: 'pozíciók jelenleg',
            show: 'Mutasd',
            more: 'több',
            less: 'kevesebb',
            purchaseTime: 'Vételi idő',
            closePosition: 'Pozíció zárása',
            priceOpen: 'Vételi ár',
            priceSl: 'Stop Loss Ár',
            priceTp: 'Take Profit Ár',
            type: 'Pozíció iránya',
            pnl: 'Profit/Loss',
            cancelOrder: 'Pozíció törlése',
            orderType: 'Ügylet típusa',
            cancelled: 'Törölve',
            tradingHistory: 'Kereskedési történet',
            noHistoryLine1: 'Eddig nincs ...',
            noHistoryLine2: 'Zárt pozíciók eddig',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Közgazdasági naptár'
        },
        widgetMarketNews: {
            marketNews: 'Piaci hírek'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
timedOrder: 'Timed Order',
            ordersActive: 'Aktív megbízások',
            ordersHistory: 'Megbízások története',
            id: 'ID',
            symbol: 'Szimbólum',
            type: 'Típus',
            volume: 'Volumen',
            openPrice: 'Nyitási ár',
            openTime: 'Nyitási időpont',
            closePrice: 'Záró ár',
            closeTime: 'Zárási idő',
            sl: 'SL',
            tp: 'TP',
            price: 'Ár',
            pnl: 'PnL',
            actions: 'Akciók',
            edit: 'Szerkesztés',
            close: 'Zárás',
            commission: 'Bizottság',
            swap: 'Csere',
            swapLong: 'Long csere',
            swapShort: 'Short csere'
        },
        assetInfoModal: {
            assetInfo: 'Eszköz infó',
            tradingConditions: 'Kereskedési kondíciók',
            information: 'Információ',
            bid: 'Ajánlat',
            ask: 'Kérés',
            sessionChange: 'Munkamenet váltás',
            tradeNow: 'Kereskedjen most',
            opens: 'Nyitások',
            closes: 'Zárások',
            at: '-nál',
            open247: 'Non-stop nyitva',
            today: 'ma',
            tomorrow: 'holnap',
            sunday: 'Vasárnap',
            monday: 'Hétfő',
            tuesday: 'Kedd',
            wednesday: 'Szerda',
            thursday: 'Csütörtök',
            friday: 'Péntek',
            saturday: 'Szombat',
            contractSpecification: 'Szerződéses előírás',
            symbol: 'Szimbólum',
            name: 'Név',
            market: 'Piac',
            baseCurrency: 'Fő valuta',
            digits: 'Számjegyek',
            lotSize: 'Lot méret',
            lotStep: 'Lot lépés',
            minVolume: 'Minimum Volumen',
            maxVolume: 'Maximum Volumen',
            leverage: 'Áttét',
            commission: 'Kamat',
            swapLong: 'Long csere',
            swapShort: 'Short csere',
            schedule: 'Ütemterv',
            weekday: 'Munkanap',
            tradingTime: 'Kereskedési idő',
            closed: 'zárva',
            sell: 'Eladás',
            buy: 'Vétel',
            low: 'Alacsony',
            high: 'Magas',
            oneHourChange: '1 órás változás',
            oneDayChange: '1 napos változás',
            oneWeekChange: '1 heti változás',
            oneMonthChange: '1 havi változás',
            threeMonthsChange: '3 havi változás',
            oneYearChange: '1 éves változás',
            loading: 'Töltés...',
        },
        autoTrader: {
            days: 'days',
            title: 'Auto Trader',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Risk Level: Low',
            riskLevelMedium: 'Risk Level: Medium',
            riskLevelHigh: 'Risk Level: High',
            perDay: 'Trades per day:',
            desc: `Trading CFDs and other leveraged products can lead to losses. Before trading, clients should
                read the
                relevant risk statements on our Risk Disclosure page. Automated trading does not guarantee results. The
                company accepts no responsibility for the loss of funds in automatic trading. Please make sure that you
                fully understand the risks and take measures to manage risks.`,
            accept: 'Accept',
            save: 'Mentés',
            cancel: 'Mégse',
        },
        cardBox: {
            name: 'Név',
            cardNumber: 'Kártyaszám',
            expiration: 'Lejár (hh/éé)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: 'Kereskedés',
            startTrading: 'Kereskedés kezdése',
            deposit: 'Befizetés',
            dashboard: 'Vezérlőpult',
            personalInfo: 'Személyes infó',
            contactInfo: 'Kapcsolatfelvételi információk',
            withdrawal: 'Kivétel',
            verification: 'Ellenőrzés',
            accounts: 'Fiókok',
            liveChat: 'Élő Chat',
            savings: 'Megtakarítások',
            settings: 'Beállítások',
            logOut: 'Kijelentkezés',
        },
        fullScreenDeposit: {
            methods: 'METHODS',
            sendOtp: 'Küldés otp-be',
            otpCode: 'Otp kód',
            addWalletAddress: 'A pénztárca ehhez a befizetési módhoz nincs beállítva. Kérjük, lépjen kapcsolatba ügynökével vagy ügyfélszolgálatával.',
            addressReceipt: 'Kérjük, várjon a cím betöltésére',
            makeDeposit: 'Fizessen be',
            instant: 'Azonnali',
            minutes: 'percek',
            hours: 'órák',
            days: 'napok',
            amount: 'Mennyiség',
            continue: 'Folytatás',
            qrCode: 'QR kód',
            depositAddress: 'Befizetési cím',
            copy: 'Másol',
            last: 'UTOLSÓ',
            deposits: 'BEFIZETÉS',
            time: 'Idő',
            currency: 'Valuta',
            status: 'Státusz',
            info: 'Infó',
        },
        fullScreenCcExt: {
            pleaseWait: 'Kérem, várjon. Kapcsolódás a fizetési szolgáltatóhoz...',
            cardPayment: 'Kártyás fizetés',
            inOrder: 'Az összeg elküldéséhez',
            pleaseProvide: 'kérjük, adja meg a kártya adatait:'
        },
        fullScreenDashboard: {
            completeVerification: 'Please Complete Verification',
            totalBalance: 'Teljes egyenleg',
            totalPnl: 'Összes PNL',
            profitableOrders: 'Nyereséges megrendelések',
            roi: 'ROI',
            activityLog: 'Tevékenységi napló',
            loginFromIp: 'Bejelentkezés IP-ről',
            tradingResults: 'Trading Results',
            week: 'Hét',
            month: 'Hónap',
            year: 'Év',
            successRate: 'Sikerességi arány',
            closedWithProfit: 'Nyereséggel zárt',
            closedWithLoss: 'Veszteséggel zárt',
            account: 'Fiók',
            balance: 'Egyenleg',
            leverage: 'Tőkeáttétel',
            credit: 'Hitel',
            tradeNow: 'Kereskedés most',
            usingCurrentRate: 'aktuális árfolyamot használ',
            totalDeposits: 'Összes betét'
        },
        fullScreenPersonal: {
            personalDetails: 'Személyes adatok',
            profilePhoto: 'Profilkép',
            firstName: 'Keresztnév',
            country: 'Ország',
            lastName: 'Utónév',
            address: 'Cím',
            email: 'E-mail',
            telefon: 'Telefonszám',
            saveChanges: 'Változások mentése',
            state: 'Város',
            postcode: 'Irányítószám',
            created: "Fiók létrehozva",
        },
        fullScreenAvatar: {
            dropFile: 'Huzzon egy fájlt a fenti körre a feltöltéshez',
            notAllowed: 'Nem engedélyezett a közzététel',
            notAllowedLine1: 'Kifejezetten szexuális vagy pornográf jellegű fényképek',
            notAllowedLine2: 'Etnikai vagy faji gyűlöletkeltésre vagy agresszióra irányuló képek',
            notAllowedLine3: '18 év alatti személyeket ábrázoló képek',
            notAllowedLine4: 'Harmadik fél által szerzői jogvédelem alatt álló fényképek',
            notAllowedLine5: '5 MB-nál nagyobb és nem JPG, GIF vagy PNG formátumú képek',
            requirements: 'Az arcnak jól láthatónak kell lennie a fényképen. Az Ön által feltöltött összes fényképnek és videónak meg kell felelnie ezeknek a követelményeknek, ellenkező esetben eltávolíthatók.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Kérjük, lépjen kapcsolatba számlavezetőjével a kifizetés részleteiért',
            cryptocurrency: 'Cryptocurrency',
            withdrawal: 'Visszavonás',
            requestNewWithdrawal: 'ÚJ KIFIZETÉS KÉRÉSE',
            amount: 'Összeg',
            currentBalance: 'Az Ön aktuális számlaegyenlege',
            withdrawAll: 'Minden pénzfelvétel',
            requestWithdrawal: 'Kifizetés kérése',
            last: 'UTOLSÓ',
            withdrawalRequests: 'Kifizetési kérelmek',
            time: 'Idő',
            currency: 'Pénznem',
            info: 'Info',
            status: 'Állapot',
            bank: 'Bank',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            card: 'Kártya',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Kifizetés részletei',
            address: 'Pénztárca címe',
            bankAccount: 'Számlaszám',
            bankHolder: 'Számlatulajdonos neve',
            bankIban: 'IBAN',
            bankSwift: 'Bank SWIFT-kódja',
            recipientAddress: 'Címzett címe',
            routingNumber: 'Útvonalszám',
            bankBeneficiary: 'Kedvezményezett bankjának neve',
            bankAddress: 'Bank címe',
            bankCode: 'Bankkód',
            desc: 'Leírás',
            cardNumber: 'Kártyaszám',
            cardHolder: 'Kártyabirtokos neve',
        },
        fullScreenVerification: {
            dragAndDrop: 'húzza és helyezze a dokumentumot erre a területre',
            documentsList: 'A FELTÖLTÖTT DOKUMENTUMOK LISTÁJA',
            document: 'Dokumentum',
            timeUploaded: 'Feltöltés ideje',
            timeProcessed: 'Feldolgozott idő',
            status: 'Állapot',
            types: ['Személyi igazolvány', 'Lakcímkártya', 'Bankkártya előlap', 'Bankkártya hátlap', 'Személyi igazolvány hátlap', 'Szelfi'],
            types2: ['Személyazonossági igazolvány (POI)', 'Lakcímkártya', 'Bankkártya előlap', 'Bankkártya hátlap', 'Személyazonossági igazolvány hátlap', 'Selfie azonosítóval'],
            upload: 'Feltöltés',
            isConfirmed: 'megerősített',
            uploadAnotherDocument: 'Újabb dokumentum feltöltése',
            isUnderReview: 'felülvizsgálat alatt',
            questionnaire: 'Kérdőív',
            sendAnswers: 'Válaszok küldése',
            progressBarOf: 'ennyiből: ',
            progressBarText: 'dokumentumai feltöltésre és megerősítésre kerültek'
        },
        fullScreenAccounts: {
            manageAccounts: 'Pénzátutalás a számlák között',
            transferFrom: 'Számláról',
            transferTo: 'Számlára',
            amount: 'Összeg',
            makeTransfer: 'Átutalás végrehajtása',
            yourAccounts: 'Saját számlái',
            account: 'Fiók',
            currency: 'Pénznem',
            balance: 'Egyenleg',
            credit: 'Hitel',
            makeActive: 'Tegye aktívvá',
            active: 'Aktív'
        },
        fullScreenSupport: {
            sendMessage: 'Üzenet küldése'
        },
        fullScreenSavings: {
            pct_0: 'Rugalmas',
            pct_30: '1 hónapig zárolva',
            pct_90: '1 negyedévig zárolva',
            pct_180: '6 hónapig zárolva',
            pct_365: ' 1 évig zárolva',
            savings: 'Megtakarítás',
            detailedInformation: 'Részletes információ',
            pleaseSelect: 'Kérjük, válasszon egy pénznemet és egy időszakot',
            openSavings: 'Nyitott megtakarítások',
            currentTime: 'Aktuális idő',
            releaseTime: 'Kibocsátási idő',
            currencyChosen: 'Kiválasztott pénznem',
            periodChosen: 'Kiválasztott időszak',
            yourRate: 'Saját árfolyam',
            yourEarnings: 'Bevételei',
            accountFrom: 'Számla, ahonnan átutalni kívánja a pénzt',
            enterAmount: 'Adja meg az összeget',
            in: 'Bent',
            openAndInvest: 'Takarékszámla megnyitása és befektetés',
            investment: 'Befektetés',
            period: 'Időszak',
            daysTotal: 'Napok összesen',
            finalEarnings: 'Végleges bevétel',
            created: 'Létrehozva',
            daysElapsed: 'Elmúlt napok',
            days: 'napok',
            earnings: 'Bevételek',
            fundsReleasedIn: 'Alapok ekkor szabadultak fel',
            claim: 'Igénylés',
        },
        fullScreenSettings: {
            updatePassword: 'JELSZÓ FRISSÍTÉSE',
            currentPassword: 'Jelenlegi jelszó',
            newPassword: 'Új jelszó',
            changePassword: 'Jelszó módosítása',
            activationForGoogle: 'Aktiváló kód a Google Authenticatorhoz',
            activateAndEnter: 'Aktiválja a hitelesítőt és adja meg a generált kódot az alábbi mezőben',
            qrCode: 'QR-kód',
            activateProtection: '2FA-védelem aktiválása',
            protectionActive: 'A 2FA-védelem aktív',
            twoFactorCode: '2FA-kód',
            disableProtection: '2FA-védelem kikapcsolása',
            language: 'Nyelv',
            dashboardCurrency: 'Az irányítópult pénzneme',
            confirmNewPassword: 'Új jelszó megerősítése',
        },
        fullScreenReferrals: {
            yourLink: 'AZ ÖN HIVATKOZÁSI LINKJE',
            referredFriends: 'REFERRED FRIENDS',
            id: 'ID',
            name: 'Név',
            registered: 'Regisztráció',
            level: 'Szint',
            payout: 'Kifizetés',
            lastPayouts: 'UTOLSÓ KIFIZETÉS',
            time: 'Idő',
            amount: 'Összeg',
            currency: 'Pénznem',
            info: 'Info'
        },
        months: {
            Jan: 'Jan',
            Feb: 'Feb',
            Mar: 'Márc',
            Apr: 'Ápr',
            May: 'Máj',
            Jun: 'Jún',
            Jul: 'Júl',
            Aug: 'Aug',
            Sep: 'Sep',
            Oct: 'Okt',
            Nov: 'Nov',
            Dec: 'Dec'
        },
        orderTypes: ['Vásárlás', 'Eladás', 'Vételi limit', 'Eladási limit', 'Vételi stop', 'Eladási stop'],
        statuses: ['Függőben', 'Jóváhagyva', 'Elutasítva', 'Feldolgozás'],
        errors: {
            NOT_FOUND: 'Hiba a kérésben',
            FORBIDDEN: 'Hozzáférés megtagadva. Kérjük, jelentkezzen be újra',
            SERVER_ERROR: 'A művelet sikertelen. Kérjük, próbálja meg később újra',
            INVALID_ACCOUNT: 'A munkamenet lejárt. Kérjük, jelentkezzen be újra',
            INVALID_EMAIL: 'Érvénytelen e-mail cím',
            INVALID_PHONE: 'Érvénytelen telefonszám',
            INVALID_COUNTRY: 'Érvénytelen ország',
            INVALID_LOGIN: 'Érvénytelen bejelentkezési adatok',
            USER_BLOCKED: 'Fiók letiltva. Kérjük, lépjen kapcsolatba az ügyfélszolgálattal',
            USER_REGISTERED: 'A megadott e-mail címmel vagy telefonszámmal rendelkező felhasználó már regisztrált',
            INVALID_USER: 'A felhasználó nem található',
            REJECTED: 'A művelet nem engedélyezett a platformkorlátozások miatt',
            INVALID_OLD_PASSWORD: 'A régi jelszó érvénytelen',
            INVALID_SYMBOL: 'Érvénytelen kereskedési szimbólum',
            INVALID_SYMBOL_PRECISION: 'Érvénytelen szimbólum pontossága',
            INVALID_FRAME: 'Érvénytelen grafikonkeret',
            INVALID_CURRENCY: 'Érvénytelen pénznem',
            INVALID_PRODUCT: 'Érvénytelen számla kereskedési típusa',
            INSUFFICIENT_FUNDS: 'Elégtelen pénzeszközök',
            INVALID_WITHDRAWAL: 'Kifizetés nem található',
            INVALID_STATUS: 'Érvénytelen státusz',
            INVALID_FILE: 'Érvénytelen fájlkiterjesztés vagy túl nagy fájl',
            INVALID_DOCUMENT: 'Dokumentum nem található',
            INVALID_NAME: 'Érvénytelen név',
            INVALID_LOT_SIZE: 'Érvénytelen tételméret',
            INVALID_LOT_STEP: 'Érvénytelen tétellépés',
            INVALID_MIN_VOLUME: 'Érvénytelen minimális mennyiség',
            INVALID_MAX_VOLUME: 'Érvénytelen maximális mennyiség',
            INVALID_GROUP: 'Érvénytelen csoport',
            INVALID_SPREAD: 'Érvénytelen szórás',
            REQUEST_TIMEOUT: 'A kérés időzített. Kérjük, próbálja meg később újra',
            EXISTING_OPEN_ORDERS: 'Van egy nyitott rendelése, először zárja le a rendelést ehhez a felhasználóhoz, majd próbálja újra.',
            INVALID_TYPE: 'Érvénytelen megbízás típusa',
            INVALID_VOLUME: 'Érvénytelen megbízási mennyiség',
            INVALID_SL: 'Érvénytelen stop loss érték',
            INVALID_TP: 'Érvénytelen take profit érték',
            INVALID_PRICE: 'Érvénytelen megbízási ár',
            INVALID_EXPIRATION: 'Érvénytelen megbízás lejárata',
            NOT_ENOUGH_MARGIN: 'Nem elégséges margin',
            INVALID_ORDER: 'A megbízás nem található',
            MARKET_CLOSED: 'A kereskedés nem elérhető. Kérjük, próbálja meg újra',
            INVALID_BALANCE_TYPE: 'Érvénytelen típusú egyenlegművelet',
            INVALID_HASH: 'Érvénytelen hash',
            HASH_EXPIRED: 'A jelszó-visszaállítási link lejárt. Kérjük, kérjen újat',
            INVALID_CODE: 'Érvénytelen 2FA-kód',
            CHAT_DISABLED: 'A támogatási chathez való hozzáférés le lett zárva',
            WITHDRAWAL_NOT_ALLOWED: 'A kifizetésekhez való hozzáférés lezárult',
            TRADING_DISABLED: 'A kereskedés letiltva a folyószámlán',
            PENDING_DEPOSITS_LIMIT: 'Betéti limit túllépve',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Csak egy függőben lévő kifizetés engedélyezett',
            LOCAL_DEPOSIT_ONLY_REAL: 'Betét csak a valós számlán érhető el',
            LOCAL_DEPOSIT_POSITIVE: 'A befizetés összegének nagyobbnak kell lennie 0-nál',
            LOCAL_INVALID_CARD_NUMBER: 'Érvénytelen kártyaszám',
            LOCAL_INVALID_CARD_EXPIRATION: 'Érvénytelen kártya lejárat',
            LOCAL_INVALID_CVC: 'Érvénytelen CVV/CVC kód',
            LOCAL_PASSWORDS_NOT_MATCH: 'Jelszavak nem egyeznek, kérjük, adja meg újra',
            LOCAL_NO_TRADING_ACCOUNTS: 'Ön nem rendelkezik elérhető fizetési számlával. Kérjük, lépjen kapcsolatba az ügyfélszolgálattal',
            LOCAL_MIN_DEPOSIT: 'A minimális befizetés $',
            LOCAL_MIN_WITHDRAWAL: 'A minimális kifizetési összeg $',
            LOCAL_INVALID_FILE_TYPE: 'Nem támogatott fájlformátum. Kérjük, csak JPG, PNG vagy PDF fájlokat csatoljon',
            LOCAL_MIN_STAKING: 'A minimális befizetés ebben a pénznemben ',
            FLOOD_REJECTED: 'Ön túl gyakran küld üzeneteket. Kérjük, próbálja újra később'
        },
        countries: {
            AF: 'Afganisztán',
            AX: 'Aland-szigetek',
            AL: 'Albánia',
            DZ: 'Algéria',
            AS: 'Amerikai Szamoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarktisz',
            AG: 'Antigua és Barbuda',
            AR: 'Argentína',
            AM: 'Örményország',
            AW: 'Aruba',
            AU: 'Ausztrália',
            AT: 'Ausztria',
            AZ: 'Azerbajdzsán',
            BS: 'Bahama-szigetek',
            BH: 'Bahrein',
            BD: 'Banglades',
            BB: 'Barbados',
            BY: 'Fehéroroszország',
            BE: 'Belgium',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Bhután',
            BO: 'Bolívia',
            BA: 'Bosznia-Hercegovina',
            BW: 'Botswana',
            BV: 'Bouvet-sziget',
            BR: 'Brazília',
            IO: 'Brit Indiai-óceáni Terület',
            BN: 'Brunei Darussalam',
            BG: 'Bulgária',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Kambodzsa',
            CM: 'Kamerun',
            CA: 'Kanada',
            CV: 'Zöld-foki Köztársaság',
            KY: 'Kajmán-szigetek',
            CF: 'Közép-afrikai Köztársaság',
            TD: 'Csád',
            CL: 'Chile',
            CN: 'Kína',
            CX: 'Karácsony-sziget',
            CC: 'Cocos (Keeling) szigetek',
            CO: 'Kolumbia',
            KM: 'Comore-szigetek',
            CG: 'Kongó',
            CD: 'Kongói Demokratikus Köztársaság',
            CK: 'Cook-szigetek',
            CR: 'Costa Rica',
            CI: 'Elefántcsontpart',
            HR: 'Horvátország',
            CU: 'Kuba',
            CY: 'Ciprus',
            CZ: 'Cseh Köztársaság',
            DK: 'Dánia',
            DJ: 'Dzsibuti',
            DM: 'Dominika',
            DO: 'Dominikai Köztársaság',
            EC: 'Ecuador',
            EG: 'Egyiptom',
            SV: 'El Salvador',
            GQ: 'Egyenlítői-Guinea',
            ER: 'Eritrea',
            EE: 'Észtország',
            ET: 'Etiópia',
            FK: 'Falkland-szigetek (Malvin-szigetek)',
            FO: 'Feröer-szigetek',
            FJ: 'Fidzsi-szigetek',
            FI: 'Finnország',
            FR: 'Franciaország',
            GF: 'Francia Guyana',
            PF: 'Francia Polinézia',
            TF: 'Francia Déli Területek',
            GA: 'Gabon',
            GM: 'Gambia',
            GE: 'Grúzia',
            DE: 'Németország',
            GH: 'Ghána',
            GI: 'Gibraltár',
            GR: 'Görögország',
            GL: 'Grönland',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Heard-sziget és Mcdonald-szigetek',
            VA: 'Szentszék (Vatikánváros Állam)',
            HN: 'Honduras',
            HK: 'Hongkong',
            HU: 'Magyarország',
            IS: 'Izland',
            IN: 'India',
            ID: 'Indonézia',
            IR: 'Irán',
            IQ: 'Irak',
            IE: 'Írország',
            IM: 'Man-sziget',
            IL: 'Izrael',
            IT: 'Olaszország',
            JM: 'Jamaica',
            JP: 'Japán',
            JE: 'Jersey',
            JO: 'Jordánia',
            KZ: 'Kazahsztán',
            KE: 'Kenya',
            KI: 'Kiribati',
            KR: 'Korea',
            KW: 'Kuvait',
            KG: 'Kirgizisztán',
            LA: 'Laoszi Népi Demokratikus Köztársaság',
            LV: 'Lettország',
            LB: 'Libanon',
            LS: 'Lesotho',
            LR: 'Libéria',
            LY: 'Líbiai Arab Dzsamahiriya',
            LI: 'Liechtenstein',
            LT: 'Litvánia',
            LU: 'Luxemburg',
            MO: 'Makaó',
            MK: 'Macedónia',
            MG: 'Madagaszkár',
            MW: 'Malawi',
            MY: 'Malajzia',
            MV: 'Maldív-szigetek',
            ML: 'Mali',
            MT: 'Málta',
            MH: 'Marshall-szigetek',
            MQ: 'Martinique',
            MR: 'Mauritánia',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Mexikó',
            FM: 'Mikronézia',
            MD: 'Moldova',
            MC: 'Monaco',
            MN: 'Mongólia',
            ME: 'Montenegró',
            MS: 'Montserrat',
            MA: 'Marokkó',
            MZ: 'Mozambik',
            MM: 'Mianmar',
            NA: 'Namíbia',
            NR: 'Nauru',
            NP: 'Nepál',
            NL: 'Hollandia',
            AN: 'Holland Antillák',
            NC: 'Új-Kaledónia',
            NZ: 'Új-Zéland',
            NI: 'Nicaragua',
            NE: 'Niger',
            NG: 'Nigéria',
            NU: 'Niue',
            NF: 'Norfolk-sziget',
            MP: 'Északi-Mariana-szigetek',
            NO: 'Norvégia',
            OM: 'Omán',
            PK: 'Pakisztán',
            PW: 'Palau',
            PS: 'Palesztin terület, megszállva',
            PA: 'Panama',
            PG: 'Pápua Új-Guinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Fülöp-szigetek',
            PN: 'Pitcairn',
            PL: 'Lengyelország',
            PT: 'Portugália',
            PR: 'Puerto Rico',
            QA: 'Katar',
            RE: 'Reunion',
            RO: 'Románia',
            RU: 'Oroszország',
            RW: 'Ruanda',
            BL: 'Saint Barthelemy',
            SH: 'Saint Helena',
            KN: 'Saint Kitts és Nevis',
            LC: 'Saint Lucia',
            MF: 'Saint Martin',
            PM: 'Saint Pierre és Miquelon',
            VC: 'Saint Vincent és Grenadine-szigetek',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tomé és Príncipe',
            SA: 'Szaúd-Arábia',
            SN: 'Szenegál',
            RS: 'Szerbia',
            SC: 'Seychelle-szigetek',
            SL: 'Sierra Leone',
            SG: 'Szingapúr',
            SK: 'Szlovákia',
            SI: 'Szlovénia',
            SB: 'Salamon-szigetek',
            SO: 'Szomália',
            ZA: 'Dél-Afrika',
            GS: 'Dél-Georgia és a Szendvics-szigetek',
            ES: 'Spanyolország',
            LK: 'Sri Lanka',
            SD: 'Szudán',
            SR: 'Suriname',
            SJ: 'Svalbard és Jan Mayen',
            SZ: 'Szváziföld',
            SE: 'Svédország',
            CH: 'Svájc',
            SY: 'Szíriai Arab Köztársaság',
            TW: 'Tajvan',
            TJ: 'Tádzsikisztán',
            TZ: 'Tanzánia',
            TH: 'Thaiföld',
            TL: 'Kelet-Timor',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad és Tobago',
            TN: 'Tunézia',
            TR: 'Törökország',
            TM: 'Türkmenisztán',
            TC: 'Turks- és Caicos-szigetek',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukrajna',
            AE: 'Egyesült Arab Emírségek',
            GB: 'Egyesült Királyság',
            US: 'Egyesült Államok',
            UM: 'Egyesült Államok külső szigetei',
            UY: 'Uruguay',
            UZ: 'Üzbegisztán',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Viet Nam',
            VG: 'Brit Virgin-szigetek',
            VI: 'Virgin-szigetek, Egyesült Államok',
            WF: 'Wallis és Futuna',
            EH: 'Nyugat-Szahara',
            YE: 'Jemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe'
        },
    },
    ro: {
        _name: 'Romanian',
        timePickerModal: {
            title: 'Close In Time'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Încasare profit & Oprire pierdere',
            takeProfit: 'Încasare profit',
            stopLoss: 'Oprire pierdere',
            updateOrder: 'Actualizați comanda',
        },
        balanceModal: {
            balance: 'Sold',
            credit: 'Credit',
            equity: 'Capital',
            practice: 'EXERSAȚI',
            real: 'REAL',
            account: 'CONT',
            invested: 'Investit',
            profit: 'Profit',
            loss: 'Pierderi',
            margin: 'Marjă',
            marginLevel: 'Nivelul marjei',
            marginFree: 'Marjă liberă'
        },
        header: {
            cfd: 'CFD',
            platform: 'Platformă',
            deposit: 'Depozit'
        },
        sideMenu: {
            marketWatch: 'Monitorizarea pieței',
            activeOrders: 'Comenzi active',
            tradingHistory: 'Istoric de tranzacționare',
            economicCalendar: 'Calendar economic',
            marketNews: 'Știri despre piață'
        },
        closePositionModal: {
            closeOrder: 'Poziția de închidere',
            areYouSure: 'Sunteți sigur că închideți poziția',
            buy: 'Cumpărați',
            sell: 'Vindeți',
            yes: 'Da',
            no: 'Nu',
        },
        pciDssModal: {
            desc: `Conformitatea cu standardul de securitate a datelor din industria cardurilor de plată (PCI DSS) este obligatorie pentru toate entitățile care stochează, procesează sau transmit datele titularilor de carduri Visa/Master Card/Stripe, inclusiv instituții financiare, comercianți și furnizori de servicii. Acesta este standardul global de securitate pentru toate entitățile care stochează, procesează sau transmit datele titularilor de card și/sau date de autentificare sensibile. PCI DSS stabilește un nivel de protecție de bază pentru consumatori și ajută la reducerea fraudei și a încălcărilor de date în întregul ecosistem de plată.`
        },
        chart: {
            addNewChart: 'Adăugați o nouă diagramă',
        },
        symbolsModal: {
            watchlist: 'Lista de supraveghere',
            asset: 'Activ',
            price: 'Preț',
            changePct: 'Modificare 24h'
        },
        pendingModal: {
            pending: 'În așteptare',
            assetPrice: 'Prețul activului',
            current: 'Curent',
            revert: 'Revenirea la prețul pieței',
            automatically: 'Poziția va fi deschisă automat atunci când prețul atinge acest nivel'
        },
        orderMenu: {
            default: 'implicit',
            volume: 'Volum',
            lots: 'loturi',
            units: 'unități',
            currency: 'monedă',
            contractSize: 'Dimensiunea contractului',
            position: 'Poziția',
            margin: 'Marjă',
            freeMargin: 'Marjă liberă',
            takeProfitStopLoss: 'Încasare profit & Oprire pierdere',
            pending: 'În așteptare',
            market: 'Piață',
            leverage: 'Pârghie',
            spread: 'Marjă',
            notSet: 'Nedefinită',
            at: 'la',
            buy: 'cumpărați',
            sell: 'vindeți',
        },
        footer: {
            supportBanner: 'ÎN FIECARE ZI, 24 DE ORE DIN 24',
            currentTime: 'ORA CURENTĂ',
            liveChat: 'Live Chat'
        },
        statusBar: {
            balance: 'Sold',
            credit: 'Credit',
            equity: 'Capital',
            margin: 'Marjă',
            marginLevel: 'Nivelul marjei',
            freeMargin: 'Marjă liberă',
            pnl: 'PnL',
            profitTotal: 'PnL pe întreaga perioadă'
        },
        accountModal: {
            dateRegistered: 'Dată înregistrare',
            userId: 'ID-ul utilizatorului',
            dashboard: 'Tabloul de bord',
            personalData: 'Date cu caracter personal',
            deposit: 'Depozit',
            withdrawFunds: 'Retrageți fonduri',
            savings: 'Economii',
            settings: 'Setări',
            logout: 'Ieșire'
        },
        depositModal: {
            BT_INVOICE: 'Introduceți suma și generați factura',
            BT_TITLE: 'Datele noastre bancare',
            account: 'Cont',
            bank_name: 'Numele băncii',
            beneficiary: 'Beneficiar',
            beneficiary_address: 'Adresa beneficiarului',
            swift: 'Swift',
            reference: 'Reference',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Suma',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Descriere',
            TOKEN_PAYMENT_DETAILS: 'Detalii de plată',
            PENDING_DEPOSITS_LIMIT: 'Limita depozitului',
            makeDeposit: 'Efectuați un depozit',
            practiceAccount: 'Contul de practică',
            realAccount: 'Cont real',
            noWithdrawals: 'Fără retrageri',
            easyWithdrawals: 'Retrageri ușoare',
            allAssets: 'Toate activele disponibile',
            fullFledged: 'Platformă cu drepturi depline',
            fillUpTo: 'Alimentați până la',
            freeReplenishment: 'Reaprovizionare liberă',
            topUp: 'Reîncărcați contul dumneavoastră',
            minimumAmount: 'Suma minimă',
            canSwitch: 'Puteți trece de la un cont la altul în orice moment'
        },
        gridsModal: {
            chartGrids: 'GRILE GRAFIC',
            chart1: '1 diagramă',
            chart2: '2 diagrame',
            chart3: '3 diagrame',
            chart4: '4 diagrame',
        },
        noConnectionModal: {
            connectionLost: 'Conexiunea cu serverul este pierdută',
            retryNow: 'Reîncercați acum',
        },
        loginModal: {
            loginToTradeRoom: 'Conectați-vă la Traderoom',
            email: 'Adresa de e-mail',
            enterEmail: 'Introduceți e-mailul dumneavoastră',
            twoFactor: 'Codul 2FA (dacă este activat)',
            twoFactorAuth: 'Autentificare cu doi factori',
            password: 'Parola',
            yourPassword: 'Parola dumneavoastră',
            signIn: 'Conectați-vă',
            dontHaveAccount: 'Nu aveți un cont?',
            restore: 'Restaurați',
            fullName: 'Nume complet',
            havePromo: 'Am un cod promoțional',
            promo: 'Cod promoțional',
            login: 'Autentificare',
            haveAccount: 'Aveți deja un cont?',
            hide: 'Ascundeți',
            goTo: 'Mergeți înapoi'
        },
        newPasswordModal: {
            setNewPassword: 'Setați o nouă parolă',
            newPassword: 'Parolă nouă',
            confirmNewPassword: 'Confirmați noua parolă',
            setButton: 'Setați'
        },
        registerModal: {
            repeatPassword: 'Repetați parola',
            createNewAccount: 'Creați un cont nou',
            email: 'Adresa de e-mail',
            firstName: 'Prenume',
            lastName: 'Nume',
            country: 'Țara',
            phone: 'Telefon',
            password: 'Parola',
            createAccount: 'Creați cont',
            currency: 'Monedă',
            privacyAndPolicy: "Confidențialitate și Politică",
            customerAgreement: 'Acord cu clientul',

        },
        forgotPasswordModal: {
            forgotPassword: 'Ați uitat parola?',
            submitEmail: 'Vă rugăm să trimiteți un e-mail folosit pentru înregistrare, verificați-vă căsuța de e-mail și urmați instrucțiunile furnizate',
            submit: 'Trimiteți'
        },
        notifications: {
            error: 'Eroare',
            success: 'Succes',
            deposit: 'Depozit',
            withdrawal: 'Retragere',
            depositApproved: 'Depozitul a fost aprobat',
            depositDeclined: 'Depozitul a fost refuzat',
            withdrawalApproved: 'Retragerea a fost aprobată',
            withdrawalDeclined: 'Retragerea a fost refuzată'
        },
        markets: {
            forex: 'Forex',
            stocks: 'Stocuri',
            commodities: 'Mărfuri',
            indices: 'Indici',
            crypto: 'Cripto',
            metals: 'Metale',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'Cumpărați',
            profitCalculator: 'Calculator de profit',
            sell: 'Vindeți',
            volume: 'Volum',
            entryPrice: 'Preț de intrare',
            takeProfit: 'Încasare profit',
            stopLoss: 'Oprire pierdere',
            maxPosition: 'Poziția maximă',
            calculate: 'Calculați',
            calculationsFor: 'Calcule pentru',
            leverage: 'Pârghie',
            requiredMargin: 'Marja necesară',
            profitFromTP: 'Profit din TP',
            lossFromSL: 'Pierdere din SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Depozitul a avut succes',
            text: 'Depozitul dumneavoastră a fost procesat cu succes!',
        },
        depositFailModal: {
            title: 'Depozitul a eșuat',
            text: 'Depozitul dumneavoastră nu a fost procesat.',
        },
        widgetMarketWatch: {
            marketWatch: 'Monitorizarea pieței',
            search: 'Căutați...',
        },
        widgetOrders: {
            commission: 'Comision',
            volume: 'Volum',
            margin: 'Marjă',
            active: 'Activ',
            pending: 'În așteptare',
            activeOrders: 'Comenzi active',
            portfolio: 'Portofoliu',
            allPositions: 'Toate pozițiile',
            noPositionsLine1: 'Nu aveți deschise',
            noPositionsLine2: 'poziții deocamdată',
            show: 'Afișați',
            more: 'mai mult',
            less: 'mai puțin',
            purchaseTime: 'Ora achiziției',
            closePosition: 'Poziția de închidere',
            priceOpen: 'Preț de achiziție',
            priceSl: 'Preț Stop Loss',
            priceTp: 'Preț Take Profit',
            type: 'Direcția poziției',
            pnl: 'Profit/Pierderi',
            cancelOrder: 'Anulați comanda',
            orderType: 'Tip comandă',
            cancelled: 'Anulată',
            tradingHistory: 'Istoric de tranzacționare',
            noHistoryLine1: 'Nu aveți',
            noHistoryLine2: 'tranzacții încheiate până acum',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Calendar economic'
        },
        widgetMarketNews: {
            marketNews: 'Știri despre piață'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'Comenzi active',
            ordersHistory: 'Istoricul comenzilor',
            id: 'ID',
            symbol: 'Simbol',
            type: 'Tip',
            volume: 'Volum',
            openPrice: 'Prețul de deschidere',
            openTime: 'Oră de deschidere',
            closePrice: 'Preț de închidere',
            closeTime: 'Oră de închidere',
            sl: 'SL',
            tp: 'TP',
            price: 'Preț',
            pnl: 'PnL',
            actions: 'Acțiuni',
            edit: 'Editați',
            close: 'Închideți',
            commission: 'Comision',
            swap: 'Schimbați',
            swapLong: 'Schimbați lung',
            swapShort: 'Schimbați scurt'
        },
        assetInfoModal: {
            assetInfo: 'Informații despre activ',
            tradingConditions: 'Condiții comerciale',
            information: 'Informații',
            bid: 'Ofertă',
            ask: 'Cereți',
            sessionChange: 'Schimbare sesiune',
            tradeNow: 'Tranzacționați acum',
            opens: 'Se deschide',
            closes: 'Se închide',
            at: 'la',
            open247: 'Deschis 24/7',
            today: 'astăzi',
            tomorrow: 'mâine',
            sunday: 'Duminică',
            monday: 'Luni',
            tuesday: 'Marți',
            wednesday: 'Miercuri',
            thursday: 'Joi',
            friday: 'Vineri',
            saturday: 'Sâmbătă',
            contractSpecification: 'Specificația contractului',
            symbol: 'Simbol',
            name: 'Nume',
            market: 'Piață',
            baseCurrency: 'Moneda de bază',
            digits: 'Cifre',
            lotSize: 'Dimensiunea lotului',
            lotStep: 'Pasul lotului',
            minVolume: 'Volum minim',
            maxVolume: 'Volum maxim',
            leverage: 'Pârghie',
            commission: 'Comision',
            swapLong: 'Schimbați lung',
            swapShort: 'Schimbați scurt',
            schedule: 'Program',
            weekday: 'În timpul săptămânii',
            tradingTime: 'Oră de tranzacționare',
            closed: 'închis',
            sell: 'Vindeți',
            buy: 'Cumpărați',
            low: 'Scăzut',
            high: 'Ridicat',
            oneHourChange: 'Schimbare 1 oră',
            oneDayChange: 'Schimbare 1 zi',
            oneWeekChange: 'Schimbare 1 săptămână',
            oneMonthChange: 'Schimbare 1 lună',
            threeMonthsChange: 'Schimbare la 3 luni',
            oneYearChange: 'Schimbare 1 an',
            loading: 'Se încarcă...',
        },
        autoTrader: {
            days: 'days',
            title: 'Auto Trader',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Risk Level: Low',
            riskLevelMedium: 'Risk Level: Medium',
            riskLevelHigh: 'Risk Level: High',
            perDay: 'Trades per day:',
            desc: `Trading CFDs and other leveraged products can lead to losses. Before trading, clients should
                read the
                relevant risk statements on our Risk Disclosure page. Automated trading does not guarantee results. The
                company accepts no responsibility for the loss of funds in automatic trading. Please make sure that you
                fully understand the risks and take measures to manage risks.`,
            accept: 'Accept',
            save: 'Salvează',
            cancel: 'Anulează',
        },
        cardBox: {
            name: 'Nume',
            cardNumber: 'Număr card',
            expiration: 'Expirare (ll/aa)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "Tranzacționați",
            startTrading: 'Începeți să tranzacționați',
            deposit: 'Depozit',
            dashboard: 'Tabloul de bord',
            personalInfo: 'Informații personale',
            contactInfo: 'Informații de contact',
            withdrawal: 'Retragere',
            verification: 'Verificare',
            accounts: 'Conturi',
            liveChat: 'Live Chat',
            savings: 'Economii',
            settings: 'Setări',
            logOut: 'Ieșire',
        },
        fullScreenDeposit: {
            methods: 'METODE',
            sendOtp: 'Trimiteți otp',
            otpCode: 'Cod Otp',
            addWalletAddress: 'Portofelul pentru această metodă de depunere nu este setat. Vă rugăm să contactați agentul dumneavoastră sau echipa de asistență.',
            addressReceipt: 'Vă rugăm să așteptați ca adresa să se încarce',
            makeDeposit: 'Efectuați un depozit',
            instant: 'Instant',
            minutes: 'minute',
            hours: 'ore',
            days: 'zile',
            amount: 'Suma',
            continue: 'Continuați',
            qrCode: 'COD QR',
            depositAddress: 'ADRESA DE DEPOZIT',
            copy: 'Copiați',
            last: 'ULTIMUL',
            deposits: 'DEPOZITE',
            time: 'Oră',
            currency: 'monedă',
            status: 'Stare',
            info: 'Informații',
        },
        fullScreenCcExt: {
            pleaseWait: 'Vă rugăm așteptați. Se conectează la furnizorul de servicii de plăți...',
            cardPayment: 'Plată cu cardul',
            inOrder: 'Pentru a trimite suma',
            pleaseProvide: 'vă rugăm să furnizați datele cardului:'
        },
        fullScreenDashboard: {
            completeVerification: 'Please Complete Verification',
            totalBalance: 'Sold total',
            totalPnl: 'PNL Total',
            profitableOrders: 'Comenzi profitabile',
            roi: 'ROI',
            activityLog: 'Jurnal de activități',
            loginFromIp: 'Autentificare de la IP',
            tradingResults: 'Rezultate de tranzacționare',
            week: 'Săptămâna',
            month: 'Luna',
            year: 'Anul',
            successRate: 'Rata de succes',
            closedWithProfit: 'Închis cu profit',
            closedWithLoss: 'Închis cu pierdere',
            account: 'CONT',
            balance: 'Sold',
            leverage: 'Pârghie',
            credit: 'Credit',
            tradeNow: 'Tranzacționați acum',
            usingCurrentRate: 'utilizând cursul de schimb actual',
            totalDeposits: 'Total depozite'
        },
        fullScreenPersonal: {
            personalDetails: 'Date personale',
            profilePhoto: 'Fotografie de profil',
            firstName: 'Prenume',
            country: 'Țara',
            lastName: 'Nume',
            address: 'Adresa',
            email: 'E-mail',
            phone: 'Număr de telefon',
            saveChanges: 'Salvați modificările',
            state: 'Oraș',
            postcode: 'Cod poștal',
            created: "Cont creat la",
        },
        fullScreenAvatar: {
            dropFile: 'Poziționați un fișier pe cercul de mai sus pentru a-l încărca',
            notAllowed: 'Nu este permisă publicarea',
            notAllowedLine1: 'Fotografii cu caracter explicit sexual sau pornografic',
            notAllowedLine2: 'Imagini care au ca scop incitarea la ură sau agresiune etnică sau rasială',
            notAllowedLine3: 'Fotografii în care sunt implicate persoane cu vârsta sub 18 ani',
            notAllowedLine4: 'Fotografii protejate de drepturi de autor ale unor terțe părți',
            notAllowedLine5: 'Imagini mai mari de 5 MB și într-un alt format decât JPG, GIF sau PNG',
            requirements: 'Fața dumneavoastră trebuie să fie clar vizibilă în fotografie. Toate fotografiile și videoclipurile încărcate de dumneavoastră trebuie să respecte aceste cerințe, în caz contrar acestea pot fi eliminate.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Vă rugăm să contactați managerul dumneavoastră de cont pentru detalii de retragere',
            cryptocurrency: 'Cryptocurrency',
            withdrawal: 'Retragere',
            requestNewWithdrawal: 'SOLICITAȚI O NOUĂ RETRAGERE',
            amount: 'Suma',
            currentBalance: 'Soldul contului dumneavoastră curent este',
            withdrawAll: 'Retrageți tot',
            requestWithdrawal: 'Cerere de retragere',
            last: 'ULTIMUL',
            withdrawalRequests: 'CERERI DE RETRAGERE',
            time: 'Oră',
            currency: 'monedă',
            info: 'Informații',
            status: 'Stare',
            bank: 'Banca',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            card: 'Card',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Detalii de retragere',
            address: 'Adresa portofelului',
            bankAccount: 'Număr de cont',
            bankHolder: 'Numele titularului de cont',
            bankIban: 'IBAN',
            bankSwift: 'Codul SWIFT al băncii',
            recipientAddress: 'Adresa destinatarului',
            routingNumber: 'Număr de rutare',
            bankBeneficiary: 'Numele băncii beneficiarului',
            bankAddress: 'Adresa băncii',
            bankCode: 'Codul băncii',
            desc: 'Descriere',
            cardNumber: 'Numărul cardului',
            cardHolder: 'Numele titularului de card',
        },
        fullScreenVerification: {
            dragAndDrop: 'trageți și plasați documentul în această zonă',
            documentsList: 'LISTA DOCUMENTELOR ÎNCĂRCATE',
            document: 'Document',
            timeUploaded: 'Ora de încărcare',
            timeProcessed: 'Oră de procesare',
            status: 'Stare',
            types: ['Dovada de identitate', 'Dovada de reședință', 'Partea din față a cardului de credit', 'Partea din spate a cardului de credit', 'Partea din spate a dovezii de identitate', 'Selfie'],
            types2: ['Dovada de identitate (POI)', 'Dovada adresei de reședință', 'Partea din față a cardului de credit', 'Partea din spate a cardului de credit', 'Partea din spate a dovezii de identitate', 'Selfie cu dovada identității'],
            upload: 'Încărcați',
            isConfirmed: 'este confirmat',
            uploadAnotherDocument: 'Încărcați un alt document',
            isUnderReview: 'este în curs de examinare',
            questionnaire: 'Chestionar',
            sendAnswers: 'Trimiteți răspunsuri',
            progressBarOf: 'din',
            progressBarText: 'din încărcarea documentelor dumneavoastră au fost încărcate și confirmate'
        },
        fullScreenAccounts: {
            manageAccounts: 'Transferați fonduri între conturile dumneavoastră',
            transferFrom: 'Din contul',
            transferTo: 'În contul',
            amount: 'Suma',
            makeTransfer: 'Efectuați transferul',
            yourAccounts: 'Conturile dumneavoastră',
            account: 'CONT',
            currency: 'monedă',
            balance: 'Sold',
            credit: 'Credit',
            makeActive: 'Activați',
            active: 'Activ'
        },
        fullScreenSupport: {
            sendMessage: 'Trimiteți mesajul'
        },
        fullScreenSavings: {
            pct_0: 'Flexibil',
            pct_30: 'Blocat 1 lună',
            pct_90: 'Blocat 1 trimestru',
            pct_180: 'Blocat 6 luni',
            pct_365: 'Blocat 1 an',
            savings: 'Economii',
            detailedInformation: 'Informații detaliate',
            pleaseSelect: 'Vă rugăm să selectați o monedă și o perioadă',
            openSavings: 'Deschideți economii',
            currentTime: 'ORA CURENTĂ',
            releaseTime: 'Oră eliberare',
            currencyChosen: 'Moneda aleasă',
            periodChosen: 'Perioada aleasă',
            yourRate: 'Tariful dumneavoastră de schimb',
            yourEarnings: 'Câștigurile dumneavoastră',
            accountFrom: 'Cont pentru a transfera fonduri din',
            enterAmount: 'Introduceți suma',
            in: 'în',
            openAndInvest: 'Deschideți un cont de economii și investiți',
            investment: 'Investiție',
            period: 'Perioadă',
            daysTotal: 'Total zile',
            finalEarnings: 'Câștiguri finale',
            created: 'Creat',
            daysElapsed: 'Zilele scurse',
            days: 'zile',
            earnings: 'Câștiguri',
            fundsReleasedIn: 'Fonduri deblocate în',
            claim: 'Revendicați',
        },
        fullScreenSettings: {
            updatePassword: 'ACTUALIZAȚI PAROLA',
            currentPassword: 'Parola curentă',
            newPassword: 'Parolă nouă',
            changePassword: 'Schimbați parola',
            activationForGoogle: 'Cod de activare pentru Google Authenticator',
            activateAndEnter: 'Activați Authenticator și introduceți un cod generat în câmpul de mai jos',
            qrCode: 'COD QR',
            activateProtection: 'Activați protecția autentificări în doi pași 2FA',
            protectionActive: 'Protecția 2FA este activă',
            twoFactorCode: 'Cod 2FA',
            disableProtection: 'Dezactivați protecția 2FA',
            language: 'Limba',
            dashboardCurrency: 'Monedă tablou de bord',
            confirmNewPassword: 'Confirmați noua parolă',
        },
        fullScreenReferrals: {
            yourLink: 'LINK-UL DUMNEAVOASTRĂ DE REFERIRE',
            referredFriends: 'PRIETENI RECOMANDAȚI',
            id: 'ID',
            name: 'Nume',
            registered: 'Înregistrare',
            level: 'Nivel',
            payout: 'Plată',
            lastPayouts: 'ULTIMELE PLĂȚI DE RECOMANDARE',
            time: 'Oră',
            amount: 'Suma',
            currency: 'monedă',
            info: 'Informații'
        },
        months: {
            Jan: 'Ian',
            Feb: 'Feb',
            Mar: 'Mar',
            Apr: 'Apr',
            May: 'Mai',
            Jun: 'Iun',
            Jul: 'Iul',
            Aug: 'Aug',
            Sep: 'Sep',
            Oct: 'Oct',
            Nov: 'Nov',
            Dec: 'Dec'
        },
        orderTypes: ['Cumpărați', 'Vindeți', 'Limită cumpărare', 'Limită de vânzare', 'Oprire cumpărare', 'Oprire vânzare'],
        statuses: ['În așteptare', 'Aprobată', 'Refuzată', 'În procesare'],
        errors: {
            NOT_FOUND: 'Eroare de cerere',
            FORBIDDEN: 'Acces refuzat. Vă rugăm să vă autentificați din nou',
            SERVER_ERROR: 'Acțiune eșuată. Vă rugăm să încercați din nou mai târziu',
            INVALID_ACCOUNT: 'Sesiune expirată. Vă rugăm să vă autentificați din nou',
            INVALID_EMAIL: 'Adresa de e-mail invalidă',
            INVALID_PHONE: 'Număr de telefon invalid',
            INVALID_COUNTRY: 'Țară invalidă',
            INVALID_LOGIN: 'Date de autentificare invalide',
            USER_BLOCKED: 'Contul este blocat. Vă rugăm să contactați serviciul de asistență',
            USER_REGISTERED: 'Utilizatorul cu adresa de e-mail sau numărul de telefon introdus este deja înregistrat',
            INVALID_USER: 'Utilizatorul nu a fost găsit',
            REJECTED: 'Funcționarea nu este permisă din cauza restricțiilor platformei',
            INVALID_OLD_PASSWORD: 'Parola veche este invalidă',
            INVALID_SYMBOL: 'Simbol de tranzacționare invalid',
            INVALID_SYMBOL_PRECISION: 'Precizie invalidă a simbolului',
            INVALID_FRAME: 'Cadru grafic invalid',
            INVALID_CURRENCY: 'Monedă invalidă',
            INVALID_PRODUCT: 'Tip de cont de tranzacționare invalid',
            INSUFFICIENT_FUNDS: 'Fonduri insuficiente',
            INVALID_WITHDRAWAL: 'Retragerea nu a fost găsită',
            INVALID_STATUS: 'Stare invalidă',
            INVALID_FILE: 'Extensie de fișier invalidă sau fișierul este prea mare',
            INVALID_DOCUMENT: 'Documentul nu a fost găsit',
            INVALID_NAME: 'Nume invalid',
            INVALID_LOT_SIZE: 'Dimensiunea invalidă a lotului',
            INVALID_LOT_STEP: 'Pas de lot nevalabil',
            INVALID_MIN_VOLUME: 'Volum minim invalid',
            INVALID_MAX_VOLUME: 'Volum maxim invalid',
            INVALID_GROUP: 'Grup invalid',
            INVALID_SPREAD: 'Marjă invalidă',
            REQUEST_TIMEOUT: 'Cererea a expirat. Vă rugăm să încercați din nou mai târziu',
            EXISTING_OPEN_ORDERS: 'Aveți o comandă deschisă, închideți mai întâi comanda pentru acest utilizator și încercați din nou.',
            INVALID_TYPE: 'Tip de comandă invalid',
            INVALID_VOLUME: 'Volum de comandă invalid',
            INVALID_SL: 'Valoare invalidă a stop loss',
            INVALID_TP: 'Valoare nevalabilă a take profit',
            INVALID_PRICE: 'Preț de comandă invalid',
            INVALID_EXPIRATION: 'Expirarea comenzii invalidă',
            NOT_ENOUGH_MARGIN: 'Marjă insuficientă',
            INVALID_ORDER: 'Comanda nu a fost găsită',
            MARKET_CLOSED: 'Tranzacționarea nu este disponibilă. Vă rugăm să încercați din nou',
            INVALID_BALANCE_TYPE: 'Tip nevalabil de operațiune sold',
            INVALID_HASH: 'Hash invalid',
            HASH_EXPIRED: 'Linkul de resetare a parolei dumneavoastră a expirat. Vă rugăm să solicitați una din nou',
            INVALID_CODE: 'Cod 2FA invalid',
            CHAT_DISABLED: 'Accesul la chat-ul de asistență a fost închis',
            WITHDRAWAL_NOT_ALLOWED: 'Accesul la retrageri a fost închis',
            TRADING_DISABLED: 'Tranzacționarea este dezactivată pentru contul curent',
            PENDING_DEPOSITS_LIMIT: 'Limita depozitelor a fost depășită',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Este permisă doar o singură retragere în așteptare',
            LOCAL_DEPOSIT_ONLY_REAL: 'Depozitul este disponibil doar pentru contul real',
            LOCAL_DEPOSIT_POSITIVE: 'Valoarea depozitului trebuie să fie mai mare de 0',
            LOCAL_INVALID_CARD_NUMBER: 'Număr de card invalid',
            LOCAL_INVALID_CARD_EXPIRATION: 'Expirare invalidă a cardului',
            LOCAL_INVALID_CVC: 'Cod CVV/CVC invalid',
            LOCAL_PASSWORDS_NOT_MATCH: 'Parolele nu se potrivesc, vă rugăm să le introduceți din nou',
            LOCAL_NO_TRADING_ACCOUNTS: 'Nu aveți conturi de tranzacționare disponibile. Vă rugăm să contactați serviciul de asistență',
            LOCAL_MIN_DEPOSIT: 'Depozitul minim este $',
            LOCAL_MIN_WITHDRAWAL: 'Suma minimă de retragere este de $',
            LOCAL_INVALID_FILE_TYPE: 'Formatul de fișier nu este acceptat. Vă rugăm să atașați doar JPG, PNG sau PDF',
            LOCAL_MIN_STAKING: 'Depozitul minim pentru această monedă este ',
            FLOOD_REJECTED: 'Trimiteți mesaje prea des. Vă rugăm să încercați din nou mai târziu'
        },
        countries: {
            AF: 'Afganistan',
            AX: 'Insulele Aland',
            AL: 'Albania',
            DZ: 'Algeria',
            AS: 'Samoa Americană',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarctica',
            AG: 'Antigua și Barbuda',
            AR: 'Argentina',
            AM: 'Armenia',
            AW: 'Aruba',
            AU: 'Australia',
            AT: 'Austria',
            AZ: 'Azerbaijan',
            BS: 'Bahamas',
            BH: 'Bahrain',
            BD: 'Bangladesh',
            BB: 'Barbados',
            BY: 'Belarus',
            BE: 'Belgia',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Butan',
            BO: 'Bolivia',
            BA: 'Bosnia și Herțegovina',
            BW: 'Botswana',
            BV: 'Insula Bouvet',
            BR: 'Brazilia',
            IO: 'Teritoriul britanic din Oceanul Indian',
            BN: 'Brunei Darussalam',
            BG: 'Bulgaria',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Cambodgia',
            CM: 'Camerun',
            CA: 'Canada',
            CV: 'Capul Verde',
            KY: 'Insulele Cayman',
            CF: 'Republica Centrafricană',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'China',
            CX: 'Insula Christmas',
            CC: 'Insulele Cocos (Keeling)',
            CO: 'Colombia',
            KM: 'Comore',
            CG: 'Congo',
            CD: 'Congo, Republica Democrată',
            CK: 'Insulele Cook',
            CR: 'Costa Rica',
            CI: 'Coasta de Fildeș',
            HR: 'Croația',
            CU: 'Cuba',
            CY: 'Cipru',
            CZ: 'Republica Cehă',
            DK: 'Danemarca',
            DJ: 'Djibouti',
            DM: 'Dominica',
            DO: 'Republica Dominicană',
            EC: 'Ecuador',
            EG: 'Egipt',
            SV: 'El Salvador',
            GQ: 'Guineea Ecuatorială',
            ER: 'Eritreea',
            EE: 'Estonia',
            ET: 'Etiopia',
            FK: 'Insulele Falkland (Malvine)',
            FO: 'Insulele Feroe',
            FJ: 'Fiji',
            FI: 'Finlanda',
            FR: 'Franța',
            GF: 'Guiana Franceză',
            PF: 'Polinezia Franceză',
            TF: 'Teritoriile australe franceze',
            GA: 'Gabon',
            GM: 'Gambia',
            GE: 'Georgia',
            DE: 'Germania',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Grecia',
            GL: 'Groenlanda',
            GD: 'Grenada',
            GP: 'Guadelupa',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guineea',
            GW: 'Guinee-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Insula Heard și Insulele Mcdonald',
            VA: 'Sfântul Scaun (Statul Vatican)',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Ungaria',
            IS: 'Islanda',
            IN: 'India',
            ID: 'Indonezia',
            IR: 'Iran',
            IQ: 'Irak',
            IE: 'Irlanda',
            IM: 'Insula Man',
            IL: 'Israel',
            IT: 'Italia',
            JM: 'Jamaica',
            JP: 'Japonia',
            JE: 'Jersey',
            JO: 'Iordania',
            KZ: 'Kazahstan',
            KE: 'Kenya',
            KI: 'Kiribati',
            KR: 'Coreea',
            KW: 'Kuweit',
            KG: 'Kârgâzstan',
            LA: 'Republica Democrată Populară Laos',
            LV: 'Letonia',
            LB: 'Liban',
            LS: 'Lesotho',
            LR: 'Liberia',
            LY: 'Marea Jamahirie Arabă Socialistă Populară Libiană',
            LI: 'Liechtenstein',
            LT: 'Lituania',
            LU: 'Luxemburg',
            MO: 'Macao',
            MK: 'Macedonia',
            MG: 'Madagascar',
            MW: 'Malawi',
            MY: 'Malaysia',
            MV: 'Maldive',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Insulele Marshall',
            MQ: 'Martinica',
            MR: 'Mauritania',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Mexic',
            FM: 'Micronezia',
            MD: 'Moldova',
            MC: 'Monaco',
            MN: 'Mongolia',
            ME: 'Muntenegru',
            MS: 'Montserrat',
            MA: 'Maroc',
            MZ: 'Mozambic',
            MM: 'Myanmar',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Țările de Jos',
            AN: 'Antilele Olandeze',
            NC: 'Noua Caledonie',
            NZ: 'Noua Zeelandă',
            NI: 'Nicaragua',
            NE: 'Niger',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Insula Norfolk',
            MP: 'Insulele Mariane de Nord',
            NO: 'Norvegia',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Teritoriul palestinian, ocupat',
            PA: 'Panama',
            PG: 'Papua Noua Guinee',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Filipine',
            PN: 'Pitcairn',
            PL: 'Polonia',
            PT: 'Portugalia',
            PR: 'Puerto Rico',
            QA: 'Qatar',
            RE: 'Reunion',
            RO: 'România',
            RU: 'Rusia',
            RW: 'Rwanda',
            BL: 'Saint Barthelemy',
            SH: 'Sfânta Elena',
            KN: 'Sfântul Cristofor și Nevis',
            LC: 'Sfânta Lucia',
            MF: 'Insula Sfântul Martin',
            PM: 'Saint Pierre și Miquelon',
            VC: 'Sfântul Vincențiu și Grenadinele',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tome și Principe',
            SA: 'Arabia Saudită',
            SN: 'Senegal',
            RS: 'Serbia',
            SC: 'Seychelles',
            SL: 'Sierra Leone',
            SG: 'Singapore',
            SK: 'Slovacia',
            SI: 'Slovenia',
            SB: 'Insulele Solomon',
            SO: 'Somalia',
            ZA: 'Africa de Sud',
            GS: 'Georgia de Sud și Insulele Sandwich de Sud',
            ES: 'Spania',
            LK: 'Sri Lanka',
            SD: 'Sudan',
            SR: 'Surinam',
            SJ: 'Svalbard și Jan Mayen',
            SZ: 'Swaziland',
            SE: 'Suedia',
            CH: 'Elveția',
            SY: 'Republica Arabă Siriană',
            TW: 'Taiwan',
            TJ: 'Tajikistan',
            TZ: 'Tanzania',
            TH: 'Thailanda',
            TL: 'Timorul de Est',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad și Tobago',
            TN: 'Tunisia',
            TR: 'Turcia',
            TM: 'Turkmenistan',
            TC: 'Insulele Turks și Caicos',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ucraina',
            AE: 'Emiratele Arabe Unite',
            GB: 'Regatul Unit',
            US: 'Statele Unite ale Americii',
            UM: 'Insulele Minore Îndepărtate ale Statelor Unite',
            UY: 'Uruguay',
            UZ: 'Uzbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Vietnam',
            VG: 'Insulele Virgine, britanice',
            VI: 'Insulele Virgine, S.U.A.',
            WF: 'Wallis și Futuna',
            EH: 'Sahara Occidentală',
            YE: 'Yemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe'
        },
    },
    ge: {
        _name: 'Georgia',
        timePickerModal: {
            title: 'Close In Time'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'სარგებლის მიღება, დანაკარგების გაჩერება',
            takeProfit: 'სარგებლის მიღება',
            stopLoss: 'დანაკარგების გაჩერება',
            updateOrder: 'შეკვეთის განახლება',
        },
        balanceModal: {
            balance: 'ბალანსი',
            credit: 'კრედიტი',
            equity: 'აქცია',
            practice: 'პრაქტიკა',
            real: 'ნამდვილი',
            account: 'ანგარიში',
            invested: 'ინვესტირებული',
            profit: 'მოგება',
            loss: 'წაგება',
            margin: 'შემოსავალი',
            marginLevel: 'შემოსავლის დონე',
            marginFree: 'ხელმისაწვდომი შემოსავალი'
        },
        header: {
            cfd: 'სხვაობათა კონტრაქტი',
            platform: 'პლატფორმა',
            deposit: 'ჩარიცხვა/დეპოზიტი'
        },
        sideMenu: {
            marketWatch: 'ბაზარზე დაკვირვება',
            activeOrders: 'აქტიური შეკვეთები',
            tradingHistory: 'ვაჭრობის ისტორია',
            economicCalendar: 'ეკონომიკური კალენდარი',
            marketNews: 'ბაზრის ახალი ამბები'
        },
        closePositionModal: {
            closeOrder: 'პოზიციის დახურვა',
            areYouSure: 'დარწმუნებული ხარ, რომ პოზიციის დახურვა გსურს?',
            buy: 'გიყდვა',
            sell: 'გაყიდვა',
            yes: 'კი',
            no: 'არა',
        },
        pciDssModal: {
            desc: `გადახდის ბარათების ინდუსტრიის მონაცემთა უსაფრთხოების სტანდარტი (PCI DSS) მოთხოვნა არის საჭირო ყველა ენთიტებისთვის, რომელნიც შენახავენ, გადაამოწმებენ ან გადაამოწმებენ Visa/Master Card/Stripe ბარათის პორტინფოლიოს მონაცემებს, მოწყობილობებს და მომსახურებელებს.
            ეს არის გლობალური უსაფრთხოების სტანდარტი ყველა ენთიტეტისთვის, რომელნიც შენახავენ, გადაამოწმებენ ან გადაამოწმებენ ბარათის პორტინფოლიოს მონაცემებს და/ან გამფრთხილებიანი ავტენტიკაციის მონაცემებს. PCI DSS დაყენებისთვის აწყობს საბაზისო დაცვის დონის კონსუმერებისთვის და გადახაზებებისა და მონაცემთა დაცვის პრაქტიკის საერთაშორისო ეკოსისტემაში დამატებითი უზრუნველყოფა.`
        },
        chart: {
            addNewChart: 'ახალი ცხრილის აგება',
        },
        symbolsModal: {
            watchlist: 'საყურადღებო აქციები',
            asset: 'აქცია',
            price: 'ფასი',
            changePct: 'ცვლილება ბოლო 24 სთ'
        },
        pendingModal: {
            pending: 'განხილვაშია',
            assetPrice: 'აქციის ფასი',
            current: 'ახლანდელი',
            revert: 'საბაზრო ფასზე გადატანა',
            automatically: 'პოზიცია აუტომატურად გაიხსნება, როდესაც ფასი მიაღწევს ამ დონეს'
        },
        orderMenu: {
            default: 'ჩვეულებრივი',
            volume: 'მოცულობა',
            lots: 'წილები',
            units: 'ერთეულები',
            currency: 'ვალუტა',
            contractSize: 'კონტრაქტის ზომა',
            position: 'პოზიცია',
            margin: 'შემოსავალი',
            freeMargin: 'ხელმისაწვდომი შემოსავალი',
            takeProfitStopLoss: 'სარგებლის მიღება & დანაკარგების შეჩერება',
            pending: 'განხილვაშია',
            market: 'ბაზარი',
            leverage: 'ბერკეტი',
            spread: 'ფასთა სხვაობა',
            notSet: 'არაა დაყენებული',
            at: 'ამ დონეზე',
            buy: 'ყიდვა',
            sell: 'გაყიდვა',
        },
        footer: {
            supportBanner: 'ყოველ დღე, მთელი დღე',
            currentTime: 'დრო',
            liveChat: 'ლაივ ჩატი'
        },
        statusBar: {
            balance: 'ბალანსი',
            credit: 'კრედიტი',
            equity: 'აქცია',
            margin: 'შემოსავალი',
            marginLevel: 'შემოსავლის დონე',
            freeMargin: 'ხელმისაწვდომი შემოსავალი',
            pnl: 'PnL',
            profitTotal: 'PnL-ის ამოუწურავი მარაგი'
        },
        accountModal: {
            dateRegistered: 'რეგისტრაციის თარიღი',
            userId: 'მომხმარებლის ID',
            dashboard: 'მართვის დაფა',
            personalData: 'პირადი მონაცემები',
            deposit: 'დეპოზიტი',
            withdrawFunds: 'თანხის გამოტანა',
            savings: 'დაგროვილი თანხა',
            settings: 'პარამეტრები',
            logout: 'გასვლა'
        },
        depositModal: {
            BT_INVOICE: 'შეიტანეთ რაოდენობა და შექმნეით ინვოისი',
            BT_TITLE: 'ჩვენი საბანკო მონაცემები',
            account: 'ანგარიში',
            bank_name: 'ბანკის დასახელება',
            beneficiary: 'მიმღები',
            beneficiary_address: 'მიმღების მისამართი',
            swift: 'Swift',
            reference: 'Reference',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'რაოდენობა',
            TOKEN_SUBTOTAL: 'შეუალედური ჯამი',
            TOKEN_TOTAL: 'ჯამი',
            TOKEN_DESCRIPTION: 'აღწერა',
            TOKEN_PAYMENT_DETAILS: 'გადახდის დეტალები',
            PENDING_DEPOSITS_LIMIT: 'დეპოზიტის ლიმიტი',
            makeDeposit: 'დეპოზიტის შექმნა',
            practiceAccount: 'საპრაქტიკო ანგარიში',
            realAccount: 'ნამდვილი ანგარიში',
            noWithdrawals: 'არაა გამოტანები',
            easyWithdrawals: 'მარტივი გამოტანები',
            allAssets: 'ყველა აქცია ხელმისაწვდომია',
            fullFledged: 'სრულყოფილი პლატფორმა',
            fillUpTo: 'შევსება ამ დონემდე',
            freeReplenishment: 'თავისუფალი შევსება',
            topUp: 'ანგარიშის შევსება',
            minimumAmount: 'მინიმალური რაოდენობა',
            canSwitch: 'ნებისმიერ დროს შეგიძლიათ შეცვალოთ ანგარიშები'
        },
        gridsModal: {
            chartGrids: 'ცხრილები',
            chart1: '1 ცხრილი',
            chart2: '2 ცხრილი',
            chart3: '3 ცხრილი',
            chart4: '4 ცხრილი',
        },
        noConnectionModal: {
            connectionLost: 'სერვერთან კავშირი დაიკარგა',
            retryNow: 'თავიდან ცდა',
        },
        loginModal: {
            loginToTradeRoom: 'შესვლა სავაჭრო ოთახში',
            email: 'ი-მეილი',
            enterEmail: 'შეიყვანეთ ი-მეილი',
            twoFactor: '2FA კოდი (თუ არის ჩართული)',
            twoFactorAuth: 'ორ-ფაქტორიანი ავტორიზაცია',
            password: 'პაროლი',
            yourPassword: 'შენი პაროლი',
            signIn: 'შესვლა',
            dontHaveAccount: 'არ გაქვთ ანგარიში?',
            restore: 'აღდგენა',
            fullName: 'სახელი & გვარი',
            havePromo: 'მაქვს პრომო კოდი',
            promo: 'პრომო კოდი',
            login: 'შესვლა',
            haveAccount: 'უკვე გაქვთ ანგარიში?',
            hide: 'დამალვა',
            goTo: 'უკან'
        },
        newPasswordModal: {
            setNewPassword: 'ახალი პაროლის დაყენება',
            newPassword: 'ახალი პაროლი',
            confirmNewPassword: 'ახალი პაროლის დადასტურება',
            setButton: 'დაყენება'
        },
        registerModal: {
            repeatPassword: 'პაროლის გამეორება',
            createNewAccount: 'ახალი ანგარიშის შექმნა',
            email: 'ი-მეილი',
            firstName: 'სახელი',
            lastName: 'გვარი',
            country: 'ქვეყანა',
            phone: 'ტელეფონი',
            password: 'პაროლი',
            createAccount: 'ანგარიშის შექმნა',
            currency: 'ვალუტა',
            privacyAndPolicy: "კონფიდენციალურობა და პოლიტიკა",
            customerAgreement: 'მომხმარებლის შეთანხმება',

        },
        forgotPasswordModal: {
            forgotPassword: 'დაგავიწყდა პაროლი?',
            submitEmail: 'გთხოვთ, შეიყვანეთ ი-მეილი, გადადით ი-მეილზე და მიყევით ინსტრუქციებს',
            submit: 'დადასტურება'
        },
        notifications: {
            error: 'შეცდომა',
            success: 'წარმატება',
            deposit: 'დეპოზიტი',
            withdrawal: 'გამოტანა',
            depositApproved: 'დეპოზიტი დადასტურებულია',
            depositDeclined: 'დეპოზიტი უარყოფილია',
            withdrawalApproved: 'გამოტანა დადასტურებულია',
            withdrawalDeclined: 'გამოტანა უარყოფილია'
        },
        markets: {
            forex: 'ფორექსი',
            stocks: 'აქციები',
            commodities: 'საქონელი',
            indices: 'ინდექსები',
            crypto: 'კრიპტო',
            metals: 'მეტალები',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'ყიდვა',
            profitCalculator: 'მოგების კალკულატორი',
            sell: 'გაყიდვა',
            volume: 'მოცულობა',
            entryPrice: 'თავდაპირველი ფასი',
            takeProfit: 'მოგების გამოტანა',
            stopLoss: 'წაგების შეჩერება',
            maxPosition: 'მაქს. პოზიცია',
            calculate: 'კალკულაცია',
            calculationsFor: 'კალკულაცია შემდეგი მონაცემისთვის',
            leverage: 'ბერკეტი',
            requiredMargin: 'საჭირო სხვაობა',
            profitFromTP: 'მოგება TP-დან',
            lossFromSL: 'წაგება SL-დან',
            roe: 'მოგება ინვესტიციიდან',
        },
        depositSuccessModal: {
            title: 'დეპოზიტი წარმატებულად შესრულდა',
            text: 'თქვენი დეპოზიტი წარმატებულად შესრულდა!',
        },
        depositFailModal: {
            title: 'დეპოზიტი წარუმატებელია',
            text: 'თქვენი დეპოზიტი ვერ შესრულდა.',
        },
        widgetMarketWatch: {
            marketWatch: 'ბაზარზე დაკვირვება',
            search: 'ძებნა...',
        },
        widgetOrders: {
            commission: 'საკომისიო',
            volume: 'მოცულობა',
            margin: 'სხვაობა',
            active: 'აქტიური',
            pending: 'მუშავდება',
            activeOrders: 'აქტიური შეკვეთები',
            portfolio: 'პორტფელი',
            allPositions: 'ყველა პოზიცია',
            noPositionsLine1: 'თქვენ არ გაქვთ ღია პოზიციები',
            noPositionsLine2: 'ჯერ-ჯერობით',
            show: 'ჩვენება',
            more: 'მეტი',
            less: 'ნაკლები',
            purchaseTime: 'ყიდვის დრო',
            closePosition: 'დახურვის პოზიცია',
            priceOpen: 'ფასი ყიდვისას',
            priceSl: 'წაგების შეჩერების ფასი',
            priceTp: 'მოგების გამოტანის ფასი',
            type: 'პოზიციის მიმართულება',
            pnl: 'მოგება/წაგება',
            cancelOrder: 'შეკვეთის გაუქმება',
            orderType: 'შეკვეთის ტიპი',
            cancelled: 'გაუქმებული',
            tradingHistory: 'ვაჭრობის ისტორია',
            noHistoryLine1: 'თქვენ ჯერ-ჯერობით არ გაქვთ',
            noHistoryLine2: 'დახურული შეკვეთები',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'ეკონომიკური კალენდარი'
        },
        widgetMarketNews: {
            marketNews: 'ბაზრის ახალი ამბები'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'აქტიური შეკვეთები',
            ordersHistory: 'შეკვეთების ისტორია',
            id: 'ID',
            symbol: 'სიმბოლო',
            type: 'ტიპი',
            volume: 'მოცულობა',
            openPrice: 'ფასი გაღებისას',
            openTime: 'გაღების დრო',
            closePrice: 'ფასი დახურვისას',
            closeTime: 'დახურვის დრო',
            sl: 'SL',
            tp: 'TP',
            price: 'ფასი',
            pnl: 'PnL',
            actions: 'ქმედებები',
            edit: 'შეცვლა',
            close: 'დახურვა',
            commission: 'საკომისიო',
            swap: 'სვოპი',
            swapLong: 'გრძელი სვოპი',
            swapShort: 'მოკლე სვოპი'
        },
        assetInfoModal: {
            assetInfo: 'აქციის მონაცემები',
            tradingConditions: 'სავაჭრო პირობები',
            information: 'ინფორმაცია',
            bid: 'შეთავაზება',
            ask: 'თხოვნა',
            sessionChange: 'შესიის შეცვლა',
            tradeNow: 'ვაჭრობა',
            opens: 'იღება',
            closes: 'იხურება',
            at: 'აქ/ამ მონაცემზე',
            open247: 'ღიაა 24/7',
            today: 'დღეს',
            tomorrow: 'ხვალ',
            sunday: 'კვირა',
            monday: 'ორშაბათი',
            tuesday: 'სამშაბათი',
            wednesday: 'ოთხშაბათი',
            thursday: 'ხუთშაბათი',
            friday: 'პარასკევი',
            saturday: 'შაბათი',
            contractSpecification: 'კონტრაქტის დაზუსტება',
            symbol: 'სიმბოლო',
            name: 'სახელი',
            market: 'ბაზარი',
            baseCurrency: 'საბაზისო ვალუტა',
            digits: 'ციფრები',
            lotSize: 'წილის ზომა',
            lotStep: 'წილის მომატების დონე',
            minVolume: 'მინ. მოცულობა',
            maxVolume: 'მაქს. მოცულობა',
            leverage: 'ბერკეტი',
            commission: 'საკომისიო',
            swapLong: 'გრძელი სვოპი',
            swapShort: 'მოკლე სვოპი',
            schedule: 'განრიგი',
            weekday: 'სამუშაო დღე',
            tradingTime: 'სავაჭრო დრო',
            closed: 'დახურული',
            sell: 'გაყიდვა',
            buy: 'ყიდვა',
            low: 'დაბალი',
            high: 'მაღალი',
            oneHourChange: 'ცვლილება ბოლო 1 სთ.',
            oneDayChange: 'ცვლილება ბოლო 1 დღე',
            oneWeekChange: 'ცვლილება ბოლო 1 კვირა',
            oneMonthChange: 'ცვლილება ბოლო 1 თვე',
            threeMonthsChange: 'ცვლილება ბოლო 3 თვე',
            oneYearChange: 'ცვლილება ბოლო 1 წელი',
            loading: 'იტვირთება...',
        },
        autoTrader: {
            days: 'days',
            title: 'Auto Trader',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Risk Level: Low',
            riskLevelMedium: 'Risk Level: Medium',
            riskLevelHigh: 'Risk Level: High',
            perDay: 'Trades per day:',
            desc: `Trading CFDs and other leveraged products can lead to losses. Before trading, clients should
                read the
                relevant risk statements on our Risk Disclosure page. Automated trading does not guarantee results. The
                company accepts no responsibility for the loss of funds in automatic trading. Please make sure that you
                fully understand the risks and take measures to manage risks.`,
            accept: 'Accept',
            save: 'შენახვა',
            cancel: 'გაუქმება',
        },
        cardBox: {
            name: 'სახელი',
            cardNumber: 'ბარათის ნომერი',
            expiration: 'ვადა (თვ/წწ)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "ვაჭრობა",
            startTrading: 'დაიწყეთ ვაჭრობა',
            deposit: 'დეპოზიტი',
            dashboard: 'მართვის დაფა',
            personalInfo: 'პირადი მონაცემები',
            contactInfo: 'კონტაქტური ინფორმაცია',
            withdrawal: 'გამოტანა',
            verification: 'ავტორიზაცია',
            accounts: 'ანგარიშები',
            liveChat: 'ლაივ ჩატი',
            savings: 'დაგროვეული რაოდენობა',
            settings: 'პარამეტრები',
            logOut: 'გასვლა',
        },
        fullScreenDeposit: {
            methods: 'მეთოდები',
            sendOtp: 'otp გაგზავნა',
            otpCode: 'Otp კოდი',
            addWalletAddress: 'დეპოზიტის საფულე არაა მითითებული. გთოხვთ, დაუკავშირდით აგენტს ან მხარდაჭერის პერსონალს',
            addressReceipt: 'გთხოვთ, დაიცადეთ, სანამ მისამართი იტვირთება',
            makeDeposit: 'დეპოზიტის შექმნა',
            instant: 'დაუყოვნებელი',
            minutes: 'წუთები',
            hours: 'საათი',
            days: 'დღე',
            amount: 'რაოდენობა',
            continue: 'გაგრძელება',
            qrCode: 'QR კოდი',
            depositAddress: 'დეპოზიტის მისამართი',
            copy: 'კოპირება',
            last: 'ბოლო',
            deposits: 'დეპოზიტები',
            time: 'დრო',
            currency: 'ვალუტა',
            status: 'სტატუსი',
            info: 'ინფორმაცია',
        },
        fullScreenCcExt: {
            pleaseWait: 'გთხოვთ, დაიცადეთ. ვუკავშირდებით გადახდის პროვაიდერს...',
            cardPayment: 'ბარათით გადახდა',
            inOrder: 'იმისათვის, რომ გაიგზავნოს ეს ოდენობა,',
            pleaseProvide: 'გთხოვთ, მიუთითეთ ბარათის მონაცემები'
        },
        fullScreenDashboard: {
            completeVerification: 'Please Complete Verification',
            totalBalance: 'ჯამური ბალანსი',
            totalPnl: 'ჯამური PNL',
            profitableOrders: 'მომგებიანი შეკვეთები',
            roi: 'მოგება ინვესტიციიდან',
            activityLog: 'ქმედებების ისტორია',
            loginFromIp: 'შესვლა IP-დან',
            tradingResults: 'ვაჭრობის შედეგები',
            week: 'კვირა',
            month: 'თვე',
            year: 'წელი',
            successRate: 'წარმატების სიხშირე',
            closedWithProfit: 'დაიხურა მოგებით',
            closedWithLoss: 'დაიხურა წაგებით',
            account: 'ანგარიში',
            balance: 'ბალანსი',
            leverage: 'ბერკეტი',
            credit: 'კრედიტი',
            tradeNow: 'ვაჭრობა',
            usingCurrentRate: 'გამოიყენება ვალუტის ახლანდელი კურსი',
            totalDeposits: 'ჯამური დეპოზიტები'
        },
        fullScreenPersonal: {
            personalDetails: 'პირადი მონაცემები',
            profilePhoto: 'პროფილის სურათი',
            firstName: 'სახელი',
            country: 'ქვეყანა',
            lastName: 'გვარი',
            address: 'მისამართი',
            email: 'ი-მეილი',
            phone: 'ტელეფონი',
            saveChanges: 'ცვლილებების შენახვა',
            state: 'ქალაქი',
            postcode: 'საფოსტო ინდექსი',
            created: "ანგარიში შექმნილია",
        },
        fullScreenAvatar: {
            dropFile: 'სურათის ასატვირთათ, შეიყვანეთ ზემოთ აღნიშნულ წრეში',
            notAllowed: 'შენახვა არაა შესაძლებელი',
            notAllowedLine1: 'პორნოგრაფიული ხასიათის სურათები',
            notAllowedLine2: 'ეთნიკური ზიზღის ან რასიზმის შემცველი სურათები',
            notAllowedLine3: '18 წლამდე ასაკის ბავშვების შემცველი სურათები',
            notAllowedLine4: 'სურათები, რომლებიც დაცულია საავტორო უფლებებით',
            notAllowedLine5: '5 MB-ზე დიდი სურათები ან სურათები, რომლებიც არ არიან  JPG, GIF ან PNG ფორმატის',
            requirements: 'თქვენი სახე მკაფიოდ უნდა ჩანდეს. ყველა სურათი უნდა ექვემდებარებოდეს სტანდარტებს, ან წაიშლება.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'გთხოვთ, დაუკავშირდით თქვენი ანგარიშის მენეჯერს გამოტანის დეტალებთან დაკავშირებით.',
            cryptocurrency: 'Cryptocurrency',
            withdrawal: 'გამოტანა',
            requestNewWithdrawal: 'ახალი გამოტანის მოთხოვნა',
            amount: 'რაოდენობა',
            currentBalance: 'თქვენი ანგარიშის ბალანსი არის',
            withdrawAll: 'ყველაფრის გამოტანა',
            requestWithdrawal: 'გამოტანის მოთხოვნა',
            last: 'ბოლო',
            withdrawalRequests: 'გამოტანის მოთხოვნები',
            time: 'დრო',
            currency: 'ვალუტა',
            info: 'ინფორმაცია',
            status: 'სტატუსი',
            bank: 'ბანკი',
            bitcoin: 'ბიტკოინი',
            zelle: 'Zelle',
            card: 'ბარათი',
            ethereum: 'ეთერიუმი',
            eth: 'ეთერიუმი',
            usdt: 'USDT',
            withdrawalDetails: 'გამოტანის დეტალები',
            address: 'საფულის მისამართი',
            bankAccount: 'ანგარიშის ნომერი',
            bankHolder: 'ანგარიშის მფლობელის სახელი',
            bankIban: 'IBAN',
            bankSwift: 'ბანკის SWIFT კოდი',
            recipientAddress: 'მიმღების მისამართი',
            routingNumber: 'მარშრუტის ნომერი',
            bankBeneficiary: 'ბენეფიციარის ბანკის სახელი',
            bankAddress: 'ბანკის მისამართი',
            bankCode: 'ბანკის კოდი',
            desc: 'აღწერა',
            cardNumber: 'ბარათის ნომერი',
            cardHolder: 'ბარათის მფლობელის სახელი',
        },
        fullScreenVerification: {
            dragAndDrop: 'ჩააგდეთ დოკუმენტი აქ',
            documentsList: 'ატვირთული დოკუმენტების სია',
            document: 'დოკუმენტი',
            timeUploaded: 'ატვირთვის დრო',
            timeProcessed: 'დამუშავების დრო',
            status: 'სტატუსი',
            types: ['Id-ს სინამდვილე', 'რეზიდენციის მტკიცებულება', 'ბარათის წინა მხარე', 'ბარათის უკანა მხარე', 'Id-ს სინამდვილე', 'სელფი'],
            types2: ['იდენტიფიკაციის მტკიცებულება (POI)', 'სარეზიდენციო მისამართის მტკიცებულება', 'ბარათის წინა მხარე', 'ბარათის უკანა მხარე', 'ID-ს უკანა მხარე', 'სელფი ID-თან ერთად'],
            upload: 'ატვირთვა',
            isConfirmed: 'დადასტურებულია',
            uploadAnotherDocument: 'ატვირთეთ ახალი დოკუმენტი',
            isUnderReview: 'მიმდინარეობს მიმოხილვა',
            questionnaire: 'კითხვარი',
            sendAnswers: 'პასუხების გაგზავნა',
            progressBarOf: 'დან',
            progressBarText: 'დოკუმენტი აიტვირთა და დადასტურდა'
        },
        fullScreenAccounts: {
            manageAccounts: 'თანხის გადარიცხვა ანგარიშებს შორის',
            transferFrom: 'ამ ანგარიშიდან',
            transferTo: 'ამ ანგარიშზე',
            amount: 'რაოდენობა',
            makeTransfer: 'გადარიცხვა',
            yourAccounts: 'თქვენი ანგარიშები',
            account: 'ანგარიში',
            currency: 'ვალუტა',
            balance: 'ბალანსი',
            credit: 'კრედიტი',
            makeActive: 'გააქტიურება',
            active: 'აქტიური'
        },
        fullScreenSupport: {
            sendMessage: 'მესიჯის გაგზავნა'
        },
        fullScreenSavings: {
            pct_0: 'მოქნილი',
            pct_30: 'დახურულია 1 თვის განმავლობაში',
            pct_90: 'დახურულია 3 თვის განმავლობაში',
            pct_180: 'დახურულია 6 თვის განმავლობაში',
            pct_365: 'დახურულია 1 წლის განმავლობაში',
            savings: 'დაგროვილი თანხა',
            detailedInformation: 'ინფორმაცია',
            pleaseSelect: 'გთხოვთ, აირჩიეთ ვალუტა და პერიოდი',
            openSavings: 'დაგროვილი თანხის ნახვა',
            currentTime: 'დრო',
            releaseTime: 'გაშვების დრო',
            currencyChosen: 'არჩეული ვალუტა',
            periodChosen: 'არჩეული პერიოდი',
            yourRate: 'თქვენი დონე',
            yourEarnings: 'თქვენი მოგება',
            accountFrom: 'ანგარიშები, საიდანაც ირიცხება თანხა',
            enterAmount: 'შეიყვანეთ რაოდენობა',
            in: '-ში',
            openAndInvest: 'გახსენით დაგროვილი თანხების ანგარიში და ჩადევით ინვესტიცია',
            investment: 'ინვესტიცია',
            period: 'პერიოდი',
            daysTotal: 'დღეების ჯამური რაოდენობა',
            finalEarnings: 'საბოლოო მოგება',
            created: 'შექმნილია',
            daysElapsed: 'გასული დღეები',
            days: 'დღეები',
            earnings: 'მოგება',
            fundsReleasedIn: 'გაშვებული ოდენობა',
            claim: 'მიღება',
        },
        fullScreenSettings: {
            updatePassword: 'პაროლის განახლება',
            currentPassword: 'ახლანდელი პაროლი',
            newPassword: 'ახალი პაროლი',
            changePassword: 'პაროლის შეცვლა',
            activationForGoogle: 'Google Authenticator-ის აქტივაციის კოდი',
            activateAndEnter: 'ჩართეთ ავტორიზაცია და შეიყვანეთ კოდი ქვემოთ',
            qrCode: 'QR კოდი',
            activateProtection: '2FA დაცვის აქტივაცია',
            protectionActive: '2FA დაცვა აქტიურია',
            twoFactorCode: '2FA კოდი',
            disableProtection: '2FA დაცვის გაუქმება',
            language: 'ენა',
            dashboardCurrency: 'მართვის პანელის ვალუტა',
            confirmNewPassword: 'ახალი პაროლის დადასტურება',
        },
        fullScreenReferrals: {
            yourLink: 'თქვენი რეფერალური ლინკი',
            referredFriends: 'რეფერალით მოსარგებლე მეგობრები',
            id: 'ID',
            name: 'სახელი',
            registered: 'რეგისტრაცია',
            level: 'დონე',
            payout: 'კომპენსაცია',
            lastPayouts: 'უკანასკნელი რეფერალების კომპენსაცია',
            time: 'დრო',
            amount: 'რაოდენობა',
            currency: 'ვალუტა',
            info: 'ინფო'
        },
        months: {
            Jan: 'იანვარი',
            Feb: 'თებერვალი',
            Mar: 'მარტი',
            Apr: 'აპრილი',
            May: 'მაისი',
            Jun: 'ივნისი',
            Jul: 'ივლისი',
            Aug: 'აგვისტო',
            Sep: 'სექტემბერი',
            Oct: 'ოქტომბერი',
            Nov: 'ნოემბერი',
            Dec: 'დეკემბერი'
        },
        orderTypes: ['ყიდვა', 'გაყიდვა', 'ყიდვის ლიმიტი', 'გაყიდვის ლიმიტი', 'ყიდვის შეჩერება', 'გაყიდვის შეჩერება'],
        statuses: ['განიხილება', 'დადასტურებულია', 'უარყოფილია', 'მუშავდება'],
        errors: {
            NOT_FOUND: 'დაფიქსირდა შეცდომა',
            FORBIDDEN: 'შევლა უარყოფილია. გთხოვთ, სცადოთ განმეორებით',
            SERVER_ERROR: 'ქმედება ვერ შესრულდა. გთხოვთ, სცადეთ მოგვიანებით',
            INVALID_ACCOUNT: 'სესია ამოიწრუა. გთხოვთ, თავიდან შედით.',
            INVALID_EMAIL: 'არასწორი ი-მეილი',
            INVALID_PHONE: 'არასწორი ტელ. ნომერი',
            INVALID_COUNTRY: 'არასწორი ქვეყანა',
            INVALID_LOGIN: 'არასწორი მონაცემები',
            USER_BLOCKED: 'ანგარიში დაბლოკილია, გთხოვთ, დაუკავშირდით მხარდაჭერის გუნდს',
            USER_REGISTERED: 'ეს ი-მეილი ან ტელ. ნომერი დაკავშირებულია არსებულ ანგარიშთან',
            INVALID_USER: 'მომხმარებელი არ იძებნება',
            REJECTED: 'ოპერაცია არაა დაშვებული პლატფორმის წესებიდან გამომდინარე',
            INVALID_OLD_PASSWORD: 'ძველი პაროლი არაა სწორი',
            INVALID_SYMBOL: 'არასწორი სავაჭრო სიმბოლო',
            INVALID_SYMBOL_PRECISION: 'სიმბოლოს შეუსაბამო სიზუსტე',
            INVALID_FRAME: 'ცხრილის ჩარჩო არასწორია',
            INVALID_CURRENCY: 'არასწორი ვალუტა',
            INVALID_PRODUCT: 'ანგარისთან შეუსაბამო ვაჭრობის ტიპი',
            INSUFFICIENT_FUNDS: 'თანხა არაა საკმარისი',
            INVALID_WITHDRAWAL: 'გამოტანა არ იძებნება',
            INVALID_STATUS: 'სტატუსი არასწორია',
            INVALID_FILE: 'ფაილის ტიპი არასწორია ან ფაილი ზომის ლიმტის აჭარბებს',
            INVALID_DOCUMENT: 'დოკუმენტი არ იძებნება',
            INVALID_NAME: 'არასწორი სახელი',
            INVALID_LOT_SIZE: 'წილის არასწორი ზომა',
            INVALID_LOT_STEP: 'წილის მომატების დონე არასწორია',
            INVALID_MIN_VOLUME: 'მინიმალური მოცულობა არასწორია',
            INVALID_MAX_VOLUME: 'მაქსიმალური მოცულობა არასწორია',
            INVALID_GROUP: 'ჯგუფი არასწორია',
            INVALID_SPREAD: 'სხვაობა არასწორია',
            REQUEST_TIMEOUT: 'მოთხოვნა გაუქმებულია. გთხოვთ, სცადეთ მოგვიანებით',
            EXISTING_OPEN_ORDERS:'თქვენ გაქვთ ღია შეკვეთა, ჯერ დახურეთ შეკვეთა ამ მომხმარებლისთვის და სცადეთ თავიდან.',
            INVALID_TYPE: 'შეკვეთის ტიპი არასწორია',
            INVALID_VOLUME: 'შეკვეთის მოცულობა არასწორია',
            INVALID_SL: 'წაგების შეჩერების ოდენობა არასწორია',
            INVALID_TP: 'მოგების გამოტანის ოდენობა არასწორია',
            INVALID_PRICE: 'შეკვეთის ფასი არასწორია',
            INVALID_EXPIRATION: 'შეკვეთის გაუქმება არასწორია',
            NOT_ENOUGH_MARGIN: 'მოგება არაა საკმარისი',
            INVALID_ORDER: 'შეკვეთა არ იძებნება',
            MARKET_CLOSED: 'ვაჭრობა არაა ხელმისაწვდომი, გთხოვთ, სცადეთ მოგვიანებით',
            INVALID_BALANCE_TYPE: 'ბალანსის ოპერაციის ტიპი არასწორია',
            INVALID_HASH: 'ჰეში არასწორია',
            HASH_EXPIRED: 'პაროლის შეცვლის ლინკი გაუქმებულია, გთხოვთ, მოითხოვეთ კიდევ ერთხელ.',
            INVALID_CODE: 'არასწორი 2FA კოდი',
            CHAT_DISABLED: 'საფორთის ჩატზე წვდომა შეჩერებულია',
            WITHDRAWAL_NOT_ALLOWED: 'გამოტანებზე წვდომა შეჩერებულია',
            TRADING_DISABLED: 'ვაჭრობა შეჩერებულია ამ ანგარიშზე',
            PENDING_DEPOSITS_LIMIT: 'დეპოზიტის ლიმიტი გადაჭარბებულია',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'დაშვებულია მხოლოდ ერთი მიმდინარე გამოტანა',
            LOCAL_DEPOSIT_ONLY_REAL: 'დეპოზიტები ხელმისაწვდომია მხოლოდ რეაური ანგარიშებისთვის',
            LOCAL_DEPOSIT_POSITIVE: 'დეპოზიტის ოდენობა უნდა აღემატებოდეს 0-ს',
            LOCAL_INVALID_CARD_NUMBER: 'ბარათის ნომერი არასწორია',
            LOCAL_INVALID_CARD_EXPIRATION: 'ბარათის ვადა არასწორია',
            LOCAL_INVALID_CVC: 'CVV/CVC კოდი არასწორია',
            LOCAL_PASSWORDS_NOT_MATCH: 'პაროლები ერთმანეთს არ შეესაბამება, გთხოვთ, შეიყვანთ თავიდან',
            LOCAL_NO_TRADING_ACCOUNTS: 'არ გაქვთ ხელმისაწვდომი სავაჭრო ანგარიში, გთხოვთ, დაუკავშირდით მხარდაჭერის გუნდს.',
            LOCAL_MIN_DEPOSIT: 'მინიმალური დეპოზიტი არის $',
            LOCAL_MIN_WITHDRAWAL: 'გამოტანის მინიმალური ოდენობა არის $',
            LOCAL_INVALID_FILE_TYPE: 'ფაილის ფორმატი არასწორია. გთხოვთ, გამოიყენეთ JPG, PNG ან PDF',
            LOCAL_MIN_STAKING: 'მოცემული ვალუტის მინიმალური დეპოზიტი არის ',
            FLOOD_REJECTED: 'თქვენ გადააჭარბეთ მესიჯების ლიმიტს. გთხოვთ, სცადეთ მოგვიანებით.'
        },
        countries: {
            AF: 'ავღანეთი',
            AX: 'აალანდის კუნძულები',
            AL: 'ალბანეთი',
            DZ: 'ალჟირი',
            AS: 'ამერიკული სამოა',
            AD: 'ანდორა',
            AO: 'ანგოლა',
            AI: 'ანგუილა',
            AQ: 'ანტარქტიდა',
            AG: 'ანტიგუია და ბარბუდა',
            AR: 'არგენტინა',
            AM: 'სომხეთი',
            AW: 'არუბა',
            AU: 'ავსტრალია',
            AT: 'ავსტრია',
            AZ: 'აზერბაიჯანი',
            BS: 'ბაჰამის კუნძულები',
            BH: 'ბაჰრეინი',
            BD: 'ბანგლადეში',
            BB: 'ბარბადოსი',
            BY: 'ბელორუსი',
            BE: 'ბელგია',
            BZ: 'ბელიზი',
            BJ: 'ბენინი',
            BM: 'ბერმუდა',
            BT: 'ბუტანი',
            BO: 'ბოლივია',
            BA: 'ბოსნია და ჰერცეგოვინა',
            BW: 'ბოტსვანა',
            BV: 'ბუვეტის კუნძული',
            BR: 'ბრაზილია',
            IO: 'ინდოეთის ოკეანის ბრიტანული ტერიტორია',
            BN: 'ბრუნეი დარუსალამი',
            BG: 'ბულგარეთი',
            BF: 'ბურკინა ფასო',
            BI: 'ბურუნდი',
            KH: 'კამბოჯა',
            CM: 'კამერუნი',
            CA: 'კანადა',
            CV: 'კაპე ვერდე',
            KY: 'კაიმანის კუნძულები',
            CF: 'ცენტრალური აფრიკის რესპუბლიკა',
            TD: 'ჩადი',
            CL: 'ჩილე',
            CN: 'ჩინეთი',
            CX: 'ქრისტმასის კუნძული',
            CC: 'ქოქოსის კუნძულები',
            CO: 'კოლუმბია',
            KM: 'კომოროსი',
            CG: 'კონგო',
            CD: 'კონგოს დემოკრატიული რესპუბლიკა',
            CK: 'ქუკის კუნძულები',
            CR: 'კოსტა რიკა',
            CI: 'კოტ-დ იუვარი',
            HR: 'ხორვატია',
            CU: 'კუბა',
            CY: 'კვიპროსი',
            CZ: 'ჩეხეთის რესპუბლიკა',
            DK: 'დანია',
            DJ: 'ჯიბუტი',
            DM: 'დომინიკა',
            DO: 'დომინიკის რესპუბლიკა',
            EC: 'ეკუადორი',
            EG: 'ეგვიპტე',
            SV: 'ელ სალვადორი',
            GQ: 'ეკვატორული გვინეა',
            ER: 'ერითრეა',
            EE: 'ესტონეთი',
            ET: 'ეთიოპია',
            FK: 'ფალკლანდის კუნძულები',
            FO: 'ფარერის კუნძულები',
            FJ: 'ფიჯი',
            FI: 'ფინეთი',
            FR: 'საფრანგეთი',
            GF: 'საფრანგეთის გვიანა',
            PF: 'საფრანგეთის პოლინეზია',
            TF: 'საფრანგეთის სამხრეთი ტერიტორიები',
            GA: 'გაბონი',
            GM: 'გამბია',
            GE: 'საქართველო',
            DE: 'გერმანია',
            GH: 'განა',
            GI: 'გიბრალტარი',
            GR: 'საბერძნეთი',
            GL: 'გრენლანდია',
            GD: 'გრენადა',
            GP: 'გუადალუპე',
            GU: 'გუამი',
            GT: 'გუატემალა',
            GG: 'გერნზი',
            GN: 'გვინეა',
            GW: 'გვიანა-ბისაუ',
            GY: 'გაიანა',
            HT: 'ჰაიტი',
            HM: 'ჰერდის კუნძული & მაკდონალდის კუნძულები',
            VA: 'წმინდა ეპარქია (ქალაქ ვატიკანი)',
            HN: 'ჰონდურასი',
            HK: 'ჰონგ კონგი',
            HU: 'უნგრეთი',
            IS: 'ისლანდია',
            IN: 'ინდოეთი',
            ID: 'ინდონეზია',
            IR: 'ირანი',
            IQ: 'ერაყი',
            IE: 'ირლანდია',
            IM: 'მანის კუნძული',
            IL: 'ისრაელი',
            IT: 'იტალია',
            JM: 'იამაიკა',
            JP: 'იაპონია',
            JE: 'ჯერზი',
            JO: 'იორდანია',
            KZ: 'ყაზახეთი',
            KE: 'კენია',
            KI: 'კირიბატი',
            KR: 'კორეა',
            KW: 'კუვეიტი',
            KG: 'ყირღიზეთი',
            LA: 'ლაოსის სახალხო დემოკრატიული რესპუბლიკა',
            LV: 'ლატვია',
            LB: 'ლებანონი',
            LS: 'ლესოტო',
            LR: 'ლიბერია',
            LY: 'ლიბია',
            LI: 'ლიხტენშტეინი',
            LT: 'ლიტვა',
            LU: 'ლუქსემბურგი',
            MO: 'მაკაო',
            MK: 'მაკედონია',
            MG: 'მადაგასგარი',
            MW: 'მალავი',
            MY: 'მალაიზია',
            MV: 'მალდივები',
            ML: 'მალი',
            MT: 'მალტა',
            MH: 'მარშალის კუნძულები',
            MQ: 'მარტინიკი',
            MR: 'მაურიტანია',
            MU: 'მაურიტიუს',
            YT: 'მაიოტა',
            MX: 'მექსიკა',
            FM: 'მიკრონეზია',
            MD: 'მოლდოვა',
            MC: 'მონაკო',
            MN: 'მონღოლეთი',
            ME: 'მონტენეგრო',
            MS: 'მონტსერატი',
            MA: 'მაროკო',
            MZ: 'მოზამბიკი',
            MM: 'მიანმარი',
            NA: 'ნამიბია',
            NR: 'ნაურუ',
            NP: 'ნეპალი',
            NL: 'ნიდერლანდები',
            AN: 'ნიდერლანდების ანტილები',
            NC: 'ახალი კალედონია',
            NZ: 'ახალი ზელანდია',
            NI: 'ნიკარაგუა',
            NE: 'ნიგერი',
            NG: 'ნიგერია',
            NU: 'ნიუე',
            NF: 'ნორფოლკის კუნძულები',
            MP: 'ჩრდილოეთ მარიანას კუნძულები',
            NO: 'ნორვეგია',
            OM: 'ომანი',
            PK: 'პაკისტანი',
            PW: 'პალაუ',
            PS: 'პალესტინის ოკუპირებული ტერიტორიები',
            PA: 'პანამა',
            PG: 'პაპუა ახალი გვინეა',
            PY: 'პარაგვაი',
            PE: 'პერუ',
            PH: 'ფილიპინები',
            PN: 'პიტკერნის კუნძულები',
            PL: 'პოლონეთი',
            PT: 'პორტუგალია',
            PR: 'პუერტო რიკო',
            QA: 'ყატარი',
            RE: 'რეუნიონი',
            RO: 'რუმინეთი',
            RU: 'რუსეთი',
            RW: 'რუანდა',
            BL: 'სენ-ბართლემი',
            SH: 'სენტ-ელენეს კუნძული',
            KN: 'სენ-კიტსი და ნევისი',
            LC: 'სენტ-ლუსიას კუნძული',
            MF: 'სენტ-მარტინის კუნძული',
            PM: 'სენტ-პიერის და მიკელონის კუნძული',
            VC: 'სენტ-ვინსენტი და გრენადიები',
            WS: 'სამოა',
            SM: 'სან მარინო',
            ST: 'სან-ტომე და პრინსიპი',
            SA: 'საუდის არაბეთი',
            SN: 'სენეგალი',
            RS: 'სერბეთი',
            SC: 'შეიშელის კუნძულები',
            SL: 'სიერა ლეონე',
            SG: 'სუნგაპური',
            SK: 'სლოვაკია',
            SI: 'სლოვენია',
            SB: 'სოლომონის კუნძულები',
            SO: 'სომალია',
            ZA: 'სამხრეთ აფრიკა',
            GS: 'სამხრეთ ჯორჯიას და სენდვიჩის კუნძულები',
            ES: 'ესპანეთი',
            LK: 'შრილანკა',
            SD: 'სუდანი',
            SR: 'სურინამი',
            SJ: 'შვალბარდი და იან-მაიენი',
            SZ: 'სვაზილენდი',
            SE: 'შვედეთი',
            CH: 'შვეიცარია',
            SY: 'სირია',
            TW: 'ტაივანი',
            TJ: 'ტაიჯიკეთი',
            TZ: 'ტანზანია',
            TH: 'ტაილანდი',
            TL: 'ტიმორ-ლესტე',
            TG: 'ტოგო',
            TK: 'ტოკელაუ',
            TO: 'ტონგა',
            TT: 'ტრინიდადი და ტობაგო',
            TN: 'ტუნიზია',
            TR: 'თურქეთი',
            TM: 'თურქმენეთი',
            TC: 'ტერქსისა და კაიკოსის კუნძულები',
            TV: 'ტუვალუ',
            UG: 'უგანდა',
            UA: 'უკრაინა',
            AE: 'არაბთა გაერთიანებული ემირატები',
            GB: 'გაერთინაებული სამეფო (ბრიტანეთი)',
            US: 'შეერთებული შტატები',
            UM: 'აშშ-ის მიმდებარე კუნძულები',
            UY: 'ურუგვაი',
            UZ: 'უზბეკეთი',
            VU: 'ვანუატუ',
            VE: 'ვენეზუელა',
            VN: 'ვიეტნამი',
            VG: 'ბრიტანეთის ვირჯინიის კუნძულები',
            VI: 'აშშ-ის ვირჯინიის კუნძულები',
            WF: 'უოლისი და ფუტუნა',
            EH: 'დასავლეთ საჰარა',
            YE: 'იემენი',
            ZM: 'ზამბია',
            ZW: 'ზიმბაბვე'
        },
    },
    az: {
        _name: 'Azerbaijani',
        timePickerModal: {
            title: 'Close In Time'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Mənfəət götür və Zərəri dayandır',
            takeProfit: 'Mənfəət götür',
            stopLoss: 'Zərəri dayandır',
            updateOrder: 'Sifarişi yenilə',
        },
        balanceModal: {
            balance: 'Balans',
            credit: 'Kredit',
            equity: 'Kapital',
            practice: 'MƏŞQ',
            real: 'REAL',
            account: 'HESAB',
            invested: 'İnvestisiya edilib',
            profit: 'Mənfəət',
            loss: 'Zərər',
            margin: 'Marja',
            marginLevel: 'Marja səviyyəsi',
            marginFree: 'Sərbəst marja'
        },
        header: {
            cfd: 'CFD',
            platform: 'Platforma',
            deposit: 'Depozit'
        },
        sideMenu: {
            marketWatch: 'Bazara baxış',
            activeOrders: 'Aktiv sifarişlər',
            tradingHistory: 'Ticarət tarixçəsi',
            economicCalendar: 'İqtisadi təqvim',
            marketNews: 'Bazar xəbərləri'
        },
        closePositionModal: {
            closeOrder: 'Pozisiyanı bağla',
            areYouSure: 'Pozisiyanı bağlamaq istədiyinizə əminsiniz?',
            buy: 'Al',
            sell: 'Sat',
            yes: 'Hə',
            no: 'Yox',
        },
        pciDssModal: {
            desc: `Ödəniş Kartları Sənədlərinin Təhlükəsizliyi İndustri Standartı (PCI DSS) compliance Visa/Master Card/Stripe kart sahibinin məlumatlarını saxlayan, emal edən və ya ötürən bütün şəxslər üçün vacibdir, bu daxil olmaqla, maliyyə institutları, təcili və xidmət təminatçıları.
            Bu, kart sahibinin məlumatlarını və/ya hassas avtentikasiya məlumatlarını saxlayan, emal edən və ya ötürən bütün şəxslər üçün global təhlükəsizlik standartıdır. PCI DSS istehsalçılar üçün bazal səviyyədə qoruma təyin edir və istehlakçılar üçün məsələlərin minimum səviyyədə saxlanmasına kömək edir və bütün ödəniş ekosistemində dolandırıcılığı və məlumatlarının pozulmasını azaldır.`
        },
        chart: {
            addNewChart: 'Yeni qrafik əlavə et',
        },
        symbolsModal: {
            watchlist: 'İzləmə siyahısı',
            asset: 'Aktiv',
            price: 'Qiymət',
            changePct: '24 saat dəyiş'
        },
        pendingModal: {
            pending: 'Gözləyir',
            assetPrice: 'Aktivin qiyməti',
            current: 'Cari',
            revert: 'Bazar qiymətinə qayıt',
            automatically: 'Qiymət bu səviyyəyə çatdıqda pozisiya avtomatik olaraq açılacaq'
        },
        orderMenu: {
            default: 'standart',
            volume: 'Həcm',
            lots: 'lot',
            units: 'vahid',
            currency: 'valyuta',
            contractSize: 'Müqavilə ölçüsü',
            position: 'Pozisiya',
            margin: 'Marja',
            freeMargin: 'Sərbəst marja',
            takeProfitStopLoss: 'Mənfəət götür və Zərəri dayandır',
            pending: 'Gözləyir',
            market: 'Bazar',
            leverage: 'Çiyin',
            spread: 'Spred',
            notSet: 'Təyin edilməyib',
            at: 'burada',
            buy: 'al',
            sell: 'sat',
        },
        footer: {
            supportBanner: 'HƏR GÜN, 24 SAAT ƏRZİNDƏ',
            currentTime: 'HAZIRKI VAXT',
            liveChat: 'Canlı Çat'
        },
        statusBar: {
            balance: 'Balans',
            credit: 'Kredit',
            equity: 'Kapital',
            margin: 'Marja',
            marginLevel: 'Marja səviyyəsi',
            freeMargin: 'Sərbəst marja',
            pnl: 'PnL',
            profitTotal: 'Ömürlük PnL'
        },
        accountModal: {
            dateRegistered: 'Qeydiyyat tarixi',
            userId: 'İstifadəçi ID',
            dashboard: 'İdarəetmə paneli',
            personalData: 'Şəxsi məlumatlar',
            deposit: 'Depozit',
            withdrawFunds: 'Vəsaitləri çıxar',
            savings: 'Əmanətlər',
            settings: 'Parametrlər',
            logout: 'Çıxış'
        },
        depositModal: {
            BT_INVOICE: 'Məbləği daxil et və faktura yarat',
            BT_TITLE: 'Bank məlumatlarımız',
            account: 'Hesab',
            bank_name: 'Bankın adı',
            beneficiary: 'Benefisiar',
            beneficiary_address: 'Benefisiarın ünvanı',
            swift: 'Swift',
            reference: 'Reference',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Məbləğ',
            TOKEN_SUBTOTAL: 'Ara toplam məbləğ',
            TOKEN_TOTAL: 'Toplam məbləğ',
            TOKEN_DESCRIPTION: 'Təsvir',
            TOKEN_PAYMENT_DETAILS: 'Ödəniş detalları',
            PENDING_DEPOSITS_LIMIT: 'Depozit limiti',
            makeDeposit: 'Depozit qoymaq',
            practiceAccount: 'Məşq hesabı',
            realAccount: 'Real hesab',
            noWithdrawals: 'Pul çıxarma yoxdur',
            easyWithdrawals: 'Asan pul çıxarma',
            allAssets: 'Bütün aktivlər mövcuddur',
            fullFledged: 'Universal platforma',
            fillUpTo: 'Bu məbləğədək doldur',
            freeReplenishment: 'Pulsuz doldurma',
            topUp: 'Hesabın balansını artır',
            minimumAmount: 'Minimum məbləğ',
            canSwitch: 'İstənilən vaxt hesablarınız arasında keçid edə bilərsiniz'
        },
        gridsModal: {
            chartGrids: 'QRAFİK ŞƏBƏKƏLƏR',
            chart1: '1 qrafik',
            chart2: '2 qrafik',
            chart3: '3 qrafik',
            chart4: '4 qrafik',
        },
        noConnectionModal: {
            connectionLost: 'Server ilə əlaqə kəsildi',
            retryNow: 'Yenidən cəhd et',
        },
        loginModal: {
            loginToTradeRoom: 'Ticarət otağına daxil ol',
            email: 'E-poçt ünvanı',
            enterEmail: 'E-poçt ünvanınızı daxil edin',
            twoFactor: '2FA Kodu (əgər aktivdirsə)',
            twoFactorAuth: 'İki faktorlu autentifikasiya',
            password: 'Şifrə',
            yourPassword: 'Şifrəniz',
            signIn: 'Daxil ol',
            dontHaveAccount: 'Hesabınız yoxdur?',
            restore: 'Bərpa et',
            fullName: 'Tam ad',
            havePromo: 'Promokodum var',
            promo: 'Promokod',
            login: 'Giriş',
            haveAccount: 'Artıq hesabınız var?',
            hide: 'Gizlət',
            goTo: 'Geriyə qayıt'
        },
        newPasswordModal: {
            setNewPassword: 'Yeni şifrə təyin et',
            newPassword: 'Yeni şifrə',
            confirmNewPassword: 'Yeni şifrəni təsdiqlə',
            setButton: 'Təyin et'
        },
        registerModal: {
            repeatPassword: 'Şifrəni təkrarla',
            createNewAccount: 'Yeni hesab yarat',
            email: 'E-poçt ünvanı',
            firstName: 'Ad',
            lastName: 'Soyad',
            country: 'Ölkə',
            phone: 'Telefon',
            password: 'Şifrə',
            createAccount: 'Hesab yarat',
            currency: 'Valyuta',
            privacyAndPolicy: "Gizlilik və Siyasət",
            customerAgreement: 'Müştəri müqaviləsi',

        },
        forgotPasswordModal: {
            forgotPassword: 'Şifrəni unutmusunuz?',
            submitEmail: 'Zəhmət olmasa qeydiyyat zamanı istifadə etdiyiniz e-poçt ünvanını göndərin, gələnlər qutusunu yoxlayın və verilən təlimatlara əməl edin',
            submit: 'Göndər'
        },
        notifications: {
            error: 'Xəta',
            success: 'Müvəffəqiyyət',
            deposit: 'Depozit',
            withdrawal: 'Çıxarma',
            depositApproved: 'Depozit təsdiq edildi',
            depositDeclined: 'Depozit rədd edildi',
            withdrawalApproved: 'Çıxarma təsdiq edildi',
            withdrawalDeclined: 'Çıxarma rədd edildi'
        },
        markets: {
            forex: 'Forex',
            stocks: 'Səhmlər',
            commodities: 'Əmtəələr',
            indices: 'İndekslər',
            crypto: 'Kripto',
            metals: 'Metallar',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'AL',
            profitCalculator: 'Mənfəət kalkulyatoru',
            sell: 'SAT',
            volume: 'Həcm',
            entryPrice: 'Giriş qiyməti',
            takeProfit: 'Mənfəət götür',
            stopLoss: 'Zərəri dayandır',
            maxPosition: 'Maks. pozisiya',
            calculate: 'Hesabla',
            calculationsFor: 'Hesablamalar',
            leverage: 'Çiyin',
            requiredMargin: 'Tələb olunan marja',
            profitFromTP: 'TP-dən qazanc',
            lossFromSL: 'SL-dan zərər',
            roe: 'İnvestisiya gəliri',
        },
        depositSuccessModal: {
            title: 'Uğurlu depozit əməliyyatı',
            text: 'Depozitiniz uğurla emal edildi!',
        },
        depositFailModal: {
            title: 'Uğursuz depozit əməliyyatı',
            text: 'Depozitiniz emal edilmədi.',
        },
        widgetMarketWatch: {
            marketWatch: 'Bazara baxış',
            search: 'Axtarış...',
        },
        widgetOrders: {
            commission: 'Komissiya',
            volume: 'Həcm',
            margin: 'Marja',
            active: 'Aktiv',
            pending: 'Gözləyir',
            activeOrders: 'Aktiv sifarişlər',
            portfolio: 'Portfolio',
            allPositions: 'Bütün pozisiyalar',
            noPositionsLine1: 'Açıq pozisiyalarınız',
            noPositionsLine2: 'hələki yoxdur',
            show: 'Göstər',
            more: 'daha çox',
            less: 'daha az',
            purchaseTime: 'Alış vaxtı',
            closePosition: 'Pozisiyanı bağla',
            priceOpen: 'Alış qiyməti',
            priceSl: 'Zərəri dayandırma qiyməti',
            priceTp: 'Mənfəəti götürmə qiyməti',
            type: 'Pozisiyanın istiqaməti',
            pnl: 'Mənfəət/Zərər',
            cancelOrder: 'Sifarişi ləğv et',
            orderType: 'Sifariş növü',
            cancelled: 'Ləğv olunub',
            tradingHistory: 'Ticarət tarixçəsi',
            noHistoryLine1: 'Sizin hələki heç bir',
            noHistoryLine2: 'bağlanmış əməliyyatınız yoxdur',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'İqtisadi təqvim'
        },
        widgetMarketNews: {
            marketNews: 'Bazar xəbərləri'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'Aktiv sifarişlər',
            ordersHistory: 'Sifarişlərin tarixçəsi',
            id: 'ID',
            symbol: 'Simvol',
            type: 'Növ',
            volume: 'Həcm',
            openPrice: 'Açılma qiyməti',
            openTime: 'Açılma vaxtı',
            closePrice: 'Bağlanma qiyməti',
            closeTime: 'Bağlanma vaxtı',
            sl: 'SL',
            tp: 'TP',
            price: 'Qiymət',
            pnl: 'PnL',
            actions: 'Fəaliyyətlər',
            edit: 'Dəyiş',
            close: 'Bağla',
            commission: 'Komisssiya',
            swap: 'Swap',
            swapLong: 'Swap Uzun',
            swapShort: 'Swap Qısa'
        },
        assetInfoModal: {
            assetInfo: 'Aktiv haqqında məlumat',
            tradingConditions: 'Ticarət şərtləri',
            information: 'Seansın dəyişdirilməsi',
            bid: 'Təklif',
            ask: 'Tələb',
            sessionChange: 'Seansın dəyişdirilməsi',
            tradeNow: 'Ticarətə indi başla',
            opens: 'Açılır',
            closes: 'Bağlanır',
            at: 'burada',
            open247: '24/7 açıq',
            today: 'bu gün',
            tomorrow: 'sabah',
            sunday: 'Bazar günü',
            monday: 'Bazar ertəsi',
            tuesday: 'Çərşənbə axşamı',
            wednesday: 'Çərşənbə',
            thursday: 'Cümə axşamı',
            friday: 'Cümə',
            saturday: 'Şənbə',
            contractSpecification: 'Müqavilənin xüsusiyyətləri',
            symbol: 'Simvol',
            name: 'Ad',
            market: 'Bazar',
            baseCurrency: 'Əsas valyuta',
            digits: 'Rəqəmlər',
            lotSize: 'Lot ölçüsü',
            lotStep: 'Lot addımı',
            minVolume: 'Min. həcm',
            maxVolume: 'Maks. həcm',
            leverage: 'Çiyin',
            commission: 'Komissiya',
            swapLong: 'Swap Uzun',
            swapShort: 'Swap Qısa',
            schedule: 'Cədvəl',
            weekday: 'Həftə içi',
            tradingTime: 'Ticarət vaxtı',
            closed: 'bağlanıb',
            sell: 'Sat',
            buy: 'Al',
            low: 'Aşağı',
            high: 'Yuxarı',
            oneHourChange: '1 saat dəyişikliyi',
            oneDayChange: '1 gün dəyişikliyi',
            oneWeekChange: '1 həftə dəyişikliyi',
            oneMonthChange: '1 ay dəyişikliyi',
            threeMonthsChange: '3 ay dəyişikliyi',
            oneYearChange: '1 il dəyişikliyi',
            loading: 'Yüklənir...',
        },
        autoTrader: {
            days: 'days',
            title: 'Avtotreyder',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Risk səviyyəsi: Aşağı',
            riskLevelMedium: 'Risk səviyyəsi: Orta',
            riskLevelHigh: 'Risk səviyyəsi: Yüksək',
            perDay: 'Gündəlik əməliyyat sayı:',
            desc: `CFD və kredit çiyini istifadə olunduğu digər məhsullarla ticarət itkilərə səbəb ola bilər. Ticarət etməzdən əvvəl müştərilər
                Risklərə dair xəbərdarlıq səhifəsindəki
                müvafiq risk bəyanatları ilə tanış olmalıdırlar. Avtomatlaşdırılmış ticarət nəticələrə zəmanət vermir.
                Şirkət avtomatlaşdırılmış ticarət zamanı baş verə biləcək pul itkilərinə görə heç bir məsuliyyət daşımır. Riskləri tam başa düşdüyünüzə
                və riskləri idarə etmək üçün lazımi tədbirlər gördüyünüzə əmin olun.`,
            accept: 'Qəbul et',
            save: 'Yadda saxla', 
            cancel: 'Ləğv et',
        },
        cardBox: {
            name: 'Ad',
            cardNumber: 'Kart nömrəsi',
            expiration: 'Bitmə (ay/ill)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "Ticarət",
            startTrading: 'Ticarətə başla',
            deposit: 'Depozit',
            dashboard: 'İdarəetmə paneli',
            personalInfo: 'Şəxsi məlumatlar',
            contactInfo: 'Əlaqə məlumatları',
            withdrawal: 'Çıxarma',
            verification: 'Təsdiqləmə',
            accounts: 'Hesablar',
            liveChat: 'Canlı çat',
            savings: 'Əmanətlər',
            settings: 'Parametrlər',
            logOut: 'Çıxış',
        },
        fullScreenDeposit: {
            methods: 'ÜSULLAR',
            confirm: 'Bankınızın tətbiqində təsdiqləyin',
            sendOtp: 'Birdəfəlik şifrə göndər',
            otpCode: 'Birdəfəlik şifrə',
            addWalletAddress: 'Bu depozit üsulu üçün pul kisəsi təyin edilməyib. Zəhmət olmasa agentiniz və ya dəstək xidməti ilə əlaqə saxlayın.',
            addressReceipt: 'Zəhmət olmasa, ünvanın yüklənməsini gözləyin',
            makeDeposit: 'DEPOZİT YERLƏŞDİR',
            instant: 'Dərhal',
            minutes: 'dəqiqə',
            hours: 'saat',
            days: 'gün',
            amount: 'Məbləğ',
            continue: 'Davam et',
            qrCode: 'QR KOD',
            depositAddress: 'DEPOZİT ÜNVANI',
            copy: 'Kopiyala',
            last: 'SON',
            deposits: 'DEPOZİTLƏR',
            time: 'Vaxt',
            currency: 'Valyuta',
            status: 'Status',
            info: 'Məlumat',
        },
        fullScreenCcExt: {
            pleaseWait: 'Zəhmət olmasa, gözləyin. Ödəniş xidməti provayderi ilə bağlantı yaradılır...',
            cardPayment: 'Kartla ödəniş',
            inOrder: 'Məbləği göndərmək üçün',
            pleaseProvide: 'kart məlumatlarını qeyd edin:'
        },
        fullScreenDashboard: {
            completeVerification: 'Zəhmət olmasa, təsdiqləməni tamamlayın',
            noOrderYet: 'Hələki heç bir sifariş yoxdur',
            totalBalance: 'Ümumi balans',
            totalPnl: 'Ümumi PNL',
            profitableOrders: 'Mənfəətli sifarişlər',
            roi: 'İnvestisiya gəliri',
            activityLog: 'Fəaliyyət jurnalı',
            loginFromIp: 'IP-dən giriş',
            tradingResults: 'Ticarət nəticələri',
            week: 'Həftə',
            month: 'Ay',
            year: 'İl',
            successRate: 'Müvəffəqiyyət dərəcəsi',
            closedWithProfit: 'Mənfəətlə bağlanıb',
            closedWithLoss: 'Zərərlə bağlanıb',
            account: 'Hesab',
            balance: 'Balans',
            leverage: 'Çiyin',
            credit: 'Kredit',
            tradeNow: 'İndi ticarət et',
            usingCurrentRate: 'hazırkı məzənnədən istifadə edərək',
            totalDeposits: 'Ümumi depozit sayı'
        },
        fullScreenPersonal: {
            personalDetails: 'Şəxsi məlumatlar',
            profilePhoto: 'Profil şəkli',
            firstName: 'Ad',
            country: 'Ölkə',
            lastName: 'Soyad',
            address: 'Ünvan',
            email: 'E-poçt',
            phone: 'Telefon nömrəsi',
            saveChanges: 'Dəyişiklikləri yadda saxla',
            state: 'Şəhər',
            postcode: 'Poçt indeksi',
            created: "Hesab yaradıldı",
        },
        fullScreenAvatar: {
            dropFile: 'Yükləmək üçün faylı yuxarıdakı dairəyə atın',
            notAllowed: 'Bunu dərc etməyə icazə verilmir',
            notAllowedLine1: 'Aşkar seksual və ya pornoqrafik xarakterli fotoşəkillər',
            notAllowedLine2: 'Etnik və ya irqi nifrət və ya təcavüzü qızışdırmağa yönəlmiş şəkillər',
            notAllowedLine3: '18 yaşından kiçik şəxslərin iştirak etdiyi fotoşəkillər',
            notAllowedLine4: 'Üçüncü tərəfin müəllif hüquqları ilə qorunan fotoşəkilləri',
            notAllowedLine5: '5 MB-dan böyük və JPG, GIF və ya PNG formatından başqa formatda olan şəkillər',
            requirements: 'Şəkildə üzünüz aydın görünməlidir. Yüklədiyiniz bütün foto və videolar bu tələblərə uyğun olmalıdır, əks halda onlar silinə bilər.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Pul çıxarma əməliyyatı ilə bağlı daha ətraflı məlumat əldə etmək üçün hesab menecerinizlə əlaqə saxlayın',
            cryptocurrency: 'Kriptovalyuta',
            withdrawal: 'Çıxarma',
            requestNewWithdrawal: 'YENİ ÇIXARMA SORĞULA',
            amount: 'Hesab',
            currentBalance: 'Cari hesab balansınız',
            withdrawAll: 'Hamısını çıxar',
            requestWithdrawal: 'Çıxarma sorğula',
            last: 'SON',
            withdrawalRequests: 'ÇIXARMA SORĞULARI',
            time: 'Vaxt',
            currency: 'Valyuta',
            info: 'Məlumat',
            status: 'Status',
            bank: 'Bank',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            card: 'Kart',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Çıxarma məlumatları',
            address: 'Pul kisəsinin ünvanı',
            bankAccount: 'Hesab nömrəsi',
            bankHolder: 'Hesab sahibinin adı',
            bankIban: 'IBAN',
            bankSwift: 'Bankın SWIFT kodu',
            recipientAddress: 'Alıcının ünvanı',
            routingNumber: 'Yönlendirme nömrəsi',
            bankBeneficiary: 'Faydalananın bankının adı',
            bankAddress: 'Bank ünvanı',
            bankCode: 'Bank kodu',
            desc: 'Təsvir',
            cardNumber: 'Kartın nömrəsi',
            cardHolder: 'Kart sahibinin adı',
        },
        fullScreenVerification: {
            dragAndDrop: 'sənədi bu sahəyə çəkib atın',
            documentsList: 'YÜKLƏNMİŞ SƏNƏDLƏRİN SİYAHISI',
            document: 'Sənəd',
            timeUploaded: 'Yüklənmə vaxtı',
            timeProcessed: 'Emal olunma vaxt',
            status: 'Status',
            types: ['Şəxsiyyəti təsdiq edən sənəd', 'Yaşayış ünvanını təsdiq edən sənəd', 'Kredit kartının ön tərəfi', 'Kredit kartının arxa tərəfi', 'Şəxsiyyəti təsdiq edən sənədin arxa tərəfi', 'Selfi'],
            types2: ['Şəxsiyyəti təsdiq edən sənəd (POI)', 'Yaşayış ünvanını təsdiq edən sənəd', 'Kredit kartının ön tərəfi', 'Kredit kartının arxa tərəfi', 'Şəxsiyyəti təsdiq edən sənədin arxa tərəfi', 'Şəxsiyyət vəsiqəsi ilə selfi şəkli'],
            upload: 'Yüklə',
            isConfirmed: 'təsdiqlənib',
            uploadAnotherDocument: 'Başqa bir sənəd yükləyin',
            isUnderReview: 'baxılır',
            questionnaire: 'Anket',
            sendAnswers: 'Cavabları göndər',
            progressBarOf: '',
            progressBarText: 'sənədiniz yükləndi və təsdiqləndi'
        },
        fullScreenAccounts: {
            manageAccounts: 'Vəsaitlərinizi hesablarınız arasında köçürün',
            transferFrom: 'Hesabından',
            transferTo: 'Hesaba',
            amount: 'Məbləğ',
            makeTransfer: 'Köçür',
            yourAccounts: 'Hesablarınız',
            account: 'Hesab',
            currency: 'Valyuta',
            balance: 'Balans',
            credit: 'Kredit',
            makeActive: 'Aktivləşdir',
            active: 'Aktiv'
        },
        fullScreenSupport: {
            sendMessage: 'Mesaj göndər'
        },
        fullScreenSavings: {
            pct_0: 'Çevik',
            pct_30: 'Bloklanıb 1 ay',
            pct_90: 'Bloklanıb 1 kvartal',
            pct_180: 'Bloklanıb 6 ay',
            pct_365: 'Bloklanıb 1 il',
            savings: 'Əmanətlər',
            detailedInformation: 'Ətraflı məlumat',
            pleaseSelect: 'Zəhmət olmasa, valyutanı və dövrü seçin',
            openSavings: 'Əmanətləri açın',
            currentTime: 'Hazırkı vaxt',
            releaseTime: 'Buraxılış vaxtı',
            currencyChosen: 'Valyuta seçilib',
            periodChosen: 'Dövr seçilib',
            yourRate: 'Dərəcəniz',
            yourEarnings: 'Qazanclarınız',
            accountFrom: 'Hansı hesabdan vəsaitlər köçürüləcək',
            enterAmount: 'Məbləği daxil edin',
            in: 'burada',
            openAndInvest: 'Əmanət hesabı açın və investisiya qoyun',
            investment: 'İnvestisiya',
            period: 'Dövr',
            daysTotal: 'Günlərin ümumi sayı',
            finalEarnings: 'Yekun qazanc',
            created: 'Yaradılıb',
            daysElapsed: 'Gün keçib',
            days: 'gün',
            earnings: 'Qazanc',
            fundsReleasedIn: 'Bu dövrdə buraxılan vəsaitlər',
            claim: 'Götürmək',
        },
        fullScreenSettings: {
            updatePassword: 'ŞİFRƏNİ YENİLƏ',
            currentPassword: 'Hazırkı şifrə',
            newPassword: 'Yeni şifrə',
            changePassword: 'Şifrəni dəyişdir',
            activationForGoogle: 'Google Autentifikatoru üçün aktivasiya kodu',
            activateAndEnter: 'Autentifikatoru aktivləşdirin və generasiya olunmuş kodu aşağıdakı sahəyə daxil edin',
            qrCode: 'QR kod',
            activateProtection: '2FA qorunmanı aktivləşdir',
            protectionActive: '2FA qorunma aktivdir',
            twoFactorCode: '2FA kodu',
            disableProtection: '2FA qorunmasını söndür',
            language: 'Dil',
            dashboardCurrency: 'İdarəetmə paneli valyutası',
            confirmNewPassword: 'Yeni şifrəni təsdiqlə',
        },
        fullScreenReferrals: {
            yourLink: 'DƏVƏT LİNKİNİZ',
            referredFriends: 'DƏVƏT OLUNMUŞ DOSTLAR',
            id: 'ID',
            name: 'Ad',
            registered: 'Qeydiyyatdan keç',
            level: 'Səviyyə',
            payout: 'Ödəniş',
            lastPayouts: 'DƏVƏTƏ GÖRƏ SON ÖDƏNİŞLƏR',
            time: 'Vaxt',
            amount: 'Məbləğ',
            currency: 'Valyuta',
            info: 'Məlumat'
        },
        months: {
            Jan: 'Yan',
            Feb: 'Fev',
            Mar: 'Mar',
            Apr: 'Apr',
            May: 'May',
            Jun: 'İyn',
            Jul: 'İyl',
            Aug: 'Avq',
            Sep: 'Sen',
            Oct: 'Okt',
            Nov: 'Noy',
            Dec: 'Dek'
        },
        orderTypes: ['Al', 'Sat', 'Alış limiti', 'Satış Limiti', 'Alışı dayandır', 'Satışı dayandır'],
        statuses: ['Gözləyir', 'Təsdiqlənib', 'Rədd edilib', 'Emal olunur'],
        errors: {
            NOT_FOUND: 'Sorğu xətası',
            FORBIDDEN: 'Girişə icazə verilmədi. Zəhmət olmasa yenidən daxil olun',
            SERVER_ERROR: 'Fəaliyyət uğursuz oldu. Zəhmət olmasa bir az sonra yenidən cəhd edin',
            INVALID_ACCOUNT: 'Seansın vaxtı bitib. Zəhmət olmasa yenidən daxil olun',
            INVALID_EMAIL: 'Yanlış e-poçt ünvanı',
            INVALID_PHONE: 'Yanlış telefon nömrəsi',
            INVALID_COUNTRY: 'Yanlış ölkə',
            INVALID_LOGIN: 'Yanlış istifadəçi adı və ya şifrə',
            USER_BLOCKED: 'Hesab bloklanıb. Zəhmət olmasa, dəstək xidməti ilə əlaqə saxlayın',
            USER_REGISTERED: 'Daxil edilmiş e-poçt və ya telefon nömrəsi olan istifadəçi artıq qeydiyyatdan keçib',
            INVALID_USER: 'İstifadəçi tapılmadı',
            REJECTED: 'Platformanın qoyduğu məhdudiyyətlər səbəbindən əməliyyata icazə verilmir',
            INVALID_OLD_PASSWORD: 'Köhnə şifrə səhvdir',
            INVALID_SYMBOL: 'Yanlış ticarət simvolu',
            INVALID_SYMBOL_PRECISION: 'Yanlış simvol dəqiqliyi',
            INVALID_FRAME: 'Yanlış qrafik çərçivəsi',
            INVALID_CURRENCY: 'Yanlış valyuta',
            INVALID_PRODUCT: 'Yanlış ticarət hesabı növü',
            INSUFFICIENT_FUNDS: 'Kifayət qədər vəsait yoxdur',
            INVALID_WITHDRAWAL: 'Çıxarma əməliyyatı tapılmadı',
            INVALID_STATUS: 'Yanlış status',
            INVALID_FILE: 'Yanlış fayl formatı və ya faylın ölçüsü çox böyükdür',
            INVALID_DOCUMENT: 'Sənəd tapılmadı',
            INVALID_NAME: 'Yanlış ad',
            INVALID_LOT_SIZE: 'Yanlış lot ölçüsü',
            INVALID_LOT_STEP: 'Yanlış lot addımı',
            INVALID_MIN_VOLUME: 'Yanlış minimal həcm',
            INVALID_MAX_VOLUME: 'Yanlış maksimal həcm',
            INVALID_GROUP: 'Yanlış qrup',
            INVALID_SPREAD: 'Yanlış spred',
            REQUEST_TIMEOUT: 'Sorğunun vaxtı bitdi. Zəhmət olmasa, bir az sonra yenidən cəhd edin',
            EXISTING_OPEN_ORDERS: 'Açıq sifarişiniz var, əvvəlcə bu istifadəçi üçün sifarişi bağlayın və yenidən cəhd edin.',
            INVALID_TYPE: 'Yanlış sifariş növü',
            INVALID_VOLUME: 'Yanlış sifariş həcmi',
            INVALID_SL: 'Yanlış zərəri dayandırma dəyəri',
            INVALID_TP: 'Yanlış mənfəət götürmə dəyəri',
            INVALID_PRICE: 'Yanlış sifariş qiyməti',
            INVALID_EXPIRATION: 'Yanlış sifarişin bitmə müddəti',
            NOT_ENOUGH_MARGIN: 'Kifayət qədər marja yoxdur',
            INVALID_ORDER: 'Sifariş tapılmadı',
            MARKET_CLOSED: 'Ticarət işləmir. Zəhmət olmasa, yenidən cəhd edin',
            INVALID_BALANCE_TYPE: 'Yanlış balans əməliyyatı növü',
            INVALID_HASH: 'Yanlış hash',
            HASH_EXPIRED: 'Şifrəni sıfırlama linkinizin etibarlılıq müddəti bitib. Zəhmət olmasa, yenisini sorğulayın',
            INVALID_CODE: 'Yanlış 2FA kodu',
            CHAT_DISABLED: 'Dəstək xidməti çatına giriş bağlanıb',
            WITHDRAWAL_NOT_ALLOWED: 'Çıxarma əməliyyatına giriş bağlanıb',
            TRADING_DISABLED: 'Hazırkı hesab üçün ticarət funksiyası söndürülüb',
            PENDING_DEPOSITS_LIMIT: 'Depozit limiti aşılıb',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Yalnız bir gözləyən çıxarma əməliyyatına icazə verilir',
            LOCAL_DEPOSIT_ONLY_REAL: 'Depozit funksiyası yalnız real hesabda işləyir',
            LOCAL_DEPOSIT_POSITIVE: 'Depozit məbləği 0-dan çox olmalıdır',
            LOCAL_INVALID_CARD_NUMBER: 'Yanlış kart nömrəsi',
            LOCAL_INVALID_CARD_EXPIRATION: 'Yanlış kartın bitmə tarixi',
            LOCAL_INVALID_CVC: 'Yanlış CVV/CVC kod',
            LOCAL_PASSWORDS_NOT_MATCH: 'Şifrələr bir-birinə uyğun gəlmir, zəhmət olmasa, yenidən daxil edin',
            LOCAL_NO_TRADING_ACCOUNTS: 'Əlçatan ticarət hesablarınız yoxdur. Zəhmət olmasa dəstək xidməti ilə əlaqə saxlayın',
            LOCAL_MIN_DEPOSIT: 'Minimum depozit məbləği $ təşkil edir',
            LOCAL_MIN_WITHDRAWAL: 'Minimum çıxarma məbləği $ təşkil edir',
            LOCAL_INVALID_FILE_TYPE: 'Bu fayl formatı dəstəklənmir. Zəhmət olmasa, yalnız JPG, PNG və ya PDF fayllarını əlavə edin',
            LOCAL_MIN_STAKING: 'Bu valyuta üçün minimum depozit: ',
            FLOOD_REJECTED: 'Siz çox tez-tez mesaj göndərirsiniz. Zəhmət olmasa bir az sonra yenidən cəhd edin'
        },
        countries: {
            AF: 'Əfqanıstan',
            AX: 'Aland adaları',
            AL: "Albaniya",
            DZ: 'Əlcəzair',
            AS: 'Amerika Samoası',
            AD: 'Andorra',
            AO: 'Anqola',
            AI: 'Angilya',
            AQ: 'Antarktida',
            AG: 'Antiqua və Barbuda',
            AR: 'Argentina',
            AM: 'Ermənistan',
            AW: 'Aruba',
            AU: 'Avstraliya',
            AT: 'Avstriya',
            AZ: 'Azərbaycan',
            BS: 'Baham adaları',
            BH: 'Bəhreyn',
            BD: 'Banqladeş',
            BB: 'Barbados',
            BY: 'Belarus',
            BE: 'Belçika',
            BZ: 'Beliz',
            BJ: 'Benin',
            BM: 'Bermud adaları',
            BT: 'Butan',
            BO: 'Boliviya',
            BA: 'Bosniya və Herseqovina',
            BW: 'Botsvana',
            BV: 'Buve adası',
            BR: 'Braziliya',
            IO: 'Britaniya Hind okeanı əraziləri',
            BN: 'Bruney',
            BG: 'Bolqarıstan',
            BF: 'Burkina-Faso',
            BI: 'Burundi',
            KH: 'Kamboca',
            CM: 'Kamerun',
            CA: 'Kanada',
            CV: 'Keyp Qrin',
            KY: 'Kayman adaları',
            CF: 'Mərkəzi Afrika Respublikası',
            TD: 'Çad',
            CL: 'Çili',
            CN: 'Çin',
            CX: 'Milad adası',
            CC: 'Kokos adaları',
            CO: 'Kolumbiya',
            KM: 'Komor adaları',
            CG: 'Konqo',
            CD: 'Konqo Demokratik Respublikası',
            CK: 'Kuk adaları',
            CR: 'Kosta-Rika',
            CI: 'Fildişi Sahili',
            HR: "Xorvatiya",
            CU: 'Kuba',
            CY: 'Kipr',
            CZ: 'Çexiya',
            DK: 'Danimarka',
            DJ: 'Cibuti',
            DM: 'Dominika',
            DO: 'Dominikan Respublikası',
            AK: 'Ekvador',
            EG: 'Misir',
            SV: 'Salvador',
            GQ: 'Ekvatorial Qvineya',
            ER: 'Eritreya',
            EE: 'Estoniya',
            ET: 'Efiopiya',
            FK: 'Folklend adaları (Malvin adaları)',
            FO: 'Farer adaları',
            FJ: 'Fici',
            FI: 'Finlandiya',
            FR: 'Fransa',
            GF: 'Fransa Qvianası',
            PF: 'Fransız Polineziyası',
            TF: 'Fransanın Cənub əraziləri',
            GA: 'Qabon',
            GM: 'Qambiya',
            GE: 'Gürcüstan',
            DE: 'Almaniya',
            GH: 'Qana',
            GI: 'Cəbəllütariq',
            GR: 'Yunanıstan',
            GL: 'Qrenlandiya',
            GD: 'Qrenada',
            GP: 'Qvadelupa',
            GU: 'Quam',
            GT: 'Qvatemala',
            GG: 'Gernsi',
            GN: 'Qvineya',
            GW: 'Qvineya-Bisau',
            GY: 'Qayana',
            HT: 'Haiti',
            HM: 'Herd adası və Makdonald adaları',
            VA: 'Müqəddəs Taxt (Vatikan Dövləti)',
            HN: 'Honduras',
            HK: 'Honkonq',
            HU: 'Macarıstan',
            IS: 'İslandiya',
            ID: 'İndoneziya',
            IR: 'İran',
            IQ: 'İraq',
            IE: 'İrlandiya',
            IM: 'Men adası',
            IL: 'İsrail',
            IT: 'İtaliya',
            JM: 'Yamayka',
            JP: 'Yaponiya',
            JE: 'Cersi',
            JO: 'İordaniya',
            KZ: 'Qazaxıstan',
            KE: 'Keniya',
            KI: 'Kiribati',
            KR: 'Koreya',
            KW: 'Küveyt',
            KG: 'Qırğızıstan',
            LA: 'Laos Xalq Demokratik Respublikası',
            LV: 'Latviya',
            LB: 'Livan',
            LS: 'Lesoto',
            LR: 'Liberiya',
            LY: 'Liviya',
            LI: 'Lixtenşteyn',
            LT: 'Litva',
            LU: 'Lüksemburq',
            MO: 'Makao',
            MK: 'Makedoniya',
            MG: 'Madaqaskar',
            MW: 'Malavi',
            MY: 'Malayziya',
            MV: 'Maldiv adaları',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marşal adaları',
            MQ: 'Martinika',
            MR: 'Mavritaniya',
            IN: 'Mavrikiy',
            YT: 'Mayotta',
            MX: 'Meksika',
            FM: 'Mikroneziya',
            MD: 'Moldova',
            MC: "Monako",
            MN: 'Monqolustan',
            ME: 'Monteneqro',
            MS: 'Montserrat',
            MA: 'Mərakeş',
            MZ: 'Mozambik',
            MM: 'Myanma',
            NA: 'Namibiya',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Niderland',
            AN: 'Niderland Antil adaları',
            NC: 'Yeni Kaledoniya',
            NZ: 'Yeni Zelandiya',
            NI: 'Nikaraqua',
            NE: 'Niger',
            NG: 'Nigeriya',
            NU: 'Niue',
            NF: 'Norfolk adası',
            MP: 'Şimali Marian adaları',
            NO: 'Norveç',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'İşğal olunmuş Fələstin ərazisi',
            PA: 'Panama',
            PG: 'Papua Yeni Qvineya',
            PY: 'Paraqvay',
            PE: 'Peru',
            PH: 'Filippin',
            PN: 'Pitkern adaları',
            PL: 'Polşa',
            PT: 'Portuqaliya',
            PR: 'Puerto-Riko',
            QA: 'Qətər',
            RE: 'Reyunyon',
            RO: 'Rumıniya',
            RU: 'Rusiya',
            RW: 'Ruanda',
            BL: 'Sen-Bartelemi',
            SH: 'Müqəddəs Yelena adası',
            KN: 'Sent-Kits və Nevis',
            LC: 'Sent-Lüsiya',
            MF: 'Sen-Marten',
            PM: 'Sen-Pyer və Mikelon',
            VC: 'Sent Vinsent və Qrenadinlər',
            WS: 'Samoa',
            SM: 'San-Marino',
            ST: 'San-Tome və Prinsipi',
            SA: 'Səudiyyə Ərəbistanı',
            SN: 'Seneqal',
            RS: 'Serbiya',
            SC: 'Seyşel adaları',
            SL: 'Syerra-Leone',
            SG: 'Sinqapur',
            SK: 'Slovakiya',
            SI: 'Sloveniya',
            SB: 'Solomon adaları',
            SO: 'Somali',
            ZA: 'Cənubi Afrika Respublikası',
            GS: 'Cənubi Georgiya və Sandviç adaları',
            ES: 'İspaniya',
            LK: 'Şri-Lanka',
            SD: 'Sudan',
            SR: 'Surinam',
            SJ: 'Svalbard və Jan Mayen',
            SZ: 'Svazilend',
            SE: 'İsveç',
            CH: 'İsveçrə',
            SY: 'Suriya Ərəb Respublikası',
            TW: 'Tayvan',
            TJ: 'Tacikistan',
            TZ: 'Tanzaniya',
            TH: 'Tayland',
            TL: 'Şərqi Timor',
            TG: 'Toqo',
            TK: 'Tokelau',
            TO: 'Tonqa',
            TT: 'Trinidad və Tobaqo',
            TN: 'Tunis',
            TR: 'Türkiyə',
            TM: 'Türkmənistan',
            TC: 'Törks və Kaykos adaları',
            TV: 'Tuvalu',
            UG: 'Uqanda',
            UA: 'Ukrayna',
            AE: 'Birləşmiş Ərəb Əmirlikləri',
            GB: 'Birləşmiş Krallıq',
            US: 'Amerika Birləşmiş Ştatları',
            UM: 'Birləşmiş Ştatların xarici adaları',
            UY: 'Uruqvay',
            UZ: 'Özbəkistan',
            VU: 'Vanuatu',
            VE: 'Venesuela',
            VN: 'Vyetnam',
            VG: 'Britaniya Vircin adaları ',
            VI: 'ABŞ Vircin adaları',
            WF: 'Uollis və Futuna',
            EH: 'Qərbi Sahara',
            YE: 'Yəmən',
            ZM: 'Zambiya',
            ZW: 'Zimbabve'
        },
    },
    tr: {
        _name: 'Türkçe',
        timePickerModal: {
            title: 'Close In Time'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Kârı Al ve Zararı Durdur',
            takeProfit: 'Kârı Al',
            stopLoss: 'Zararı Durdur',
            updateOrder: 'Emri Güncelle',
        },
        balanceModal: {
            balance: 'Bakiye',
            credit: 'Kredi',
            equity: 'Özkaynak',
            practice: 'PRATİK',
            real: 'GERÇEK',
            account: 'HESAP',
            invested: 'Yatırıldı',
            profit: 'Kâr',
            loss: 'Zarar',
            margin: 'Marjin',
            marginLevel: 'Marjin Seviyesi',
            marginFree: 'Serbest Marjin'
        },
        header: {
            cfd: 'CFD',
            platform: 'Platform',
            deposit: 'Para Yatır'
        },
        sideMenu: {
            marketWatch: 'Piyasa Takibi',
            activeOrders: 'Aktif Emirler',
            tradingHistory: 'İşlem Geçmişi',
            economicCalendar: 'Ekonomik Takvim',
            marketNews: 'Piyasa Haberleri'
        },
        closePositionModal: {
            closeOrder: 'Pozisyonu Kapat',
            areYouSure: 'Pozisyonu kapatmak istediğinizden emin misiniz',
            buy: 'Satın Al',
            sell: 'Sat',
            yes: 'Evet',
            no: 'Hayır',
        },
        pciDssModal: {
            desc: `Ödeme Kartı Endüstrisi Veri Güvenliği Standartları (PCI DSS) uyumluluğu, Visa/Master Card/Stripe kart sahibi verilerini depolayan, işleyen veya ileten tüm kuruluşlardan, finansal kuruluşlardan, satıcılar ve hizmet sağlayıcılardan istenir. PCI DSS, kart sahibi verilerini ve/veya hassas kimlik doğrulama verilerini depolayan, işleyen veya ileten tüm kuruluşlar için küresel güvenlik standartıdır. PCI DSS, tüketiciler için bir taban seviyesi koruma belirler ve tüm ödeme ekosistemi genelinde dolandırıcılığı ve veri ihlallerini azaltmaya yardımcı olur.`
        },
        chart: {
            addNewChart: 'Yeni Grafik Ekle',
        },
        symbolsModal: {
            watchlist: 'Takip Listesi',
            asset: 'Varlık',
            price: 'Fiyat',
            changePct: '24s Değişim'
        },
        pendingModal: {
            pending: 'Beklemede',
            assetPrice: 'Varlık Fiyatı',
            current: 'Güncel',
            revert: 'Piyasa Fiyatına Geri Dön',
            automatically: 'Fiyat bu seviyeye ulaştığında pozisyon otomatik olarak açılacak'
        },
        orderMenu: {
            default: 'varsayılan',
            volume: 'Hacim',
            lots: 'lot',
            units: 'birim',
            currency: 'para birimi',
            contractSize: 'Sözleşme büyüklüğü',
            position: 'Pozisyon',
            margin: 'Marjin',
            freeMargin: 'Serbest Marjin',
            takeProfitStopLoss: 'Kârı Al ve Zararı Durdur',
            pending: 'Beklemede',
            market: 'Piyasa',
            leverage: 'Kaldıraç',
            spread: 'Yayılım',
            notSet: 'Ayarlanmadı',
            at: 'saat',
            buy: 'satın al',
            sell: 'sat',
        },
        footer: {
            supportBanner: 'HER GÜN, HER SAAT',
            currentTime: 'GÜNCEL SAAT',
            liveChat: 'Canlı Sohbet'
        },
        statusBar: {
            balance: 'Bakiye',
            credit: 'Kredi',
            equity: 'Özkaynak',
            margin: 'Marjin',
            marginLevel: 'Marjin seviyesi',
            freeMargin: 'Serbest Marjin',
            pnl: 'PnL',
            profitTotal: 'Ömür Boyu PnL'
        },
        accountModal: {
            dateRegistered: 'Kayıt Tarihi',
            userId: 'Kullanıcı ID`si',
            dashboard: 'Pano',
            personalData: 'Kişisel Veriler',
            deposit: 'Para Yatır',
            withdrawFunds: 'Para Çek',
            savings: 'Birikimler',
            settings: 'Ayarlar',
            logout: 'Çıkış Yap'
        },
        depositModal: {
            BT_INVOICE: 'Tutarı girin ve faturayı oluşturun',
            BT_TITLE: 'Banka bilgilerimiz',
            account: 'Hesap',
            bank_name: 'Banka Adı',
            beneficiary: 'Lehdar',
            beneficiary_address: 'Lehdar Adresi',
            swift: 'Swift',
            reference: 'Reference',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Tutar',
            TOKEN_SUBTOTAL: 'Ara Toplam',
            TOKEN_TOTAL: 'Toplam',
            TOKEN_DESCRIPTION: 'Açıklama',
            TOKEN_PAYMENT_DETAILS: 'Ödeme Detayları',
            PENDING_DEPOSITS_LIMIT: 'Para Yatırma Limiti',
            makeDeposit: 'Para Yatır',
            practiceAccount: 'Pratik Hesabı',
            realAccount: 'Gerçek Hesap',
            noWithdrawals: 'Para Çekme Yok',
            easyWithdrawals: 'Kolay Para Çekme',
            allAssets: 'Tüm varlıklar mevcut',
            fullFledged: 'Tam teşekküllü platform',
            fillUpTo: 'Maksimum Doldurma',
            freeReplenishment: 'Ücretsiz yükleme',
            topUp: 'Hesabınıza Para Yükleyin',
            minimumAmount: 'Minimum tutar',
            canSwitch: 'Hesaplarınız arasında istediğiniz zaman geçiş yapabilirsiniz'
        },
        gridsModal: {
            chartGrids: 'GRAFİK ÇİZELGELERİ',
            chart1: '1 grafik',
            chart2: '2 grafik',
            chart3: '3 grafik',
            chart4: '4 grafik',
        },
        noConnectionModal: {
            connectionLost: 'Sunucu ile bağlantı kesildi',
            retryNow: 'Şimdi Tekrar Dene',
        },
        loginModal: {
            loginToTradeRoom: 'Traderoom`a Giriş Yapın',
            email: 'E-posta adresi',
            enterEmail: 'E-posta adresinizi girin',
            twoFactor: '2FA Kodu (etkinleştirildiyse)',
            twoFactorAuth: 'İki faktörlü kimlik doğrulama',
            password: 'Şifre',
            yourPassword: 'Şifreniz',
            signIn: 'Giriş Yap',
            dontHaveAccount: 'Hesabınız yok mu?',
            restore: 'Geri Yükle',
            fullName: 'Ad Soyad',
            havePromo: 'Bir promosyon kodum var',
            promo: 'Promosyon kodu',
            login: 'Giriş Yap',
            haveAccount: 'Zaten bir hesabınız var mı?',
            hide: 'Gizle',
            goTo: 'Geri dön'
        },
        newPasswordModal: {
            setNewPassword: 'Yeni Şifre Belirle',
            newPassword: 'Yeni şifre',
            confirmNewPassword: 'Yeni şifreyi onayla',
            setButton: 'Ayarla'
        },
        registerModal: {
            repeatPassword: 'Şifreyi Tekrarla',
            createNewAccount: 'Yeni Bir Hesap Oluşturun',
            email: 'E-posta adresi',
            firstName: 'Ad',
            lastName: 'Soyadı',
            country: 'Ülke',
            phone: 'Telefon',
            password: 'Şifre',
            createAccount: 'Hesap Oluştur',
            currency: 'Para Birimi',
            privacyAndPolicy: "Gizlilik ve Politika",
            customerAgreement: 'Müşteri anlaşması',

        },
        forgotPasswordModal: {
            forgotPassword: 'Şifrenizi mi unuttunuz?',
            submitEmail: 'Lütfen kayıt olurken kullandığınız e-postayı yazın, gelen kutunuzu kontrol edin ve verilen talimatları izleyin',
            submit: 'Gönder'
        },
        notifications: {
            error: 'Hata',
            success: 'Başarılı',
            deposit: 'Para Yatır',
            withdrawal: 'Para Çek',
            depositApproved: 'Para yatırma işlemi onaylandı',
            depositDeclined: 'Para yatırma işlemi reddedildi',
            withdrawalApproved: 'Para çekme işlemi onaylandı',
            withdrawalDeclined: 'Para çekme işlemi reddedildi'
        },
        markets: {
            forex: 'Forex',
            stocks: 'Hisseler',
            commodities: 'Emtialar',
            indices: 'Endeksler',
            crypto: 'Kripto',
            metals: 'Metaller',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'AL',
            profitCalculator: 'Kâr Hesaplayıcı',
            sell: 'SAT',
            volume: 'Hacim',
            entryPrice: 'Giriş Fiyatı',
            takeProfit: 'Kârı Al',
            stopLoss: 'Zararı Durdur',
            maxPosition: 'Maks Pozisyon',
            calculate: 'Hesapla',
            calculationsFor: 'Hesaplama sonucu',
            leverage: 'Kaldıraç',
            requiredMargin: 'Gerekli Marjin',
            profitFromTP: 'TP`den kâr',
            lossFromSL: 'SL`den zarar',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Para yatırma başarılı',
            text: 'Para yatırma işleminiz başarıyla tamamlandı!',
        },
        depositFailModal: {
            title: 'Para yatırma başarısız',
            text: 'Para yatırma işleminiz gerçekleştirilmedi.',
        },
        widgetMarketWatch: {
            marketWatch: 'Piyasa Takibi',
            search: 'Ara...',
        },
        widgetOrders: {
            commission: 'Komisyon',
            volume: 'Hacim',
            margin: 'Marjin',
            active: 'Aktif',
            pending: 'Beklemede',
            activeOrders: 'Aktif Emirler',
            portfolio: 'Portföy',
            allPositions: 'Tüm Pozisyonlar',
            noPositionsLine1: 'Henüz hiç açık',
            noPositionsLine2: 'pozisyonunuz yok',
            show: 'Göster',
            more: 'daha fazla',
            less: 'daha az',
            purchaseTime: 'Satın Alma Zamanı',
            closePosition: 'Pozisyonu Kapat',
            priceOpen: 'Satın Alma Fiyatı',
            priceSl: 'Zararı Durdurma Fiyatı',
            priceTp: 'Kârı Alma Fiyatı',
            type: 'Pozisyon Yönü',
            pnl: 'Kâr/Zarar',
            cancelOrder: 'Emiri İptal Et',
            orderType: 'Emir Türü',
            cancelled: 'İptal Edildi',
            tradingHistory: 'İşlem Geçmişi',
            noHistoryLine1: 'Henüz hiç',
            noHistoryLine2: 'kapalı anlaşmanız yok',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Ekonomik Takvim'
        },
        widgetMarketNews: {
            marketNews: 'Piyasa Haberleri'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
        timedOrder: 'Timed Order',
            ordersActive: 'Aktif Emirler',
            ordersHistory: 'Emir Geçmişi',
            id: 'Kimlik',
            symbol: 'Sembol',
            type: 'Tür',
            volume: 'Hacim',
            openPrice: 'Açılış Fiyatı',
            openTime: 'Açılış Zamanı',
            closePrice: 'Kapanış Fiyatı',
            closeTime: 'Kapanış Zamanı',
            sl: 'SL',
            tp: 'TP',
            price: 'Fiyat',
            pnl: 'PnL',
            actions: 'Eylemler',
            edit: 'Düzenle',
            close: 'Kapat',
            commission: 'Komisyon',
            swap: 'Takas',
            swapLong: 'Uzun Takas',
            swapShort: 'Kısa Takas'
        },
        assetInfoModal: {
            assetInfo: 'Varlık Bilgisi',
            tradingConditions: 'İşlem Koşulları',
            information: 'Oturum Değişikliği',
            bid: 'Teklif',
            ask: 'İste',
            sessionChange: 'Oturum Değişikliği',
            tradeNow: 'Şimdi Al-Sat',
            opens: 'Açıklar',
            closes: 'Kapalılar',
            at: 'saat',
            open247: '7/24 açık',
            today: 'bugün',
            tomorrow: 'yarın',
            sunday: 'Pazar',
            monday: 'Pazartesi',
            tuesday: 'Salı',
            wednesday: 'Çarşamba',
            thursday: 'Perşembe',
            friday: 'Cuma',
            saturday: 'Cumartesi',
            contractSpecification: 'Sözleşme Özellikleri',
            symbol: 'Sembol',
            name: 'Adı',
            market: 'Piyasa',
            baseCurrency: 'Baz Para Birimi',
            digits: 'Basamak',
            lotSize: 'Lot Boyutu',
            lotStep: 'Lot Adımı',
            minVolume: 'Min Hacim',
            maxVolume: 'Maks Hacim',
            leverage: 'Kaldıraç',
            commission: 'Komisyon',
            swapLong: 'Uzun Takas',
            swapShort: 'Kısa Takas',
            schedule: 'Çizelge',
            weekday: 'Hafta içi',
            tradingTime: 'İşlem Süresi',
            closed: 'kapalı',
            sell: 'Sat',
            buy: 'Al',
            low: 'Düşük',
            high: 'Yüksek',
            oneHourChange: '1 saatlik değişim',
            oneDayChange: '1 günlük değişim',
            oneWeekChange: '1 haftalık değişim',
            oneMonthChange: '1 aylık değişim',
            threeMonthsChange: '3 aylık değişim',
            oneYearChange: '1 yıllık değişim',
            loading: 'Yükleniyor...',
        },
        autoTrader: {
            days: 'days',
            title: 'Oto Al-Sat',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Risk Seviyesi: Düşük',
            riskLevelMedium: 'Risk Seviyesi: Orta',
            riskLevelHigh: 'Risk Seviyesi: Yüksek',
            perDay: 'Günlük işlem sayısı:',
            desc: `CFD'ler ve diğer kaldıraçlı ürünlerle işlem yapmak zarara yol açabilir. Müşteriler işlem yapmadan önce
                Risk Bildirimi
                sayfamızdaki ilgili risk bildirimlerini mutlaka okumalıdır. Otomatik alım satım sonuç garantisi vermez. Şirket,
                otomatik işlemlerde fon kaybı konusunda hiçbir sorumluluk kabul etmez. Lütfen riskleri tam olarak
                anladığınızdan ve risk yönetimi için önlemler aldığınızdan emin olun.`,
            accept: 'Kabul Et',
            save: 'Kaydet',
            cancel: 'İptal',
        },
        cardBox: {
            name: 'Ad',
            cardNumber: 'Kart Numarası',
            expiration: 'Son kullanma tarihi (aa/gg)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "Al-Sat",
            startTrading: 'Alım Satıma Başla',
            deposit: 'Para Yatırma',
            dashboard: 'Pano',
            personalInfo: 'Kişisel Bilgiler',
            contactInfo: 'İletişim Bilgileri',
            withdrawal: 'Para Çekme',
            verification: 'Doğrulama',
            accounts: 'Hesaplar',
            liveChat: 'Canlı Sohbet',
            savings: 'Birikimler',
            settings: 'Ayarlar',
            logOut: 'Çıkış Yap',
        },
        fullScreenDeposit: {
            methods: 'YÖNTEMLER',
            confirm: 'Bankanızın uygulamasında onaylayın',
            sendOtp: 'Otp gönder',
            otpCode: 'Otp kodu',
            addWalletAddress: 'Bu para yatırma yöntemi için cüzdan ayarlanmadı. Lütfen temsilcinizle veya destek ekibinizle iletişime geçin.',
            addressReceipt: 'Lütfen adresin yüklenmesini bekleyin',
            makeDeposit: 'PARA YATIRIN',
            instant: 'Anında',
            minutes: 'dakika',
            hours: 'saat',
            days: 'gün',
            amount: 'Tutar',
            continue: 'Devam Et',
            qrCode: 'QR KODU',
            depositAddress: 'YATIRMA ADRESİ',
            copy: 'Kopyala',
            last: 'SONUNCU',
            deposits: 'YATIRMA İŞLEMLERİ',
            time: 'Zaman',
            currency: 'Para Birimi',
            status: 'Durum',
            info: 'Bilgi',
        },
        fullScreenCcExt: {
            pleaseWait: 'Lütfen bekleyin. Ödeme hizmeti sağlayıcısına bağlanılıyor...',
            cardPayment: 'Kartlı ödeme',
            inOrder: 'Tutarı göndermek için',
            pleaseProvide: 'lütfen kart bilgilerinizi girin:'
        },
        fullScreenDashboard: {
            completeVerification: 'Lütfen Doğrulamayı Tamamlayın',
            noOrderYet: 'Henüz emir yok',
            totalBalance: 'Toplam Bakiye',
            totalPnl: 'Toplam PNL',
            profitableOrders: 'Kârlı Emirler',
            roi: 'ROI',
            activityLog: 'Aktivite Günlüğü',
            loginFromIp: 'IP`den giriş',
            tradingResults: 'Al-Sat Sonuçları',
            week: 'Hafta',
            month: 'Ay',
            year: 'Yıl',
            successRate: 'Başarı oranı',
            closedWithProfit: 'Kârla Kapandı',
            closedWithLoss: 'Zararla Kapandı',
            account: 'Hesap',
            balance: 'Bakiye',
            leverage: 'Kaldıraç',
            credit: 'Kredi',
            tradeNow: 'Şimdi Al-Sat',
            usingCurrentRate: 'güncel döviz kurunu kullanarak',
            totalDeposits: 'Toplam Yatırma'
        },
        fullScreenPersonal: {
            personalDetails: 'Kişisel Bilgiler',
            profilePhoto: 'Profil Fotoğrafı',
            firstName: 'Ad',
            country: 'Ülke',
            lastName: 'Soyadı',
            address: 'Adres',
            email: 'E-posta',
            phone: 'Telefon numarası',
            saveChanges: 'Değişiklikleri Kaydet',
            state: 'Şehir',
            postcode: 'Posta kodu',
            created: "Hesap oluşturuldu",
        },
        fullScreenAvatar: {
            dropFile: 'Yüklemek için yukarıdaki dairenin üzerine bir dosya bırakın',
            notAllowed: 'Aşağıdakilerin yayınlanmasına izin verilmez;',
            notAllowedLine1: 'Açıkça cinsel veya pornografik nitelikteki fotoğraflar',
            notAllowedLine2: 'Etnik veya ırkçı nefret veya saldırganlığı kışkırtmaya yönelik görüntüler',
            notAllowedLine3: 'On sekiz yaşından küçük kişilerin yer aldığı fotoğraflar',
            notAllowedLine4: 'Üçüncü taraf telif hakkı ile korunan fotoğraflar',
            notAllowedLine5: '5 MB`den büyük olan ve JPG, GIF veya PNG haricindeki bir formatta olan görüntüler',
            requirements: 'Yüzünüz fotoğrafta açıkça görülebilir olmalıdır. Yüklediğiniz tüm fotoğraf ve videolar bu şartlara uygun olmalıdır, aksi takdirde kaldırılabilirler.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Para çekme ile ilgili detaylar için lütfen hesap yöneticinizle iletişime geçin',
            cryptocurrency: 'Kripto Para',
            withdrawal: 'Para Çekme',
            requestNewWithdrawal: 'YENİ BİR PARA ÇEKME TALEBİNDE BULUNUN',
            amount: 'Tutar',
            currentBalance: 'Güncel hesap bakiyeniz',
            withdrawAll: 'Hepsini çek',
            requestWithdrawal: 'Para Çekme Talebinde Bulunun',
            last: 'SON',
            withdrawalRequests: 'PARA ÇEKME TALEBİ',
            time: 'Zaman',
            currency: 'Para Birimi',
            info: 'Bilgi',
            status: 'Durum',
            bank: 'Banka',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            card: 'Kart',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Para Çekme Detayları',
            address: 'Cüzdan Adresi',
            bankAccount: 'Hesap Numarası',
            bankHolder: 'Hesap Sahibinin Adı',
            bankIban: 'IBAN',
            bankSwift: 'Banka SWIFT kodu',
            recipientAddress: 'Alıcının Adresi',
            routingNumber: 'Yönlendirme Numarası',
            bankBeneficiary: 'Faydalanıcı Banka Adı',
            bankAddress: 'Banka Adresi',
            bankCode: 'Banka kodu',
            desc: 'Açıklama',
            cardNumber: 'Kart Numarası',
            cardHolder: 'Kart Sahibi Adı',
        },
        fullScreenVerification: {
            dragAndDrop: 'belgeyi bu alana sürükleyip bırakın',
            documentsList: 'YÜKLENEN BELGELERİN LİSTESİ',
            document: 'Belge',
            timeUploaded: 'Yüklenme Zamanı',
            timeProcessed: 'İşlenme Zamanı',
            status: 'Durum',
            types: ['Kimlik Kanıtı', 'İkametgâh Kanıtı', 'Kredi Kartı Ön Yüzü', 'Kredi Kartı Arka Yüzü', 'Kimlik Arka Yüzünün Kanıtı', 'Özçekim'],
            types2: ['Kimlik kanıtı (POI)', 'İkametgah adresinin kanıtı', 'Kredi Kartı Ön Yüzü', 'Kredi Kartı Arka Yüzü', 'Kimlik Arka Yüzünün Kanıtı', 'Kimlik ile Özçekim'],
            upload: 'Yükleme',
            isConfirmed: 'onaylandı',
            uploadAnotherDocument: 'Başka bir belge yükleyin',
            isUnderReview: 'inceleme altında',
            questionnaire: 'Anket',
            sendAnswers: 'Cevap Gönder',
            progressBarOf: '/',
            progressBarText: 'belgeniz yüklendi ve onaylandı'
        },
        fullScreenAccounts: {
            manageAccounts: 'Hesaplarınız arasında para transferi yapın',
            transferFrom: 'Hesaptan',
            transferTo: 'Hesaba',
            amount: 'Tutar',
            makeTransfer: 'Transfer Yap',
            yourAccounts: 'Hesaplarınız',
            account: 'Hesap',
            currency: 'Para Birimi',
            balance: 'Bakiye',
            credit: 'Kredi',
            makeActive: 'Etkinleştirin',
            active: 'Etkin'
        },
        fullScreenSupport: {
            sendMessage: 'Mesaj Gönder'
        },
        fullScreenSavings: {
            pct_0: 'Esnek',
            pct_30: '1 Ay Kilitli',
            pct_90: '3 Ay Kilitli',
            pct_180: '6 Ay Kilitli',
            pct_365: '1 Yıl Kilitli',
            savings: 'Birikimler',
            detailedInformation: 'Detaylı Bilgi',
            pleaseSelect: 'Lütfen bir para birimi ve bir dönem seçin',
            openSavings: 'Birikimleri Aç',
            currentTime: 'Güncel Zaman',
            releaseTime: 'Çıkış Zamanı',
            currencyChosen: 'Seçilen Para Birimi',
            periodChosen: 'Seçilen Dönem',
            yourRate: 'Oranınız',
            yourEarnings: 'Kazancınız',
            accountFrom: 'Fon aktarılacak hesap',
            enterAmount: 'Tutar Girin',
            in: 'zaman',
            openAndInvest: 'Birikim Hesabı açın ve yatırım yapın',
            investment: 'Yatırım',
            period: 'Dönem',
            daysTotal: 'Toplam gün',
            finalEarnings: 'Nihai kazançlar',
            created: 'Oluşturuldu',
            daysElapsed: 'Geçen Günler',
            days: 'gün',
            earnings: 'Kazançlar',
            fundsReleasedIn: 'Fonların Serbest Bırakıldığı Zaman',
            claim: 'Al',
        },
        fullScreenSettings: {
            updatePassword: 'ŞİFREYİ GÜNCELLE',
            currentPassword: 'Geçerli Şifre',
            newPassword: 'Yeni Şifre',
            changePassword: 'Şifre Değiştir',
            activationForGoogle: 'Google Authenticator için aktivasyon kodu',
            activateAndEnter: 'Authenticator`ı etkinleştirin ve oluşturulan kodu aşağıdaki alana girin',
            qrCode: 'QR Kodu',
            activateProtection: '2FA Korumasını Etkinleştir',
            protectionActive: '2FA koruması etkin',
            twoFactorCode: '2FA Kodu',
            disableProtection: '2FA Korumasını Devre Dışı Bırak',
            language: 'Dil',
            dashboardCurrency: 'Pano Para Birimi',
            confirmNewPassword: 'Yeni şifreyi onayla',
        },
        fullScreenReferrals: {
            yourLink: 'REFERANS BAĞLANTINIZ',
            referredFriends: 'YÖNLENDİRİLEN ARKADAŞLAR',
            id: 'ID',
            name: 'Adı',
            registered: 'Kayıt Ol',
            level: 'Seviye',
            payout: 'Ödeme',
            lastPayouts: 'SON YÖNLENDİRME ÖDEMESİ',
            time: 'Zaman',
            amount: 'Tutar',
            currency: 'Para Birimi',
            info: 'Bilgi'
        },
        months: {
            Jan: 'Oca',
            Feb: 'Şub',
            Mar: 'Mar',
            Apr: 'Nis',
            May: 'May',
            Jun: 'Haz',
            Jul: 'Tem',
            Aug: 'Ağu',
            Sep: 'Eyl',
            Oct: 'Eki',
            Nov: 'Kas',
            Dec: 'Ara'
        },
        orderTypes: ['Al', 'Sat', 'Alım Limiti', 'Satış Limiti', 'Alış Stopu', 'Satış Stopu'],
        statuses: ['Beklemede', 'Onaylandı', 'Reddedildi', 'İşleniyor'],
        errors: {
            NOT_FOUND: 'Talep hatası',
            FORBIDDEN: 'Erişim reddedildi. Lütfen yeniden giriş yapın',
            SERVER_ERROR: 'İşlem başarısız oldu. Lütfen daha sonra tekrar deneyin',
            INVALID_ACCOUNT: 'Oturumun süresi doldu. Lütfen yeniden giriş yapın',
            INVALID_EMAIL: 'Geçersiz e-posta adresi',
            INVALID_PHONE: 'Geçersiz telefon numarası',
            INVALID_COUNTRY: 'Geçersiz ülke',
            INVALID_LOGIN: 'Geçersiz giriş bilgileri',
            USER_BLOCKED: 'Hesap engellendi. Lütfen destek ekibiyle iletişime geçin',
            USER_REGISTERED: 'Girilen e-posta veya telefon numarasıyla zaten kayıtlı bir kullanıcı var',
            INVALID_USER: 'Kullanıcı bulunamadı',
            REJECTED: 'Platform kısıtlamaları nedeniyle işleme izin verilmiyor',
            INVALID_OLD_PASSWORD: 'Eski şifre geçersiz',
            INVALID_SYMBOL: 'Geçersiz işlem sembolü',
            INVALID_SYMBOL_PRECISION: 'Geçersiz sembol doğruluğu',
            INVALID_FRAME: 'Geçersiz grafik çerçevesi',
            INVALID_CURRENCY: 'Geçersiz para birimi',
            INVALID_PRODUCT: 'Geçersiz hesap işlem türü',
            INSUFFICIENT_FUNDS: 'Yetersiz fon',
            INVALID_WITHDRAWAL: 'Para çekme işlemi bulunamadı',
            INVALID_STATUS: 'Geçersiz durum',
            INVALID_FILE: 'Geçersiz dosya uzantısı veya dosya çok büyük',
            INVALID_DOCUMENT: 'Belge bulunamadı',
            INVALID_NAME: 'Geçersiz ad',
            INVALID_LOT_SIZE: 'Geçersiz lot büyüklüğü',
            INVALID_LOT_STEP: 'Geçersiz lot adımı',
            INVALID_MIN_VOLUME: 'Geçersiz minimum hacim',
            INVALID_MAX_VOLUME: 'Geçersiz maksimum hacim',
            INVALID_GROUP: 'Geçersiz grup',
            INVALID_SPREAD: 'Geçersiz yayılım',
            REQUEST_TIMEOUT: 'İstek zaman aşımına uğradı. Lütfen daha sonra tekrar deneyin',
            EXISTING_OPEN_ORDERS: 'Açık bir siparişiniz var, önce bu kullanıcı için siparişi kapatın ve tekrar deneyin.',
            INVALID_TYPE: 'Geçersiz emir türü',
            INVALID_VOLUME: 'Geçersiz emir hacmi',
            INVALID_SL: 'Geçersiz zarar durdurma değeri',
            INVALID_TP: 'Geçersiz kâr alma değeri',
            INVALID_PRICE: 'Geçersiz emir fiyatı',
            INVALID_EXPIRATION: 'Geçersiz emir süresi',
            NOT_ENOUGH_MARGIN: 'Yeterli marjim yok',
            INVALID_ORDER: 'Emir bulunamadı',
            MARKET_CLOSED: 'İşlem yapılamıyor. Lütfen tekrar deneyin',
            INVALID_BALANCE_TYPE: 'Geçersiz bakiye işlemi türü',
            INVALID_HASH: 'Geçersiz hash',
            HASH_EXPIRED: 'Şifre sıfırlama bağlantınızın süresi doldu. Lütfen yeni bir bağlantı talep edin',
            INVALID_CODE: 'Geçersiz 2FA kodu',
            CHAT_DISABLED: 'Destek sohbetine erişim kapatıldı',
            WITHDRAWAL_NOT_ALLOWED: 'Para çekme işlemlerine erişim kapatıldı',
            TRADING_DISABLED: 'Mevcut hesap için alım satım işlemi devre dışı bırakıldı',
            PENDING_DEPOSITS_LIMIT: 'Para yatırma limiti aşıldı',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Beklemede sadece bir para çekme işlemi olabilir',
            LOCAL_DEPOSIT_ONLY_REAL: 'Para yatırma işlemi yalnızca gerçek hesap için geçerlidir',
            LOCAL_DEPOSIT_POSITIVE: 'Para yatırma miktarı 0`dan fazla olmalıdır',
            LOCAL_INVALID_CARD_NUMBER: 'Geçersiz kart numarası',
            LOCAL_INVALID_CARD_EXPIRATION: 'Geçersiz kart son kullanma tarihi',
            LOCAL_INVALID_CVC: 'Geçersiz CVV/CVC kodu',
            LOCAL_PASSWORDS_NOT_MATCH: 'Şifreler eşleşmiyor, lütfen tekrar girin',
            LOCAL_NO_TRADING_ACCOUNTS: 'Kullanılabilir işlem hesabınız yok. Lütfen destek ile iletişime geçin',
            LOCAL_MIN_DEPOSIT: 'Minimum para yatırma $',
            LOCAL_MIN_WITHDRAWAL: 'Minimum para çekme $',
            LOCAL_INVALID_FILE_TYPE: 'Dosya formatı desteklenmiyor. Lütfen yalnızca JPG, PNG veya PDF biçiminde dosya ekleyin',
            LOCAL_MIN_STAKING: 'Bu para birimi için minimum para yatırma tutarı ',
            FLOOD_REJECTED: 'Çok sık mesaj gönderiyorsunuz. Lütfen daha sonra tekrar deneyin'
        },
        countries: {
            AF: 'Afganistan',
            AX: 'Aland Adaları',
            AL: 'Arnavutluk',
            DZ: 'Cezayir',
            AS: 'Amerikan Samoası',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarktika',
            AG: 'Antigua ve Barbuda',
            AR: 'Arjantin',
            AM: 'Ermenistan',
            AW: 'Aruba',
            AU: 'Avustralya',
            AT: 'Avusturya',
            AZ: 'Azerbaycan',
            BS: 'Bahamalar',
            BH: 'Bahreyn',
            BD: 'Bangladeş',
            BB: 'Barbados',
            BY: 'Belarus',
            BE: 'Belçika',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Bhutan',
            BO: 'Bolivya',
            BA: 'Bosna Hersek',
            BW: 'Botsvana',
            BV: 'Bouvet Adası',
            BR: 'Brezilya',
            IO: 'Britanya Hint Okyanusu Toprakları',
            BN: 'Brunei Darussalam',
            BG: 'Bulgaristan',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Kamboçya',
            CM: 'Kamerun',
            CA: 'Kanada',
            CV: 'Cape Verde',
            KY: 'Cayman Adaları',
            CF: 'Orta Afrika Cumhuriyeti',
            TD: 'Çad',
            CL: 'Şili',
            CN: 'Çin',
            CX: 'Christmas Adası',
            CC: 'Cocos (Keeling) Adaları',
            CO: 'Kolombiya',
            KM: 'Komorlar',
            CG: 'Kongo',
            CD: 'Demokratik Kongo Cumhuriyeti',
            CK: 'Cook Adaları',
            CR: 'Kosta Rika',
            CI: 'Cote D\'Ivoire',
            HR: 'Hırvatistan',
            CU: 'Küba',
            CY: 'Kıbrıs',
            CZ: 'Çek Cumhuriyeti',
            DK: 'Danimarka',
            DJ: 'Djibouti',
            DM: 'Dominika',
            DO: 'Dominik Cumhuriyeti',
            EC: 'Ekvador',
            EG: 'Mısır',
            SV: 'El Salvador',
            GQ: 'Ekvator Ginesi',
            ER: 'Eritre',
            EE: 'Estonya',
            ET: 'Etiyopya',
            FK: 'Falkland Adaları (Malvinas)',
            FO: 'Faroe Adaları',
            FJ: 'Fiji',
            FI: 'Finlandiya',
            FR: 'Fransa',
            GF: 'Fransız Guyanası',
            PF: 'Fransız Polinezyası',
            TF: 'Fransız Güney Toprakları',
            GA: 'Gabon',
            GM: 'Gambiya',
            GE: 'Gürcistan',
            DE: 'Almanya',
            GH: 'Gana',
            GI: 'Cebelitarık',
            GR: 'Yunanistan',
            GL: 'Grönland',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Gine',
            GW: 'Gine-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Heard Adası ve Mcdonald Adaları',
            VA: 'Holy See (Vatikan Şehir Devleti)',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Macaristan',
            IS: 'İzlanda',
            IN: 'Hindistan',
            ID: 'Endonezya',
            IR: 'İran',
            IQ: 'Irak',
            IE: 'İrlanda',
            IM: 'Isle Of Man',
            IL: 'İsrail',
            IT: 'İtalya',
            JM: 'Jamaika',
            JP: 'Japonya',
            JE: 'Jersey',
            JO: 'Ürdün',
            KZ: 'Kazakistan',
            KE: 'Kenya',
            KI: 'Kiribati',
            KR: 'Kore',
            KW: 'Kuveyt',
            KG: 'Kırgızistan',
            LA: 'Lao Demokratik Halk Cumhuriyeti',
            LV: 'Letonya',
            LB: 'Lübnan',
            LS: 'Lesotho',
            LR: 'Liberya',
            LY: 'Libya Arap Cemahiriyesi',
            LI: 'Liechtenstein',
            LT: 'Litvanya',
            LU: 'Lüksemburg',
            MO: 'Makao',
            MK: 'Makedonya',
            MG: 'Madagaskar',
            MW: 'Malavi',
            MY: 'Malezya',
            MV: 'Maldivler',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marshall Adaları',
            MQ: 'Martinik',
            MR: 'Moritanya',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Meksika',
            FM: 'Mikronezya',
            MD: 'Moldova',
            MC: 'Monako ',
            MN: 'Moğolistan',
            ME: 'Karadağ',
            MS: 'Montserrat',
            MA: 'Fas',
            MZ: 'Mozambik',
            MM: 'Myanmar',
            NA: 'Namibya',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Hollanda',
            AN: 'Hollanda Antilleri',
            NC: 'Yeni Kaledonya',
            NZ: 'Yeni Zelanda',
            NI: 'Nikaragua',
            NE: 'Niger',
            NG: 'Nijerya',
            NU: 'Niue',
            NF: 'Norfolk Adası',
            MP: 'Kuzey  Mariana Adaları',
            NO: 'Norveç',
            OM: 'Umman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'İşgal Altındaki Filistin Toprakları',
            PA: 'Panama',
            PG: 'Papua Yeni Gine',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Filipinler',
            PN: 'Pitcairn',
            PL: 'Polonya',
            PT: 'Portekiz',
            PR: 'Porto Riko',
            QA: 'Katar',
            RE: 'Reunion',
            RO: 'Romanya',
            RU: 'Rusya',
            RW: 'Rwanda',
            BL: 'Saint Barthelemy',
            SH: 'Saint Helena',
            KN: 'Saint Kitts ve Nevis',
            LC: 'Saint Lucia',
            MF: 'Saint Martin',
            PM: 'Saint Pierre ve Miquelon',
            VC: 'Saint Vincent ve Grenadines',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tome ve Principe',
            SA: 'Suudi Arabistan',
            SN: 'Senegal',
            RS: 'Sırbistan',
            SC: 'Seyşeller',
            SL: 'Sierra Leone',
            SG: 'Singapur',
            SK: 'Slovakya',
            SI: 'Slovenya',
            SB: 'Solomon Adaları',
            SO: 'Somali',
            ZA: 'Güney Afrika',
            GS: 'Güney Georgia ve Sandwich Adaları',
            ES: 'İspanya',
            LK: 'Sri Lanka',
            SD: 'Sudan',
            SR: 'Surinam',
            SJ: 'Svalbard ve Jan Mayen',
            SZ: 'Svaziland',
            SE: 'İsveç',
            CH: 'İsviçre',
            SY: 'Suriye Arap Cumhuriyeti',
            TW: 'Tayvan',
            TJ: 'Tacikistan',
            TZ: 'Tanzanya',
            TH: 'Tayland',
            TL: 'Timor-Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad ve Tobago',
            TN: 'Tunus',
            TR: 'Türkiye',
            TM: 'Türkmenistan',
            TC: 'Turks ve Caicos Adaları',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukrayna',
            AE: 'Birleşik Arap Emirlikleri',
            GB: 'Birleşik Krallık',
            US: 'Birleşik Devletler',
            UM: 'Birleşik Devletler Dış Adaları',
            UY: 'Uruguay',
            UZ: 'Özbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Viet Nam',
            VG: 'Britanya Virjin Adaları',
            VI: 'ABD Virjin Adaları',
            WF: 'Wallis ve Futuna',
            EH: 'Batı Sahra',
            YE: 'Yemen',
            ZM: 'Zambiya',
            ZW: 'Zimbabve'
        },
    },
    rs: {
        _name: 'Srpski',
        timePickerModal: {
            title: 'Close In Time'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Uzmite profit i sprečite gubitak',
            takeProfit: 'Uzmite profit',
            stopLoss: 'Sprečite gubitak',
            updateOrder: 'Izmeni narudžbinu',
        },
        balanceModal: {
            balance: 'Saldo',
            credit: 'Kredit',
            equity: 'Kapital',
            practice: 'VEŽBA',
            real: 'REALNO',
            account: 'NALOG',
            invested: 'Investirano',
            profit: 'Profit',
            loss: 'Gubitak',
            margin: 'Marža',
            marginLevel: 'Nivo marže',
            marginFree: 'Besplatna marža'
        },
        header: {
            cfd: 'CFD',
            platform: 'Platforma',
            deposit: 'Depozit'
        },
        sideMenu: {
            marketWatch: 'Market Watch',
            activeOrders: 'Aktivne narudžbine',
            tradingHistory: 'Istorija trgovanja',
            economicCalendar: 'Privredni kalendar',
            marketNews: 'Novosti sa tržišta'
        },
        closePositionModal: {
            closeOrder: 'Zatvori poziciju',
            areYouSure: 'Sigurno želite da zatvorite poziciju?',
            buy: 'Kupi',
            sell: 'Prodaj',
            yes: 'Da',
            no: 'Ne',
        },
        pciDssModal: {
            desc: `Usklađenost sa Standardima sigurnosti podataka industrije platnih kartica (PCI DSS) je obavezna za sve subjekte koji skladište, obrađuju ili prenose podatke o vlasnicima kartica Visa/Master Card/Stripe, uključujući finansijske institucije, trgovce i pružaoce usluga. To je globalni sigurnosni standard za sve subjekte koji skladište, obrađuju ili prenose podatke o vlasnicima kartica i/ili osetljive autentifikacijske podatke. PCI DSS postavlja osnovni nivo zaštitе za potrošače i pomaže u smanjenju prevara i curenja podataka širom celokupnog plaćanja.`
        },
        chart: {
            addNewChart: 'Dodaj nov grafikon',
        },
        symbolsModal: {
            watchlist: 'Lista za gledanje',
            asset: 'Imovina',
            price: 'Cena',
            changePct: 'Promeni 24h'
        },
        pendingModal: {
            pending: 'Na čekanju',
            assetPrice: 'Cena imovine',
            current: 'trenutno',
            revert: 'Povratak na tržišnu cenu',
            automatically: 'Pozicija će se automatski otvoriti kada cena dostigne ovaj nivo'
        },
        orderMenu: {
            default: 'podrazumevano',
            volume: 'Obim',
            lots: 'lotovi',
            units: 'jedinice',
            currency: 'valuta',
            contractSize: 'Veličina ugovora',
            position: 'Pozicija',
            margin: 'Marža',
            freeMargin: 'Besplatna marža',
            takeProfitStopLoss: 'Uzmite profit i sprečite gubitak',
            pending: 'Na čekanju',
            market: 'Tržište',
            leverage: 'Leveridž',
            spread: 'Širenje',
            notSet: 'Nije podešeno',
            at: 'u',
            buy: 'kupi',
            sell: 'prodaj',
        },
        footer: {
            supportBanner: 'SVAKI DAN, SVAKI SAT',
            currentTime: 'TRENUTNO VREME',
            liveChat: 'Čet uživo'
        },
        statusBar: {
            balance: 'Saldo',
            credit: 'Kredit',
            equity: 'Kapital',
            margin: 'Marža',
            marginLevel: 'Nivo marže',
            freeMargin: 'Besplatna marža',
            pnl: 'PnL',
            profitTotal: 'Doživotni PnL'
        },
        accountModal: {
            dateRegistered: 'Datum registracije',
            userId: 'Korisnički ID',
            dashboard: 'Komandna tabla',
            personalData: 'Lični podaci',
            deposit: 'Depozit',
            withdrawFunds: 'Povuci sredstva',
            savings: 'Uštede',
            settings: 'Podešavanja',
            logout: 'Odjava'
        },
        depositModal: {
            BT_INVOICE: 'Unesite iznos i generišete fakturu',
            BT_TITLE: 'Naši bankovni podaci',
            account: 'Račun',
            bank_name: 'Naziv banke',
            beneficiary: 'Korisnik',
            beneficiary_address: 'Adresa korisnika',
            swift: 'Swift',
            reference: 'Reference',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Correspondent account',
            TOKEN_AMOUNT: 'Iznos',
            TOKEN_SUBTOTAL: 'Suma stavke',
            TOKEN_TOTAL: 'Ukupno',
            TOKEN_DESCRIPTION: 'Opis',
            TOKEN_PAYMENT_DETAILS: 'Podaci o plaćanju',
            PENDING_DEPOSITS_LIMIT: 'Limit depozita',
            makeDeposit: 'Uplatite depozit',
            practiceAccount: 'Nalog za vežbanje',
            realAccount: 'Pravi nalog',
            noWithdrawals: 'Bez povlačenja',
            easyWithdrawals: 'Jednostavna povlačenja',
            allAssets: 'Sva sredstva dostupna',
            fullFledged: 'Punopravna platforma',
            fillUpTo: 'Napuniti do',
            freeReplenishment: 'Besplatno dopunjavanje',
            topUp: 'Dopunite svoj račun',
            minimumAmount: 'Minimalni iznos',
            canSwitch: 'U svakom trenutku možete da prelazite sa jednog naloga na drugi'
        },
        gridsModal: {
            chartGrids: 'MREŽE GRAFIKONA',
            chart1: '1 grafikon',
            chart2: '2 grafikona',
            chart3: '3 grafikona',
            chart4: '4 grafikona',
        },
        noConnectionModal: {
            connectionLost: 'Veza sa serverom je izgubljena',
            retryNow: 'Pokušajte ponovo sada',
        },
        loginModal: {
            loginToTradeRoom: 'Prijava u Sobu za trgovanje',
            email: 'Email adresa',
            enterEmail: 'Unesite svoj email',
            twoFactor: '2FA kod (ako je omogućen)',
            twoFactorAuth: 'Dvofaktorska autentifikacija',
            password: 'Lozinka',
            yourPassword: 'Vaša lozinka',
            signIn: 'Prijava',
            dontHaveAccount: 'Nemate nalog?',
            restore: 'Povrati',
            fullName: 'Ime i prezime',
            havePromo: 'Imam promo-kod',
            promo: 'Promo-kod',
            login: 'Prijava',
            haveAccount: 'Već imate nalog?',
            hide: 'Sakrij',
            goTo: 'Idi pozadi'
        },
        newPasswordModal: {
            setNewPassword: 'Podesite novu lozinku',
            newPassword: 'Nova lozinka',
            confirmNewPassword: 'Potvrdite novu lozinku',
            setButton: 'Podesi'
        },
        registerModal: {
            repeatPassword: 'Ponovite lozinku',
            createNewAccount: 'Kreirajte nov nalog',
            email: 'Email adresa',
            firstName: 'Ime',
            lastName: 'Prezime',
            country: 'Zemlja',
            phone: 'Telefon',
            password: 'Lozinka',
            createAccount: 'Kreirajte nalog',
            currency: 'Valuta',
            privacyAndPolicy: "Privatnost i Politika",
            customerAgreement: 'Ugovor sa klijentom',

        },
        forgotPasswordModal: {
            forgotPassword: 'Zaboravili ste lozinku?',
            submitEmail: 'Pošaljite email koji ste koristili za registraciju, proverite inboks i pratite data uputstva',
            submit: 'Pošalji'
        },
        notifications: {
            error: 'Greška',
            success: 'Uspešno',
            deposit: 'Depozit',
            withdrawal: 'Povlačenje',
            depositApproved: 'Depozit je odobren',
            depositDeclined: 'Depozit je odbijen',
            withdrawalApproved: 'Povlačenje je odobreno',
            withdrawalDeclined: 'Povlačenje je odbijeno'
        },
        markets: {
            forex: 'Forex',
            stocks: 'Akcije',
            commodities: 'Roba',
            indices: 'Indeksi',
            crypto: 'Kripto',
            metals: 'Metali',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'KUPI',
            profitCalculator: 'Kalkulator profita',
            sell: 'PRODAJ',
            volume: 'Obim',
            entryPrice: 'Ulazna cena',
            takeProfit: 'Uzmite profit',
            stopLoss: 'Sprečite gubitak',
            maxPosition: 'Maks. pozicija',
            calculate: 'Izračunaj',
            calculationsFor: 'Proračun za',
            leverage: 'Leveridž',
            requiredMargin: 'Zahtevana marža',
            profitFromTP: 'Profit od TP',
            lossFromSL: 'Gubitak od SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Depozit uspešan',
            text: 'Vaš depozit je uspešno obrađen!',
        },
        depositFailModal: {
            title: 'Depozit neuspešan',
            text: 'Vaš depozit je neuspešan.',
        },
        widgetMarketWatch: {
            marketWatch: 'Market Watch',
            search: 'Pretraga...',
        },
        widgetOrders: {
            commission: 'Provizija',
            volume: 'Obim',
            margin: 'Marža',
            active: 'Aktivno',
            pending: 'Na čekanju',
            activeOrders: 'Aktivne narudžbine',
            portfolio: 'Portfolio',
            allPositions: 'Sve pozicije',
            noPositionsLine1: 'Nemate otvorenih',
            noPositionsLine2: 'pozicija još uvek',
            show: 'Prikaži',
            more: 'više',
            less: 'manje',
            purchaseTime: 'Vreme kupovine',
            closePosition: 'Zatvori poziciju',
            priceOpen: 'Kupovna cena',
            priceSl: 'Cena sprečavanja gubitka',
            priceTp: 'Cena uzimanja profita',
            type: 'Smer pozicije',
            pnl: 'Profit/gubitak',
            cancelOrder: 'Otkaži narudžbinu',
            orderType: 'Tip narudžbine',
            cancelled: 'Otkazano',
            tradingHistory: 'Istorija trgovanja',
            noHistoryLine1: 'Nemate nijedan',
            noHistoryLine2: 'završeni posao još uvek',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Privredni kalendar'
        },
        widgetMarketNews: {
            marketNews: 'Novosti na tržištu'
        },
        ordersPane: {
            closeInTime: 'Close In Time',
            timedOrder: 'Timed Order',
            ordersActive: 'Aktivne narudžbine',
            ordersHistory: 'Istorija narudžbina',
            id: 'ID',
            symbol: 'Simbol',
            type: 'Tip',
            volume: 'Obim',
            openPrice: 'Cena otvaranja',
            openTime: 'Vreme otvaranja',
            closePrice: 'Cena zatvaranja',
            closeTime: 'Vreme zatvaranja',
            sl: 'SL',
            tp: 'TP',
            price: 'Cena',
            pnl: 'PnL',
            actions: 'Radnje',
            edit: 'Uredi',
            close: 'Zatvori',
            commission: 'Provizija',
            swap: 'Zamena',
            swapLong: 'Zameni dugo',
            swapShort: 'Zameni kratko'
        },
        assetInfoModal: {
            assetInfo: 'Podaci o sredstvima',
            tradingConditions: 'Uslovi trgovanja',
            information: 'Promena sesije',
            bid: 'Licitiraj',
            ask: 'Pitaj',
            sessionChange: 'Promena sesije',
            tradeNow: 'Trguj sada',
            opens: 'Otvaranje',
            closes: 'Zatvaranje',
            at: 'u',
            open247: 'Otvoreno 24/7',
            today: 'danas',
            tomorrow: 'sutra',
            sunday: 'Nedelja',
            monday: 'Ponedeljak',
            tuesday: 'Utorak',
            wednesday: 'Sreda',
            thursday: 'Četvrtak',
            friday: 'Petak',
            saturday: 'Subota',
            contractSpecification: 'Specifikacije ugovora',
            symbol: 'Simbol',
            name: 'Naziv',
            market: 'Tržište',
            baseCurrency: 'Osnovna valuta',
            digits: 'Cifre',
            lotSize: 'Veličina lota',
            lotStep: 'Korak lota',
            minVolume: 'Min. obim',
            maxVolume: 'Maks. obim',
            leverage: 'Leveridž',
            commission: 'Provizija',
            swapLong: 'Zameni dugo',
            swapShort: 'Zameni kratko',
            schedule: 'Raspored',
            weekday: 'Radni dan',
            tradingTime: 'Vreme trgovanja',
            closed: 'zatvoreno',
            sell: 'Prodaj',
            buy: 'Kupi',
            low: 'Nisko',
            high: 'Visoko',
            oneHourChange: '1-satna promena',
            oneDayChange: '1-dnevna promena',
            oneWeekChange: '1-nedeljna promena',
            oneMonthChange: '1-mesečna promena',
            threeMonthsChange: '3-mesečna promena',
            oneYearChange: '1-godišnja promena',
            loading: 'Učitavanje...',
        },
        autoTrader: {
            days: 'days',
            title: 'Automatski trgovac',
            customTitle: 'Time period Autotrader',
            riskLevelLow: 'Nivo rizika: nizak',
            riskLevelMedium: 'Nivo rizika: srednji',
            riskLevelHigh: 'Nivo rizika: visok',
            perDay: 'Trgovanja po danu:',
            desc: `Trgovanje CFD-ovima i drugim proizvodima sa leveridžomom može dovesti do gubitaka. Pre trgovanja, klijenti treba da
                pročitaju relevantne izjave o riziku na našoj stranici za otkrivanje rizika. Automatsko trgovanje ne garantuje rezultate.
                 Kompanija ne prihvata odgovornost za gubitak sredstava u automatskom trgovanju. Uverite se da ste
                potpuno razumeli rizike i preduzeli mere za upravljanje rizicima.`,
            accept: 'Prihvati',
            save: 'Sačuvaj',
            cancel: 'Otkaži',
        },
        cardBox: {
            name: 'Ime',
            cardNumber: 'Broj kartice',
            expiration: 'Ističe (mm/gg)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Hi',
            trade: "Trgovanje",
            startTrading: 'Započni trgovanje',
            deposit: 'Depozit',
            dashboard: 'Komandna tabla',
            personalInfo: 'Lični podaci',
            contactInfo: 'Kontakt informacije',
            withdrawal: 'Povlačenje',
            verification: 'Verifikacija',
            accounts: 'Nalozi',
            liveChat: 'Čet uživo',
            savings: 'Uštede',
            settings: 'Podešavanja',
            logOut: 'Odjava',
        },
        fullScreenDeposit: {
            methods: 'METODE',
            confirm: 'Potvrdite u aplikaciji svoje banke',
            sendOtp: 'Pošalji otp',
            otpCode: 'Otp kod',
            addWalletAddress: 'Novčanik za ovaj metod depozita nije podešen. Molimo kontaktirajte svog agenta ili tim za podršku.',
            addressReceipt: 'Sačekajte da se adresa učita',
            makeDeposit: 'UPLATITE DEPOZIT',
            instant: 'Odmah',
            minutes: 'minuti',
            hours: 'sati',
            days: 'dani',
            amount: 'Iznos',
            continue: 'Nastavi',
            qrCode: 'QR KOD',
            depositAddress: 'ADRESA DEPOZITA',
            copy: 'Kopiraj',
            last: 'POSLEDNJI',
            deposits: 'DEPOZITI',
            time: 'Vreme',
            currency: 'Valuta',
            status: 'Status',
            info: 'Informacije',
        },
        fullScreenCcExt: {
            pleaseWait: 'Sačekajte. Povezivanje sa provajderom usluga plaćanja...',
            cardPayment: 'Plaćanje karticom',
            inOrder: 'Kako biste poslali iznos',
            pleaseProvide: 'obezbedite podatke sa kartice:'
        },
        fullScreenDashboard: {
            completeVerification: 'Završite verifikaciju',
            noOrderYet: 'Nema narudžbina još uvek',
            totalBalance: 'Ukupni saldo',
            totalPnl: 'Ukupni PNL',
            profitableOrders: 'Profitabilne narudžbine',
            roi: 'ROI',
            activityLog: 'Evidencija aktivnosti',
            loginFromIp: 'Prijava sa IP',
            tradingResults: 'Rezultati trgovanja',
            week: 'Nedelja',
            month: 'Mesec',
            year: 'Godina',
            successRate: 'Stopa uspešnosti',
            closedWithProfit: 'Zatvori sa profitom',
            closedWithLoss: 'Zatvori sa gubitkom',
            account: 'Račun',
            balance: 'Saldo',
            leverage: 'Leveridž',
            credit: 'Kredit',
            tradeNow: 'Trgujte sada',
            usingCurrentRate: 'koristeći trenutni kurs',
            totalDeposits: 'Ukupni depoziti'
        },
        fullScreenPersonal: {
            personalDetails: 'Lični podaci',
            profilePhoto: 'Profilna slika',
            firstName: 'Ime',
            country: 'Zemlja',
            lastName: 'Prezime',
            address: 'Adresa',
            email: 'E-mail',
            phone: 'Broj telefona',
            saveChanges: 'Sačuvaj promene',
            state: 'Grad',
            postcode: 'Poštanski broj',
            created: "Nalog kreiran",
        },
        fullScreenAvatar: {
            dropFile: 'spustite datoteku u krug iznad da biste je otpremili​',
            notAllowed: 'Nije dozvoljeno objavljivanje',
            notAllowedLine1: 'Fotografije eksplicitno seksualne ili pornografske prirode',
            notAllowedLine2: 'Slika koje imaju za cilj podsticanje etničke ili rasne mržnje ili agresije',
            notAllowedLine3: 'Fotografija koje uključuju osobe mlađe od 18 godina',
            notAllowedLine4: 'Fotografije zaštićene autorskim pravima trećih strana',
            notAllowedLine5: 'Slike veće od 5 MB i u formatu koji nije JPG, GIF ili PNG',
            requirements: 'Vaše lice mora biti jasno vidljivo na fotografiji. Sve fotografije i video snimci koje ste otpremili moraju biti u skladu sa ovim zahtevima, inače se mogu ukloniti.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Kontaktirajte svog menadžera naloga za detalje o povlačenju',
            cryptocurrency: 'Kripto-valuta',
            withdrawal: 'Povlačenje',
            requestNewWithdrawal: 'ZATRAŽITE NOVO POVLAČENJE',
            amount: 'Iznos',
            currentBalance: 'Vaš trenutni saldo na računu je',
            withdrawAll: 'Povuci sve',
            requestWithdrawal: 'Zatražite povlačenje',
            last: 'POSLEDNI',
            withdrawalRequests: 'ZAHTEVI ZA POVLAČENJE',
            time: 'Vreme',
            currency: 'Valuta',
            info: 'Info',
            status: 'Status',
            bank: 'Banka',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            card: 'Kartica',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Podaci o povlačenju',
            address: 'Adresa novčanika',
            bankAccount: 'Broj računa',
            bankHolder: 'Ime vlasnika računa',
            bankIban: 'IBAN',
            bankSwift: 'Bank SWIFT kod',
            recipientAddress: 'Adresa primaoca',
            routingNumber: 'Broj rute',
            bankBeneficiary: 'Ime banke korisnika',
            bankAddress: 'Adresa banke',
            bankCode: 'Bankarski kod',
            desc: 'Opis',
            cardNumber: 'Broj kartice',
            cardHolder: 'Ime vlasnika kartica',
        },
        fullScreenVerification: {
            dragAndDrop: 'prevucite i otpustite dokument u ovu oblast',
            documentsList: 'POPIS OTPREMLJENIH DOKUMENATA',
            document: 'Dokument',
            timeUploaded: 'Vreme otpremanja',
            timeProcessed: 'Vreme obrade',
            status: 'Status',
            types: ['Dokaz o ličnom dokumentu', 'Dokaz o prebivalištu', 'Prednja strana kreditne kartice', 'Povratak kreditne kartice', 'Dokaz o ličnom identitetu', 'Selfi'],
            types2: ['Dokaz o identifikaciji (POI)', 'Dokaz adrese stanovanja', 'Prednja strana kreditne kartice', 'Poleđina kreditne kartice', 'Dokaz o identifikaciji nazad', 'Selfi sa ličnom kartom'],
            upload: 'Otpremanje',
            isConfirmed: 'je potvrđeno',
            uploadAnotherDocument: 'Otpremite drugi dokument',
            isUnderReview: 'pregleda se',
            questionnaire: 'Upitnik',
            sendAnswers: 'Pošaljite odgovore',
            progressBarOf: '',
            progressBarText: 'da su vaši dokumenti otpremljeni i potvrđeni'
        },
        fullScreenAccounts: {
            manageAccounts: 'Prenesite sredstva između svojih naloga',
            transferFrom: 'Sa naloga',
            transferTo: 'Na nalog',
            amount: 'Iznos',
            makeTransfer: 'Prenesite',
            yourAccounts: 'Svoje naloge',
            account: 'Nalog',
            currency: 'Valuta',
            balance: 'Saldo',
            credit: 'Kredit',
            makeActive: 'Aktivirajte',
            active: 'Aktivno'
        },
        fullScreenSupport: {
            sendMessage: 'Pošalji poruku'
        },
        fullScreenSavings: {
            pct_0: 'Fleksibilno',
            pct_30: 'Zaključano 1 mesec',
            pct_90: 'Zaključano 3 meseca',
            pct_180: 'Zaključano 6 meseci',
            pct_365: 'Zaključano 1 godinu',
            savings: 'Uštede',
            detailedInformation: 'Detaljne informacije',
            pleaseSelect: 'Izaberite valutu i period',
            openSavings: 'Otvorene uštede',
            currentTime: 'Trenutno vreme',
            releaseTime: 'Vreme objavljivanja',
            currencyChosen: 'Izabrana valuta',
            periodChosen: 'Izabran period',
            yourRate: 'Vaša stopa',
            yourEarnings: 'Vaša zarada',
            accountFrom: 'Račun za prenos sredstava sa',
            enterAmount: 'Unesite iznos',
            in: 'U',
            openAndInvest: 'Otvorite štedni račun i investirajte',
            investment: 'Investicija',
            period: 'Period',
            daysTotal: 'Ukupno dana',
            finalEarnings: 'Krajnja zarada',
            created: 'Kreirano',
            daysElapsed: 'Protekli dani',
            days: 'dani',
            earnings: 'Zarade',
            fundsReleasedIn: 'Sredstva puštena u',
            claim: 'Potraživanje',
        },
        fullScreenSettings: {
            updatePassword: 'IZMENI LOZINKU',
            currentPassword: 'Trenutna lozinka',
            newPassword: 'Nova lozinka',
            changePassword: 'Izmeni lozinku',
            activationForGoogle: 'Aktivacioni kod za Google Authenticator',
            activateAndEnter: 'Aktivirajte Authenticator i unesite generisani kod u polje ispod',
            qrCode: 'QR kod',
            activateProtection: 'Aktivirajte 2FA zaštitu',
            protectionActive: '2FA zaštita je aktivna',
            twoFactorCode: '2FA kod',
            disableProtection: 'Onemogućite 2FA zaštitu',
            language: 'Jezik',
            dashboardCurrency: 'Valuta komandne table',
            confirmNewPassword: 'Potvrdite novu lozinku',
        },
        fullScreenReferrals: {
            yourLink: 'VAŠ REFERALNI LINK',
            referredFriends: 'REFERIRANI PRIJATELJI',
            id: 'ID',
            name: 'Ime',
            registered: 'Registracija',
            level: 'Nivo',
            payout: 'Isplata',
            lastPayouts: 'POSLEDNJE ISPLATE ZA REFERALE',
            time: 'Vreme',
            amount: 'Iznos',
            currency: 'Valuta',
            info: 'Info'
        },
        months: {
            Jan: 'Jan',
            Feb: 'Feb',
            Mar: 'Mar',
            Apr: 'Apr',
            May: 'Maj',
            Jun: 'Jun',
            Jul: 'Jul',
            Aug: 'Avg',
            Sep: 'Sep',
            Oct: 'Okt',
            Nov: 'Nov',
            Dec: 'Dec'
        },
        orderTypes: ['Buy', 'Sell', 'Buy Limit', 'Sell Limit', 'Buy Stop', 'Sell Stop'],
        statuses: ['Na čekanju', 'Odobreno', 'Odbijeno', 'Obrada'],
        errors: {
            NOT_FOUND: 'Zahtev za greškom',
            FORBIDDEN: 'Pristup odbijen. Ponovo se prijavite',
            SERVER_ERROR: 'Radnja neuspešna. Pokušajte ponovo kasnije',
            INVALID_ACCOUNT: 'Sesija je istekla. Ponovo se prijavite',
            INVALID_EMAIL: 'Nevažeća e-mail adresa',
            INVALID_PHONE: 'Nevažeći broj telefona',
            INVALID_COUNTRY: 'Nevažeća zemlja',
            INVALID_LOGIN: 'Nevažeći podaci za prijavu',
            USER_BLOCKED: 'Račun je blokiran. Molimo kontaktirajte podršku',
            USER_REGISTERED: 'Korisnik sa unetim e-mailom ili brojem telefona je već registrovan',
            INVALID_USER: 'Korisnik nije pronađen',
            REJECTED: 'Rad nije dozvoljen zbog ograničenja platforme',
            INVALID_OLD_PASSWORD: 'Stara lozinka je nevažeća',
            INVALID_SYMBOL: 'Nevažeći simbol za trgovanje',
            INVALID_SYMBOL_PRECISION: 'Nevažeći simbol',
            INVALID_FRAME: 'Nevažeći okvir grafikona',
            INVALID_CURRENCY: 'Nevažeća valuta',
            INVALID_PRODUCT: 'Nevažeći tip trgovanja na nalogu',
            INSUFFICIENT_FUNDS: 'Nedovoljno sredstava',
            INVALID_WITHDRAWAL: 'Povlačenje nije pronađeno',
            INVALID_STATUS: 'Nevažeći status',
            INVALID_FILE: 'Nevažeća ekstenzija datoteke ili je datoteka prevelika',
            INVALID_DOCUMENT: 'Dokument nije pronađen',
            INVALID_NAME: 'Nevažeće ime',
            INVALID_LOT_SIZE: 'Nevažeća veličina lota',
            INVALID_LOT_STEP: 'Nevažeći korak lota',
            INVALID_MIN_VOLUME: 'Nevažeći minimalni obim',
            INVALID_MAX_VOLUME: 'Nevažeći maksimalni obim',
            INVALID_GROUP: 'Nevažeća grupa',
            INVALID_SPREAD: 'Nevažeće širenje',
            REQUEST_TIMEOUT: 'Zahtev istekao. Pokušajte ponovo kasnije',
            EXISTING_OPEN_ORDERS:'Imate otvorenu narudžbu, prvo zatvorite narudžbu za ovog korisnika i pokušajte ponovo.',
            INVALID_TYPE: 'Nevažeći tip narudžbine',
            INVALID_VOLUME: 'Nevažeći obim narudžbine',
            INVALID_SL: 'Nevažeća vrednost sprečavanja gubitka',
            INVALID_TP: 'Nevažeća vrednost uzimanja profita',
            INVALID_PRICE: 'Nevažeća cena narudžbine',
            INVALID_EXPIRATION: 'Nevažeći istek porudžbine',
            NOT_ENOUGH_MARGIN: 'Nedovoljno marže',
            INVALID_ORDER: 'Narudžbina nije pronađena',
            MARKET_CLOSED: 'Trgovanje nije dostupno. Molim vas, pokušajte ponovo',
            INVALID_BALANCE_TYPE: 'Nevažeći tip operacije salda',
            INVALID_HASH: 'Nevažeći hash',
            HASH_EXPIRED: 'Vaš link za resetovanje lozinke je istekla. Zatražite novu',
            INVALID_CODE: 'Nevažeći 2FA kod',
            CHAT_DISABLED: 'Pristup ćaskanju podrške je zatvoren',
            WITHDRAWAL_NOT_ALLOWED: 'Pristup isplatama je zatvoren',
            TRADING_DISABLED: 'Trgovanje je onemogućeno za tekući račun',
            PENDING_DEPOSITS_LIMIT: 'Prekoračen je limit depozita',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Dozvoljeno je samo jedno povlačenje na čekanju',
            LOCAL_DEPOSIT_ONLY_REAL: 'Depozit je dostupan samo za pravi račun',
            LOCAL_DEPOSIT_POSITIVE: 'Iznos depozita mora biti veći od 0',
            LOCAL_INVALID_CARD_NUMBER: 'Nevažeći broj kartice',
            LOCAL_INVALID_CARD_EXPIRATION: 'Nevažeći istek kartice',
            LOCAL_INVALID_CVC: 'Nevažeći CVV/CVC kod',
            LOCAL_PASSWORDS_NOT_MATCH: 'Lozinke se ne podudaraju, unesite ponovo',
            LOCAL_NO_TRADING_ACCOUNTS: 'Nemate dostupnih trgovačkih računa. Molimo kontaktirajte podršku',
            LOCAL_MIN_DEPOSIT: 'Minimum depozit je $',
            LOCAL_MIN_WITHDRAWAL: 'Minimum iznos za povlačenje je $',
            LOCAL_INVALID_FILE_TYPE: 'Format datoteke nije podržan. Priložite samo JPG, PNG ili PDF',
            LOCAL_MIN_STAKING: 'Minimum depozit za ovu valutu je ',
            FLOOD_REJECTED: 'Prečesto šaljete poruke. Pokušajte ponovo kasnije'
        },
        countries: {
            AF: 'Avganistan',
            AX: 'Alandska Ostrva',
            AL: 'Albanija',
            DZ: 'Alžir',
            AS: 'Američka Samoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Ankila',
            AQ: 'Antarktik',
            AG: 'Antigva i Barbuda',
            AR: 'Argentina',
            AM: 'Jermenija',
            AW: 'Aruba',
            AU: 'Australija',
            AT: 'Austrija',
            AZ: 'Azerbejdžan',
            BS: 'Bahami',
            BH: 'Bahrein',
            BD: 'Bangladeš',
            BB: 'Barbados',
            BY: 'Belorusija',
            BE: 'Belgija',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Butan',
            BO: 'Bolivija',
            BA: 'Bosna i Hercegovina',
            BW: 'Bocvana',
            BV: 'Bouvet Ostrvo',
            BR: 'Brazil',
            IO: 'British Indian Ocean teritorija',
            BN: 'Brunei Darussalam',
            BG: 'Bugarska',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Kambodža',
            CM: 'Kamerun',
            CA: 'Kanada',
            CV: 'Cape Verde',
            KY: 'Kajmanska Ostrva',
            CF: 'Centralno-afrička Republika',
            TD: 'Čad',
            CL: 'Čile',
            CN: 'Kina',
            CX: 'Božićna Ostrva',
            CC: 'Koko (Keeling) Ostrva',
            CO: 'Kolumbija',
            KM: 'Komoros',
            CG: 'Kongo',
            CD: 'Kongo, Demokratska Republika',
            CK: 'Kukova Ostrva',
            CR: 'Kostarika',
            CI: 'Obala slonovače',
            HR: 'Hrvatska',
            CU: 'Kuba',
            CY: 'Kipar',
            CZ: 'Češka Republika',
            DK: 'Danska',
            DJ: 'Džibuti',
            DM: 'Dominika',
            DO: 'Dominikanska Republika',
            EC: 'Ekvador',
            EG: 'Egipat',
            SV: 'El Salvador',
            GQ: 'Ekvatorijalna Gvineja',
            ER: 'Eritreja',
            EE: 'Estonija',
            ET: 'Etiopija',
            FK: 'Folklandska Ostrva (Malvinas)',
            FO: 'Farska Ostrva',
            FJ: 'Fidži',
            FI: 'Finska',
            FR: 'Francuska',
            GF: 'Francuska Gvajana',
            PF: 'Francuska Polinezija',
            TF: 'Francuske Južne Teritorije',
            GA: 'Gabon',
            GM: 'Gambija',
            GE: 'Gruzija',
            DE: 'Nemačka',
            GH: 'Gana',
            GI: 'Gibraltar',
            GR: 'Grčka',
            GL: 'Grenland',
            GD: 'Grenada',
            GP: 'Gvadalupe',
            GU: 'Guam',
            GT: 'Gvatemala',
            GG: 'Gernzi',
            GN: 'Gvineja',
            GW: 'Gvineja-Bisao',
            GY: 'Gvajana',
            HT: 'Haiti',
            HM: 'Ostrvo Herd i Mekdonald Ostrva',
            VA: 'Vatikan',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Mađarska',
            IS: 'Island',
            IN: 'Indija',
            ID: 'Indonezija',
            IR: 'Iran',
            IQ: 'Irak',
            IE: 'Irska',
            IM: 'Ostrvo Čoveka',
            IL: 'Izrael',
            IT: 'Italija',
            JM: 'Jamajka',
            JP: 'Japan',
            JE: 'Džerzi',
            JO: 'Jordan',
            KZ: 'Kazakhstan',
            KE: 'Kenija',
            KI: 'Kiribati',
            KR: 'Koreja',
            KW: 'Kuvajt',
            KG: 'Kirgistan',
            LA: 'Lao Narodna Demokratska Republika',
            LV: 'Letonija',
            LB: 'Libanon',
            LS: 'Lesoto',
            LR: 'Liberija',
            LY: 'Libija',
            LI: 'Lihtenštajn',
            LT: 'Litvanija',
            LU: 'Luksemburg',
            MO: 'Makao',
            MK: 'Makedonija',
            MG: 'Madagaskar',
            MW: 'Malavi',
            MY: 'Malezija',
            MV: 'Maldivi',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Maršalska Ostrva',
            MQ: 'Martinik',
            MR: 'Mauritanija',
            MU: 'Mauricijus',
            YT: 'Majote',
            MX: 'Meksiko',
            FM: 'Mikronezija',
            MD: 'Moldavija',
            MC: 'Monako',
            MN: 'Mongolija',
            ME: 'Crna gora',
            MS: 'Montserat',
            MA: 'Maroko',
            MZ: 'Mozambik',
            MM: 'Mjanmar',
            NA: 'Namibija',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Nizozemska',
            AN: 'Holandski antili',
            NC: 'Nova Kaledonija',
            NZ: 'Novi Zeleand',
            NI: 'Nikaragva',
            NE: 'Niger',
            NG: 'Nigerija',
            NU: 'Niue',
            NF: 'Norfolk Ostrva',
            MP: 'Severna Marijanska Ostrva',
            NO: 'Norveška',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Palestinska Teritorija, okupirana',
            PA: 'Panama',
            PG: 'Papua Nova Gvineja',
            PY: 'Paragvaj',
            PE: 'Peru',
            PH: 'Filipini',
            PN: 'Pitcairn',
            PL: 'Poljska',
            PT: 'Portugal',
            PR: 'Portoriko',
            QA: 'Katar',
            RE: 'Reunion',
            RO: 'Rumunija',
            RU: 'Rusija',
            RW: 'Ruanda',
            BL: 'Sveti Bartelemi,',
            SH: 'Sveta Jelena',
            KN: 'Sveti Kits i Nevis',
            LC: 'Sveta Lucija',
            MF: 'Sveti Martin',
            PM: 'Sveti Pjer i Mikelon',
            VC: 'Sveti vinsent i Grenadini',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tome i Principe',
            SA: 'Saudijska Arabija',
            SN: 'Senegal',
            RS: 'Srbija',
            SC: 'Sejšeli',
            SL: 'Sijera Leone',
            SG: 'Singapor',
            SK: 'Slovačka',
            SI: 'Slovenija',
            SB: 'Solomonska Ostrva',
            SO: 'Somalija',
            ZA: 'Južna Afrika',
            GS: 'Južna Džordžija i Sendvič Ostrvo',
            ES: 'Španija',
            LK: 'Sri Lanka',
            SD: 'Sudan',
            SR: 'Surinam',
            SJ: 'Svalbard i Jan Majen',
            SZ: 'Svazilend',
            SE: 'Švedska',
            CH: 'Švajcarska',
            SY: 'Sirijska Arapska Republika',
            TW: 'Tajvan',
            TJ: 'Tadžikistan',
            TZ: 'Tanzanija',
            TH: 'Tajland',
            TL: 'Timor-Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad i Tobago',
            TN: 'Tunis',
            TR: 'Turska',
            TM: 'Turkmenistan',
            TC: 'Ostrva Turks i Kaikos',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukrajina',
            AE: 'Ujedinjeni Arapski Emirati',
            GB: 'Ujedinjeno Kraljevstvo',
            US: 'Sjedinjenje Države',
            UM: 'Spoljna ostrva Sjedinjenih Država',
            UY: 'Urugvaj',
            UZ: 'Uzbekistan',
            VU: 'Vanuatu',
            VE: 'Venecuela',
            VN: 'Vijetnam',
            VG: 'Devičanska Ostrva, Britanska',
            VI: 'Devičanska Ostrva, SAD',
            WF: 'Valis i Futuna',
            EH: 'Zapadna Sahara',
            YE: 'Jemen',
            ZM: 'Zambija',
            ZW: 'Zimbabve'
        },
    },
    pt: {
        _name: 'Português',
        timePickerModal: {
            title: 'Perto no Tempo'
        },
        autoCloseModal: {
            takeProfitStopLoss: 'Take Profit e Stop Loss',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            updateOrder: 'Ordem de Atualização',
        },
        balanceModal: {
            balance: 'Saldo',
            credit: 'Crédito',
            equity: 'Patrimônio líquido',
            practice: 'PRÁTICA',
            real: 'REAL',
            account: 'CONTA',
            invested: 'Investido',
            profit: 'Lucro',
            loss: 'Perda',
            margin: 'Margem',
            marginLevel: 'Nível de Margem',
            marginFree: 'Margem Livre'
        },
        header: {
            cfd: 'CFD',
            platform: 'Plataforma',
            deposit: 'Depósito'
        },
        sideMenu: {
            marketWatch: 'Observação do Mercado',
            activeOrders: 'Ordens Ativas',
            tradingHistory: 'Histórico de Negociação',
            economicCalendar: 'Calendário Econômico',
            marketNews: 'Notícias do Mercado'
        },
        closePositionModal: {
            closeOrder: 'Fechar Posição',
            areYouSure: 'Tem certeza de que fechará a posição',
            buy: 'Comprar',
            sell: 'Vender',
            yes: 'Sim',
            no: 'Não',
        },
        pciDssModal: {
            desc: `A conformidade com o Padrão de Segurança de Dados do Setor de Cartões de Pagamento (PCI DSS) é exigida de todas as entidades que armazenam, processam ou transmitem dados de titulares de cartões Visa/Master Card/Stripe, 
            incluindo instituições financeiras, comerciantes e provedores de serviços.
            A TI é o padrão de segurança global para todas as entidades que armazenam, processam ou transmitem dados de titulares de cartões e/ou dados de autenticação confidenciais. O PCI DSS define um nível básico de proteção para os consumidores e ajuda a reduzir fraudes e violações de dados em todo o ecossistema de pagamentos.`
        },
        chart: {
            addNewChart: 'Adicionar Novo Gráfico',
        },
        symbolsModal: {
            watchlist: 'Lista de Observação',
            asset: 'Ativo',
            price: 'Preço',
            changePct: 'Mudança 24h'
        },
        pendingModal: {
            pending: 'Pendente',
            assetPrice: 'Preço do Ativo',
            current: 'Atual',
            revert: 'Reverter para o Preço de Mercado',
            automatically: 'A posição será aberta automaticamente quando o preço atingir esse nível'
        },
        orderMenu: {
            default: 'padrão',
            volume: 'Volume',
            lots: 'lotes',
            units: 'unidades',
            currency: 'moeda',
            contractSize: 'Tamanho do Contrato',
            position: 'Posição',
            margin: 'Margem',
            freeMargin: 'Margem Livre',
            takeProfitStopLoss: 'Take Profit e Stop Loss',
            pending: 'Pendente',
            market: 'Mercado',
            leverage: 'Alavancagem',
            spread: 'Spread',
            notSet: 'Não definido',
            at: 'na',
            buy: 'compra',
            sell: 'venda',
        },
        footer: {
            supportBanner: 'TODOS OS DIAS, 24 HORAS POR DIA',
            currentTime: 'HORA ATUAL',
            liveChat: 'Bate-papo ao Vivo'
        },
        statusBar: {
            balance: 'Saldo',
            credit: 'Crédito',
            equity: 'Patrimônio líquido',
            margin: 'Margem',
            marginLevel: 'Nível da margem',
            freeMargin: 'Margem livre',
            pnl: 'PnL',
            profitTotal: 'PnL vitalícia'
        },
        accountModal: {
            dateRegistered: 'Fecha de Registro',
            userId: 'ID do Usuário',
            dashboard: 'Painel',
            personalData: 'Dados Pessoais',
            deposit: 'Depósito',
            withdrawFunds: 'Retirada de Fundos',
            savings: 'Poupança',
            settings: 'Configurações',
            logout: 'Logout'
        },
        depositModal: {
            BT_INVOICE: 'Insira o valor e gere a fatura',
            BT_TITLE: 'Nossos dados bancários',
            account: 'Conta',
            bank_name: 'Nome do Banco',
            beneficiary: 'Beneficiário',
            beneficiary_address: 'Endereço do Beneficiário',
            swift: 'Swift',
            reference: 'Reference',
            iban: 'IBAN',
            ru_inn: 'INN',
            ru_kpp: 'KPP',
            corresponding_account: 'Conta do correspondente',
            TOKEN_AMOUNT: 'Montante',
            TOKEN_SUBTOTAL: 'Subtotal',
            TOKEN_TOTAL: 'Total',
            TOKEN_DESCRIPTION: 'Descrição',
            TOKEN_PAYMENT_DETAILS: 'Detalhes do Pagamento',
            PENDING_DEPOSITS_LIMIT: 'Limite de Depósito',
            makeDeposit: 'Fazer um Depósito',
            practiceAccount: 'Conta de Prática',
            realAccount: 'Conta Real',
            noWithdrawals: 'Sem saques',
            easyWithdrawals: 'Retiradas fáceis',
            allAssets: 'Todos os ativos disponíveis',
            fullFledged: 'Plataforma completa',
            fillUpTo: 'Preencher até',
            freeReplenishment: 'Reabastecimento gratuito',
            topUp: 'Recarregue sua Conta',
            minimumAmount: 'Valor mínimo',
            canSwitch: 'Você pode alternar entre suas contas a qualquer momento'
        },
        gridsModal: {
            chartGrids: 'GRADES DE GRÁFICOS',
            chart1: '1 gráfico',
            chart2: '2 gráfico',
            chart3: '3 gráfico',
            chart4: '4 gráfico',
        },
        noConnectionModal: {
            connectionLost: 'A conexão com o servidor é perdida',
            retryNow: 'Tentar Novamente Agora',
        },
        loginModal: {
            loginToTradeRoom: 'Login na Sala de Negociação',
            email: 'Endereço de e-mail',
            enterEmail: 'Digite seu e-mail',
            twoFactor: 'Código 2FA (se habilitado)',
            twoFactorAuth: 'Autenticação de dois fatores',
            password: 'Senha',
            yourPassword: 'Sua senha',
            signIn: 'Entrar',
            dontHaveAccount: 'Não tem uma conta?',
            restore: 'Restaurar',
            fullName: 'Nome Completo',
            havePromo: 'Tenho um código promocional',
            promo: 'Código promocional',
            login: 'Login',
            haveAccount: 'Já tem uma conta?',
            hide: 'Esconder',
            goTo: 'Ir para trás'
        },
        newPasswordModal: {
            setNewPassword: 'Definir Nova Senha',
            newPassword: 'Nova senha',
            confirmNewPassword: 'Confirmar a nova senha',
            setButton: 'Conjunto'
        },
        registerModal: {
            repeatPassword: 'Repetir Senha',
            createNewAccount: 'Criar uma Nova Conta',
            email: 'Endereço de e-mail',
            firstName: 'Primeiro Nome',
            lastName: 'Sobrenome',
            country: 'País',
            phone: 'Telefone',
            password: 'Senha',
            createAccount: 'Criar Conta',
            currency: 'Moeda',
            privacyAndPolicy: "Privacidade e Política",
            customerAgreement: 'Acordo com o cliente',

        },
        forgotPasswordModal: {
            forgotPassword: `Esqueceu sua senha?`,
            submitEmail: 'Envie um e-mail usado para registro, verifique sua caixa de entrada e siga as instruções fornecidas',
            submit: 'Enviar'
        },
        notificações: {
            error: 'Erro',
            success: 'Sucesso',
            depósito: 'Depósito',
            withdrawal: 'Retirada',
            depositApproved: 'O depósito foi aprovado',
            depositDeclined: 'O depósito foi recusado',
            withdrawalApproved: 'A retirada foi aprovada',
            withdrawalDeclined: 'A retirada foi recusada'
        },
        markets: {
            forex: 'Forex',
            stocks: 'Ações',
            commodities: 'Mercadorias',
            indices: 'Índices',
            crypto: 'Cripto',
            metals: 'Metais',
            nft: 'NFT'
        },
        profitCalculatorModal: {
            buy: 'Comprar',
            profitCalculator: 'Calculadora de lucro',
            sell: 'Vender',
            volume: 'Volume',
            entryPrice: 'Preço de entrada',
            takeProfit: 'Take Profit',
            stopLoss: 'Stop Loss',
            maxPosition: 'Posição máxima',
            calculate: 'Calcular',
            calculationsFor: 'Cálculos para',
            leverage: 'Alavancagem',
            requiredMargin: 'Margem necessária',
            profitFromTP: 'Lucro do TP',
            lossFromSL: 'Perda de SL',
            roe: 'ROE',
        },
        depositSuccessModal: {
            title: 'Depósito bem-sucedido',
            text: 'Seu depósito foi processado com sucesso!',
        },
        depositFailModal: {
            title: 'Depósito falhou',
            text: 'Seu depósito não foi processado',
        },
        widgetMarketWatch: {
            marketWatch: 'Observação do mercado',
            search: 'Pesquisar...',
        },
        widgetOrders: {
            commission: 'Comissão',
            volume: 'Volume',
            margin: 'Margem',
            active: `Ativo`,
            pending: `Pendente`,
            activeOrders: 'Ordens ativas',
            portfolio: 'Portfólio',
            allPositions: 'Todas as posições',
            noPositionsLine1: 'Você não tem nenhuma aberta',
            noPositionsLine2: 'posições ainda',
            show: 'Mostrar',
            more: 'mais',
            less: 'menos',
            purchaseTime: 'Tempo de compra',
            closePosition: 'Posição de fechamento',
            priceOpen: 'Preço de compra',
            priceSl: 'Preço de Stop Loss',
            priceTp: `Preço de Take Profit`,
            type: `Direção da posição`,
            pnl: 'Lucro/Perda',
            cancelOrder: 'Cancelar ordem',
            orderType: 'Tipo de ordem',
            cancelled: 'Cancelado',
            tradingHistory: 'Histórico de negociação',
            noHistoryLine1: 'Você não tem nenhum',
            noHistoryLine2: 'negócios fechados ainda',
        },
        widgetEconomicCalendar: {
            economicCalendar: 'Calendário Econômico'
        },
        widgetMarketNews: {
            marketNews: 'Notícias do mercado'
        },
        ordersPane: {
            closeInTime: 'Fechar no tempo',
            timedOrder: 'Ordem Temporizada',
            ordersActive: 'Ordens ativas',
            ordersHistory: 'Histórico de pedidos',
            id: 'ID',
            symbol: 'Símbolo',
            type: 'Tipo',
            volume: 'Volume',
            openPrice: 'Preço de abertura',
            openTime: 'Hora de abertura',
            closePrice: 'Preço de fechamento',
            closeTime: 'Hora de fechamento',
            sl: 'SL',
            tp: 'TP',
            price: 'Preço',
            pnl: 'PnL',
            actions: 'Ações',
            edit: 'Editar',
            close: 'Fechar',
            commission: 'Comissão',
            swap: 'Trocar',
            swapLong: 'Troca longa',
            swapShort: 'Troca curta'
        },
        assetInfoModal: {
            assetInfo: 'Informações sobre o ativo',
            tradingConditions: 'Condições de negociação',
            information: 'Mudança de sessão',
            bid: 'Lance',
            ask: 'Pergunte',
            sessionChange: 'Mudança de sessão',
            tradeNow: 'Negociar agora',
            opens: 'Abre',
            closes: 'Fecha',
            at: 'no',
            open247: 'Aberto 24/7',
            today: 'hoje',
            tomorrow: 'amanhã',
            sunday: 'Domingo',
            monday: 'Segunda-feira',
            tuesday: 'terça-feira',
            wednesday: 'Quarta-feira',
            thursday: 'Quinta-feira',
            friday: 'sexta-feira',
            saturday: 'Sábado',
            contractSpecification: 'Especificação do contrato',
            symbol: 'Símbolo',
            name: 'Nome',
            market: 'Mercado',
            baseCurrency: 'Moeda base',
            digits: 'Dígitos',
            lotSize: 'Tamanho do lote',
            lotStep: 'Etapa do lote',
            minVolume: 'Volume mínimo',
            maxVolume: 'Volume máximo',
            leverage: 'Alavancagem',
            commission: 'Comissão',
            swapLong: 'Troca longa',
            swapShort: 'Troca curta',
            schedule: 'Agenda',
            weekday: 'Dia da semana',
            tradingTime: 'Horário de negociação',
            closed: 'fechado',
            sell: 'Vender',
            buy: 'Comprar',
            low: 'Baixo',
            high: 'Alto',
            oneHourChange: 'Mudança de 1 hora',
            oneDayChange: 'Mudança de 1 dia',
            oneWeekChange: 'Mudança de 1 semana',
            oneMonthChange: 'Mudança de 1 mês',
            threeMonthsChange: 'Mudança de 3 meses',
            oneYearChange: 'Mudança de 1 ano',
            loading: 'Carregando...',
        },
        autoTrader: {
            dias: 'dias',
            título: 'Negociador Automático',
            customTitle: 'Período de tempo Negociador automático',
            riskLevelLow: 'Nível de Risco: baixo',
            riskLevelMedium: 'Nível de Risco: Médio',
            riskLevelHigh: 'Nível de Risco: alto',
            perDay: 'Negociações por dia:',
            desc: `A negociação de CFDs e outros produtos alavancados pode levar a perdas. Antes de negociar, os clientes devem
                ler as
                declarações de risco relevantes em nossa página de Divulgação de Risco. A negociação automatizada não garante resultados. A
                empresa não aceita nenhuma responsabilidade pela perda de fundos em negociações automáticas. Por favor, certifique-se de que você
                compreenda totalmente os riscos e tome medidas para gerenciá-los.`,
            accept: 'Aceitar',
            save: 'Salvar',
            cancel: 'Cancelar',
        },
        cardBox: {
            name: 'Nome',
            cardNumber: 'Número do cartão',
            expiration: 'Expiração (mm/aa)',
            securityCode: 'CVV'
        },
        fullScreen: {
            hi: 'Olá',
            trade: 'Negociar',
            startTrading: 'Iniciar negociação',
            deposit: 'Depósito',
            dashboard: 'Painel',
            personalInfo: 'Informações Pessoais',
            contactInfo: 'Informações de Contato',
            withdrawal: 'Levantamento',
            verification: 'Verificação',
            accounts: 'Contas',
            liveChat: 'Chat',
            savings: 'Poupança',
            settings: 'Configurações',
            logOut: 'Sair',
        },
        fullScreenDeposit: {
            methods: 'MÉTODOS',
            confirm: 'Confirme no aplicativo do seu banco',
            sendOtp: 'Enviar otp',
            otpCode: 'Código otp',
            addWalletAddress: 'A carteira para esse método de depósito não está definida. Por favor, entre em contato com seu agente ou equipe de suporte.',
            addressReceipt: 'Por favor, aguarde o carregamento do endereço',
            makeDeposit: 'FAZER UM DEPÓSITO',
            instant: 'Instantâneo',
            minutes: 'minutos',
            hours: 'horas',
            dias: 'dias',
            amount: 'Valor',
            continue: 'Continuar',
            qrCode: 'CÓDIGO QR',
            depositAddress: 'ENDEREÇO DO DEPÓSITO',
            copy: 'Copiar',
            last: 'ÚLTIMO',
            deposits: 'DEPÓSITOS',
            time: 'Hora',
            currency: 'Moeda',
            status: 'Status',
            info: 'Informações',
        },
        fullScreenCcExt: {
            pleaseWait: 'Aguarde, por favor. Conectando-se ao provedor de serviços de pagamento...',
            cardPayment: 'Pagamento com cartão',
            inOrder: 'A fim de enviar o valor',
            pleaseProvide: 'forneça os detalhes do cartão:'
        },
        fullScreenDashboard: {
            completeVerification: 'Por favor, complete a verificação',
            noOrderYet: 'Nenhum pedido ainda',
            totalBalance: `Saldo total`,
            totalPnl: 'Total PNL',
            profitableOrders: 'Pedidos lucrativos',
            roi: 'ROI',
            activityLog: 'Registro de atividade',
            loginFromIp: 'Login a partir do IP',
            tradingResults: 'Resultados da negociação',
            week: 'Semana',
            month: 'Mês',
            year: 'Ano',
            successRate: 'Taxa de sucesso',
            closedWithProfit: 'Fechado com lucro',
            closedWithLoss: 'Fechado com prejuízo',
            account: 'Conta',
            balance: 'Saldo',
            leverage: 'Alavancagem',
            crédito: 'Crédito',
            tradeNow: 'Negocie agora',
            usingCurrentRate: 'usando a taxa de câmbio atual',
            totalDeposits: 'Depósitos Totais'
        },
        fullScreenPersonal: {
            personalDetails: 'Detalhes Pessoais',
            profilePhoto: 'Foto do Perfil',
            firstName: 'Primeiro Nome',
            country: 'País',
            lastName: 'Sobrenome',
            address: 'Endereço',
            email: 'E-mail',
            phone: 'Número de telefone',
            saveChanges: 'Salvar alterações',
            state: 'Cidade',
            postcode: 'Código postal',
            created: 'Conta criada em',
        },
        fullScreenAvatar: {
            dropFile: 'Solte um arquivo no círculo acima para fazer o upload',
            notAllowed: 'Não é permitido publicar',
            notAllowedLine1: 'Fotos de natureza explicitamente sexual ou pornográfica',
            notAllowedLine2: 'Imagens destinadas a incitar o ódio ou a agressão étnica ou racial',
            notAllowedLine3: 'Fotos envolvendo pessoas com menos de 18 anos de idade',
            notAllowedLine4: 'Fotos protegidas por direitos autorais de terceiros',
            notAllowedLine5: 'Imagens com mais de 5 MB e em um formato diferente de JPG, GIF ou PNG',
            requirements: 'Seu rosto deve estar claramente visível na foto. Todas as fotos e vídeos carregados por você devem atender a esses requisitos, caso contrário, poderão ser removidos.'
        },
        fullScreenWithdrawal: {
            withdrawalOff: 'Entre em contato com o gerente da sua conta para obter detalhes sobre saques',
            cryptocurrency: 'Criptomoeda',
            withdrawal: 'Retirada',
            requestNewWithdrawal: 'PEDIDO DE LEVANTAMENTO',
            amount: 'Valor',
            currentBalance: 'O saldo de sua conta corrente é',
            withdrawAll: 'Retirar tudo',
            requestWithdrawal: 'Solicitar retirada',
            last: 'ÚLTIMO',
            withdrawalRequests: 'SOLICITAÇÕES DE RETIRADA',
            time: 'Hora',
            currency: 'Moeda',
            info: 'Informações',
            status: 'Status',
            bank: 'Banco',
            bitcoin: 'Bitcoin',
            zelle: 'Zelle',
            card: 'Cartão',
            ethereum: 'Ethereum',
            eth: 'Ethereum',
            usdt: 'USDT',
            matic: 'Matic',
            withdrawalDetails: 'Detalhes do levantamento',
            address: 'Endereço da Carteira',
            bankAccount: 'Número da conta',
            bankHolder: 'Nome do Titular da Conta',
            bankIban: 'IBAN',
            bankSwift: 'Código SWIFT do Banco',
            recipientAddress: 'Endereço do destinatário',
            routingNumber: 'Número de roteamento',
            bankBeneficiary: 'Nome do banco do beneficiário',
            bankAddress: 'Endereço do banco',
            bankCode: 'Código do banco',
            desc: 'Descrição',
            cardNumber: 'Número do Cartão',
            cardHolder: 'Nome do Titular do Cartão',
        },
        fullScreenVerification: {
            dragAndDrop: 'arraste e solte o documento nessa área',
            documentsList: 'LISTA DE DOCUMENTOS CARREGADOS',
            document: 'Documento',
            timeUploaded: 'Tempo Carregado',
            timeProcessed: 'Tempo Processado',
            status: 'Status',
            types: ['Comprovante de Identidade', 'Comprovante de Residência', 'Cartão de Crédito Frente', 'Cartão de Crédito Verso', 'Comprovante de Identidade Verso', 'Selfie'],
            types2: ['Comprovante de identificação (POI)', 'Comprovante de endereço residencial', 'Frente do Cartão de Crédito', 'Verso do Cartão de Crédito', 'Verso do Comprovante de Identificação', 'Selfie com ID'],
            upload: 'Upload',
            isConfirmed: 'está confirmado',
            uploadAnotherDocument: 'Fazer upload de outro documento',
            isUnderReview: 'está sendo revisado',
            questionnaire: 'Questionário',
            sendAnswers: 'Enviar respostas',
            progressBarOf: 'de',
            progressBarText: 'de seus documentos foram carregados e confirmados'
        },
        fullScreenAccounts: {
            manageAccounts: 'Transferir fundos entre suas contas',
            transferFrom: 'Da conta',
            transferTo: `Para a conta`,
            amount: 'Valor',
            makeTransfer: 'Fazer transferência',
            yourAccounts: 'Suas contas',
            account: 'Conta',
            currency: 'Moeda',
            balance: 'Saldo',
            credit: 'Crédito',
            makeActive: 'Tornar Ativo',
            active: 'Ativo'
        },
        fullScreenSupport: {
            sendMessage: 'Enviar Mensagem'
        },
        fullScreenSavings: {
            pct_0: 'Flexível',
            pct_30: 'Bloqueado 1 mês',
            pct_90: 'Bloqueado por 1 trimestre',
            pct_180: `Bloqueado 6 meses`,
            pct_365: 'Bloqueado 1 ano',
            savings: 'Poupança',
            detailedInformation: 'Informações Detalhadas',
            pleaseSelect: 'Por favor, selecione uma moeda e um período',
            openSavings: 'Poupança aberta',
            currentTime: 'Hora atual',
            releaseTime: 'Hora da liberação',
            currencyChosen: 'Moeda escolhida',
            periodChosen: 'Período escolhido',
            yourRate: 'Sua taxa',
            yourEarnings: 'Seus ganhos',
            accountFrom: 'Conta da qual transferir fundos',
            enterAmount: 'Digite o Valor',
            in: 'Em',
            openAndInvest: 'Abrir Conta de Poupança e investir',
            investment: 'Investimento',
            period: 'Período',
            daysTotal: 'Total de dias',
            finalEarnings: 'Ganhos finais',
            created: 'Criado',
            daysElapsed: 'Dias decorridos',
            days: 'dias',
            earnings: 'Ganhos',
            fundsReleasedIn: 'Fundos liberados em',
            claim: 'Reivindicação',
        },
        fullScreenSettings: {
            updatePassword: 'ATUALIZAR SENHA',
            currentPassword: 'Senha Atual',
            newPassword: 'Nova Senha',
            changePassword: 'Alterar Senha',
            activationForGoogle: 'Código de ativação do Google Authenticator',
            activateAndEnter: 'Ative o Authenticator(autenticador) e digite um código gerado no campo abaixo',
            qrCode: 'Código QR',
            activateProtection: 'Ativar Proteção 2FA',
            protectionActive: 'A proteção 2FA está ativa',
            twoFactorCode: 'Código 2FA',
            disableProtection: 'Desativar a Proteção 2FA',
            language: 'Idioma',
            dashboardCurrency: 'Moeda do painel',
            confirmNewPassword: 'Confirmar nova senha',
        },
        fullScreenReferrals: {
            yourLink: 'SEU LINK DE REFERÊNCIA',
            referredFriends: 'REFERRED FRIENDS',
            id: 'ID',
            name: 'Nome',
            registered: 'Registrar',
            nível: 'Nível',
            payout: 'Pagamento',
            lastPayouts: 'ÚLTIMOS PAGAMENTOS DE REFERÊNCIA',
            time: 'Hora',
            amount: 'Valor',
            currency: 'Moeda',
            info: 'Informações'
        },
        months: {
            Jan: 'Jan',
            Feb: 'Fev',
            Mar: 'Mar',
            Apr: 'Abr',
            May: 'Maio',
            Jun: 'Junho',
            Jul: 'Julho',
            Aug: 'Ago',
            Sep: 'Set',
            Oct: 'Out',
            Nov: 'Nov',
            Dec: 'Dez'
        },
        orderTypes: ['Comprar', 'Vender', 'Limite de Compra', 'Limite de Venda', 'Parada de Compra', 'Parada de Venda'],
        statuses: ['Pendente', 'Aprovado', 'Recusado', 'Processando'],
        erros: {
            NOT_FOUND: 'Erro de solicitação',
            FORBIDDEN: 'Acesso negado. Favor refazer o login',
            SERVER_ERROR: 'A ação falhou. Por favor, tente novamente mais tarde',
            INVALID_ACCOUNT: 'A sessão expirou. Favor refazer o login',
            INVALID_EMAIL: 'Endereço de e-mail inválido',
            INVALID_PHONE: 'Número de telefone inválido',
            INVALID_COUNTRY: 'País inválido',
            INVALID_LOGIN: 'Credenciais de login inválidas',
            USER_BLOCKED: 'A conta está bloqueada. Por favor, entre em contato com o suporte',
            USER_REGISTERED: 'O usuário com o e-mail ou número de telefone inserido já está registrado',
            INVALID_USER: `O usuário não foi encontrado`,
            REJECTED: 'A operação não é permitida devido a restrições de plataforma',
            INVALID_OLD_PASSWORD: 'A senha antiga é inválida',
            INVALID_SYMBOL: 'Símbolo de negociação inválido',
            INVALID_SYMBOL_PRECISION: 'Precisão de símbolo inválida',
            INVALID_FRAME: 'Quadro de gráfico inválido',
            INVALID_CURRENCY: 'Moeda inválida',
            INVALID_PRODUCT: 'Tipo de negociação de conta inválido',
            INSUFFICIENT_FUNDS: 'Fundos insuficientes',
            INVALID_WITHDRAWAL: 'A retirada não foi encontrada',
            INVALID_STATUS: 'Status inválido',
            INVALID_FILE: 'Extensão de arquivo inválida ou o arquivo é muito grande',
            INVALID_DOCUMENT: 'O documento não foi encontrado',
            INVALID_NAME: 'Nome inválido',
            INVALID_LOT_SIZE: 'Tamanho do lote inválido',
            INVALID_LOT_STEP: 'Etapa do lote inválida',
            INVALID_MIN_VOLUME: 'Volume mínimo inválido',
            INVALID_MAX_VOLUME: 'Volume máximo inválido',
            INVALID_GROUP: 'Grupo inválido',
            INVALID_SPREAD: 'Propagação inválida',
            REQUEST_TIMEOUT: 'A solicitação expirou. Por favor, tente novamente mais tarde',
            EXISTING_OPEN_ORDERS:'Você tem um pedido em aberto, primeiro feche o pedido para este usuário e tente novamente.',
            INVALID_TYPE: 'Tipo de pedido inválido',
            INVALID_VOLUME: 'Volume de ordem inválido',
            INVALID_SL: 'Valor de stop loss inválido',
            INVALID_TP: 'Valor de take profit inválido',
            INVALID_PRICE: 'Preço do pedido inválido',
            INVALID_EXPIRATION: 'Expiração inválida da ordem',
            NOT_ENOUGH_MARGIN: 'Margem insuficiente',
            INVALID_ORDER: 'A ordem não foi encontrada',
            MARKET_CLOSED: 'A negociação não está disponível. Por favor, tente novamente',
            INVALID_BALANCE_TYPE: 'Tipo inválido de operação de saldo',
            INVALID_HASH: 'Hash inválido',
            HASH_EXPIRED: 'Seu link de redefinição de senha expirou. Por favor, solicite um novo',
            INVALID_CODE: 'Código 2FA inválido',
            CHAT_DISABLED: 'O acesso ao bate-papo de suporte foi encerrado',
            WITHDRAWAL_NOT_ALLOWED: 'O acesso às retiradas foi fechado',
            TRADING_DISABLED: 'A negociação está desativada para a conta atual',
            PENDING_DEPOSITS_LIMIT: 'Limite de depósitos excedido',
            LOCAL_ONE_PENDING_WITHDRAWAL: 'Somente um saque pendente é permitido',
            LOCAL_DEPOSIT_ONLY_REAL: 'O depósito está disponível somente para contas reais',
            LOCAL_DEPOSIT_POSITIVE: 'O valor do depósito deve ser maior que 0',
            LOCAL_INVALID_CARD_NUMBER: 'Número de cartão inválido',
            LOCAL_INVALID_CARD_EXPIRATION: 'Validade do cartão inválida',
            LOCAL_INVALID_CVC: 'Código CVV/CVC inválido',
            LOCAL_PASSWORDS_NOT_MATCH: 'As senhas não correspondem, por favor, digite novamente',
            LOCAL_NO_TRADING_ACCOUNTS: 'Você não tem contas de negociação disponíveis. Por favor, entre em contato com o suporte',
            LOCAL_MIN_DEPOSIT: 'O depósito mínimo é de $',
            LOCAL_MIN_WITHDRAWAL: 'O valor mínimo de saque é $',
            LOCAL_INVALID_FILE_TYPE: 'Formato de arquivo não suportado. Favor anexar somente JPG, PNG ou PDF',
            LOCAL_MIN_STAKING: 'O depósito mínimo para essa moeda é ',
            FLOOD_REJECTED: 'Você está enviando mensagens com muita frequência. Por favor, tente novamente mais tarde'
        },
        countries: {
            AF: 'Afeganistão',
            AX: 'Ilhas Aland',
            AL: 'Albânia',
            DZ: 'Argélia',
            AS: 'Samoa Americana',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antártica',
            AG: 'Antígua e Barbuda',
            AR: 'Argentina',
            AM: 'Armênia',
            AW: 'Aruba',
            AU: 'Austrália',
            AT: 'Áustria',
            AZ: 'Azerbaijão',
            BS: 'Bahamas',
            BH: 'Bahrein',
            BD: 'Bangladesh',
            BB: 'Barbados',
            BY: 'Belarus',
            BE: 'Bélgica',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Butão',
            BO: 'Bolívia',
            BA: 'Bósnia e Herzegovina',
            BW: 'Botsuana',
            BV: 'Ilha Bouvet',
            BR: 'Brasil',
            IO: 'Território Britânico do Oceano Índico',
            BN: 'Brunei Darussalam',
            BG: 'Bulgária',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            KH: 'Camboja',
            CM: 'Camarões',
            CA: 'Canadá',
            CV: 'Cabo Verde',
            KY: 'Ilhas Cayman',
            CF: 'República Centro-Africana',
            TD: `Chade`,
            CL: 'Chile',
            CN: 'China',
            CX: 'Ilha Christmas',
            CC: 'Ilhas Cocos (Keeling)',
            CO: 'Colômbia',
            KM: 'Comoros',
            CG: 'Congo',
            CD: 'Congo, República Democrática',
            CK: 'Ilhas Cook',
            CR: 'Costa Rica',
            CI: `Cote D'Ivoire`,
            HR: 'Croácia',
            CU: 'Cuba',
            CY: 'Chipre',
            CZ: 'República Tcheca',
            DK: 'Dinamarca',
            DJ: 'Djibuti',
            DM: 'Dominica',
            DO: 'República Dominicana',
            EC: 'Equador',
            EG: 'Egito',
            SV: 'El Salvador',
            GQ: 'Guiné Equatorial',
            ER: 'Eritreia',
            EE: 'Estônia',
            ET: 'Etiópia',
            FK: 'Ilhas Falkland (Malvinas)',
            FO: 'Ilhas Faroe',
            FJ: 'Fiji',
            FI: 'Finlândia',
            FR: 'França',
            GF: 'Guiana Francesa',
            PF: 'Polinésia Francesa',
            TF: 'Territórios Franceses do Sul',
            GA: 'Gabão',
            GM: `Gâmbia`,
            GE: 'Geórgia',
            DE: 'Alemanha',
            GH: 'Gana',
            GI: 'Gibraltar',
            GR: 'Grécia',
            GL: 'Groenlândia',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guiné',
            GW: 'Guiné-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Ilha Heard e Ilhas Mcdonald',
            VA: 'Santa Sé (Estado da Cidade do Vaticano)',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Hungria',
            IS: 'Islândia',
            IN: 'Índia',
            ID: 'Indonésia',
            IR: 'Irã',
            IQ: 'Iraque',
            IE: 'Irlanda',
            IM: 'Ilha de Man',
            IL: 'Israel',
            IT: 'Itália',
            JM: 'Jamaica',
            JP: 'Japão',
            JE: 'Jersey',
            JO: 'Jordânia',
            KZ: 'Cazaquistão',
            KE: 'Quênia',
            KI: 'Kiribati',
            KR: 'Coreia',
            KW: 'Kuwait',
            KG: 'Quirguistão',
            LA: 'República Democrática Popular do Laos',
            LV: 'Letônia',
            LB: 'Líbano',
            LS: 'Lesotho',
            LR: 'Libéria',
            LY: 'Jamahiriya Árabe Líbia',
            LI: 'Liechtenstein',
            LT: 'Lituânia',
            LU: 'Luxemburgo',
            MO: 'Macau',
            MK: 'Macedônia',
            MG: 'Madagascar',
            MW: 'Malawi',
            MY: 'Malásia',
            MV: 'Maldivas',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Ilhas Marshall',
            MQ: 'Martinica',
            MR: 'Mauritânia',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'México',
            FM: 'Micronésia',
            MD: 'Moldova',
            MC: 'Mônaco',
            MN: 'Mongólia',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Marrocos',
            MZ: 'Moçambique',
            MM: 'Myanmar',
            NA: 'Namíbia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Países Baixos',
            AN: 'Antilhas Holandesas',
            NC: 'Nova Caledônia',
            NZ: 'Nova Zelândia',
            NI: 'Nicarágua',
            NE: 'Níger',
            NG: 'Nigéria',
            NU: 'Niue',
            NF: 'Ilha Norfolk',
            MP: 'Ilhas Marianas do Norte',
            NO: 'Noruega',
            OM: 'Omã',
            PK: 'Paquistão',
            PW: 'Palau',
            PS: 'Território Palestino, Ocupado',
            PA: 'Panamá',
            PG: 'Papua Nova Guiné',
            PY: 'Paraguai',
            PE: 'Peru',
            PH: 'Filipinas',
            PN: 'Pitcairn',
            PL: 'Polônia',
            PT: 'Portugal',
            PR: 'Porto Rico',
            QA: 'Qatar',
            RE: 'Reunião',
            RO: 'Romênia',
            RU: 'Rússia',
            RW: 'Ruanda',
            BL: 'São Bartolomeu',
            SH: 'Santa Helena',
            KN: 'São Cristóvão e Névis',
            LC: 'Santa Lúcia',
            MF: 'Saint Martin',
            PM: 'São Pedro e Miquelon',
            VC: 'São Vicente e Granadinas',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'São Tomé e Príncipe',
            SA: 'Arábia Saudita',
            SN: 'Senegal',
            RS: 'Sérvia',
            SC: 'Seychelles',
            SL: 'Serra Leoa',
            SG: 'Cingapura',
            SK: 'Eslováquia',
            SI: 'Eslovênia',
            SB: 'Ilhas Salomão',
            SO: 'Somália',
            ZA: 'África do Sul',
            GS: 'Ilhas Geórgia do Sul e Sandwich',
            ES: 'Espanha',
            LK: 'Sri Lanka',
            SD: 'Sudão',
            SR: 'Suriname',
            SJ: 'Svalbard e Jan Mayen',
            SZ: 'Suazilândia',
            SE: 'Suécia',
            CH: 'Suíça',
            SY: 'República Árabe da Síria',
            TW: 'Taiwan',
            TJ: 'Tajiquistão',
            TZ: 'Tanzânia',
            TH: 'Tailândia',
            TL: 'Timor-Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad e Tobago',
            TN: 'Tunísia',
            TR: 'Turquia',
            TM: 'Turcomenistão',
            TC: 'Ilhas Turcas e Caicos',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ucrânia',
            AE: 'Emirados Árabes Unidos',
            GB: 'Reino Unido',
            US: 'Estados Unidos',
            UM: 'Ilhas Distantes dos Estados Unidos',
            UY: 'Uruguai',
            UZ: 'Uzbequistão',
            VU: 'Vanuatu',
            VE: 'Venezuela',
            VN: 'Vietnã',
            VG: 'Ilhas Virgens Britânicas',
            VI: 'Ilhas Virgens Americanas',
            WF: 'Wallis e Futuna',
            EH: 'Saara Ocidental',
            YE: 'Iêmen',
            ZM: 'Zâmbia',
            ZW: 'Zimbábue'
        },
    },

}

export default langs
