import {pspConfig} from './psp-config-list'

let config = window.app.config

if (window.app.config.NODE_ENV === 'development') {
    config = process.env
}

export const BRAND = config.VUE_APP_BRAND
export const SOCKET_ENDPOINT = config.VUE_APP_SOCKET_ENDPOINT
export const REST_ENDPOINT = config.VUE_APP_REST_ENDPOINT
export const WEBSITE = config.VUE_APP_WEBSITE || 'https://' + window.location.host.split('.').slice(1).join('.')
export const LOGO_REDIRECT_OFF = config.VUE_APP_LOGO_REDIRECT_OFF
export const CDN_ENABLED = config.VUE_APP_CDN

export let STATIC_BASE = config.VUE_APP_STATIC_BASE
export const COMPANY_NAME = config.VUE_APP_COMPANY_NAME
export const SUPPORT_EMAIL = config.VUE_APP_SUPPORT_EMAIL
export const SYMBOLS_ACTIVE = JSON.parse(config.VUE_APP_SYMBOLS_ACTIVE)
export const LOGO = JSON.parse(config.VUE_APP_LOGO)
export const FAVICON = config.VUE_APP_FAVICON
export const LANGUAGES = JSON.parse(config.VUE_APP_LANGUAGES)
export const VARIABLES = JSON.parse(config.VUE_APP_VARIABLES).length > 0 ? JSON.parse(config.VUE_APP_VARIABLES) : ['BT_ACCOUNT', 'BT_BANK_NAME', 'BT_BENEFICIARY', 'BT_ADDRESS', 'BT_SWIFT', 'BT_IBAN', 'PENDING_DEPOSITS_LIMIT','BT_INN','BT_KPP','BT_CORRESPONDING','AUTO_LOGOUT','CFD_DISABLE_EDIT_USER_INFO']
export const LANG_DEFAULT = config.VUE_APP_LANG_DEFAULT
export const DISABLE_REGISTRATION = config.VUE_APP_DISABLE_REGISTRATION || 0
export const DROP_SESSION = config.VUE_APP_DROP_SESSION || 0
export const MINIMUM_DEPOSIT = config.VUE_APP_MINIMUM_DEPOSIT || 250
export const MAXIMUM_DEPOSIT = config.VUE_APP_MAXIMUM_DEPOSIT || 1000000
export const MINIMUM_WITHDRAWAL = config.VUE_APP_MINIMUM_WITHDRAWAL || 0
export const CHART_FULL_TIMEFRAMES = ['1', '5', '15', '30', '60', '240', '1440', '1W', '1M']
export const VOLUME_TYPES = config.VUE_APP_VOLUME_TYPES ? JSON.parse(config.VUE_APP_VOLUME_TYPES) : ['lots', 'units', 'currency']
export const MULTIGRID = config.VUE_APP_MULTIGRID
export const KYC_DOCUMENTS = JSON.parse(config.VUE_APP_KYC_DOCUMENTS)
export const KYC_DOCUMENTS_ICONS = config.VUE_APP_KYC_DOCUMENTS_ICONS ? JSON.parse(config.VUE_APP_KYC_DOCUMENTS_ICONS) : ['prof', 'home', 'cardFront', 'cardBack', 'file', 'selfile']
export const APPS = config.VUE_APP_APPS ? JSON.parse(config.VUE_APP_APPS) : []

export const PSP_CONFIG = pspConfig() || {}

export let DEPOSIT_BUTTONS = {
    USD: [10, 50, 100, 250, 500, 1000, 2000, 3000, 5000, 10000],
    EUR: [10, 50, 100, 250, 500, 1000, 2000, 3000, 5000, 10000],
    GBP: [10, 50, 100, 250, 500, 1000, 2000, 3000, 5000, 10000],
    RUB: [1500, 5000, 10000, 19000, 25000, 30000, 45000, 60000]
}

export const COLOR_BG_FOOTER = config.VUE_APP_COLOR_BG_FOOTER || '#18202d' // footer
export const COLOR_BG_DARK = config.VUE_APP_COLOR_BG_DARK || '#181f2d' // MAIN BACKGROUND EVERYWHERE
export const COLOR_BG_DARK_MID = config.VUE_APP_COLOR_BG_DARK_MID || '#252c3b' // account modal, left part bg (+ most pads in dashboard)
export const COLOR_BG = config.VUE_APP_COLOR_BG || '#374054' // text input (+toast)
export const COLOR_BG_LIGHT = config.VUE_APP_COLOR_BG_LIGHT || '#363f52' // bg of left menu and ordermenu inputs
export const COLOR_WHITE = config.VUE_APP_COLOR_WHITE || '#ffffff'
export const COLOR_FONT = config.VUE_APP_COLOR_FONT || '#8c8f96'
export const COLOR_BRAND = config.VUE_APP_COLOR_BRAND || '#1d98f9'
export const COLOR_GREEN = config.VUE_APP_COLOR_GREEN || '#2bab40'
export const COLOR_GREEN_BUTTON = config.VUE_APP_COLOR_GREEN_BUTTON || '#2bab40'
export const COLOR_RED = config.VUE_APP_COLOR_RED || '#f15350'
export const COLOR_PRIMARY_BUTTON = config.VUE_APP_COLOR_PRIMARY_BUTTON || '#1d98f9'
export const COLOR_SECONDARY_BUTTON = config.VUE_APP_COLOR_SECONDARY_BUTTON || '#424a5e'
export const COLOR_BORDER = config.VUE_APP_COLOR_BORDER || '#353c4e'
export const TRADINGVIEW_BG = config.VUE_APP_TRADINGVIEW_BG || '#181f2d'
export const TRADINGVIEW_THEME = config.VUE_APP_TRADINGVIEW_THEME || 'Dark'
export const TRADINGVIEW_LINE = config.VUE_APP_TRADINGVIEW_LINE || 'rgba(255,255,255,.1)'
export const COLOR_MARKET_WATCH_TITLE = config.VUE_APP_COLOR_MARKET_WATCH_TITLE || '#374054' // bg by default
export const COLOR_BG_ASSETS_INFO = config.VUE_APP_COLOR_BG_ASSETS_INFO || 'linear-gradient(0deg, rgba(24, 31, 45, 1) 0%, rgba(24, 31, 45, 1) 45%, rgba(24, 31, 45, 0.45) 100%)'
export const COLOR_BG_LOGIN = config.VUE_APP_COLOR_BG_LOGIN || 'linear-gradient(151.15deg, rgba(155, 162, 187, 0.85) 1.95%, rgba(57, 57, 57, 0.85) 82.08%)'
export const COLOR_BG_LOGIN_BTN = config.VUE_APP_COLOR_BG_LOGIN_BTN || '#247243'

export const NEW_DESIGN = config.VUE_APP_NEW_DESIGN || false
export const PERSONAL_BTNS = config.VUE_APP_PERSONAL_BTNS || false
export const CLOSE_WITHDRAWAL = config.VUE_APP_CLOSE_WITHDRAWAL || 0

export const ROLE = config.VUE_APP_ROLE || 0
export const HIDE_FORGOT_PASSWORD = config.VUE_APP_HIDE_FORGOT_PASSWORD || 0

export const SHOW_LEVERAGE = config.VUE_APP_SHOW_LEVERAGE || 1
export const SCHEDULE = config.VUE_APP_SUPPORT_SCHEDULE || ''

export const PLATES_FOLDER = config.VUE_APP_PLATES_FOLDER || 'plates'

export const MARKET_WATCH_DEFAULT_CAT = config.VUE_APP_MARKET_WATCH_DEFAULT_CAT || 'forex'

export const NEWS_CATEGORIES = config.VUE_APP_NEWS_CATEGORIES ? JSON.parse(config.VUE_APP_NEWS_CATEGORIES) : 'all' // env is an array: ['crypto', 'nft', ...], only crypto for now

export const WITHDRAWAL_OPTIONS = config.VUE_APP_WITHDRAWAL_OPTIONS ? JSON.parse(config.VUE_APP_WITHDRAWAL_OPTIONS) : {
    "bank": "bank",
    "bitcoin": "bitcoin",
    "card": "card",
    "ethereum": "ethereum",
    "usdt": "usdt",
    "matic": "matic",
    "zelle": "zelle",
}

export const INITIAL_DASHBOARD = config.VUE_APP_INITIAL_DASHBOARD || 0
export const SHOW_REGISTER_MODAL = config.VUE_APP_SHOW_REGISTER_MODAL || 0
export const SHOW_REGISTER_TEXT = config.VUE_APP_SHOW_REGISTER_TEXT || 0
export const DISABLE_ACTIVITY_LOG = config.VUE_APP_DISABLE_ACTIVITY_LOG || false
export const ROBOT_AI = config.VUE_APP_ROBOT_AI || 0
export const ASPIN_DESIGN = config.VUE_APP_ASPIN_DESIGN || ''
export const NEW_KYC_DOCUMENT_TEXT = config.VUE_APP_NEW_KYC_DOCUMENT_TEXT
export const MORE_CARD_INFO = config.VUE_APP_MORE_CARD_INFO
export const QR_TITLE = config.VUE_APP_QR_TITLE
export const QR_BRAND_TITLE = config.VUE_APP_QR_BRAND_TITLE
export const HIDE_LOGO = config.VUE_APP_HIDE_LOGO
export const OTP_CONFIRM = config.VUE_APP_OTP_CONFIRM
export const REMOVE_ECONOMIC_CALENDAR = config.VUE_APP_REMOVE_ECONOMIC_CALENDAR || 0
export const CUSTOM_PSP_NAME = config.VUE_APP_CUSTOM_PSP_NAME ? JSON.parse(config.VUE_APP_CUSTOM_PSP_NAME) : ''
export const WITHDRAWAL_CURRENCY = config.VUE_APP_WITHDRAWAL_CURRENCY ? JSON.parse(config.VUE_APP_WITHDRAWAL_CURRENCY) : ''
export const HIDE_TRADING_BOT = config.VUE_APP_HIDE_TRADING_BOT || 0
export const CUSTOM_TPSL = config.VUE_APP_CUSTOM_TPSL || 0
export const BITCOIN_WITHDRAWAL = config.VUE_APP_BITCOIN_WITHDRAWAL || ''
export const DISABLE_ORDER_SORT = config.VUE_APP_DISABLE_ORDER_SORT || ''
export const HIDE_EMAIL = config.VUE_APP_HIDE_EMAIL || 0
export const HIDE_DOC_UPLOADED_LINE = config.VUE_APP_HIDE_DOC_UPLOADED_LINE || ''
export const WIDGET_LOGO_SHOW = config.VUE_APP_WIDGET_LOGO_SHOW || false
export const WHITE_ASPIN_THEME = config.VUE_APP_WHITE_ASPIN_THEME || ''
export const NEW_CHAT = config.VUE_APP_NEW_CHAT || ''
export const CUSTOM_LOGIN_MODAL = config.VUE_APP_CUSTOM_LOGIN_MODAL || ''
export const TRADERS_PER_DAY = config.VUE_APP_TRADERS_PER_DAY ? JSON.parse(config.VUE_APP_TRADERS_PER_DAY) : ''
export const TRADERS_TITLE = config.VUE_APP_TRADERS_TITLE || ''
export const HIDE_PCI_DSS_CERTIFICATE = config.VUE_APP_HIDE_PCI_DSS_CERTIFICATE || ''
export const HIDE_FAVICON = config.VUE_APP_HIDE_FAVICON || 0
export const STATUSBAR_ITEMS_SHOW_MOBILE = config.VUE_APP_STATUSBAR_ITEMS_SHOW_MOBILE || ''
export const HIDE_TOTAL_DEPOSITS = config.VUE_APP_HIDE_TOTAL_DEPOSITS || ''
export const DISABLE_QUESTIONS = config.VUE_APP_DISABLE_QUESTIONS || false
export const BANK_WITHDRAWAL_FIELDS = config.VUE_APP_BANK_WITHDRAWAL_FIELDS ? typeof config.VUE_APP_BANK_WITHDRAWAL_FIELDS  === 'string' ? JSON.parse(config.VUE_APP_BANK_WITHDRAWAL_FIELDS) : config.VUE_APP_BANK_WITHDRAWAL_FIELDS : ['account','holder','iban','swift','recipientAddress','routingNumber','bankBeneficiary','bankAddress','bankCode','desc'] // if env from cpanel it is a array, if local env it is a string
export const PRIVACY_POLICY_LINK = config.VUE_APP_PRIVACY_POLICY_LINK || ''
export const CUSTOMER_AGREEMENT_LINK = config.VUE_APP_CUSTOMER_AGREEMENT_LINK || ''
export const DARK_THEME = config.VUE_APP_DARK_THEME || ''
export const LIGHT_THEME = config.VUE_APP_LIGHT_THEME || ''
export const NEW_PLATFORM_DESIGN = config.VUE_APP_NEW_PLATFORM_DESIGN || false
export const TRADINGVIEW_DEFAULT_TIMEFRAME = config.VUE_APP_TRADINGVIEW_DEFAULT_TIMEFRAME || false
export const FOOTER_REDIRECT_BUTTON = config.VUE_APP_FOOTER_REDIRECT_BUTTON || false
